import { ClientERPSystem, IClientInfo, SalesforceStage } from './client'
import { IARAPReconcileSummary, IBorrowingBase, OngoingReportingType, TemplateTypes } from './bbc'
import { IFile } from './box'
import { IUser } from './user'
import { IClientEntityRelationship } from './integration'
import { ISalesforceProspectInfo } from './prospects'
import { INote } from './notes'

export interface ISalesforceDueDiligenceInfo {
  id: string
  clientName: string
  clientInfo?: IClientInfo
  closeDate?: string
  age?: number
  boxLink?: string
  salesforceLink?: string
  commitmentAmount?: number
}

export enum DueDiligenceProgressStatus {
  NotStarted = 'Not started',
  InProgress = 'In progress',
  Completed = 'Completed',
  NotApplicable = 'Not applicable',
}

export enum DueDiligenceProgressStep {
  Application = 'Application',
  DiligenceCalls = 'Diligence calls',
  ThirdPartyExam = 'Third party exam',
  CreditApproval = 'Credit approval',
  ContractFinalized = 'Contract finalized',
  CollateralDocs = 'Collateral docs',
  FirstFunding = 'First funding',
}

export interface IDueDiligenceProgress {
  [DueDiligenceProgressStep.Application]: DueDiligenceProgressStatus
  [DueDiligenceProgressStep.DiligenceCalls]: DueDiligenceProgressStatus
  [DueDiligenceProgressStep.ThirdPartyExam]: DueDiligenceProgressStatus
  [DueDiligenceProgressStep.CreditApproval]: DueDiligenceProgressStatus
  [DueDiligenceProgressStep.ContractFinalized]: DueDiligenceProgressStatus
  [DueDiligenceProgressStep.CollateralDocs]: DueDiligenceProgressStatus
  [DueDiligenceProgressStep.FirstFunding]: DueDiligenceProgressStatus
}

export interface IDueDiligenceInfo {
  id: string
  clientName: string
  clientInfo?: IClientInfo
  progress: IDueDiligenceProgress
}

export const DEFAULT_DUE_DILIGENCE_PROGRESS = {
  [DueDiligenceProgressStep.Application]: DueDiligenceProgressStatus.NotStarted,
  [DueDiligenceProgressStep.DiligenceCalls]: DueDiligenceProgressStatus.NotStarted,
  [DueDiligenceProgressStep.ThirdPartyExam]: DueDiligenceProgressStatus.NotStarted,
  [DueDiligenceProgressStep.CreditApproval]: DueDiligenceProgressStatus.NotStarted,
  [DueDiligenceProgressStep.ContractFinalized]: DueDiligenceProgressStatus.NotStarted,
  [DueDiligenceProgressStep.CollateralDocs]: DueDiligenceProgressStatus.NotStarted,
  [DueDiligenceProgressStep.FirstFunding]: DueDiligenceProgressStatus.NotStarted,
}

export interface IDueDiligence extends IClientInfo {
  dueDiligenceClientInfo?: IDueDiligenceCompanyInfo
  ddInfo?: IDueDiligenceInfo
  salesforceDDInfo?: ISalesforceDueDiligenceInfo
  managers?: IUser[]
  salesforceProspectInfo?: ISalesforceProspectInfo
  latestBBC?: IBorrowingBase
  primaryContact?: {
    name: string
    salesforceLink: string
  }
}

interface IDueDiligenceDashboradData extends ISalesforceDueDiligenceInfo {
  riskRating: number
  ebitda: number
  netRevenue: number
  progress: number
  maxFinancialsDate: string
}

export interface IDueDiligenceAggregation {
  data: IDueDiligenceDashboradData[]
  totalItems: number
}

export interface IDueDiligenceStats {
  numTermSheets: number
  numDealsInDiligence: number
  dealsClosed: number
  totalCommitments: number
  dealsClosedYtd: number
  avgDaysInDD: number
  avgDaysInDDLastYear: number
  avgDaysInDDGoal: number
  updatedAt: string
}

export interface IDdProspect extends IClientInfo {
  clientName: string
  closeDate?: string
  age?: number
  boxLink?: string
  lastActivityDate?: string
}

export interface IDdProspectsAggregation {
  data: IDdProspect[]
  totalItems: number
  prospects: IDdProspect[]
  numTermSheets: number
  numDealsInDiligence: number
  dealsClosed: number
  dealsClosedYtd: number
  avgDaysinDD: number
  avgDaysinDDLastYear: number
  avgDaysinDDGoal: number
  updatedAt: Date | string
}

export const DUE_DILIGENCE_DOCUMENTS_TYPES = [
  OngoingReportingType.IncomeStatement,

  OngoingReportingType.BalanceSheet,
  OngoingReportingType.ARGeneralLedger,
  OngoingReportingType.SalesBySKU,
  OngoingReportingType.IncomeStatementProjections,
  OngoingReportingType.BalanceSheetProjections,
  OngoingReportingType.AR,
  OngoingReportingType.AP,
  OngoingReportingType.Inventory,
  OngoingReportingType.BankTransactions,
  OngoingReportingType.CapTable,
]

export enum DueDiligenceReportingStatus {
  NotStarted = 'Not started',
  InProgress = 'In progress',
  Completed = 'Completed',
}

export interface IDueDiligenceReportingFlowData {
  recordDate: string | null
  recordDates?: string[]
  startDate?: string
  endDate?: string
  bankAccount?: string
  bankBalance?: number
  error?: string
  arReconcileSummary?: IARAPReconcileSummary
  apReconcileSummary?: IARAPReconcileSummary
}

export interface IDueDiligenceReportingFlow {
  id: string
  clientName: string
  fileId: string
  file: IFile
  isProcessed: boolean
  isCompleted: boolean
  data: IDueDiligenceReportingFlowData
  createdAt: string
  updatedAt: string
  isParent?: boolean
  dueDiligenceReportingId: string
  dueDiligenceReporting: IDueDiligenceReporting
}

export interface IDueDiligenceReportingFlowData {
  data: IDueDiligenceReportingFlow[]
  dueDiligenceReporting: IDueDiligenceReporting
}

export interface IDueDiligenceReporting {
  id: string
  clientName: string
  type: OngoingReportingType
  status: DueDiligenceReportingStatus
  boxLink: string
  tableauLink: string
  dueDiligenceReportingFlows?: IDueDiligenceReportingFlow[]
  createdAt: string
  updatedAt: string
}

export const DUE_DILIGENCE_DOCUMENTS_TITLE_MAP = {
  [OngoingReportingType.IncomeStatement]: 'Historical P&L',
  [OngoingReportingType.BalanceSheet]: 'Historical Balance Sheet',
  [OngoingReportingType.ARGeneralLedger]: 'General Ledger',
  [OngoingReportingType.SalesBySKU]: 'Sales By SKU',
  [OngoingReportingType.IncomeStatementProjections]: 'Projected P&L',
  [OngoingReportingType.BalanceSheetProjections]: 'Projected Balance Sheet',
  [OngoingReportingType.AR]: 'AR Aging Detail Report',
  [OngoingReportingType.AP]: 'AP Aging Details Reports',
  [OngoingReportingType.Inventory]: 'Detailed Inventory Perpetual Report',
  [OngoingReportingType.BankTransactions]: 'Bank Statement',
  [OngoingReportingType.CapTable]: 'Detailed Cap Table',
}

export const DUE_DILIGENCE_DOCUMENTS_FOLDERS_MAP = {
  [OngoingReportingType.IncomeStatement]: '04. Financials/a. Historical',
  [OngoingReportingType.BalanceSheet]: '04. Financials/a. Historical',
  [OngoingReportingType.ARGeneralLedger]: '08. Other Reports/General Ledger',
  [OngoingReportingType.SalesBySKU]: '08. Other Reports/Sales by SKU',
  [OngoingReportingType.IncomeStatementProjections]: '04. Financials/b. Forecasted',
  [OngoingReportingType.BalanceSheetProjections]: '04. Financials/b. Forecasted',
  [OngoingReportingType.AR]: '05. AR & AP/a. Receivables',
  [OngoingReportingType.AP]: '05. AR & AP/b. Payables',
  [OngoingReportingType.Inventory]: '06. Inventory/a. Inventory Report',
  [OngoingReportingType.BankTransactions]: '04. Financials/c. Bank Statements',
  [OngoingReportingType.CapTable]: '02. General/b. Cap Table',
}

export const DUE_DILIGENCE_DOCUMENTS_DEFAULT_FOLDER = '08. Other Reports'

export const DUE_DILIGENCE_ROUTES_MAP = {
  [OngoingReportingType.AR]: 'receivables',
  [OngoingReportingType.AP]: 'payables',
  [OngoingReportingType.IncomeStatement]: 'income-statement',
  [OngoingReportingType.BalanceSheet]: 'balance-sheet',
  [OngoingReportingType.IncomeStatementProjections]: 'income-statement-projections',
  [OngoingReportingType.BalanceSheetProjections]: 'balance-sheet-projections',
  [OngoingReportingType.BankTransactions]: 'bank-transactions',
  [OngoingReportingType.ARGeneralLedger]: 'ar-general-ledger',
  [OngoingReportingType.SalesBySKU]: 'sales-by-sku',
  [OngoingReportingType.Inventory]: 'inventory',
  [OngoingReportingType.CapTable]: 'cap-table',
}

export const DUE_DILIGENCE_DOCUMENTS_LIST_BREADCRUMBS_MAP = {
  [OngoingReportingType.AR]: 'AR Reports',
  [OngoingReportingType.AP]: 'AP Reports',
  [OngoingReportingType.IncomeStatement]: 'Historical P&L',
  [OngoingReportingType.BalanceSheet]: 'Historical Balance Sheet',
  [OngoingReportingType.IncomeStatementProjections]: 'Projected P&L',
  [OngoingReportingType.BalanceSheetProjections]: 'Projected Balance Sheet',
  [OngoingReportingType.BankTransactions]: 'Bank Statement',
  [OngoingReportingType.ARGeneralLedger]: 'General Ledger',
  [OngoingReportingType.SalesBySKU]: 'Sales By SKU',
  [OngoingReportingType.Inventory]: 'Inventory Reports',
  [OngoingReportingType.CapTable]: 'Cap Table',
}

export const DUE_DILIGENCE_DOCUMENTS_LIST_TITLE_MAP = {
  [OngoingReportingType.AR]: 'AR Aging',
  [OngoingReportingType.AP]: 'AP Aging',
  [OngoingReportingType.IncomeStatement]: 'Historical P&L',
  [OngoingReportingType.BalanceSheet]: 'Historical Balance Sheet',
  [OngoingReportingType.IncomeStatementProjections]: 'Projected P&L',
  [OngoingReportingType.BalanceSheetProjections]: 'Projected Balance Sheet',
  [OngoingReportingType.BankTransactions]: 'Bank Statement(s)',
  [OngoingReportingType.ARGeneralLedger]: 'General Ledger',
  [OngoingReportingType.SalesBySKU]: 'Sales by SKU',
  [OngoingReportingType.Inventory]: 'Inventory',
  [OngoingReportingType.CapTable]: 'Cap Table',
}

export const DUE_DILIGENCE_TEMPLATES_MAP = {
  [OngoingReportingType.IncomeStatement]: TemplateTypes.IncomeStatement,
  [OngoingReportingType.BalanceSheet]: TemplateTypes.BalanceSheet,
  [OngoingReportingType.ARGeneralLedger]: TemplateTypes.ARGeneralLedger,
  [OngoingReportingType.SalesBySKU]: TemplateTypes.SalesBySKU,
  [OngoingReportingType.IncomeStatementProjections]: TemplateTypes.IncomeStatement,
  [OngoingReportingType.BalanceSheetProjections]: TemplateTypes.BalanceSheet,
  [OngoingReportingType.AR]: TemplateTypes.ARDetail,
  [OngoingReportingType.AP]: TemplateTypes.APDetail,
  [OngoingReportingType.Inventory]: TemplateTypes.InventoryDetail,
  [OngoingReportingType.BankTransactions]: '',
}

export enum DueDiligenceDocumentRequestStatus {
  NotStarted = 'Not started',
  InReview = 'In Review',
  ActionRequired = 'Action Required',
  Completed = 'Completed',
  Waived = 'Waived',
}

export enum DueDiligenceDocumentRequestStep {
  CompanyBackground = 'Company Background',
  Team = 'The Team',
  Financials = 'Financials & Collateral',
}

export enum DueDiligenceDocumentRequestTypes {
  CapTable = 'CapTable',
  OrgChart = 'OrgChart',
  DriversLicenses = 'DriversLicenses',
  InvestorMarketingMaterials = 'InvestorMarketingMaterials',
  ArticlesOfIncorporation = 'ArticlesOfIncorporation',
  ShareholderAgreement = 'ShareholderAgreement',
  InsuranceSchedules = 'InsuranceSchedules',
  Contracts = 'Contracts',
  LeaseSchedules = 'LeaseSchedules',
  LoanDocumentation = 'LoanDocumentation',
  LicensingAgreements = 'LicensingAgreements',
  BalanceSheet = 'BalanceSheet',
  BalanceSheetProjections = 'BalanceSheetProjections',
  BankTransactions = 'BankTransactions',
  TrialBalance = 'TrialBalance',
  KeyWholesale = 'KeyWholesale',
  SalesByCustomer = 'SalesByCustomer',
  AR = 'ARAging',
  AP = 'APAging',
  Inventory = 'InventoryDetail',
  SalesBySKU = 'SalesBySKU',
  CorporateTaxReturns = 'CorporateTaxReturns',
  PayrollTaxReturns = 'PayrollTaxReturns',
  TaxGuardForm = 'TaxGuardForm',
  TaxPaymentsSchedule = 'TaxPaymentsSchedule',
  CurrentOpenOrders = 'CurrentOpenOrders',
  ARGeneralLedger = 'ARGeneralLedger',
  BankTransactionsReconciliations = 'BankTransactionsReconciliations',
  CancelledChecks = 'CancelledChecks',
  CashReceiptsJournal = 'CashReceiptsJournal',
  CashDisbursementsJournal = 'CashDisbursementsJournal',
  SalesJournal = 'SalesJournal',
  CreditMemoJournal = 'CreditMemoJournal',
  DebitMemoJournal = 'DebitMemoJournal',
  PurchasesJournal = 'PurchasesJournal',
  InventoryRollforwardAnalysis = 'InventoryRollforwardAnalysis',
  PurchasesByVendor = 'PurchasesByVendor',
  LatestBorrowingBaseCertificate = 'LatestBorrowingBaseCertificate',
  HeldChecks = 'HeldChecks',
  CashApplicationSample = 'CashApplicationSample',
  CashDisbursementSample = 'CashDisbursementSample',
  BillingShippingSample = 'BillingShippingSample',
  CreditMemoSample = 'CreditMemoSample',
  ARVerificationSample = 'ARVerificationSample',
  InventoryCountSample = 'InventoryCountSample',
  VendorCostSample = 'VendorCostSample',
  GrossProfitSample = 'GrossProfitSample',
}

export interface IDueDiligenceDocumentRequestType {
  id: string
  type: DueDiligenceDocumentRequestTypes
  name: string
  step: DueDiligenceDocumentRequestStep
  subStep: string
  helperText: string | null
  fileType: string | null
  isDefault: boolean
  isPriority: boolean
  folderPath?: string
}

export interface IDueDiligenceDocumentRequestComment {
  id: string
  comment: string
  userId: string
  user?: IUser
  createdAt: string
  updatedAt: string
}

export interface IDueDiligenceDocumentRequest {
  id: string
  clientName: string
  type: IDueDiligenceDocumentRequestType
  status: DueDiligenceDocumentRequestStatus
  previousStatus: DueDiligenceDocumentRequestStatus | null
  boxLink: string
  files: IFile[]
  comments: IDueDiligenceDocumentRequestComment[]
  createdAt: string
  updatedAt: string
}

export interface IDueDiligenceCompanyInfo {
  id: string
  clientName: string
  companyBackgroundProgress: number
  teamProgress: number
  financialCollateralProgress: number
  fullLegalName: string | null
  employeeCount: number | null
  executivesCount: number | null
  boardMembersCount: number | null
  billingPostalCode: string | null
  billingState: string | null
  billingCity: string | null
  billingCountry: string | null
  billingStreet: string | null
  establishedDate: string | null
  phone: string | null
  stateOfFormation: string | null
  federalTaxId: string | null
  isWithAllTaxes: boolean
  isWithAllTaxesComment: string | null
  isPendingJudgement: boolean
  isPendingJudgementComment: string | null
  isAssetsPledged: boolean
  isAssetsPledgedComment: string | null
  erpSystem: ClientERPSystem[]
  erpSystemOther: string | null
  hasContacts: boolean
  isAllContactsValid: boolean
  hasExecutives: boolean
  isAllExecutivesValid: boolean
  hasBoardMembers: boolean
  isAllBoardMembersValid: boolean
  hasReferences: boolean
  isAllReferencesValid: boolean
  hasInventoryLocations: boolean
  isAllInventoryLocationsValid: boolean
  hasBankAccounts: boolean
  clientEntityRelationship?: IClientEntityRelationship[]
  isOutsourcedCFO: boolean
}

export enum DueDiligenceTableItemType {
  Contact = 'Contact',
  Executive = 'Executive',
  BoardMember = 'Board member',
  Reference = 'Reference',
  InventoryLocation = 'Inventory location',
  BankAccount = 'Bank Account',
}

export interface IDueDiligenceContact {
  id: string
  clientName: string
  firstName: string
  lastName: string
  email: string
  phone: string
  isResponsibleRequestingFunding: boolean
  isAuthorizedSignature: boolean
}

export interface IDueDiligenceExecutive {
  id: string
  clientName: string
  firstName: string
  lastName: string
  title: string
  email: string
  phone: string
  socialSecurityNumber: string
  birthday: string
  postalCode: string | null
  state: string | null
  city: string | null
  country: string | null
  street: string | null
  isConvictedFelony: boolean
  isConvictedFelonyComment: string | null
  isDefaultedFinancialObligation: boolean
  isDefaultedFinancialObligationComment: string | null
  isPersonalBankruptcy: boolean
  isPersonalBankruptcyComment: string | null
}

export interface IDueDiligenceBoardMember {
  id: string
  email: string
  clientName: string
  firstName: string
  lastName: string
  company: string
  relationshipToCompany: string
}

export interface IDueDiligenceReference {
  id: string
  clientName: string
  firstName: string
  lastName: string
  email: string
  phone: string
  businessName: string
  relationshipToCompany: string
}

export interface IDueDiligenceInventoryLocation {
  id: string
  clientName: string
  name: string
  type: string
  landlord: string
  postalCode: string | null
  state: string | null
  city: string | null
  country: string | null
  street: string | null
  finishedGoodsAmount: number
  totalInventoryAmount: number
}

export interface IDueDiligenceBankAccount {
  id: string
  clientName: string
  bankName: string
  accountHolderName: string
  bankAccountNumber: string
  abaRoutingNumber: string
  purpose: string
}

export type IDueDiligenceTableItem =
  | IDueDiligenceContact
  | IDueDiligenceExecutive
  | IDueDiligenceBoardMember
  | IDueDiligenceReference
  | IDueDiligenceInventoryLocation
  | IDueDiligenceBankAccount

export const DUE_DILIGENCE_DOCUMENT_REQUEST_STATUS_OPTIONS = [
  {
    value: DueDiligenceDocumentRequestStatus.NotStarted,
    label: 'Not Started',
  },
  {
    value: DueDiligenceDocumentRequestStatus.InReview,
    label: 'In Review',
  },
  {
    value: DueDiligenceDocumentRequestStatus.ActionRequired,
    label: 'Action Required',
  },
  {
    value: DueDiligenceDocumentRequestStatus.Completed,
    label: 'Completed',
  },
  {
    value: DueDiligenceDocumentRequestStatus.Waived,
    label: 'Waived',
  },
]

export const DUE_DILIGENCE_STEP_STATUS_OPTIONS = [
  {
    value: DueDiligenceProgressStatus.NotStarted,
    label: 'Not Started',
  },
  {
    value: DueDiligenceProgressStatus.InProgress,
    label: 'In Progress',
  },
  {
    value: DueDiligenceProgressStatus.Completed,
    label: 'Completed',
  },
  {
    value: DueDiligenceProgressStatus.NotApplicable,
    label: 'Not Applicable',
  },
]

export const OPPORTUNITY_SALESFORCE_ARCHIVE_REASONS = [
  {
    value: SalesforceStage.Passed,
    label: 'Passed',
  },
  {
    value: SalesforceStage.ClosedLost,
    label: 'Closed lost',
  },
]

export const DUE_DILIGENCE_DOCUMENTS_TYPES_WITHOUT_UPLOAD = [
  DueDiligenceDocumentRequestTypes.BankTransactions,
  DueDiligenceDocumentRequestTypes.KeyWholesale,
]

export const DUE_DILIGENCE_DOCUMENTS_TYPES_WITH_TEMPLATES = [
  DueDiligenceDocumentRequestTypes.AR,
  DueDiligenceDocumentRequestTypes.AP,
  DueDiligenceDocumentRequestTypes.Inventory,
  DueDiligenceDocumentRequestTypes.SalesBySKU,
]

export const AUTO_REPORTING_DOCUMENTS = [
  DueDiligenceDocumentRequestTypes.AR,
  DueDiligenceDocumentRequestTypes.AP,
  DueDiligenceDocumentRequestTypes.BalanceSheet,
]

export const DUE_DILIGENCE_DOCUMENTS_TYPES_SAMPLES = [
  DueDiligenceDocumentRequestTypes.CashApplicationSample,
  DueDiligenceDocumentRequestTypes.CashDisbursementSample,
  DueDiligenceDocumentRequestTypes.BillingShippingSample,
  DueDiligenceDocumentRequestTypes.CreditMemoSample,
  DueDiligenceDocumentRequestTypes.ARVerificationSample,
  DueDiligenceDocumentRequestTypes.InventoryCountSample,
  DueDiligenceDocumentRequestTypes.VendorCostSample,
  DueDiligenceDocumentRequestTypes.GrossProfitSample,
]

export const DUE_DILIGENCE_TABLE_ITEM_REQUIRED_FIELDS = {
  [DueDiligenceTableItemType.Contact]: ['firstName', 'lastName', 'phone', 'email'],
  [DueDiligenceTableItemType.Executive]: [
    'firstName',
    'lastName',
    'phone',
    'email',
    'title',
    'socialSecurityNumber',
    'birthday',
    'street',
    'city',
    'state',
    'postalCode',
  ],
  [DueDiligenceTableItemType.BoardMember]: [
    'firstName',
    'lastName',
    'email',
    'company',
    'relationshipToCompany',
  ],
  [DueDiligenceTableItemType.Reference]: [
    'firstName',
    'lastName',
    'phone',
    'email',
    'businessName',
    'relationshipToCompany',
  ],
  [DueDiligenceTableItemType.InventoryLocation]: [
    'name',
    'type',
    'landlord',
    'finishedGoodsAmount',
    'totalInventoryAmount',
  ],
}

export const DUE_DILIGENCE_TABLE_ITEM_FIELD_LABELS = {
  firstName: 'First name',
  lastName: 'Last name',
  title: 'Title',
  email: 'Email',
  phone: 'Phone number',
  socialSecurityNumber: 'Social security number',
  birthday: 'Date of birth',
  street: 'Street',
  city: 'City',
  state: 'State',
  postalCode: 'Zip',
  company: 'Company',
  businessName: 'Business name',
  relationshipToCompany: 'Relationship to company',
}

export const DUE_DILIGENCE_STATUS_SORT_ORDER = {
  [DueDiligenceDocumentRequestStatus.ActionRequired]: 1,
  [DueDiligenceDocumentRequestStatus.InReview]: 2,
  [DueDiligenceDocumentRequestStatus.NotStarted]: 3,
  [DueDiligenceDocumentRequestStatus.Completed]: 4,
  [DueDiligenceDocumentRequestStatus.Waived]: 5,
}

export const DUE_DILIGENCE_DOCUMENTS_WITH_AUTO_REFRESH = [
  OngoingReportingType.AR,
  OngoingReportingType.AP,
  OngoingReportingType.IncomeStatement,
  OngoingReportingType.BalanceSheet,
  OngoingReportingType.ARGeneralLedger,
]

export enum DueDiligenceHeaderMenuPages {
  LCR = 'LCR',
  DiligenceProfile = 'DiligenceProfile',
  ProcessDocuments = 'ProcessDocuments',
}

export interface ILCRCommentary {
  id: string
  clientName: string
  clientInfo?: IClientInfo
  section: string
  field: string
  helperText: string | null
  createdAt: string
  updatedAt: string
  note?: INote
}

export interface ILCRCommentarySection {
  section: string
  data: ILCRCommentary[]
}

export interface IHistoricalLineTurn {
  recordDate: string
  collections: number
  estimatedDraw: number
  lineTurn: number
}

export interface IHistoricalLineTurnTotals {
  minLineTurn: number
  maxLineTurn: number
  avgLineTurn: number
}

export interface IHistoricalLineTurnData {
  data: IHistoricalLineTurn[]
  totals: IHistoricalLineTurnTotals
}

export interface IHistoricalLineTurnOptionsData {
  minDate: string
  maxDate: string
}
