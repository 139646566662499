import React, { useCallback, useState, useEffect, useMemo } from 'react'
import Box from '@mui/material/Box'
import Popover from '@mui/material/Popover'
import MenuItem from '@mui/material/MenuItem'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Form } from 'react-final-form'
import cn from 'classnames'

import styles from './../FlagContainer.module.scss'

import Flag from './../Flag'
import { BBCLogsItemLoader } from '../../BBCLogsContainer/BBCLogsItem'
import { IFlags, IFlagData, FlagTypes, IFlagsOptions } from '@common/interfaces/bbc'
import Card from '../../Common/Card/Card'
import { FLAGS_LIST_FILTERS_CONFIG, FLAGS_SORT_OPTIONS, PER_PAGE } from '@common/constants/filters'
import FlagDetailSidePanel from './../FlagDetailSidePanel'
import {
  buildFiltersDefaults,
  buildFiltersValidateSchema,
  updateDateFilters,
} from '../../../helpers/filters'
import { ReactComponent as EmptyIcon } from '@assets/images/no-notes-icon.svg'
import { ReactComponent as SortIcon } from '@assets/images/sort-icon.svg'
import FilterContainer from '../../Filters/FilterContainer/FilterContainer'
import { IClientInfo } from '@common/interfaces/client'
import { ILoadingData } from '../../../redux/types'
import { IUser, UserRole } from '@common/interfaces/user'
import useTable from '../../../hooks/useTable'

const filtersDefaults = buildFiltersDefaults(FLAGS_LIST_FILTERS_CONFIG, {
  resolved: 'false',
})
const filtersValidate = buildFiltersValidateSchema(FLAGS_LIST_FILTERS_CONFIG)

interface IProps {
  flags: IFlagData
  listFlags: (params: object) => void
  isLoading: boolean
  listClients: (params: object) => void
  clients: ILoadingData<{ data: IClientInfo[] }>
  listEntityInfo: (data: object) => Promise<{ data: any }>
  user: IUser
  flagOptions: ILoadingData<IFlagsOptions>
  getFlagsOptions: (params: object) => void
}

const FullPageFlags = ({
  flags,
  listFlags,
  isLoading,
  listClients,
  clients,
  listEntityInfo,
  user,
  flagOptions,
  getFlagsOptions,
}: IProps) => {
  const [expandedFlag, setExpandedFlag] = useState<IFlags>(null)
  const [isExpandNewFlagModalOpen, setIsExpandNewFlagModalOpen] = useState(false)
  const isAdmin = useMemo(() => user?.role === UserRole.ADMIN, [user])

  const [anchorEl, setAnchorEl] = useState(null)
  const isSortOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'fullPageFlags',
    filtersDefaults,
    sortDefault: {
      field: 'record_date',
      direction: 'DESC',
    },
  })

  const loadMappings = useCallback(
    async (inputValue: string) => {
      const res = await listEntityInfo({
        name: inputValue,
      })
      return res.data.map((mapping: any) => ({
        id: mapping.id,
        value: mapping.name,
        label: mapping.name,
      }))
    },
    [listEntityInfo],
  )

  const { currentFlags, itemsCount, totalItems } = useMemo(
    () => ({
      currentFlags: flags?.data,
      itemsCount: flags?.data?.length ?? 0,
      totalItems: flags?.totalItems ?? 0,
    }),
    [flags],
  )

  useEffect(() => {
    listClients({})
  }, [listClients])

  const { clientsData } = useMemo(
    () => ({
      clientsData: clients?.data?.data,
    }),
    [clients],
  )

  const { flagNames } = useMemo(
    () => ({
      flagNames: flagOptions?.data?.data.flagNames || [],
    }),
    [flagOptions],
  )

  const handleKeyChange = useCallback(
    (e) => {
      if (!expandedFlag) {
        return
      }
      if (e.key === 'Escape') {
        setExpandedFlag(null)
      } else if (e.key === 'ArrowDown') {
        const currentIndex = currentFlags.findIndex(({ id }) => id === expandedFlag.id)
        if (currentIndex < currentFlags.length - 1) {
          setExpandedFlag(currentFlags[currentIndex + 1])
        }
      } else if (e.key === 'ArrowUp') {
        const currentIndex = currentFlags.findIndex(({ id }) => id === expandedFlag.id)
        if (currentIndex > 0) {
          setExpandedFlag(currentFlags[currentIndex - 1])
        }
      }
    },
    [setExpandedFlag, expandedFlag, currentFlags],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyChange)

    return () => {
      window.removeEventListener('keydown', handleKeyChange)
    }
  }, [handleKeyChange])

  const handleExpandFlag = useCallback(
    (flag: IFlags) => {
      if (expandedFlag?.id === flag.id) {
        setExpandedFlag(null)
        setIsExpandNewFlagModalOpen(false)
      } else {
        setExpandedFlag(flag)
      }
    },
    [expandedFlag?.id],
  )

  useEffect(() => {
    setExpandedFlag((flag) =>
      flag ? currentFlags?.find(({ id }) => flag.id === id) || null : flag,
    )
  }, [currentFlags, expandedFlag])

  const closeExpandModal = useCallback(() => {
    setExpandedFlag(null)
    setIsExpandNewFlagModalOpen(false)
  }, [])

  useEffect(() => {
    listFlags({
      page: 0,
      perPage: PER_PAGE,
      filters: updateDateFilters(filters, FLAGS_LIST_FILTERS_CONFIG),
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      isPortfolioPage: true,
    })
  }, [listFlags, filters, orderBy])

  useEffect(() => {
    getFlagsOptions({
      isPortfolioPage: true,
    })
  }, [getFlagsOptions])

  const loadMore = useCallback(() => {
    listFlags({
      page: Math.ceil(itemsCount / PER_PAGE),
      perPage: PER_PAGE,
      loadMore: true,
      filters: updateDateFilters(filters, FLAGS_LIST_FILTERS_CONFIG),
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      isPortfolioPage: true,
    })
  }, [listFlags, itemsCount, filters, orderBy])

  const handleOpenSort = useCallback(
    (event: React.MouseEvent<SVGElement>) => {
      setAnchorEl(event.currentTarget)
    },
    [setAnchorEl],
  )

  const handleCloseSort = useCallback(() => {
    setAnchorEl(null)
  }, [setAnchorEl])

  const filtersConfig = useMemo(
    () =>
      FLAGS_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        options:
          item.field === 'clientName'
            ? clientsData?.map(({ clientName }) => ({
                value: clientName,
                label: clientName,
              }))
            : item.field === 'flagName'
            ? flagNames
            : item.field === 'flagType' && !isAdmin
            ? item.options.filter(({ value }) => value !== FlagTypes.OPS)
            : item.options,
        loadOptions: item.field === 'name' ? loadMappings : undefined,
      })),
    [clientsData, flagNames, loadMappings, isAdmin],
  )

  return (
    <Card
      className={cn(styles.flagContainer, styles.flagFullPage)}
      classes={{
        content: styles.flagCardContainer,
      }}
      withBorder={false}
      noHeaderMargin
      onKeyDown={handleKeyChange}
    >
      <Box display="flex" alignContent="center" flexDirection="column">
        <Form
          validate={filtersValidate}
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={filtersConfig}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              appliedFilters={filters}
              appliedQuickFilter={quickFilter}
              handleAppliedQuickFilterChange={handleQuickFilterChange}
              actionsSize={0}
              actionsInsideFilters
              actions={
                <div className={styles.actionButtonContainer}>
                  <Popover
                    open={isSortOpen}
                    anchorEl={anchorEl}
                    onClose={handleCloseSort}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    classes={{ paper: styles.sortMenuPopoverPaper }}
                  >
                    <div className={styles.sortMenu}>
                      {FLAGS_SORT_OPTIONS.map((option) => (
                        <MenuItem
                          key={option.value}
                          classes={{
                            root: styles.sortMenuItem,
                            selected: styles.sortMenuItemSelected,
                          }}
                          onClick={() =>
                            handleOrderChange(
                              option.value.split(' ')[0],
                              option.value.split(' ')[1],
                            )
                          }
                          selected={`${orderBy.field} ${orderBy.direction}` === option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </div>
                  </Popover>

                  <SortIcon
                    className={cn(styles.button, styles.fullPageSortButton, {
                      [styles.buttonActive]: isSortOpen,
                    })}
                    onClick={handleOpenSort}
                  />
                </div>
              }
            />
          )}
        />
      </Box>

      <div
        className={cn(styles.fullscreenFlagContainerWrapper, {
          [styles.fullscreenFlagContainerWrapperExpanded]:
            isExpandNewFlagModalOpen || !!expandedFlag,
        })}
      >
        <div className={styles.fullscreenFlagContainer}>
          {currentFlags?.length === 0 ? (
            <div className={styles.emptyContainer}>
              <EmptyIcon />
              <div className={styles.emptyContainerText}>There are no flags at this time.</div>
            </div>
          ) : (
            <div id="scrollableDiv" className={styles.flagsList}>
              <InfiniteScroll
                className={styles.infiniteScroll}
                dataLength={itemsCount}
                next={loadMore}
                hasMore={itemsCount < totalItems}
                loader={<BBCLogsItemLoader />}
                scrollableTarget="scrollableDiv"
              >
                {isLoading
                  ? Array.from({ length: 10 }).map((_, index) => <BBCLogsItemLoader key={index} />)
                  : currentFlags?.map((flag, index) => (
                      <Flag
                        flag={flag}
                        key={flag.id}
                        onClickExpandModal={handleExpandFlag}
                        isSelected={expandedFlag?.id === flag.id}
                        handleCloseExpandModal={
                          expandedFlag?.id === flag.id ? closeExpandModal : undefined
                        }
                        index={index}
                      />
                    ))}
              </InfiniteScroll>
            </div>
          )}
        </div>
        {(isExpandNewFlagModalOpen || !!expandedFlag) && (
          <FlagDetailSidePanel currentFlag={expandedFlag} closeExpandModal={closeExpandModal} />
        )}
      </div>
    </Card>
  )
}

export default FullPageFlags
