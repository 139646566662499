import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ProspectHeader from './ProspectHeader'

import { reporting, termStructureSummaryInfo } from '../../redux/prospect/selectors'
import { isBDO } from '../../redux/profile/selectors'
import { show, hide, submitOps } from '../../redux/prospect/actions'
import { updateClient } from '../../redux/clientInfo/actions'

const selector = createStructuredSelector({
  reporting,
  termStructureSummaryInfo,
  isBDO,
})
const actions = {
  show,
  hide,
  updateClient,
  submitOps,
}

export default connect(selector, actions)(ProspectHeader)
