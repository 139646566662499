import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import LCRCommentary from './LCRCommentary'

import {
  listLCRCommentary,
  addLCRCommentary,
  updateLCRCommentary,
  deleteLCRCommentary,
} from '../../redux/dueDiligence/actions'
import { dueDiligenceInfo, lcrCommentaryData } from '../../redux/dueDiligence/selectors'

const selector = createStructuredSelector({
  dueDiligenceInfo,
  lcrCommentaryData,
})

const actions = {
  listLCRCommentary,
  addLCRCommentary,
  updateLCRCommentary,
  deleteLCRCommentary,
}

export default connect(selector, actions)(LCRCommentary)
