import { combineReducers } from 'redux'
import * as actions from './actions'
import { IFolder } from '@common/interfaces/box'

export interface IBoxState {
  loadingArray: string[]
  folders: IFolder[]
  boxViewLink: string
}

export default combineReducers<IBoxState>({
  loadingArray(state: string[] = [], action) {
    switch (action.type) {
      case actions.LIST_FOLDERS_REQUEST:
        return [...state, action.type]
      case actions.LIST_FOLDERS_SUCCESS:
      case actions.LIST_FOLDERS_FAILURE:
        return state.filter(
          (item) =>
            item !== action.type.replace('SUCCESS', 'REQUEST').replace('FAILURE', 'REQUEST'),
        )
      default:
        return state
    }
  },
  folders(state: IFolder[] = [], action) {
    switch (action.type) {
      case actions.LIST_FOLDERS_SUCCESS:
        return action.data
      default:
        return state
    }
  },
  boxViewLink(state = '', action) {
    switch (action.type) {
      case actions.SET_BOX_VIEWER_LINK:
        return action.data
      case actions.GET_EMBED_LINK_REQUEST:
        return 'loading'
      case actions.GET_EMBED_LINK_SUCCESS:
        return action.data
      case actions.HIDE_BOX_PREVIEW:
        return ''
      default:
        return state
    }
  },
})
