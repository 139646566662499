import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  IHistoricalLineTurnData,
  IHistoricalLineTurnOptionsData,
} from '@common/interfaces/dueDiligence'
import { ILoadingData } from '../../redux/types'
import Card from '../Common/Card'
import { Line } from 'react-chartjs-2'
import { useParams } from 'react-router-dom'
import { Box, Grid } from '@mui/material'
import DatePicker from '../Common/DatePicker'
import moment from 'moment'
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  CoreChartOptions,
  ElementChartOptions,
  PluginChartOptions,
  DatasetChartOptions,
  ScaleChartOptions,
  LineControllerChartOptions,
  TooltipModel,
} from 'chart.js'
import {
  buildTooltip,
  GRAPH_COLOR_PRIMARY,
  buildTooltipUnderlyingMetricValueHead,
  buildTooltipUnderlyingMetricValueRow,
  FONT_SIZE,
} from '../../constants/graphs'
import { DeepPartial } from 'chart.js/dist/types/utils'
import styles from './EstimatedHistoricalLineTurn.module.scss'
import { formatAmount, formatDateMonthYearShort } from '../../helpers/helpers'
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const DEFAULT_CHART_OPTIONS: DeepPartial<
  CoreChartOptions<'line'> &
    ElementChartOptions<'line'> &
    PluginChartOptions<'line'> &
    DatasetChartOptions<'line'> &
    ScaleChartOptions<'line'> &
    LineControllerChartOptions
> = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        font: {
          size: FONT_SIZE,
        },
      },
    },
  },
}

interface IEstimatedHistoricalLineTurnProps {
  historicalLineTurnData: ILoadingData<IHistoricalLineTurnData>
  historicalLineTurnOptionsData: ILoadingData<IHistoricalLineTurnOptionsData>
  getHistoricalLineTurn: (id: string, params: object) => void
  getHistoricalLineTurnOptions: (id: string) => void
}

const EstimatedHistoricalLineTurn = ({
  historicalLineTurnData,
  historicalLineTurnOptionsData,
  getHistoricalLineTurn,
  getHistoricalLineTurnOptions,
}: IEstimatedHistoricalLineTurnProps) => {
  const { id } = useParams<{ id: string }>()
  const [clickedIndex, setClickedIndex] = useState(null)

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      const tooltipEl = document.getElementById('chartjs-tooltip')
      const chartEl = document.getElementById('historicalLineTurnChart')

      if (
        tooltipEl &&
        chartEl &&
        !tooltipEl.contains(event.target as Node) &&
        !chartEl.contains(event.target as Node)
      ) {
        tooltipEl.remove()
        setClickedIndex(null)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    getHistoricalLineTurnOptions(id)
  }, [id, getHistoricalLineTurnOptions])

  const [currentDateRange, setCurrentDateRange] = useState({
    startDate: null,
    endDate: null,
  })

  const { minDate, maxDate } = useMemo(
    () => ({
      minDate: historicalLineTurnOptionsData.data?.minDate,
      maxDate: historicalLineTurnOptionsData.data?.maxDate,
    }),
    [historicalLineTurnOptionsData],
  )

  useEffect(() => {
    if (maxDate) {
      const effectiveMinDate = moment
        .max(moment(minDate), moment(maxDate).subtract(11, 'months'))
        .format('YYYY-MM-DD')
      setCurrentDateRange({
        startDate: effectiveMinDate,
        endDate: moment(maxDate).format('YYYY-MM-DD'),
      })
    }
  }, [minDate, maxDate])

  const { historicalLineTurn, totals } = useMemo(
    () => ({
      historicalLineTurn: historicalLineTurnData.data?.data,
      totals: historicalLineTurnData.data?.totals,
    }),
    [historicalLineTurnData],
  )

  const datesBoundary = useMemo(
    () => ({
      minDate,
      maxDate,
    }),
    [minDate, maxDate],
  )

  useEffect(() => {
    if (currentDateRange.startDate && currentDateRange.endDate) {
      getHistoricalLineTurn(id, {
        startDate: currentDateRange.startDate,
        endDate: currentDateRange.endDate,
      })
    }
  }, [id, getHistoricalLineTurn, currentDateRange])

  const handleDateChange = useCallback(
    (values: { startDate: string; endDate: string }) => {
      setCurrentDateRange(values)
    },
    [setCurrentDateRange],
  )

  const data = useMemo(
    () => ({
      labels: historicalLineTurn?.map(({ recordDate }) => formatDateMonthYearShort(recordDate)),
      datasets: [
        {
          label: 'Estimated Line Turn',
          data: historicalLineTurn?.map(({ lineTurn }) => lineTurn),
          borderColor: GRAPH_COLOR_PRIMARY,
          backgroundColor: '#0066F5',
          lineTension: 0.4,
          pointRadius: (context: any) => {
            return clickedIndex === context.dataIndex ? 7 : 2
          },
          pointHoverRadius: 7,
        },
      ],
    }),
    [historicalLineTurn, clickedIndex],
  )

  const historicalLineturnTooltip = useCallback(
    (context: { chart: Chart<'line'>; tooltip: TooltipModel<'line'> }) =>
      buildTooltip((tooltip) => {
        const titleLines = tooltip.title || []
        const date = titleLines[0]

        const dataPoint = tooltip?.dataPoints?.[0]

        const lineLabel = dataPoint?.dataset?.label

        const historicalLineTurnData = historicalLineTurn?.find(
          ({ recordDate }) => formatDateMonthYearShort(recordDate) === date,
        )
        if (!historicalLineTurnData) {
          return
        }
        const { lineTurn, estimatedDraw, collections } = historicalLineTurnData

        let innerHtml: string
        innerHtml = buildTooltipUnderlyingMetricValueHead(
          lineLabel,
          `${lineTurn?.toFixed(2)} (${date})`,
        )
        innerHtml += buildTooltipUnderlyingMetricValueRow('Collections', formatAmount(collections))
        innerHtml += buildTooltipUnderlyingMetricValueRow(
          'Estimated Draw',
          formatAmount(estimatedDraw),
        )

        innerHtml += '</tbody>'

        return innerHtml
      })(context),
    [historicalLineTurn],
  )

  const chartOptions = useMemo(
    () => ({
      ...DEFAULT_CHART_OPTIONS,
      plugins: {
        ...DEFAULT_CHART_OPTIONS.plugins,
        tooltip: {
          enabled: false,
          external: historicalLineturnTooltip,
          events: ['click'],
          position: 'nearest' as const,
        },
      },
      tooltips: {
        yAlign: 'bottom',
      },

      onHover: (event: any, elements: any) => {
        // @ts-ignore
        event.native.target.style.cursor = elements[0] ? 'pointer' : 'default'
      },
      onClick: (event: any, elements: any) => {
        setClickedIndex(elements[0] ? elements[0].index : null)
      },
    }),
    [historicalLineturnTooltip],
  )

  return (
    <Card
      className={styles.card}
      title={
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <h2>Estimated Historical Line Turn</h2>
          <div className={styles.totalMetrics}>
            <div>
              <span className={styles.totalMetricsTitle}>
                L
                {currentDateRange.startDate
                  ? moment(currentDateRange.endDate).diff(
                      moment(currentDateRange.startDate),
                      'months',
                    ) + 1
                  : 0}
                M Avg. Line Turn
              </span>
              <span className={styles.totalMetricsValue}>{formatAmount(totals?.avgLineTurn)}</span>
            </div>
            <div>
              <span className={styles.totalMetricsTitle}>Max Line Turn</span>
              <span className={styles.totalMetricsValue}>{formatAmount(totals?.maxLineTurn)}</span>
            </div>
            <div>
              <span className={styles.totalMetricsTitle}>Min Line Turn</span>
              <span className={styles.totalMetricsValue}>{formatAmount(totals?.minLineTurn)}</span>
            </div>
          </div>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <DatePicker
              currentDateRange={currentDateRange}
              datesBoundary={datesBoundary}
              onChange={handleDateChange}
            />
          </Box>
        </Box>
      }
    >
      <Grid container xs={12} justifyContent={'center'}>
        <Grid item xs={12} className={styles.chartContainer} container>
          <Line id="historicalLineTurnChart" data={data} options={chartOptions} />
        </Grid>
      </Grid>
    </Card>
  )
}

export default EstimatedHistoricalLineTurn
