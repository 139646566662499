import React, { useMemo } from 'react'
import cn from 'classnames'

import styles from '../DealStructure/DealStructure.module.scss'
import termStructureStyles from '../../ProspectReportingSummaryTermStructure/ProspectReportingSummaryTermStructure.module.scss'

import { ClientABLField } from '../ClientHelpers'
import { LOAN_TYPES } from '@common/constants/client'
import {
  ClientSetupSections,
  CollateralSubSection,
  allClientTermsInfo,
  IClientInfo,
} from '@common/interfaces/client'
import RenderedValue from '../../RenderedValue'

const arFields = allClientTermsInfo.filter(
  (term) =>
    (term.section === ClientSetupSections.Collateral &&
      term.subSection === CollateralSubSection.AR) ||
    term.value === 'loanType',
)

const inventoryFields = allClientTermsInfo.filter(
  (term) =>
    (term.section === ClientSetupSections.Collateral &&
      term.subSection === CollateralSubSection.Inventory) ||
    term.value === 'loanType',
)

const arAndInventoryFields = allClientTermsInfo.filter(
  (term) => term.section === ClientSetupSections.Collateral,
)

const justDealType = allClientTermsInfo.filter((term) => term.value === 'loanType')

interface IProps {
  clientInfo: IClientInfo
  readOnly: boolean
}

const Collateral = ({ clientInfo, readOnly }: IProps) => {
  const fieldsMap = useMemo(() => {
    let fieldsMapFinal: typeof allClientTermsInfo = []

    if (!clientInfo) {
      return fieldsMapFinal
    }

    if (clientInfo.loanType === LOAN_TYPES.ar) {
      fieldsMapFinal = arFields
    }
    if (clientInfo.loanType === LOAN_TYPES.inventory) {
      fieldsMapFinal = inventoryFields
    }
    if (clientInfo.loanType === LOAN_TYPES.arAndInventory) {
      fieldsMapFinal = arAndInventoryFields
    }
    if (!clientInfo.loanType) {
      fieldsMapFinal = justDealType
    }

    return fieldsMapFinal
  }, [clientInfo])

  return (
    <div className={termStructureStyles.section}>
      <div className={termStructureStyles.sectionTitle}>Collateral</div>

      <div className={termStructureStyles.sectionData}>
        {fieldsMap.map(({ label, value, type, placeholder, options }) => (
          <div key={value} className={termStructureStyles.dataRow}>
            <div className={cn(termStructureStyles.label, styles.label)}>{label}</div>
            <div className={cn(termStructureStyles.value, styles.value)}>
              {readOnly ? (
                <RenderedValue value={clientInfo?.[value]} type={type} percentWithScale />
              ) : (
                <ClientABLField
                  name={value}
                  type={type}
                  placeholder={placeholder}
                  options={options}
                  size="small"
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Collateral
