import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import cn from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'
import styles from './BBCIneligibleARDetails.module.scss'
import genericSs from '@styles/generic.module.scss'
import ActiveToolbar from '../ActiveToolbar'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableLoader from '../Common/TableLoader'
import { IARIneligibleStatsDetails, IClientCollateral } from '@common/interfaces/bbc'
import {
  debounceEventHandler,
  formatDate,
  formatPrice,
  handleMultipleSelect,
} from '../../helpers/helpers'
import BBCIneligibleARDetailsInvoices from '../BBCIneligibleARDetailsInvoices'
import { AR_INELIGIBLE_STATS_DETAILS_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import TableFiltersRow from '../Common/TableFiltersRow'
import { ExpandDetailIcon } from '../Common/Icons/Icons'

interface IProps {
  arDetailStats: IARIneligibleStatsDetails[]
  clientCollaterals: IClientCollateral[]
  listArIneligibleStatsDetails: (id: string, data: object) => void
  filters: any
  isModalShown: boolean
  label: string
}

const BBCIneligibleARDetails = ({
  arDetailStats,
  clientCollaterals,
  listArIneligibleStatsDetails,
  filters,
  isModalShown,
  label,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)

  const [isLoading, setIsLoading] = useState(true)
  const [activeItem, setActiveItem] = useState<number>()
  const [activeItems, setActiveItems] = useState([])
  const [expanded, setExpanded] = useState([])
  const [orderBy, setOrderBy] = useState({
    field: 'debtor',
    direction: 'ASC',
  })

  const handleSelectRow = useMemo(
    () => handleMultipleSelect(setActiveItems, setActiveItem, activeItems, activeItem),
    [activeItems, activeItem],
  )
  const resetActiveItems = useCallback(() => setActiveItems([]), [])

  const handleOrderChange = useCallback((field: string) => {
    setOrderBy((order) => ({
      field,
      direction: order.field === field ? (order.direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
    }))
  }, [])

  const debounceListArIneligibleStatsDetails = useMemo(
    () =>
      debounceEventHandler(async (data: any) => {
        !data.loadMore && setIsLoading(true)
        await listArIneligibleStatsDetails(id, {
          ...data,
          label,
          nestedRows: {
            keys: ['label'],
          },
        })
        setIsLoading(false)
      }, 500),
    [id, listArIneligibleStatsDetails, label],
  )

  useEffect(() => {
    !arDetailStats &&
      debounceListArIneligibleStatsDetails({
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        page: 0,
      })
  }, [orderBy, filters, debounceListArIneligibleStatsDetails, arDetailStats])

  useEffect(() => {
    debounceListArIneligibleStatsDetails({
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: 0,
    })
  }, [orderBy, filters, debounceListArIneligibleStatsDetails])

  const loadMore = useCallback(() => {
    debounceListArIneligibleStatsDetails({
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: Math.ceil(arDetailStats?.length / PER_PAGE),
    })
  }, [orderBy, filters, debounceListArIneligibleStatsDetails, arDetailStats])

  const totalRow = useMemo(
    () =>
      arDetailStats
        ?.filter((_, index) => activeItems.includes(index))
        .reduce(
          (total, item) => {
            clientCollaterals.forEach((_, index) => {
              const field = `value_${index}`

              total[field] += item[field]
            })

            return total
          },
          {
            value_0: 0,
            value_1: 0,
            value_2: 0,
            value_3: 0,
            value_4: 0,
          },
        ),
    [activeItems, arDetailStats, clientCollaterals],
  )

  const totalCount = useMemo(() => arDetailStats?.[0]?.totalCount, [arDetailStats])

  const handleExpand = useCallback((debtor: string) => {
    setExpanded((values) =>
      values.includes(debtor) ? values.filter((item) => item !== debtor) : [...values, debtor],
    )
  }, [])

  const filtersConfig = useMemo(
    () =>
      AR_INELIGIBLE_STATS_DETAILS_FILTERS_CONFIG.filter(
        (filter) =>
          !filter.field.startsWith('value_') ||
          !!clientCollaterals[filter.field.replace('value_', '')],
      ).map((filter) => {
        if (!filter.field.startsWith('value_') || filter.field === 'value_0') {
          return filter
        }

        const index = +filter.field.replace('value_', '')
        return {
          ...filter,
          title: formatDate(clientCollaterals[index].recordDate),
        }
      }),
    [clientCollaterals],
  )

  return (
    <TableContainer
      className={styles.table}
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
      hasFooter
      size="small"
    >
      <Table ref={wrapperRef}>
        <TableHead>
          <TableFiltersRow
            filters={filtersConfig}
            orderBy={orderBy}
            handleOrderChange={handleOrderChange}
            isChildrenAtStart
          >
            <TableCell />
          </TableFiltersRow>
        </TableHead>
        <TableBody id={`scrollableIneligibleARDetails-${label}`} className={styles.scrollableDiv}>
          {isLoading ? (
            <TableLoader columnsCount={clientCollaterals.length + 1} />
          ) : (
            <InfiniteScroll
              dataLength={arDetailStats?.length || 0}
              next={loadMore}
              hasMore={arDetailStats?.length < totalCount}
              loader={<TableLoader columnsCount={clientCollaterals.length + 2} rowsCount={1} />}
              scrollableTarget={`scrollableIneligibleARDetails-${label}`}
            >
              {arDetailStats?.map((row, index) => {
                const { debtor, rows, ...rest } = row
                const isExpanded = expanded.includes(debtor)
                const hasChild =
                  ['over_90DaysAndCreditsPastDue', 'ineligibleDebtors'].includes(label) &&
                  Object.values(rest).filter(Boolean).length > 0

                return (
                  <React.Fragment key={debtor}>
                    <TableRow
                      data-index={index}
                      className={cn('activableRow', {
                        activeRow: activeItems.includes(index),
                        currentActiveRow: activeItem === index,
                      })}
                      onClick={(event) => handleSelectRow(event, index)}
                    >
                      <TableCell className={genericSs.tableTextLeft}>
                        {hasChild && (
                          <ExpandDetailIcon
                            onClick={() => handleExpand(debtor)}
                            isExpanded={isExpanded}
                          />
                        )}
                      </TableCell>
                      <TableCell className={cn(genericSs.tableTextLeft, genericSs.textCapitalize)}>
                        {debtor}
                      </TableCell>
                      {clientCollaterals.map((_, key) => (
                        <TableCell key={key} className={genericSs.tableTextRight}>
                          $ {formatPrice(row[`value_${key}`])}
                        </TableCell>
                      ))}
                    </TableRow>
                    {isExpanded && (
                      <TableRow>
                        <TableCell className={genericSs.nestedRowColumn} colSpan={7}>
                          <BBCIneligibleARDetailsInvoices
                            arDetailStats={rows}
                            filters={filters}
                            isModalShown={isModalShown}
                            label={label}
                            debtor={debtor}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                )
              })}
            </InfiniteScroll>
          )}
          <ActiveToolbar
            activeItems={activeItems}
            className={styles.activeToolbar}
            isFullscreen={isModalShown}
            containerRef={wrapperRef}
            resetActiveItems={resetActiveItems}
          >
            {clientCollaterals.map((_, index) => (
              <div key={index} className={genericSs.tableTextRight}>
                $ {formatPrice(totalRow?.[`value_${index}`])}
              </div>
            ))}
          </ActiveToolbar>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BBCIneligibleARDetails
