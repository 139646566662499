import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { Link, generatePath, useLocation, useHistory } from 'react-router-dom'
import LinkButton from '@mui/material/Link'
import queryString from 'query-string'
import Box from '@mui/material/Box'
import Card from '../../components/Common/Card'
import Grid from '@mui/material/Grid'
import { Form } from 'react-final-form'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import cn from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'

import styles from './CollectionsDashboardPage.module.scss'
import genericSs from '@styles/generic.module.scss'

import ActiveToolbar from '../../components/ActiveToolbar'
import { IAggregationData, COLLECTIONS_TABS } from '@common/interfaces/collection'
import Table from '../../components/Common/Table'
import TableHead from '../../components/Common/TableHead'
import TableBody from '../../components/Common/TableBody'
import TableFooter from '../../components/Common/TableFooter'
import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import TableContainer from '../../components/Common/TableContainer'
import Tabs from '../../components/Common/Tabs'
import {
  debounceEventHandler,
  formatPrice,
  dateToString,
  formatDate,
  handleMultipleSelect,
  formatter,
} from '../../helpers/helpers'
import { IClientInfo, ClientInfoStatus } from '@common/interfaces/client'
import Autocomplete from '../../components/Common/Autocomplete'
import IconButton from '../../components/Common/IconButton'
import CollectionAggregationRow from '../../components/CollectionAggregationRow'
import { ReactComponent as CheveronUp } from '../../assets/images/chevron-up.svg'
import { ReactComponent as CheveronDown } from '../../assets/images/down-chevron.svg'
import Modal from '../../components/Common/Modal'
import Button from '../../components/Common/Button'
import CreatableSelectField from '../../components/Common/CreatableSelectField'
import TableFiltersRow from '../../components/Common/TableFiltersRow'
import { ROUTES } from '../../constants/routes'
import DeletedCheckTable from '../../components/DeletedCheckTable'
import { ReactComponent as ArchiveIcon } from '../../assets/images/archive-icon.svg'
import { ReactComponent as DeleteIcon } from '../../assets/images/delete-icon.svg'
import { COLLECTIONS_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { IEntityInfo } from '@common/interfaces/entityInfo'
import FilterContainer from '../../components/Filters/FilterContainer'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import TableLoader from '../../components/Common/TableLoader'
import WarningModal from '../../components/WarningModal'
import { ILoadingData } from '../../redux/types'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import { DATE_DATABASE_FORMAT } from '../../constants/common'
import useTable from '../../hooks/useTable'

const TABS_ROUTES = {
  [COLLECTIONS_TABS.OVERALL_COLLECTIONS]: ROUTES.COLLECTIONS_DASHBOARD_OVERALL,
  [COLLECTIONS_TABS.NON_CLIENT_RELATED_CASH]: ROUTES.COLLECTIONS_DASHBOARD_NON_CLIENT_RELATED_CASH,
}

const today = moment()

const filtersValidate = buildFiltersValidateSchema(COLLECTIONS_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(COLLECTIONS_LIST_FILTERS_CONFIG, {
  recordDateFrom: today.format(DATE_DATABASE_FORMAT),
  recordDateTo: today.format(DATE_DATABASE_FORMAT),
})

interface IProps {
  isLoadingCollections: boolean
  isLoadingEntityInfo: boolean
  aggregation: IAggregationData
  clients: ILoadingData<{ data: IClientInfo[] }>
  listAggregation: (params?: {
    page?: number
    filters?: object
    orderBy?: string
    orderDirection?: string
  }) => void
  exportAggregation: (params?: {
    filters?: object
    orderBy?: string
    orderDirection?: string
  }) => void
  listClients: (params?: object) => void
  updateAggregationRow: (id: string, data: object) => void
  deleteAggregationRow: (data: object) => void
  listEntityInfo: (data: object) => Promise<{ data: IEntityInfo[] }>
  addEntityInfo: (data: object) => void
  listAggregationRow?: (clientName: string, recordDate: string, filters?: object) => void
}

const CollectionsDashboardPage = ({
  isLoadingCollections,
  aggregation,
  clients,
  listAggregation,
  listAggregationRow,
  exportAggregation,
  listClients,
  updateAggregationRow,
  deleteAggregationRow,
  listEntityInfo,
  addEntityInfo,
}: IProps) => {
  const wrapperRef = useRef(null)
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [isPermanent, setIsPermanent] = useState(false)
  const [isEditModalShown, setIsEditModalShown] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState({ type: null, value: false })
  const [isLoadMore, setIsLoadMore] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [actionsMenuOpen, setActionsMenuOpen] = useState(false)

  useSetPageTitle('Collections')

  // Multiple Select used for nested items
  // Modified indexes are used
  // `{parentRowIndex + 1}{rowIndex.padStart(4, '0')}`
  // 10000 - first row inside first parent row, 10004 - 5th row inside first parent row, 30004 - 4th row inside third parent row
  const [activeItem, setActiveItem] = useState(10000)
  const [activeItems, setActiveItems] = useState([10000])

  const { clientsData } = useMemo(
    () => ({
      clientsData: clients?.data?.data,
    }),
    [clients],
  )

  const existsActiveItems = useMemo(
    () =>
      (aggregation?.data || [])
        .map((item, parentIndex) =>
          (item.items || [])
            .map((_, index) => +`${parentIndex + 1}${index.toString().padStart(4, '0')}`)
            .filter((index) => activeItems.includes(index)),
        )
        .filter((items) => items.length > 0)
        .flat(),
    [activeItems, aggregation],
  )

  const history = useHistory()
  const [currentTab, setCurrentTab] = useState<string>(COLLECTIONS_TABS.OVERALL_COLLECTIONS)
  const onTabChange = useCallback(
    (tab) => {
      history.push(generatePath(TABS_ROUTES[tab]))
      setCurrentTab(tab)
    },
    [history],
  )

  const handleSelectRow = useMemo(
    () => handleMultipleSelect(setActiveItems, setActiveItem, activeItems, activeItem),
    [activeItems, activeItem],
  )

  const handleClickMenu = useCallback((row, event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setActionsMenuOpen(true)
    setSelectedItem(row)
  }, [])
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    setActionsMenuOpen(false)
    setSelectedItem(null)
  }, [])

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'collection',
    filtersDefaults,
    sortDefault: {
      field: 'record_date',
      direction: 'DESC',
    },
    quickFilterDefault: 'Today',
  })

  const [expanded, setExpanded] = useState([])
  const { search }: { search: string } = useLocation()

  const fetchAggregationList = useCallback(
    async (data: any) => {
      const params = {
        ...data,
        filters: {
          ...data.filters,
        },
      }
      if (params?.filters.recordDateFrom && typeof params.filters.recordDateFrom !== 'string') {
        params.filters.recordDateFrom = dateToString(params.filters.recordDateFrom)
      }
      if (params?.filters.recordDateTo && typeof params.filters.recordDateTo !== 'string') {
        params.filters.recordDateTo = dateToString(params.filters.recordDateTo)
      }
      await listAggregation(params)
    },
    [listAggregation],
  )

  const refetchAggregationRow = useCallback(
    (selectedRow?) => {
      selectedRow
        ? listAggregationRow(selectedRow?.clientName, selectedRow?.recordDate, filters)
        : listAggregationRow(selectedItem?.clientName, selectedItem?.recordDate, filters)
    },
    [selectedItem, filters, listAggregationRow],
  )

  const debounceListAggregation = useMemo(
    () => debounceEventHandler(fetchAggregationList, 500),
    [fetchAggregationList],
  )

  useEffect(() => {
    debounceListAggregation({
      page: 0,
      perPage: PER_PAGE,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [orderBy, filters, debounceListAggregation])

  useEffect(() => {
    if (search) {
      const parsed = queryString.parse(search)
      const { type, activityId } = parsed
      if (type && activityId) {
        handleFiltersChange((data: any) => ({
          ...data,
          type,
          activityId,
        }))
      }
    }
  }, [search, handleFiltersChange])

  useEffect(() => {
    listClients({ withBankAccounts: true })
  }, [listClients])

  const handleExpand = useCallback((index: number) => {
    setExpanded((values) =>
      values.includes(index) ? values.filter((item) => item !== index) : [...values, index],
    )
  }, [])

  const handleExpandAll = useCallback(() => {
    setExpanded((values) =>
      values.length > 0 ? [] : aggregation.data?.map((item, index) => index),
    )
  }, [aggregation])

  const handleEdit = useCallback(() => {
    setIsEditModalShown(true)
  }, [])

  const handleEditCancel = useCallback(() => {
    setSelectedItem(null)
    handleCloseMenu()
    setIsEditModalShown(false)
  }, [handleCloseMenu])

  const handleEditConfirm = useCallback(
    async (data) => {
      setIsButtonLoading({ type: 'edit', value: true })
      await updateAggregationRow(
        selectedItem.id,
        selectedItem.isCheck
          ? {
              clientName: data.clientName,
              debtor: data.checkAccount?.value,
            }
          : {
              clientName: data.clientName,
              debtor: data.debtor?.value,
            },
      )
      await refetchAggregationRow()
      setIsButtonLoading({ type: null, value: null })
      handleCloseMenu()
      setIsEditModalShown(false)
    },
    [updateAggregationRow, selectedItem, refetchAggregationRow, handleCloseMenu],
  )

  const handleArchive = useCallback((item) => {
    setIsPermanent(false)
    setSelectedItem(item)
    setIsDeleteModalShown(true)
  }, [])

  const handleDelete = useCallback((item) => {
    setIsPermanent(true)
    setSelectedItem(item)
    setIsDeleteModalShown(true)
  }, [])

  const handleDeleteCancel = useCallback(() => {
    setSelectedItem(null)
    setIsDeleteModalShown(false)
  }, [])

  const refetchAggregationList = useCallback(async () => {
    setIsLoadMore(true)
    await fetchAggregationList({
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
    setIsLoadMore(false)
  }, [orderBy, fetchAggregationList, filters])

  const handleDeleteConfirm = useCallback(async () => {
    setIsButtonLoading({ type: null, value: true })
    await deleteAggregationRow({ ids: [selectedItem.id], isPermanent })
    await refetchAggregationList()
    setIsButtonLoading({ type: null, value: null })
    handleCloseMenu()
    setIsDeleteModalShown(false)
    setActiveItems([])
  }, [
    deleteAggregationRow,
    selectedItem,
    refetchAggregationList,
    isPermanent,
    handleCloseMenu,
    setIsButtonLoading,
  ])

  const handleArchiveActiveItems = useCallback(() => {
    setIsPermanent(false)
    setIsDeleteModalShown(true)
  }, [])

  const handleDeleteActiveItems = useCallback(() => {
    setIsPermanent(true)
    setIsDeleteModalShown(true)
  }, [])

  const handleDeleteActiveItemsCancel = useCallback(() => {
    setIsDeleteModalShown(false)
  }, [])

  const handleDeleteActiveItemsConfirm = useCallback(async () => {
    setIsButtonLoading({ type: 'delete', value: true })
    await deleteAggregationRow({
      ids: aggregation?.data
        .map((item, parentIndex) =>
          (item.items || []).filter(
            (row, index) =>
              row.isEditable &&
              existsActiveItems.includes(+`${parentIndex + 1}${index.toString().padStart(4, '0')}`),
          ),
        )
        .filter((items) => items.length > 0)
        .flat()
        .map(({ id }) => id),
      isPermanent,
    })
    await refetchAggregationList()
    setIsButtonLoading({ type: null, value: null })
    setIsDeleteModalShown(false)
    setActiveItems([])
  }, [
    aggregation,
    existsActiveItems,
    deleteAggregationRow,
    isPermanent,
    setIsButtonLoading,
    refetchAggregationList,
  ])

  const handleAddDebtor = useCallback(
    (data: object) => {
      addEntityInfo(data)
    },
    [addEntityInfo],
  )

  const itemValidate = useMemo(() => {
    const itemSchema = Yup.object().shape({
      [selectedItem?.isWire ? 'debtor' : 'checkAccount']: Yup.object()
        .typeError('Customer is required')
        .required('Customer is required'),
      clientName: Yup.string().typeError('Client is required').required('Client is required'),
    })

    return makeValidate(itemSchema)
  }, [selectedItem])

  const itemInitialValues = useMemo(
    () =>
      selectedItem
        ? {
            ...selectedItem,
            debtor: selectedItem.debtor
              ? {
                  value: selectedItem.debtor || '',
                  label: selectedItem.debtor || '',
                }
              : null,
            checkAccount: selectedItem.debtor
              ? {
                  value: selectedItem.debtor || '',
                  label: selectedItem.debtor || '',
                }
              : null,
          }
        : {},
    [selectedItem],
  )

  const loadDebtors = useCallback(
    async (inputValue: string) => {
      const res = await listEntityInfo({
        name: inputValue,
      })
      return res.data.map(({ name }) => ({
        value: name,
        label: name,
      }))
    },
    [listEntityInfo],
  )

  const filtersConfig = useMemo(
    () =>
      COLLECTIONS_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        options:
          item.field === 'clients'
            ? clientsData?.map(({ clientName }) => ({
                value: clientName,
                label: clientName,
              }))
            : item.options,
        loadOptions: item.field === 'debtors' ? loadDebtors : undefined,
      })),
    [clientsData, loadDebtors],
  )

  const groupedClientsOptions = useMemo(
    () =>
      clientsData
        ?.filter(({ bankAccountInfos }) => bankAccountInfos?.length > 0)
        .reduce((result, { clientName, bankAccountInfos }) => {
          bankAccountInfos.forEach(({ bankAccountNumber }) => {
            if (!result[bankAccountNumber]) {
              result[bankAccountNumber] = []
            }

            result[bankAccountNumber].push({
              value: clientName,
              label: clientName,
            })
          })

          return result
        }, {} as { [key: string]: Array<{ value: string; label: string }> }),
    [clientsData],
  )

  const handleExportAggregation = useCallback(async () => {
    const params = {
      filters: {
        ...filters,
      },
      collectionsExport: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    }
    if (params?.filters.recordDateFrom && typeof params.filters.recordDateFrom !== 'string') {
      params.filters.recordDateFrom = dateToString(params.filters.recordDateFrom)
    }
    if (params?.filters.recordDateTo && typeof params.filters.recordDateTo !== 'string') {
      params.filters.recordDateTo = dateToString(params.filters.recordDateTo)
    }
    setIsButtonLoading({ type: 'export', value: true })
    await exportAggregation(params)
    setIsButtonLoading({ type: null, value: null })
  }, [filters, orderBy, exportAggregation])

  const loadMore = useCallback(async () => {
    setIsLoadMore(true)
    await fetchAggregationList({
      loadMore: true,
      page: Math.ceil(aggregation?.data?.length / PER_PAGE),
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
    setIsLoadMore(false)
  }, [aggregation, orderBy, filters, fetchAggregationList])

  const totalRow = useMemo(
    () =>
      aggregation?.data
        ?.map((item, parentIndex) =>
          (item.items || []).filter((_, index) =>
            existsActiveItems.includes(+`${parentIndex + 1}${index.toString().padStart(4, '0')}`),
          ),
        )
        .filter((items) => items.length > 0)
        .flat()
        .reduce(
          (result, row) => {
            result.checksPaymentSum += row.checkPaymentAmount || 0
            result.wiresPaymentSum += row.wirePaymentAmount || 0

            if (row.isEditable) {
              result.hasEditable = true
            }

            return result
          },
          {
            checksPaymentSum: 0,
            wiresPaymentSum: 0,
            hasEditable: false,
          },
        ),
    [aggregation, existsActiveItems],
  )

  return (
    <Box py={1} pr={2}>
      <Card
        withBorder={false}
        title={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Tabs
              tabs={[
                COLLECTIONS_TABS.OVERALL_COLLECTIONS,
                COLLECTIONS_TABS.NON_CLIENT_RELATED_CASH,
              ]}
              selected={currentTab}
              handleChange={onTabChange}
            />
          </Box>
        }
      >
        <Grid container spacing={3} className={styles.aggregationPage}>
          {currentTab === COLLECTIONS_TABS.OVERALL_COLLECTIONS && (
            <Grid item xs={12} ref={wrapperRef}>
              <TableContainer className={styles.aggregationTable} hasFooter>
                <Form
                  validate={filtersValidate}
                  onSubmit={handleFiltersChange}
                  initialValues={filters}
                  mutators={{
                    setFieldData: ([field, value], state, { changeValue }) => {
                      changeValue(state, field, () => value)
                    },
                  }}
                  render={({ values, handleSubmit, form: { mutators } }) => (
                    <FilterContainer
                      filters={filtersConfig}
                      handleSubmit={handleSubmit}
                      mutators={mutators}
                      values={values}
                      withFullSearch={false}
                      appliedFilters={filters}
                      appliedQuickFilter={quickFilter}
                      handleAppliedQuickFilterChange={handleQuickFilterChange}
                      handleExportAggregation={handleExportAggregation}
                      actions={
                        <Box display="flex" alignItems="center" gap={1}>
                          {existsActiveItems.length > 1 && totalRow?.hasEditable && (
                            <>
                              <Button
                                startIcon={<ArchiveIcon className={genericSs.iconPathFillWhite} />}
                                color="primary"
                                variant="contained"
                                onClick={handleArchiveActiveItems}
                                small={false}
                              >
                                Non Client Related Cash
                              </Button>
                              <Button
                                startIcon={<DeleteIcon className={genericSs.iconPathStrokeWhite} />}
                                color="primary"
                                variant="contained"
                                onClick={handleDeleteActiveItems}
                                small={false}
                              >
                                Delete
                              </Button>
                            </>
                          )}
                        </Box>
                      }
                    />
                  )}
                />
                <Table>
                  <TableHead>
                    <TableFiltersRow
                      filters={filtersConfig}
                      orderBy={orderBy}
                      handleOrderChange={handleOrderChange}
                      isChildrenAtStart
                    >
                      <TableCell>
                        <IconButton
                          className={styles.expandIcon}
                          color="primary"
                          onClick={() => handleExpandAll()}
                          disableFocusRipple
                          disableRipple
                        >
                          {expanded.length > 0 ? <CheveronUp /> : <CheveronDown />}
                        </IconButton>
                      </TableCell>
                    </TableFiltersRow>
                  </TableHead>
                  <TableBody id="scrollableTableCollections">
                    {isLoadingCollections && !isLoadMore ? (
                      <TableLoader columnsCount={9} height={26} />
                    ) : (
                      aggregation?.data &&
                      aggregation.data?.length > 0 && (
                        <InfiniteScroll
                          dataLength={aggregation?.data?.length}
                          next={loadMore}
                          hasMore={aggregation?.data?.length < aggregation?.totals.totalItems}
                          loader={<TableLoader columnsCount={9} rowsCount={1} />}
                          scrollableTarget="scrollableTableCollections"
                        >
                          {aggregation.data?.map((item, index) => {
                            const isExpanded = expanded.includes(index)

                            return (
                              <>
                                <TableRow
                                  key={`${item.clientName}-${item.recordDate}`}
                                  className={cn({
                                    [styles.expandedRow]: isExpanded,
                                  })}
                                >
                                  <TableCell>
                                    {' '}
                                    <IconButton
                                      color="primary"
                                      className={styles.expandIcon}
                                      onClick={() => handleExpand(index)}
                                      disableFocusRipple
                                      disableRipple
                                    >
                                      {isExpanded ? <CheveronUp /> : <CheveronDown />}
                                    </IconButton>
                                  </TableCell>
                                  <TableCell className={genericSs.tableTextLeft}>
                                    <LinkButton
                                      component={Link}
                                      to={generatePath(ROUTES.CLIENT_PAGE, {
                                        id: item.clientId,
                                      })}
                                    >
                                      {item.clientName}
                                    </LinkButton>
                                    {item.clientStatus === ClientInfoStatus.Past && (
                                      <span className={genericSs.grayCard}>Past</span>
                                    )}
                                  </TableCell>

                                  <TableCell />
                                  <TableCell className={genericSs.tableTextLeft}>
                                    <span className={genericSs.pricePrefix}>$</span>
                                    {formatPrice(item.checksPaymentAmount)}
                                  </TableCell>
                                  <TableCell className={genericSs.tableTextLeft}>
                                    <span>{+item.checksCount || '-'}</span>
                                  </TableCell>
                                  <TableCell className={genericSs.tableTextRight}>
                                    <span className={genericSs.pricePrefix}>$</span>
                                    {formatPrice(item.wiresPaymentAmount)}
                                  </TableCell>
                                  <TableCell className={genericSs.tableTextRight}>
                                    <span className={genericSs.pricePrefix}>$</span>
                                    {formatPrice(+item.totalPaymentAmount)}
                                  </TableCell>
                                  <TableCell className={genericSs.tableTextLeft}>
                                    {formatDate(item.recordDate)}
                                  </TableCell>
                                  <TableCell />
                                </TableRow>
                                {isExpanded && (
                                  <CollectionAggregationRow
                                    clientName={item.clientName}
                                    recordDate={item.recordDate}
                                    data={item.items}
                                    parentIndex={index + 1}
                                    activeItems={existsActiveItems}
                                    activeItem={activeItem}
                                    handleSelectRow={handleSelectRow}
                                    handleMenuClick={handleClickMenu}
                                  />
                                )}
                              </>
                            )
                          })}
                        </InfiniteScroll>
                      )
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow className={styles.aggregationTableTotals}>
                      <TableCell />
                      <TableCell className={genericSs.tableTextLeft}>Total:</TableCell>
                      <TableCell />
                      <TableCell className={genericSs.tableTextLeft}>
                        <span className={genericSs.pricePrefix}>$</span>
                        {formatPrice(aggregation?.totals?.checksPaymentSum)}
                      </TableCell>
                      <TableCell />
                      <TableCell className={genericSs.tableTextRight}>
                        <span className={genericSs.pricePrefix}>$</span>
                        {formatPrice(aggregation?.totals?.wiresPaymentSum)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        <span className={genericSs.pricePrefix}>$</span>
                        {formatPrice(
                          +aggregation?.totals?.checksPaymentSum +
                            +aggregation?.totals?.wiresPaymentSum,
                        )}
                      </TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableFooter>
                </Table>

                <ActiveToolbar
                  activeItems={existsActiveItems}
                  className={styles.activeToolbar}
                  containerRef={wrapperRef}
                >
                  <div className={genericSs.tableTextLeft}>
                    {formatter.format(totalRow?.checksPaymentSum)}
                  </div>
                  <div>&nbsp;</div>
                  <div className={genericSs.tableTextRight}>
                    {formatter.format(totalRow?.wiresPaymentSum)}
                  </div>
                  <div className={genericSs.tableTextRight}>
                    {formatter.format(totalRow?.checksPaymentSum + totalRow?.wiresPaymentSum)}
                  </div>
                </ActiveToolbar>
                <Menu
                  open={actionsMenuOpen}
                  anchorEl={anchorEl}
                  onClose={handleCloseMenu}
                  className={styles.actionsMenu}
                >
                  <MenuItem
                    onClick={() => {
                      handleArchive(selectedItem)
                    }}
                  >
                    Mark as non-client related cash
                  </MenuItem>
                  <MenuItem onClick={handleEdit}>Edit</MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDelete(selectedItem)
                    }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </TableContainer>
              {isDeleteModalShown && selectedItem && (
                <WarningModal
                  warningMessage={
                    isPermanent
                      ? `This ${selectedItem.isWire ? 'wire' : 'check'} will be deleted.`
                      : `This  ${
                          selectedItem.isWire ? 'wire' : 'check'
                        } will be marked as non-client related cash.`
                  }
                  onConfirm={handleDeleteConfirm}
                  onCancel={handleDeleteCancel}
                  confirmText="Yes, confirm"
                  cancelText="Cancel"
                  isLoading={isButtonLoading?.value}
                />
              )}

              {isDeleteModalShown && !selectedItem && existsActiveItems.length > 1 && (
                <WarningModal
                  warningMessage={
                    isPermanent
                      ? 'These checks and wires will be deleted.'
                      : 'These checks and wires will be marked as non-client related cash.'
                  }
                  onConfirm={handleDeleteActiveItemsConfirm}
                  onCancel={handleDeleteActiveItemsCancel}
                  confirmText="Yes, confirm"
                  cancelText="Cancel"
                  isLoading={isButtonLoading?.value}
                />
              )}

              {isEditModalShown && selectedItem && (
                <Form
                  onSubmit={handleEditConfirm}
                  validate={itemValidate}
                  initialValues={itemInitialValues}
                  render={({
                    pristine,
                    invalid,
                    handleSubmit,
                  }: {
                    pristine: boolean
                    invalid: boolean
                    handleSubmit: any
                  }) => (
                    <Modal
                      open={isEditModalShown}
                      onCancel={handleEditCancel}
                      title={`Edit ${selectedItem.isWire ? 'Wire' : 'Check'} Mapping`}
                      footer={null}
                      size="small"
                    >
                      <div>
                        {selectedItem.isWire ? (
                          <div>
                            <div className={styles.rowTitle}> Client </div>
                            <div>
                              <Autocomplete
                                clearIcon={null}
                                label=""
                                name="clientName"
                                placeholder="Client"
                                autocompleteSize="large"
                                defaultValue={
                                  selectedItem.clientName
                                    ? {
                                        value: selectedItem.clientName,
                                        label: selectedItem.clientName,
                                      }
                                    : null
                                }
                                disabled={
                                  groupedClientsOptions[selectedItem.accountNumber]?.length > 1
                                }
                                options={groupedClientsOptions[selectedItem.accountNumber]}
                                getOptionValue={(option) => option.value}
                              />
                            </div>
                            <div className={styles.rowTitle}> Customer </div>
                            <div>
                              <CreatableSelectField
                                height="large"
                                label=""
                                name="debtor"
                                placeholder="Customer"
                                defaultValue={
                                  selectedItem.debtor
                                    ? {
                                        value: selectedItem.debtor,
                                        label: selectedItem.debtor,
                                      }
                                    : null
                                }
                                onAddValue={(debtorName) =>
                                  handleAddDebtor({
                                    name: debtorName,
                                    type: 'debtor',
                                    wireId: selectedItem.id,
                                    clientName: selectedItem.clientName,
                                  })
                                }
                                options={[]}
                                isAsync
                                loadOptions={loadDebtors}
                                getOptionValue={(option) => option.value}
                              />
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className={styles.rowTitle}> Client </div>
                            <div>
                              <Autocomplete
                                clearIcon={null}
                                label=""
                                name="clientName"
                                placeholder="Client"
                                autocompleteSize="large"
                                defaultValue={
                                  selectedItem.clientName
                                    ? {
                                        value: selectedItem.clientName,
                                        label: selectedItem.clientName,
                                      }
                                    : null
                                }
                                options={clientsData?.map(({ clientName }) => ({
                                  value: clientName,
                                  label: clientName,
                                }))}
                                getOptionValue={(option) => option.value}
                              />
                            </div>
                            <div className={styles.rowTitle}> Customer </div>
                            <div>
                              <CreatableSelectField
                                height="large"
                                label=""
                                name="checkAccount"
                                placeholder="Customer"
                                onAddValue={(debtorName) =>
                                  handleAddDebtor({
                                    checkId: selectedItem.id,
                                    accountNumber: selectedItem.accountNumber,
                                    name: debtorName,
                                    type: 'debtor',
                                  })
                                }
                                defaultValue={
                                  selectedItem.debtor
                                    ? {
                                        value: selectedItem.debtor,
                                        label: selectedItem.debtor,
                                      }
                                    : null
                                }
                                options={[]}
                                isAsync
                                loadOptions={loadDebtors}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <Box
                        mt={2}
                        className={styles.editRowModalFooter}
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Button
                          key="submit"
                          color="primary"
                          variant="contained"
                          onClick={handleSubmit}
                          disabled={invalid || pristine}
                          className={styles.submitModalButton}
                          small={false}
                          isLoading={isButtonLoading?.type === 'edit' && isButtonLoading?.value}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Modal>
                  )}
                />
              )}
            </Grid>
          )}

          {currentTab === COLLECTIONS_TABS.NON_CLIENT_RELATED_CASH && (
            <Grid item xs={12}>
              <DeletedCheckTable />
            </Grid>
          )}
        </Grid>
      </Card>
    </Box>
  )
}

export default CollectionsDashboardPage
