import React, { useCallback, useMemo, useState } from 'react'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import cn from 'classnames'

import styles from './LCRCommentary.module.scss'
import { ReactComponent as ReplyIcon } from '@assets/images/reply-icon.svg'

import { ILCRCommentary } from '@common/interfaces/dueDiligence'
import AddComment from '../Notes/AddComment'
import LCRCommentaryNoteEditor from './LCRCommentaryNoteEditor'
import LCRCommentaryFieldReplies from './LCRCommentaryFieldReplies'
import WarningModal from '../WarningModal'
import { MenuIcon } from '../Common/Icons'
import { handleStopPropagation } from '../../helpers/helpers'
import LCRCommentaryFieldModal from './LCRCommentaryFieldModal'

interface IProps {
  lcrCommentary: ILCRCommentary
  handleEdit: (id: string, values: ILCRCommentary) => Promise<any>
  handleSaveNote: (id: string, values: object) => Promise<any>
  handleDelete: (id: string) => Promise<void>
  readOnly: boolean
  isFullWidth: boolean
  isSaving: boolean
}

const LCRCommentaryField = ({
  lcrCommentary,
  handleEdit,
  handleSaveNote,
  handleDelete,
  readOnly,
  isFullWidth,
  isSaving,
}: IProps) => {
  const { id, field, helperText, note } = lcrCommentary

  const [isReplyShown, setIsReplyShown] = useState(false)
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [isEditModalShown, setIsEditModalShown] = useState(false)
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)

  const isMenuOpen = useMemo(() => Boolean(menuAnchorEl), [menuAnchorEl])

  const onEdit = useCallback(
    async (values: ILCRCommentary) => {
      const result = await handleEdit(id, values)
      if (!result.error) {
        setIsEditModalShown(false)
      }
    },
    [id, handleEdit],
  )
  const onUpdate = useCallback((values: object) => handleSaveNote(id, values), [id, handleSaveNote])

  const noteValue = useMemo(
    () => note?.note,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [note.id],
  )

  const toggleReplyVisibility = useCallback((event?: React.MouseEvent) => {
    event?.stopPropagation()
    setIsReplyShown((isShown) => !isShown)
  }, [])

  const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setMenuAnchorEl(event.currentTarget)
  }, [])

  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null)
  }, [])

  const handleEditModalToggle = useCallback(() => {
    setMenuAnchorEl(null)
    setIsEditModalShown((isShown) => !isShown)
  }, [])

  const handleDeleteModalToggle = useCallback(() => {
    setMenuAnchorEl(null)
    setIsDeleteModalShown((isShown) => !isShown)
  }, [])

  const handleDeleteConfirm = useCallback(() => handleDelete(id), [id, handleDelete])

  return (
    <Grid item xl={isFullWidth ? 12 : 6} xs={12} className={styles.fieldWrapper}>
      <div className={styles.fieldHeader}>
        <div className={styles.fieldTitle}>{field}</div>
        {!readOnly && (
          <div
            className={cn(styles.fieldIcons, {
              [styles.fieldIconsActive]: isMenuOpen,
            })}
          >
            <Tooltip title="Reply">
              <div className={styles.fieldIcon} onClick={toggleReplyVisibility}>
                <ReplyIcon />
              </div>
            </Tooltip>

            <MenuIcon onClick={handleMenuOpen} isActive={isMenuOpen} size="small" />

            <Menu
              open={isMenuOpen}
              onClose={handleMenuClose}
              anchorEl={menuAnchorEl}
              disableScrollLock={false}
              onClick={handleStopPropagation}
            >
              <MenuItem onClick={handleEditModalToggle}>Edit</MenuItem>
              <MenuItem onClick={handleDeleteModalToggle}>Delete</MenuItem>
            </Menu>
          </div>
        )}
      </div>
      {helperText && <div className={styles.helperText}>{helperText}</div>}

      <LCRCommentaryNoteEditor
        key={note?.id}
        value={noteValue}
        handleUpdate={onUpdate}
        readOnly={readOnly}
        noteText={note?.noteText}
      />

      <LCRCommentaryFieldReplies note={note} readOnly={readOnly} />

      {!readOnly && isReplyShown && <AddComment parent={note} hideReply={toggleReplyVisibility} />}

      {isEditModalShown && (
        <LCRCommentaryFieldModal
          item={lcrCommentary}
          isSaving={isSaving}
          onSave={onEdit}
          onClose={handleEditModalToggle}
        />
      )}

      {isDeleteModalShown && (
        <WarningModal
          warningMessage="Deleting this commentary is irreversible."
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteModalToggle}
          confirmText="Delete"
          cancelText="Cancel"
          isLoading={isSaving}
        />
      )}
    </Grid>
  )
}

export default LCRCommentaryField
