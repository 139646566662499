import React, { useCallback, useMemo, useState } from 'react'
import sortBy from 'lodash/orderBy'

import styles from './LCRCommentary.module.scss'

import Note from '../Notes/Note'
import { INote } from '@common/interfaces/notes'

interface IProps {
  note: INote
  readOnly: boolean
}

const LCRCommentaryFieldReplies = ({ note }: IProps) => {
  const [isAllRepliesShown, setIsAllRepliesShown] = useState(false)

  const toggleReplyVisibility = useCallback((event?: React.MouseEvent) => {
    event?.stopPropagation()
    setIsAllRepliesShown((isShown) => !isShown)
  }, [])

  const isResolvedRepliesExists = useMemo(
    () => note?.children?.filter(({ resolved }) => resolved).length > 0,
    [note],
  )

  const notes = useMemo(
    () =>
      sortBy(
        (note?.children || []).filter(({ resolved }) => !resolved || isAllRepliesShown),
        ['resolved', 'pinned', 'createdAt'],
        ['asc', 'asc', 'asc'],
      ),
    [note, isAllRepliesShown],
  )

  if (!note?.children?.length) {
    return null
  }

  return (
    <div className={styles.repliesContainer}>
      {notes.map((child) => (
        <Note key={child.id} note={child} isChild />
      ))}

      {isResolvedRepliesExists && (
        <div className={styles.repliesShowMoreButton} onClick={toggleReplyVisibility}>
          {isAllRepliesShown ? 'Show less' : 'Show All Replies'}
        </div>
      )}
    </div>
  )
}

export default LCRCommentaryFieldReplies
