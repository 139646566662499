import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import EntitySummaryTable from './EntityReportSummaryTable'

import { entityArSummary, entityApSummary } from '../../redux/entityInfo/selectors'
import { getEntityArSummary, getEntityApSummary } from '../../redux/entityInfo/actions'
import { entity as entityInfo } from '../../redux/entityInfo/selectors'
import { debtorCategories } from '../../redux/options/selectors'

const selector = createStructuredSelector({
  entityArSummaryData: entityArSummary,
  entityApSummaryData: entityApSummary,
  entityInfo,
  debtorCategories,
})

const actions = {
  getEntityArSummary,
  getEntityApSummary,
}

export default connect(selector, actions)(EntitySummaryTable)
