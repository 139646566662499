import React, { useCallback, useState } from 'react'

import { IAccountActivity, IClientInfo } from '@common/interfaces/client'
import WarningModal from '../../WarningModal'

interface IProps {
  clientInfo: IClientInfo
  deleteAccountActivity: (clientId: string, data: object) => Promise<void>
  activeItems: IAccountActivity[]
  handleCancel: () => void
  handleConfirm: () => void
}

const ClientAccountActivityDeleteModal = ({
  clientInfo,
  deleteAccountActivity,
  activeItems,
  handleCancel,
  handleConfirm,
}: IProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleDeleteConfirm = useCallback(async () => {
    setIsLoading(true)
    await deleteAccountActivity(clientInfo.id, {
      data: activeItems
        .filter((row) => row.isDeletable && !row.isDeleted)
        .map(({ id, type }) => ({ id, type })),
    })
    handleConfirm()
    setIsLoading(false)
  }, [clientInfo, activeItems, deleteAccountActivity, handleConfirm])

  return (
    <WarningModal
      warningMessage="Account activity will be deleted and current amount will be recalculated."
      onConfirm={handleDeleteConfirm}
      onCancel={handleCancel}
      confirmText="Delete"
      cancelText="Cancel"
      isLoading={isLoading}
    />
  )
}

export default ClientAccountActivityDeleteModal
