import { combineReducers } from 'redux'
import * as actions from './actions'
import * as bbcActions from '../bbc/actions'
import {
  IAccountActivityGraphData,
  IAccountActivityData,
  IAmendmentsData,
  IClientBanner,
  IClientCashFlowData,
  IClientComplianceData,
  IClientInfo,
  IClientLoanLedgerData,
  IClientsAggregation,
  ICollectionsReconciliationData,
  ICollectionsReconciliationOptions,
  IInventoryHealthData,
  IRiskRatingData,
  IRiskRatingSummaryData,
  ISalesChannelDistributionData,
  ISalesChannelDistributionOptions,
} from '@common/interfaces/client'
import {
  IExtraReserveData,
  IArIneligibilityData,
  IFundingRequest,
  IInventoryIneligibilityCustomRule,
  IInventoryIneligibilityData,
  IInventoryIneligibilityField,
  IOveradvance,
} from '@common/interfaces/bbc'
import { IFile } from '@common/interfaces/box'
import { IUser } from '@common/interfaces/user'
import { ITermLoan } from '@common/interfaces/termLoan'
import {
  UPDATE_FUNDING_REQUEST,
  UPDATE_LOAN_BALANCE,
  CODAT_PORTFOLIO_SYNC,
} from '@common/constants/webSockets'
import { loadingArrayReducer } from '../../helpers/helpers'
import { ILoadingData } from '../types'
import { createLoadingDataReducer } from '../../helpers/redux'
import { StressCaseData } from '@common/interfaces/stressCase'
import { IPendingCashDominionData } from '@common/interfaces/bankTransactions'

interface IDocuments {
  amendments: IFile[]
  lcr: IFile[]
  lsa: IFile[]
  ucc: IFile[]
  warehouseWaivers: IFile[]
  insurance: IFile[]
  payoff: IFile[]
}

export interface IClientInfoState {
  loadingArray: string[]
  clients: ILoadingData<{ data: IClientInfo[] }>
  clientInfo: IClientInfo
  documents: IDocuments
  RMUsers: ILoadingData<{ data: IUser[] }>
  managers: ILoadingData<{ data: IUser[] }>
  accountActivity: ILoadingData<IAccountActivityData>
  fundingRequest: IFundingRequest
  clientsAggregation: ILoadingData<IClientsAggregation>
  overadvances: IOveradvance[]
  complianceData: ILoadingData<IClientComplianceData>
  termLoans: ITermLoan[]
  inventoryEligibilityData: ILoadingData<IInventoryIneligibilityData>
  fields: ILoadingData<{ data: IInventoryIneligibilityField[] }>
  customRules: ILoadingData<IInventoryIneligibilityCustomRule[]>
  customRulesLabels: string[]
  customRulesValues: { [key: string]: string[] }
  amendmentsData: IAmendmentsData
  banners: IClientBanner[]
  arEligibilityData: ILoadingData<IArIneligibilityData>
  apEligibilityData: ILoadingData<IExtraReserveData>
  financialsReports: ILoadingData<{
    asOfDates: string[]
    startDate: string
    projectionsStartDate: string
  }>
  riskRatings: ILoadingData<IRiskRatingData>
  inventoryHealth: ILoadingData<IInventoryHealthData>
  cashFlowInfo: ILoadingData<IClientCashFlowData>
  customers: ILoadingData<{
    data: string[]
  }>
  refreshCount: number
  accountActivityGraph: ILoadingData<IAccountActivityGraphData>
  loanLedger: ILoadingData<IClientLoanLedgerData>
  riskRatingDetails: ILoadingData<IRiskRatingSummaryData>
  stressCase: ILoadingData<StressCaseData>
  collectionsReconciliation: ILoadingData<ICollectionsReconciliationData>
  collectionsReconciliationOptions: ILoadingData<ICollectionsReconciliationOptions>
  salesChannelDistribution: ILoadingData<ISalesChannelDistributionData>
  salesChannelDistributionOptions: ILoadingData<ISalesChannelDistributionOptions>
  pendingCashDominion: ILoadingData<IPendingCashDominionData>
}

export default combineReducers<IClientInfoState>({
  loadingArray: loadingArrayReducer(
    [actions.prefix],
    [
      actions.LIST_INVENTORY_ELIGIBLE_CATEGORIES_REQUEST,
      actions.LIST_MASTER_INVENTORY_REQUEST,
      actions.LIST_INVENTORY_ELIGIBLE_FIELDS_REQUEST,
      actions.UPDATE_INVENTORY_INELIGIBILITY_FIELDS_REQUEST,
      actions.LIST_AR_ELIGIBILITY_REQUEST,
      actions.LIST_AP_ELIGIBILITY_REQUEST,
      actions.UPDATE_AP_ELIGIBILITY_REQUEST,
      actions.LIST_COMPLIANCE_REQUEST,
      actions.LIST_CLIENT_FINANCIALS_REPORTS_REQUEST,
      actions.LIST_AMORTIZATION_SCHEDULE_REQUEST,
      actions.UPDATE_CLIENT_TERM_LOAN_REQUEST,
      actions.CREATE_TERM_LOAN_ACTIVITY_REQUEST,
      actions.DELETE_TERM_LOAN_ACTIVITY_REQUEST,
      actions.UPDATE_TERM_LOAN_ACTIVITY_REQUEST,
    ],
  ),
  clients: createLoadingDataReducer<{ data: IClientInfo[] }>([actions.LIST_REQUEST], [], true),

  clientInfo(state: IClientInfo = null, action) {
    switch (action.type) {
      case actions.SHOW_SUCCESS:
      case actions.UPDATE_SUCCESS:
      case actions.UPDATE_CLIENT_UNDERWRITING_SUCCESS:
      case actions.UPDATE_ITEMS_TO_MONITOR_SUCCESS:
        return action.data
      case actions.CALCULATE_TERMINATE_SUCCESS:
      case actions.TERMINATE_SUCCESS:
        return {
          ...state,
          ...action.data,
        }
      case UPDATE_LOAN_BALANCE:
        if (!state || state.id !== action.data.clientId) {
          return state
        }
        return {
          ...state,
          currentAmount: action.data.loanBalance,
          totalLoanBalance: action.data.totalLoanBalance,
          totalParticipantExposureAmount: action.data.totalParticipantExposureAmount,
          totalNFE: action.data.totalNFE,
        }
      case actions.HIDE:
        return null
      default:
        return state
    }
  },
  documents(state: IDocuments = null, action) {
    switch (action.type) {
      case actions.LIST_DOCUMENTS_SUCCESS:
        return action.data
      default:
        return state
    }
  },

  RMUsers: createLoadingDataReducer<{ data: IUser[] }>([actions.LIST_RM_USERS_REQUEST], [], true),

  managers: createLoadingDataReducer<{ data: IUser[] }>(
    [actions.LIST_MANAGERS_REQUEST],
    [
      actions.ADD_MANAGER_REQUEST,
      actions.REMOVE_MANAGER_REQUEST,
      actions.UPDATE_MANAGER_REQUEST,
      actions.NOTIFY_REQUEST,
    ],
    true,
  ),
  accountActivity: createLoadingDataReducer<IAccountActivityData>([
    actions.LIST_ACCOUNT_ACTIVITY_SUCCESS,
  ]),
  fundingRequest(state: IFundingRequest = null, action) {
    switch (action.type) {
      case actions.GET_REQUEST_FUNDING_SUCCESS:
      case actions.REQUEST_FUNDING_SUCCESS:
      case actions.MODIFY_FUNDING_SUCCESS:
      case actions.CANCEL_FUNDING_SUCCESS:
        return action.data
      case UPDATE_FUNDING_REQUEST:
        return {
          ...state,
          bbcSignatureFile: action.data,
        }
      default:
        return state
    }
  },
  clientsAggregation: createLoadingDataReducer<IClientsAggregation>(
    [actions.LIST_CLIENTS_AGGREGATION_SUCCESS],
    [UPDATE_LOAN_BALANCE],
    true,
    [],
    true,
  ),

  overadvances(state: IOveradvance[] = [], action) {
    switch (action.type) {
      case actions.LIST_CLIENT_OVERADVANCE_SUCCESS:
        return action.data.data
      default:
        return state
    }
  },
  complianceData: createLoadingDataReducer<IClientComplianceData>(
    [actions.LIST_COMPLIANCE_SUCCESS],
    [actions.UPDATE_SUCCESS],
  ),
  termLoans(state: ITermLoan[] = [], action) {
    switch (action.type) {
      case actions.LIST_CLIENT_TERM_LOAN_SUCCESS:
        return action.data
      case actions.UPDATE_CLIENT_TERM_LOAN_SUCCESS:
        return state.map((termLoan) =>
          termLoan.id === action.data.id
            ? {
                ...termLoan,
                ...action.data,
              }
            : termLoan,
        )
      case actions.CREATE_CLIENT_TERM_LOAN_SUCCESS:
        return [action.data, ...state]

      case actions.DELETE_CLIENT_TERM_LOAN_SUCCESS:
        return state.filter((termLoan) => termLoan.id !== action.data.id)

      case actions.LIST_AMORTIZATION_SCHEDULE_SUCCESS:
        const termLoanId = action?.data?.termLoanId
        return state.map((termLoan) =>
          termLoan.id === termLoanId
            ? {
                ...termLoan,
                amortizationSchedule: action.data.data,
              }
            : termLoan,
        )
      default:
        return state
    }
  },
  inventoryEligibilityData: createLoadingDataReducer<IInventoryIneligibilityData>(
    [actions.LIST_MASTER_INVENTORY_REQUEST],
    [bbcActions.UPDATE_MASTER_INVENTORY_REQUEST],
  ),
  fields: createLoadingDataReducer<{ data: IInventoryIneligibilityField[] }>(
    [actions.LIST_INVENTORY_ELIGIBLE_FIELDS_REQUEST],
    [
      bbcActions.CREATE_INVENTORY_INELIGIBLE_FIELDS_REQUEST,
      bbcActions.DELETE_INVENTORY_INELIGIBLE_FIELDS_REQUEST,
      actions.UPDATE_INVENTORY_INELIGIBILITY_FIELDS_REQUEST,
    ],
  ),
  customRules: createLoadingDataReducer<IInventoryIneligibilityCustomRule[]>(
    [actions.LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_REQUEST],
    [actions.UPDATE_INVENTORY_INELIGIBILITY_FIELDS_REQUEST],
  ),
  customRulesLabels(state: string[] = [], action) {
    switch (action.type) {
      case actions.LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_LABELS_SUCCESS:
        return action.data
      default:
        return state
    }
  },
  customRulesValues(state: { [key: string]: string[] } = {}, action) {
    switch (action.type) {
      case actions.LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_VALUES_SUCCESS:
        return {
          ...state,
          [action.field]: action.data,
        }
      default:
        return state
    }
  },
  amendmentsData(state: IAmendmentsData = null, action) {
    switch (action.type) {
      case actions.LIST_AMENDMENTS_SUCCESS:
        if (!action?.params?.loadMore) {
          return action.data
        } else {
          return {
            ...state,
            amendments: [...state.amendments, ...action.data.amendments],
          }
        }
      case actions.CREATE_AMENDMENT_SUCCESS:
        return {
          ...state,
          amendments: [action.data, ...state.amendments],
        }
      case actions.UPDATE_AMENDMENT_SUCCESS:
        return {
          ...state,
          amendments: state.amendments.map((amendment) =>
            amendment.id === action.data.id ? action.data : amendment,
          ),
        }
      case actions.DELETE_AMENDMENT_SUCCESS:
        return {
          ...state,
          amendments: state.amendments.filter((amendment) => amendment.id !== action.data.id),
        }
      case actions.LIST_OVERADVANCE_PAYMENTS_SUCCESS:
        return {
          ...state,
          amendments: state.amendments.map((term) => {
            if (term?.overadvance?.id === action.data?.[0]?.overadvanceId) {
              return {
                ...term,
                overadvance: {
                  ...(term.overadvance as IOveradvance),
                  overadvancePayments: action.data,
                },
              }
            }
            return term
          }),
        }

      default:
        return state
    }
  },
  banners(state: IClientBanner[] = [], action) {
    switch (action.type) {
      case actions.GET_BANNER_SUCCESS:
        return action.data
      case actions.CLOSE_BANNER_SUCCESS:
        return state.filter(({ id }) => id !== action.data.id)
      default:
        return state
    }
  },
  arEligibilityData: createLoadingDataReducer<IArIneligibilityData>(
    [actions.LIST_AR_ELIGIBILITY_REQUEST],
    [bbcActions.UPDATE_AR_INELIGIBILITY_REQUEST],
  ),
  apEligibilityData: createLoadingDataReducer<IExtraReserveData>(
    [actions.LIST_AP_ELIGIBILITY_REQUEST],
    [actions.UPDATE_AP_ELIGIBILITY_REQUEST],
  ),
  financialsReports: createLoadingDataReducer<{
    asOfDates: string[]
    startDate: string
    projectionsStartDate: string
  }>([actions.LIST_CLIENT_FINANCIALS_REPORTS_REQUEST], [], false, [
    actions.HIDE_CLIENT_FINANCIALS_REPORTS,
  ]),
  riskRatings: createLoadingDataReducer<IRiskRatingData>([actions.LIST_RISK_RATING_REQUEST]),
  riskRatingDetails: createLoadingDataReducer<IRiskRatingSummaryData>(
    [actions.LIST_RISK_RATING_DETAILS_REQUEST],
    [UPDATE_LOAN_BALANCE],
    true,
    [],
    true,
    (state: any, action: any) => ({
      ...state,
      data: {
        ...state.data,
        data: state?.data?.data?.map((item: any) =>
          item.clientInfo?.id === action.data.clientId
            ? {
                ...item,
                clientInfo: {
                  ...item.clientInfo,
                  loanBalance:
                    action.data.recordDate === item.clientInfo.loanBalance.recordDate
                      ? {
                          ...item.clientInfo.loanBalance,
                          loanBalance: action.data.loanBalance,
                        }
                      : item.clientInfo.loanBalance,
                },
              }
            : item,
        ),
      },
    }),
  ),
  inventoryHealth: createLoadingDataReducer<IInventoryHealthData>([
    actions.LIST_INVENTORY_HEALTH_REQUEST,
  ]),
  cashFlowInfo: createLoadingDataReducer<IClientCashFlowData>([
    actions.SHOW_CASH_FLOW_SUMMARY_INFO_REQUEST,
  ]),
  customers: createLoadingDataReducer<{
    data: string[]
  }>([actions.LIST_CUSTOMERS_REQUEST]),
  refreshCount: (state = 0, action) => {
    switch (action.type) {
      case actions.REFRESH_COUNT:
      case CODAT_PORTFOLIO_SYNC:
        return state + 1
    }

    return state
  },
  accountActivityGraph: createLoadingDataReducer<IAccountActivityGraphData>([
    actions.ACCOUNT_ACTIVITY_GRAPH_REQUEST,
  ]),
  loanLedger: createLoadingDataReducer<IClientLoanLedgerData>([actions.LIST_LOAN_LEDGER_REQUEST]),
  stressCase: createLoadingDataReducer<StressCaseData>(
    [actions.LIST_STRESS_CASE_ASSUMPTIONS_REQUEST],
    [actions.UPDATE_STRESS_CASE_ASSUMPTION_REQUEST],
  ),
  collectionsReconciliation: createLoadingDataReducer<ICollectionsReconciliationData>(
    [actions.LIST_COLLECTIONS_RECONCILIATION_REQUEST],
    [],
    false,
    [actions.HIDE_COLLECTIONS_RECONCILIATION],
  ),
  collectionsReconciliationOptions: createLoadingDataReducer<ICollectionsReconciliationOptions>([
    actions.LIST_COLLECTIONS_RECONCILIATION_OPTIONS_REQUEST,
  ]),
  salesChannelDistribution: createLoadingDataReducer<ISalesChannelDistributionData>(
    [actions.LIST_SALES_CHANNEL_DISTRIBUTION_REQUEST],
    [],
    false,
    [actions.HIDE_SALES_CHANNEL_DISTRIBUTION],
  ),
  salesChannelDistributionOptions: createLoadingDataReducer<ISalesChannelDistributionOptions>([
    actions.LIST_SALES_CHANNEL_DISTRIBUTION_OPTIONS_REQUEST,
  ]),
  pendingCashDominion: createLoadingDataReducer<IPendingCashDominionData>([
    actions.LIST_PENDING_CASH_DOMINION_REQUEST,
  ]),
})
