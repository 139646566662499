import React, { useCallback, useMemo, useState, useContext, useRef, useEffect } from 'react'
import MuiAutocomplete from '@mui/material/Autocomplete'
import { createFilterOptions } from '@mui/base'
import MuiTextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { AutocompleteProps } from 'mui-rff'
import { Field, FieldInputProps } from 'react-final-form'
import cn from 'classnames'

import styles from './CreatableSelectField.module.scss'
import genericSs from '@styles/generic.module.scss'
import { ReactComponent as PlusIcon } from '../../../assets/images/add-select-field.svg'

import { debounceEventHandler } from '../../../helpers/helpers'

import { TableCellContext } from '../TableCell/TableCell'
import useLoadedOptions from '../../../hooks/useLoadedOptions'

export interface IOptionType {
  label?: string
  value: string
  inputValue?: string
}

interface IProps extends Partial<AutocompleteProps<IOptionType, false, false, false>> {
  name: string
  onAddValue: (value: string) => void
  style?: object
  placeholder?: string
  onChangeCustom?: (event: object, newValue: { value: string; label: string }) => void
  onChangeCustomWithLabel?: (input: FieldInputProps<any, HTMLElement>, newValue: object) => void
  disabledClearable?: boolean
  leftIcon?: React.ReactNode
  height?: 'small' | 'medium' | 'large'
  isAsync?: boolean
  loadOptions?: (value: string) => Promise<IOptionType[]>
  withBorder?: boolean
  inTable?: boolean
}

const filter = createFilterOptions<IOptionType>()

const CreatableSelectField = ({
  name,
  value,
  options,
  placeholder,
  style,
  onAddValue,
  helperText,
  disabled,
  disabledClearable,
  onFocus,
  onBlur,
  tabIndex,
  onChangeCustom,
  onChangeCustomWithLabel,
  leftIcon,
  height = 'small',
  isAsync = false,
  loadOptions,
  clearOnBlur,
  inTable = false,
  withBorder,
}: IProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const context = useContext(TableCellContext)
  const isActive = useMemo(() => context?.isActive, [context])
  const inputRef = useRef<HTMLInputElement>(null)

  const onChangeDefault = useCallback(
    (newValue: any, input: any) => {
      if (typeof newValue === 'string') {
        setTimeout(() => {
          onAddValue(newValue)
        })
        input.onChange({ value: newValue, label: newValue })
      } else if (newValue && newValue.inputValue) {
        onAddValue(newValue.inputValue)
        input.onChange({ value: newValue.inputValue, label: newValue.inputValue })
      } else {
        input.onChange(newValue)
      }
    },
    [onAddValue],
  )

  const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      e.stopPropagation()
    }
  }, [])

  const { asyncOptions, handleInputChange } = useLoadedOptions({
    isAsync,
    loadOptions,
  })

  const debounceHandleInputChange = useMemo(
    () => debounceEventHandler(handleInputChange, 500),
    [handleInputChange],
  )

  const getValue = useCallback((option?: IOptionType) => {
    return typeof option === 'string'
      ? option
      : option?.value !== undefined && option?.label === option?.value
      ? option.value
      : option?.label !== undefined
      ? option
      : option?.value
      ? option.value
      : option?.label === undefined
      ? null
      : option
  }, [])

  const handleOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  useEffect(() => {
    if (inputRef.current && isActive && inTable) {
      inputRef.current.focus()
      // @ts-ignore
      handleOpen()
    }
  }, [isActive, handleOpen, inTable, inputRef, name])

  const renderedValue = useMemo(() => {
    if (value) {
      if (typeof value === 'object') {
        return value.label || value.value
      }
      return value
    }
    return placeholder
  }, [value, placeholder])

  const handleOnBlur = useCallback(
    (event: React.FocusEvent<HTMLDivElement>, input: FieldInputProps<any, HTMLElement>) => {
      input.onBlur()
      if (onBlur) {
        onBlur(event)
      }
    },
    [onBlur],
  )

  return (
    <>
      {!isActive && inTable && (
        <div
          className={cn({
            [genericSs.inactiveFormField]: !value,
            syntheticInputField: inTable,
          })}
        >
          <span>{renderedValue}</span>
        </div>
      )}
      {isActive || !inTable ? (
        <Field
          name={name}
          className={cn({
            tableFormField: inTable,
          })}
          subscription={{ value: true, error: true, touched: true }}
          render={({ input, meta }) => {
            const { error, touched } = meta || {}
            const isError = !!error && touched
            const errorMessage = typeof error === 'object' && 'value' in error ? error.value : error

            return (
              <>
                <MuiAutocomplete
                  key={options?.length}
                  value={getValue(value || input.value)}
                  className={cn({
                    tableFormField: inTable,
                    [styles.inputFieldLargeHeight]: height === 'large',
                  })}
                  open={isOpen}
                  onChange={(event, newValue) =>
                    // @ts-ignore
                    onChangeCustomWithLabel
                      ? // @ts-ignore
                        onChangeCustomWithLabel(input, newValue)
                      : onChangeCustom
                      ? // @ts-ignore
                        onChangeCustom(event, newValue)
                      : onChangeDefault(newValue, input)
                  }
                  filterOptions={(currentOptions, params) => {
                    // @ts-ignore
                    const filtered = filter(currentOptions, params)

                    // Suggest the creation of a new value
                    if (
                      params.inputValue !== '' &&
                      filtered.findIndex((option) => option.value === params.inputValue) === -1
                    ) {
                      filtered.push({
                        inputValue: params.inputValue,
                        value: params.inputValue,
                        label: `New Value: ${params.inputValue}`,
                      })
                    }

                    return filtered
                  }}
                  selectOnFocus
                  handleHomeEndKeys
                  options={isAsync ? (asyncOptions as IOptionType[]) : options}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue
                    }
                    // Regular option
                    return option.label
                  }}
                  // tslint:disable-next-line:no-shadowed-variable
                  isOptionEqualToValue={(option: any, value: any) =>
                    option?.value === (value && value?.value ? value.value : value)
                  }
                  renderOption={(props, option: any) =>
                    option.label && option.label.includes('New Value: ') ? (
                      <li {...props} key={option.value}>
                        {' '}
                        {option.value} <PlusIcon className={styles.endAdorn} />
                      </li>
                    ) : (
                      <li {...props} key={option.value}>
                        {option.label}
                      </li>
                    )
                  }
                  style={style}
                  tabIndex={tabIndex}
                  freeSolo
                  disableClearable={disabledClearable}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      inputRef={inTable ? inputRef : params.InputProps.ref}
                      placeholder={placeholder}
                      helperText={isError ? errorMessage : helperText}
                      error={isError}
                      FormHelperTextProps={{ classes: { root: styles.error } }}
                      InputProps={{
                        ...params.InputProps,
                        classes: {
                          error: styles.inputError,
                        },
                        className: cn(styles.inputField, {
                          [styles.withBorder]: withBorder,
                          [styles.inputFieldMediumHeight]: height === 'medium',
                          [styles.inputFieldLargeHeight]: height === 'large',
                          [styles.withEndAdornment]: !disabledClearable && inTable,
                        }),
                        startAdornment: leftIcon ? (
                          <InputAdornment position="start"> {leftIcon}</InputAdornment>
                        ) : null,
                        disableUnderline: true,
                      }}
                      variant="standard"
                      onKeyDown={(e) => handleKeyDown(e)}
                    />
                  )}
                  disabled={disabled}
                  classes={{
                    inputRoot: styles.input,
                    popper: styles.popper,
                  }}
                  onBlur={(event) => handleOnBlur(event, input)}
                  onFocus={onFocus}
                  onInputChange={debounceHandleInputChange}
                  clearOnBlur={clearOnBlur}
                  onOpen={handleOpen}
                  onClose={handleClose}
                />
              </>
            )
          }}
        />
      ) : null}
    </>
  )
}

CreatableSelectField.defaultProps = {
  height: 'small',
}

export default CreatableSelectField
