import React, { useState, useCallback, useMemo, SetStateAction, Dispatch } from 'react'
import { useParams } from 'react-router'
import Grid from '@mui/material/Grid'
import styles from './OngoingReportingFiles.module.scss'
import { ReactComponent as UploadFileIcon } from '../../assets/images/upload-file.svg'
import { IFile } from '@common/interfaces/box'
import Button from '../../components/Common/Button'
import Modal from '../../components/Common/Modal'
import { MultipleFileSelect } from '../FileSelect'
import UploadFile from '../Common/UploadFile'
import { IOngoingReporting, FILE_TYPE_TEMPLATES } from '@common/interfaces/bbc'
import { ReactComponent as BackIcon } from '../../assets/images/back-icon.svg'
import Tooltip from '@mui/material/Tooltip'
import SaveState from '../Common/SaveState'
import SelectedFiles from '../SelectedFiles'

interface IProps {
  isLoading: boolean
  ongoingReporting: IOngoingReporting
  files: IFile[]
  fileSheets: { id?: string; fileId: string; sheetName: string }[]
  handleSelectFiles: (selectedDocuments: { fileId: string; sheetName: string }[]) => Promise<any>
  isDisabled?: boolean
  showOngoingReporting: (id: string) => Promise<void>
  uploadFile: (data: FormData) => Promise<void>
  deleteFile: (ids: string[]) => Promise<void>
  handleOpenModal?: () => void
  handleCloseModal?: () => void
  isOpen: boolean
  downloadTemplate: (fileType?: string, fileId?: string) => void
  setRefreshCounter?: Dispatch<SetStateAction<number>>
  setIsFilesSaving?: Dispatch<SetStateAction<boolean>>
}
const OngoingReportingFiles = ({
  isLoading,
  ongoingReporting,
  files,
  fileSheets,
  handleSelectFiles,
  isDisabled = false,
  showOngoingReporting,
  uploadFile,
  deleteFile,
  handleOpenModal,
  handleCloseModal,
  isOpen,
  downloadTemplate,
  setRefreshCounter,
  setIsFilesSaving,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  const [isFilesLoading, setIsFilesLoading] = useState(false)
  const [isFilesSaved, setIsFilesSaved] = useState(false)
  const [uploadDocumentOpen, setUploadDocumentOpen] = useState(false)
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [filesForDelete, setFilesForDelete] = useState<IFile[]>([])

  const reportType = useMemo(() => ongoingReporting?.type?.replaceAll(' ', ''), [ongoingReporting])

  const modalTitle = useMemo(
    () => (uploadDocumentOpen ? 'Upload file' : 'Select file'),
    [uploadDocumentOpen],
  )

  const selectedDocuments = useMemo(
    () =>
      fileSheets.map(({ id, fileId, sheetName }) => {
        const file = files?.find(({ id }) => id === fileId) || ({} as IFile)

        return {
          ...file,
          sheetName,
          fileSheetId: id,
        }
      }) as IFile[],
    [files, fileSheets],
  )

  const onSelectFiles = useCallback(
    async (fileSheets: { fileId: string; sheetName: string }[]) => {
      setIsFilesSaving && setIsFilesSaving(true)
      setIsFilesLoading(true)
      setIsFilesSaved(false)

      if (isDisabled) {
        return
      }
      const result = await handleSelectFiles(fileSheets)
      !result?.error && setIsFilesSaved(true)
      setIsFilesLoading(false)
      if (result?.data?.success) {
        fileSheets.length > 0 && handleCloseModal && handleCloseModal()
      }
      setRefreshCounter && setRefreshCounter((counter) => counter + 1)
      setIsFilesSaving && setIsFilesSaving(false)
    },
    [
      isDisabled,
      handleSelectFiles,
      setIsFilesLoading,
      setIsFilesSaved,
      handleCloseModal,
      setRefreshCounter,
      setIsFilesSaving,
    ],
  )

  const handleUploadFile = useCallback(
    async (loadedFiles: File[]) => {
      if (loadedFiles?.length) {
        const formData = new FormData()

        const [file] = loadedFiles
        formData.append('files[]', file, file.name)
        formData.append('type', `${ongoingReporting.type}`)
        formData.append('ongoingReportingId', ongoingReporting.id)

        await uploadFile(formData)
        await showOngoingReporting(id)
      }
    },
    [uploadFile, showOngoingReporting, id, ongoingReporting],
  )

  const handleDeleteFiles = useCallback(
    (indexes: number[]) => {
      setIsDeleteModalShown(true)
      setFilesForDelete(
        indexes
          .sort((a, b) => b - a)
          .map((number, index) => files[number === undefined ? index : number]),
      )
    },
    [files],
  )

  const handleDeleteCancel = useCallback(() => {
    setIsDeleteModalShown(false)
    setFilesForDelete([])
  }, [])

  const handleDeleteConfirm = useCallback(async () => {
    await deleteFile(filesForDelete.map((file) => file.id))
    await showOngoingReporting(id)
    setIsDeleteModalShown(false)
    setFilesForDelete([])
  }, [deleteFile, filesForDelete, showOngoingReporting, id])

  const reportingFiles = useMemo(
    () =>
      (files || []).map((file) => ({
        ...file,
        sheetName: fileSheets.find(({ fileId }) => file.id === fileId)?.sheetName || null,
      })),
    [files, fileSheets],
  )

  const handleClose = useCallback(() => {
    if (!isFilesLoading) {
      handleCloseModal()
    }
  }, [handleCloseModal, isFilesLoading])

  return (
    <Modal
      title={
        <Grid container justifyContent={'flex-start'} alignItems={'center'}>
          <Grid item>
            {uploadDocumentOpen && (
              <BackIcon
                className={styles.backButton}
                onClick={() => setUploadDocumentOpen(false)}
              />
            )}
          </Grid>
          <Grid item>
            <h2 className={styles.modalTitle}>{modalTitle}</h2>
          </Grid>
        </Grid>
      }
      open={isOpen}
      classes={{
        root: styles.modalPaper,
      }}
      onCancel={handleClose}
    >
      <Grid container spacing={1}>
        {!uploadDocumentOpen ? (
          <Grid container item xs={12}>
            <SelectedFiles
              selectedDocuments={selectedDocuments}
              handleSelectMultipleFiles={onSelectFiles}
            />
            <Grid item xs={12}>
              <h3 className={styles.filesListItemTitle}>{reportType}</h3>
            </Grid>
            <Grid item xs={12}>
              <div className={styles.filesList}>
                <div className={styles.filesListItem}>
                  <MultipleFileSelect
                    updateFiles={onSelectFiles}
                    files={reportingFiles}
                    className={styles.filesListSelect}
                    selectedDocuments={fileSheets}
                  />
                </div>
              </div>
            </Grid>
            <Grid container mt={2} item xs={12}>
              <Grid container item xs={8} justifyContent={'flex-start'}>
                <span className={styles.uploadDocumentHelper}>Want to use another document?</span>
              </Grid>
              <Grid
                item
                container
                xs={4}
                justifyContent={'flex-end'}
                alignItems={'center'}
                className={styles.uploadTextContainer}
                onClick={() => setUploadDocumentOpen(true)}
              >
                <UploadFileIcon />
                <span className={styles.uploadText}>Upload another</span>
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <SaveState isSaving={!isDeleteModalShown && isFilesLoading} isSaved={isFilesSaved} />
            </Grid>
          </Grid>
        ) : (
          <Grid container item xs={12} lg={12}>
            <Grid container item xs={12} lg={12} justifyContent={'center'}>
              <UploadFile
                title={''}
                key={+isLoading}
                onDelete={handleDeleteFiles}
                size="lg"
                onDropAccepted={handleUploadFile}
                acceptedFileTypes={['excel']}
                files={files}
                isInModal={false}
                isLoading={isLoading}
              />
            </Grid>
            <Grid container item xs={12} lg={12}>
              <div className={styles.filesListItem}>
                <div className={styles.filesListItemDocuments}>
                  {FILE_TYPE_TEMPLATES[reportType] &&
                    FILE_TYPE_TEMPLATES[reportType].map(
                      (item: { value: string; label: string }, index: number) => (
                        <>
                          <Tooltip placement="top" title={'Download Template'}>
                            <div
                              onClick={() => {
                                downloadTemplate(item.value)
                              }}
                              className={styles.linksBlockItem}
                            >
                              {item.label}
                            </div>
                          </Tooltip>
                          {index !== FILE_TYPE_TEMPLATES[reportType]?.length - 1 && (
                            <div className={styles.templatesDivider} />
                          )}
                        </>
                      ),
                    )}
                </div>
              </div>
            </Grid>
          </Grid>
        )}
        {isDeleteModalShown && filesForDelete.length > 0 && (
          <Modal
            open={isDeleteModalShown}
            onCancel={handleDeleteCancel}
            title="Delete file?"
            footer={[
              <Button
                key="cancel"
                color="primary"
                variant="outlined"
                onClick={handleDeleteCancel}
                disabled={isLoading}
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                color="primary"
                variant="contained"
                onClick={handleDeleteConfirm}
                isLoading={isLoading}
              >
                Delete
              </Button>,
            ]}
          >
            All related records will be deleted
          </Modal>
        )}
      </Grid>
    </Modal>
  )
}

export default OngoingReportingFiles
