import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Grid from '@mui/material/Grid'
import cn from 'classnames'
import { Link, generatePath, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import styles from './CapTableSummaryTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import { formatPriceConditionalDecimal, formatPercent, formatDate } from '../../helpers/helpers'
import TableFiltersRow from '../Common/TableFiltersRow'
import { FULLY_DILUTED_OWNERSHIP_QUERY, IFilter } from '@common/constants/filters'
import { Form } from 'react-final-form'
import useTable from '../../hooks/useTable'
import FilterContainer from '../Filters/FilterContainer'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import Card from '../Common/Card'
import { ExpandAndMinimize } from '../Common/Icons'
import { EditMapping } from '../Common/Icons/Icons'
import FullscreenModal from '../Common/FullscreenModal'
import SelectField from '../Common/SelectField'
import MultiCellTooltip from '../MultiCellTooltip'
import {
  ICapTableSummaryData,
  ICapTableSummary,
  ICapTableFilters,
} from '@common/interfaces/capTable'
import { IClientInfo } from '@common/interfaces/client'
import { ILoadingData } from '../../redux/types'
import LinkButton from '@mui/material/Link'
import { ROUTES } from '../../constants/routes'
import { EditSourceData, ExternalLink } from '../Common/Icons/Icons'
import { usePermissions } from '../../helpers/permissionContext'
import {
  CAP_TABLE_SUMMARY_TITLE_FILTERS_CONFIG,
  CAP_TABLE_SUMMARY_DATA_FILTERS_CONFIG,
} from '@common/constants/filters'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { IFile } from '@common/interfaces/box'
import useTrackVisualizationsTable from '../../hooks/useTrackVisualizationsTable'
import { CATEGORIES } from '@common/constants/tracking'
import { Link as RouterLink } from 'react-router-dom'
import { ReportingFlow } from '@common/interfaces/bbc'
import Empty from '../Common/Empty/Empty'
import TableLoader from '../Common/TableLoader'
const COUPLED_TABLES = [
  'capTableSummmary',
  'capTableSummmaryActiveToolbar',
  'capTableSummaryScrollableTableHead',
]

interface IRowProps {
  activeCells: number[][]
  activeRows: number[]
  rowIndex: number
  onSelectRow: (event: any, rowIndex: number) => void
  item?: ICapTableSummary
  headers?: string[]
}

const CapTableSummaryCell = ({
  data,
  cellIndex,
  isSelected,
  isActive,
}: {
  data: any
  rowIndex: number
  cellIndex: number
  isSelected: boolean
  isActive: boolean
}) => {
  return (
    <TableCell
      className={cn(genericSs.tableTextRight, 'activableCell', {
        activeCell: isSelected,
      })}
      data-index={cellIndex}
    >
      <MultiCellTooltip isActive={isActive}>
        <div>
          <span>$</span>
          {data !== '0' ? formatPriceConditionalDecimal(data) : '-'}
        </div>
      </MultiCellTooltip>
    </TableCell>
  )
}

const CapTableSummaryRow = ({
  activeCells,
  activeRows,
  rowIndex,
  onSelectRow,
  item,
  headers,
}: IRowProps) => {
  const { isParticipant } = usePermissions()

  const handleSelectRow = useCallback(
    (event) => onSelectRow(event, rowIndex),
    [rowIndex, onSelectRow],
  )
  const isSelected = useMemo(() => activeRows.includes(rowIndex), [activeRows, rowIndex])

  return (
    <TableRow onClick={handleSelectRow} className={'activableRow'} data-index={rowIndex}>
      <TableCell />
      <TableCell
        style={{
          zIndex: 50,
        }}
      >
        {item.entityId && !isParticipant ? (
          <LinkButton
            component={Link}
            to={generatePath(ROUTES.ENTITY_PAGE, {
              id: item.entityId,
            })}
          >
            {item.investor}
          </LinkButton>
        ) : (
          item.investor
        )}
      </TableCell>

      {item.investments &&
        headers?.map((header, index) =>
          item.investments?.[header] ? (
            <CapTableSummaryCell
              key={header}
              data={item.investments?.[header]}
              rowIndex={rowIndex}
              cellIndex={index}
              isSelected={isSelected}
              isActive={activeCells[rowIndex]?.includes(index)}
            />
          ) : (
            <TableCell key={header} />
          ),
        )}
      <TableCell
        className={cn(genericSs.tableTextRight, 'activableCell', {
          activeCell: isSelected,
        })}
      >
        <div className={styles.amountHolder}>
          <span>$</span>
          {formatPriceConditionalDecimal(item.totalInvestment)}
        </div>
      </TableCell>
      <TableCell
        className={cn(genericSs.tableTextRight, 'activableCell', {
          activeCell: isSelected,
        })}
      >
        <div className={styles.amountHolder}>{formatPercent(item.outstandingOwnership)}</div>
      </TableCell>
      <TableCell
        className={cn(genericSs.tableTextRight, 'activableCell', {
          activeCell: isSelected,
        })}
      >
        <div className={styles.amountHolder}>{formatPercent(item.fullyDilutedOwnership)}</div>
      </TableCell>
    </TableRow>
  )
}

interface IProps {
  listCapTable: (params: object) => void
  capTableSummary: ILoadingData<ICapTableSummaryData>
  clientInfo: IClientInfo
  handleOpenMappingModal?: () => void
  boxLink?: string
  handleOpenSourceData?: (value: boolean) => void
  isFileSelectShown?: boolean
  selectedFile?: IFile
  isDisabled?: boolean
  setMappingDate?: (value: string) => void
  editLink?: string
  reportingFlow: ReportingFlow
  refreshCount?: number
  refreshCapTable?: () => void
  capTableFilters: ILoadingData<ICapTableFilters>
}

const CapTableSummaryTable = ({
  listCapTable,
  capTableSummary,
  clientInfo,
  handleOpenMappingModal,
  boxLink,
  handleOpenSourceData,
  isFileSelectShown,
  selectedFile,
  isDisabled,
  setMappingDate,
  editLink,
  reportingFlow,
  refreshCount = 1,
  refreshCapTable,
  capTableFilters,
}: IProps) => {
  const { id } = useParams<{ id?: string }>()
  const wrapperRef = useRef(null)
  const [activeCells, setActiveCells] = useState<number[][]>([])
  const [selectedDate, setSelectedDate] = useState<string>(null)
  const [isModalShown, setIsModalShown] = useState(false)
  const [isAggregate, setIsAggregate] = useState(false)
  const tableRef = useRef(null)
  const {
    isLoading,
    data: capTableSummaryData,
    headers,
    file,
  } = useMemo(() => {
    return {
      isLoading: capTableSummary?.isLoading,
      data: capTableSummary?.data?.data,
      headers: capTableSummary?.data?.rounds,
      file: capTableSummary?.data?.file,
    }
  }, [capTableSummary])

  const {
    dates,
    isMappingComplete,
    isLoading: isLoadingCapTableFilters,
  } = useMemo(() => {
    return {
      dates: capTableFilters?.data?.dates,
      isMappingComplete: capTableFilters?.data?.isMappingComplete,
      isLoading: capTableFilters?.isLoading,
    }
  }, [capTableFilters])

  const filtersConfig = useMemo(
    () => [
      ...CAP_TABLE_SUMMARY_TITLE_FILTERS_CONFIG,
      ...(headers || []).map((header, index) => ({
        field: header,
        type: 'amount',
        isSortable: true,
        title: header,
      })),
      ...CAP_TABLE_SUMMARY_DATA_FILTERS_CONFIG,
    ],
    [headers],
  )

  const totalInvestmentFields = useMemo(() => {
    return CAP_TABLE_SUMMARY_DATA_FILTERS_CONFIG.map((filter) => filter.field)
  }, [])

  const filtersValidate = buildFiltersValidateSchema(filtersConfig as IFilter[])
  const filtersDefaults = buildFiltersDefaults(filtersConfig as IFilter[])

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    handleSelectRow,
    activeItems,
    setActiveItems,
  } = useTable({
    tableId: 'capTableSummary',
    filtersDefaults,
    sortDefault: {
      field: FULLY_DILUTED_OWNERSHIP_QUERY,
      direction: 'DESC',
    },
  })

  const dateOptions = useMemo(() => {
    return dates?.map((date) => ({
      value: date,
      label: formatDate(date),
    }))
  }, [dates])

  useEffect(() => {
    refreshCount &&
      selectedDate &&
      listCapTable({
        recordDate: selectedDate,
        isAggregate,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        reportingFlow,
        reportingFlowId: id,
      })
  }, [id, listCapTable, selectedDate, isAggregate, orderBy, filters, reportingFlow, refreshCount])

  useEffect(() => {
    if (
      (!selectedDate && dateOptions?.length > 0) ||
      (selectedDate && !dateOptions?.find((option) => option.value === selectedDate))
    ) {
      setSelectedDate(dateOptions[0].value)
      setMappingDate && setMappingDate(dateOptions[0].value)
    } else if (selectedDate && !dateOptions?.length) {
      setSelectedDate(null)
      setMappingDate && setMappingDate(null)
    }
  }, [selectedDate, dateOptions, setMappingDate])

  const fileLink = useMemo(() => {
    return boxLink ? selectedFile?.link : file
  }, [boxLink, selectedFile, file])

  const resetMultiCellsSelection = useCallback(() => {
    setActiveCells([])
  }, [])

  const resetMultiRowsSelection = useCallback(() => {
    setActiveItems([])
  }, [setActiveItems])

  const onCloseModal = useCallback(() => {
    setIsModalShown(false)
    resetMultiCellsSelection()
    resetMultiRowsSelection()
  }, [resetMultiCellsSelection, resetMultiRowsSelection])

  const summaryRows = useMemo(() => {
    const headerTotals = (headers || []).map((round: string, index: number) => ({
      field: round,
      amounts: (capTableSummaryData || [])
        .filter((item: ICapTableSummary, capTableIndex: number) =>
          activeItems.includes(capTableIndex),
        )
        .reduce((sum: number, item: ICapTableSummary) => {
          return sum + (parseFloat(item.investments?.[round]) || 0)
        }, 0),
    }))

    const investmentTotals = (totalInvestmentFields || []).map(
      (totalName: string, index: number) => ({
        field: totalName,
        amounts: (capTableSummaryData || [])
          .filter((item: ICapTableSummary, capTableIndex: number) =>
            activeItems.includes(capTableIndex),
          )
          .reduce((sum: number, item: ICapTableSummary) => {
            if (totalName === 'totalInvestment') {
              return sum + parseFloat(String(item.totalInvestment))
            } else {
              const fieldName = `${totalName}Percent`
              return sum + parseFloat(String(item[fieldName]))
            }
          }, 0),
      }),
    )

    return [...headerTotals, ...investmentTotals]
  }, [capTableSummaryData, activeItems, headers, totalInvestmentFields])

  const handleReportingDateChange = useCallback(
    ({ target: { value } }) => {
      setSelectedDate(value)
      setMappingDate && setMappingDate(value)
      handleOrderChange(FULLY_DILUTED_OWNERSHIP_QUERY, 'DESC')
    },
    [setSelectedDate, setMappingDate, handleOrderChange],
  )

  const handleAggregateChange = useCallback(
    ({ target: { value } }) => {
      setIsAggregate(value === 'Aggregate')
      handleOrderChange(FULLY_DILUTED_OWNERSHIP_QUERY, 'DESC')
    },
    [setIsAggregate, handleOrderChange],
  )

  const toggleExpandAndMinimize = useCallback(() => {
    setIsModalShown(!isModalShown)
  }, [isModalShown])

  const handleOpenMapping = useCallback(() => {
    handleOpenMappingModal()
    refreshCapTable()
  }, [handleOpenMappingModal, refreshCapTable])

  const isInitialized = useMemo(() => !!capTableSummaryData, [capTableSummaryData])
  const visualizationsParams = useMemo(
    () => ({
      clientId: clientInfo?.id,
    }),
    [clientInfo?.id],
  )

  const visualizationsFilters = useMemo(
    () => ({
      ...filters,
      round: isAggregate ? 'Aggregate' : 'All',
      document: selectedDate,
    }),
    [filters, isAggregate, selectedDate],
  )

  useTrackVisualizationsTable({
    isInitialized,
    category: CATEGORIES.capTableTable,
    params: visualizationsParams,
    filtersConfig: filtersConfig as IFilter[],
    filters: visualizationsFilters,
    orderBy,
  })

  const isEmpty = useMemo(() => {
    return !dateOptions?.length && !isLoadingCapTableFilters
  }, [dateOptions, isLoadingCapTableFilters])

  const handleScroll = useCallback(() => {
    const tableBody = tableRef.current?.children?.[1]
    if (tableBody && COUPLED_TABLES) {
      const scrollLeft = tableBody.scrollLeft
      COUPLED_TABLES.forEach((tableId) => {
        const coupledTable = document.getElementById(tableId)
        if (coupledTable) {
          coupledTable.scrollLeft = scrollLeft
        }
      })
    }
  }, [tableRef])

  const handleHeaderScroll = useCallback(() => {
    const tableHeader = document.getElementById('capTableSummaryScrollableTableHead')
    const tableBody = tableRef.current?.children?.[1]
    if (tableHeader && tableBody) {
      tableBody.scrollLeft = tableHeader.scrollLeft
    }
  }, [tableRef])

  return (
    <FullscreenModal
      isOpen={isModalShown}
      setIsOpen={setIsModalShown}
      classes={{ body: styles.fullScreenModal }}
      onCloseCustom={onCloseModal}
    >
      <Card noHeaderMargin ref={wrapperRef} withBorder={handleOpenSourceData ? false : true}>
        <TableContainer className={cn(styles.table)} id={'capTableSummmary'}>
          <Form
            validate={filtersValidate}
            onSubmit={handleFiltersChange}
            initialValues={filters}
            mutators={{
              setFieldData: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value)
              },
            }}
            render={({ values, handleSubmit, form: { mutators } }) => (
              <FilterContainer
                filters={filtersConfig as IFilter[]}
                handleSubmit={handleSubmit}
                mutators={mutators}
                values={values}
                appliedFilters={filters}
                withFilters={false}
                title={
                  <Box mr={2}>
                    <h2>
                      Cap Table Summary
                      {editLink && (
                        <LinkButton
                          component={RouterLink}
                          to={editLink}
                          className={styles.editLink}
                        >
                          Edit
                        </LinkButton>
                      )}
                    </h2>
                  </Box>
                }
                actions={
                  <Grid item container justifyContent="flex-end" columnSpacing={1} spacing={2}>
                    <Grid item>
                      <SelectField
                        key={'isAggregate'}
                        label="Round"
                        variant="outlined"
                        useFinalForm={false}
                        name="isAggregate"
                        options={['All', 'Aggregate'].map((option) => ({
                          value: option,
                          label: option,
                        }))}
                        onChange={handleAggregateChange}
                        value={isAggregate ? 'Aggregate' : 'All'}
                        defaultValue=""
                        className={styles.selectField}
                      />
                    </Grid>
                    <Grid item>
                      <SelectField
                        key={selectedDate}
                        label="Document"
                        variant="outlined"
                        useFinalForm={false}
                        name="recordDate"
                        options={dateOptions || []}
                        onChange={handleReportingDateChange}
                        value={selectedDate}
                        defaultValue=""
                        className={styles.selectField}
                        withTopLabel
                      />
                    </Grid>
                    {handleOpenMappingModal && !isDisabled && !isEmpty && (
                      <Grid item>
                        <EditMapping
                          mappingRequired={!isMappingComplete && isMappingComplete !== undefined}
                          action={handleOpenMapping}
                          title="Mapping"
                          mappingDisabled={isMappingComplete === undefined}
                        />
                      </Grid>
                    )}
                    {fileLink && (
                      <Grid item>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <ExternalLink link={fileLink} className={styles.externalLink} />
                        </Box>
                      </Grid>
                    )}

                    {handleOpenSourceData && !isDisabled && (
                      <Grid item>
                        <EditSourceData
                          action={() => handleOpenSourceData(!isFileSelectShown)}
                          error={!!selectedFile?.error}
                        />
                      </Grid>
                    )}

                    <Grid item>
                      <ExpandAndMinimize
                        action={toggleExpandAndMinimize}
                        isExpanded={isModalShown}
                      />
                    </Grid>
                  </Grid>
                }
              />
            )}
          />
          {isEmpty ? (
            <Empty message="No Processed Cap Table" />
          ) : (
            <Table ref={tableRef}>
              <TableHead
                className={cn(styles.tableHead)}
                id="capTableSummaryScrollableTableHead"
                onScroll={handleHeaderScroll}
              >
                <TableFiltersRow
                  filters={filtersConfig as IFilter[]}
                  orderBy={orderBy}
                  handleOrderChange={handleOrderChange}
                />
              </TableHead>
              <TableBody onScroll={handleScroll}>
                {isLoading ? (
                  <TableLoader columnsCount={filtersConfig?.length + 1} />
                ) : (
                  <>
                    {capTableSummaryData && (
                      <>
                        {capTableSummaryData.map((item: ICapTableSummary, rowIndex: number) => (
                          <CapTableSummaryRow
                            key={item.investor}
                            activeCells={activeCells}
                            activeRows={activeItems}
                            rowIndex={rowIndex}
                            onSelectRow={handleSelectRow}
                            item={item}
                            headers={headers}
                          />
                        ))}
                      </>
                    )}
                    {activeItems?.length > 1 && (
                      <TableRow className="summaryRow">
                        <TableCell />
                        <TableCell className={genericSs.tableTextLeft}>
                          {activeItems.length} rows selected
                        </TableCell>
                        {[...headers, ...totalInvestmentFields]?.map((header, index) => (
                          <TableCell key={header} className={genericSs.tableTextRight}>
                            <div className={styles.amountHolder}>
                              {totalInvestmentFields.includes(header) &&
                              header !== 'totalInvestment' ? (
                                formatPercent(summaryRows[index]?.amounts)
                              ) : (
                                <span>
                                  $ {formatPriceConditionalDecimal(summaryRows[index]?.amounts)}
                                </span>
                              )}
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Card>
    </FullscreenModal>
  )
}

export default CapTableSummaryTable
