import Base from './base'
import { IUser } from '@common/interfaces/user'

export default class ClientInfo extends Base {
  list(params: object) {
    return this.apiClient.get('clients', params)
  }

  show(id: string) {
    return this.apiClient.get(`clients/${id}`)
  }

  update(id: string, data: object) {
    return this.apiClient.post(`clients/${id}`, data)
  }

  updateTerms(id: string, data: object) {
    return this.apiClient.put(`clients/${id}/terms`, data)
  }

  calculateTerminate(id: string) {
    return this.apiClient.get(`clients/${id}/terminate`)
  }

  generateTerminationLetter(id: string) {
    return this.apiClient.get(`clients/${id}/termination-letter`)
  }

  terminate(id: string, data: object) {
    return this.apiClient.put(`clients/${id}/terminate`, data)
  }

  listDocuments(id: string) {
    return this.apiClient.get(`clients/${id}/documents`)
  }

  addManager({ clientIds, managerId }: { clientIds: string[]; managerId: string }) {
    return this.apiClient.post(`clients/managers/${managerId}`, { clientIds })
  }

  addOrRemoveManager({ clientIds, managerId }: { clientIds: string[]; managerId: string }) {
    return this.apiClient.put(`clients/managers/${managerId}`, { clientIds })
  }

  removeManager({ clientIds, managerId }: { clientIds: string[]; managerId: string }) {
    return this.apiClient.delete(`clients/managers/${managerId}`, { clientIds })
  }

  notifyManager({ clientId, managerId }: { clientId: string; managerId: string }) {
    return this.apiClient.post(`clients/${clientId}/managers/${managerId}/notify`)
  }

  updateManager({
    clientId,
    managerId,
    data,
  }: {
    clientId: string
    managerId: string
    data: Partial<IUser>
  }) {
    return this.apiClient.put(`clients/${clientId}/managers/${managerId}`, data)
  }

  clientInfo() {
    return this.apiClient.get('clients/me')
  }

  listAccountActivity(
    clientId?: string,
    params?: {
      page?: number
      perPage?: number
      filters?: object
      orderBy?: string
      orderDirection?: string
    },
  ) {
    return this.apiClient.get(`clients/${clientId || 'me'}/activity`, params)
  }

  deleteAccountActivity(clientId: string, data: object) {
    return this.apiClient.delete(`clients/${clientId}/activity`, data)
  }

  getRequestFunding() {
    return this.apiClient.get('clients/me/request-funding')
  }

  requestFunding(data: FormData) {
    return this.apiClient.post('clients/me/request-funding', data)
  }

  modifyFunding(data: FormData) {
    return this.apiClient.put('clients/me/request-funding', data)
  }

  cancelFunding(data: object) {
    return this.apiClient.delete('clients/me/request-funding', data)
  }

  getBanner() {
    return this.apiClient.get('clients/me/banner')
  }

  closeBanner(id: string) {
    return this.apiClient.post(`clients/me/banner/${id}`)
  }

  submitOngoingReporting(id: string, data: FormData) {
    return this.apiClient.post(`clients/${id}/ongoing-reporting`, data)
  }

  createRequiredReports(data: object) {
    return this.apiClient.post('clients/me/required-reports', data)
  }

  listClientsAggregation(params: object) {
    return this.apiClient.get('clients/aggregation', params)
  }

  listOveradvances(id: string, params?: any) {
    return this.apiClient.get(`clients/${id}/overadvances`, params)
  }

  createOveradvance(id: string, data: object) {
    return this.apiClient.post(`clients/${id}/overadvances`, data)
  }

  updateOveradvance(clientId: string, overadvanceId: string, data: object) {
    return this.apiClient.put(`clients/${clientId}/overadvances/${overadvanceId}`, data)
  }

  deleteOveradvance(clientId: string, overadvanceId: string) {
    return this.apiClient.delete(`clients/${clientId}/overadvances/${overadvanceId}`)
  }

  createOveradvancePayment(clientId: string, overadvanceId: string, data: object) {
    return this.apiClient.post(`clients/${clientId}/overadvances/${overadvanceId}/payments`, data)
  }

  listOveradvancePayments(clientId: string, overadvanceId: string) {
    return this.apiClient.get(`clients/${clientId}/overadvances/${overadvanceId}/payments`)
  }

  deleteOveradvancePayment(clientId: string, overadvanceId: string, paymentId: string) {
    return this.apiClient.delete(
      `clients/${clientId}/overadvances/${overadvanceId}/payments/${paymentId}`,
    )
  }

  listCompliance(params: object) {
    return this.apiClient.get('clients/compliance', params)
  }

  updateUnderwriting(id: string, data: object) {
    return this.apiClient.put(`clients/${id}/underwriting`, data)
  } //TODO: Implement soon

  createFunding(id: string, data: object) {
    return this.apiClient.post(`clients/${id}/funding`, data)
  }

  updateFunding(id: string, data: object) {
    return this.apiClient.put(`clients/${id}/funding`, data)
  }

  updatePassthroughs(id: string, data: object) {
    return this.apiClient.put(`clients/${id}/passthroughs`, data)
  }

  deletePassthroughs(id: string, data: object) {
    return this.apiClient.delete(`clients/${id}/passthroughs`, data)
  }

  listTermLoans(id: string) {
    return this.apiClient.get(`clients/${id}/term-loans`)
  }

  listAmortizationSchedule(id: string, termLoanId: string) {
    return this.apiClient.get(`clients/${id}/term-loans/${termLoanId}/amortization-schedule`)
  }

  createTermLoan(id: string, data: object) {
    return this.apiClient.post(`clients/${id}/term-loans`, data)
  }

  updateTermLoan(id: string, termLoanId: string, data: object) {
    return this.apiClient.put(`clients/${id}/term-loans/${termLoanId}`, data)
  }

  deleteTermLoan(id: string, termLoanId: string) {
    return this.apiClient.delete(`clients/${id}/term-loans/${termLoanId}`)
  }

  createTermLoanActivity(id: string, termLoanId: string, data: object) {
    return this.apiClient.post(`clients/${id}/term-loans/${termLoanId}/activity`, data)
  }

  updateTermLoanActivity(id: string, termLoanId: string, activityId: string, data: object) {
    return this.apiClient.put(`clients/${id}/term-loans/${termLoanId}/activity/${activityId}`, data)
  }

  deleteTermLoanActivity(id: string, termLoanId: string, activityId: string) {
    return this.apiClient.delete(`clients/${id}/term-loans/${termLoanId}/activity/${activityId}`)
  }

  listArEligibility(id: string, data: object) {
    return this.apiClient.get(`clients/${id}/ar-ineligibility`, data)
  }

  listExtraReserves(id: string, params: object = {}) {
    return this.apiClient.get(`clients/${id}/extra-reserves`, params)
  }

  updateExtraReserves(id: string, data: object) {
    return this.apiClient.post(`clients/${id}/extra-reserves`, data)
  }

  listOtherReserves(id: string, params: object = {}) {
    return this.apiClient.get(`clients/${id}/reserves`, params)
  }

  createOtherReserve(id: string, data: object) {
    return this.apiClient.post(`/clients/${id}/reserves`, data)
  }

  updateOtherReserve(id: string, itemId: string, data: object) {
    return this.apiClient.patch(`/clients/${id}/reserves/${itemId}`, data)
  }

  deleteOtherReserve(id: string, itemId: string) {
    return this.apiClient.delete(`/clients/${id}/reserves/${itemId}`)
  }

  listInventoryCategories(id: string, data: object) {
    return this.apiClient.get(`clients/${id}/inventory-categories/list`, data)
  }

  listMasterInventory(id: string, data: object) {
    return this.apiClient.get(`clients/${id}/master-inventory/list`, data)
  }

  listEligibleFields(id: string) {
    return this.apiClient.get(`clients/${id}/inventory-eligible-fields`)
  }

  updateEligibleFieldsAliases(id: string, data: object) {
    return this.apiClient.put(`clients/${id}/inventory-ineligibility-fields`, data)
  }

  listCustomRules(id: string, data: object) {
    return this.apiClient.get(`clients/${id}/inventory-eligible-custom-rules`, data)
  }

  listCustomRulesLabels(id: string) {
    return this.apiClient.get(`clients/${id}/inventory-eligible-custom-rules/labels`)
  }

  listCustomRulesValues(id: string, data: object) {
    return this.apiClient.get(`clients/${id}/inventory-eligible-custom-rules/values`, data)
  }

  listAmendments(id: string, data: object) {
    return this.apiClient.get(`clients/${id}/amendments`, data)
  }

  createAmendment(id: string, data: object) {
    return this.apiClient.post(`clients/${id}/amendments`, data)
  }

  updateAmendment(id: string, itemId: string, values: object) {
    return this.apiClient.put(`clients/${id}/amendments/${itemId}`, values)
  }

  deleteAmendment(id: string, itemId: string) {
    return this.apiClient.delete(`clients/${id}/amendments/${itemId}`)
  }

  exportPortfolioReview() {
    return this.apiClient.post('clients/exports/portfolio-review/', {}, { isFile: true })
  }
  exportParticipantPortfolioReview() {
    return this.apiClient.post(
      'clients/exports/participant-portfolio-review/',
      {},
      { isFile: true },
    )
  }

  listApEligibility(id: string, data: object) {
    return this.apiClient.get(`clients/${id}/ap-ineligibility`, data)
  }

  updateApEligibility(id: string, itemId: string, data: object) {
    return this.apiClient.put(`clients/${id}/ap-ineligibility/${itemId}`, data)
  }

  listFinancialsReports(id: string) {
    return this.apiClient.get(`clients/${id}/financials-reports`)
  }

  listFinancialsSummary(id: string, data: object) {
    return this.apiClient.get(`clients/${id}/financials-summary`, data)
  }
  listRiskRating(id: string) {
    return this.apiClient.get(`clients/${id}/risk-rating`)
  }

  listRiskRatingDetails(data: object) {
    return this.apiClient.get(`clients/risk-rating-details`, data)
  }

  listInventoryHealth(id: string) {
    return this.apiClient.get(`clients/${id}/inventory-health`)
  }

  showCashFlowInfo(id: string, data: object) {
    return this.apiClient.get(`clients/${id}/cash-flow`, data)
  }

  exportMasterInventory(id: string, data: object) {
    return this.apiClient.post(`clients/${id}/master-inventory/export`, data, { isFile: true })
  }
  bulkUpdateMasterInventory(id: string, data: object) {
    return this.apiClient.put(`clients/${id}/master-inventory/bulk-update`, data)
  }
  listCustomers(id?: string) {
    return this.apiClient.get(`clients/${id || 'me'}/list-customers`)
  }
  refreshAutoReporting(data: object = {}) {
    return this.apiClient.post('clients/refresh-auto-reporting', data)
  }
  getAccountActivityGraph(id: string, data: object) {
    return this.apiClient.get(`clients/${id}/account-activity-graph`, data)
  }

  listLoanLedger(id: string, data: object) {
    return this.apiClient.get(`clients/${id}/loan-ledger`, data)
  }

  listStressCaseAssumptions(id: string, data: object) {
    return this.apiClient.get(`clients/${id}/stress-case`, data)
  }

  updateStressCaseAssumption(id: string, assumptionId: string, data: object) {
    return this.apiClient.put(`clients/${id}/stress-case/${assumptionId}`, data)
  }

  listCollectionsReconciliation(id: string, params: object) {
    return this.apiClient.get(`clients/${id}/collections-reconciliation`, params)
  }

  listCollectionsReconciliationOptions(id: string, params: object) {
    return this.apiClient.get(`clients/${id}/collections-reconciliation-options`, params)
  }

  listSalesChannelDistribution(id: string, params: object) {
    return this.apiClient.get(`clients/${id}/sales-channel-distribution`, params)
  }

  listSalesChannelDistributionOptions(id: string, params: object) {
    return this.apiClient.get(`clients/${id}/sales-channel-distribution-options`, params)
  }

  listPendingCashDominion(id: string, data: object) {
    return this.apiClient.get(`clients/${id}/pending-cash-dominion`, data)
  }
}
