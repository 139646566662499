import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ClientPage from './ClientPage'

import { clientInfo } from '../../redux/clientInfo/selectors'
import { show as showClient, hide as hideClient } from '../../redux/clientInfo/actions'
import { hide as hideBBC } from '../../redux/bbc/actions'
import { isAdminRightsRole, isExtendedRightsRole } from '../../redux/profile/selectors'
import { loginAsClient } from '../../redux/profile/actions'
import { getDebtorCategories } from '../../redux/options/actions'
import { listDilutionDropdowns } from '../../redux/generalLedger/actions'
import { listCapTableFilters } from '../../redux/capTable/actions'
const selector = createStructuredSelector({
  clientInfo,
  isAdminRightsRole,
  isExtendedRightsRole,
})

const actions = {
  showClient,
  hideClient,
  hideBBC,
  loginAsClient,
  getDebtorCategories,
  listDilutionDropdowns,
  listCapTableFilters,
}

export default connect(selector, actions)(ClientPage)
