import React, { forwardRef } from 'react'
import MuiCard, { CardProps } from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import { Box } from '@mui/material'
import cn from 'classnames'
import Collapse from '@mui/material/Collapse'
import styles from './Card.module.scss'
import Divider from '@mui/material/Divider'
import Skeleton from '@mui/material/Skeleton'
import Grid from '@mui/material/Grid'
interface IProps extends CardProps {
  title?: any
  size?: 'small' | 'medium' | 'large'
  noPadding?: boolean
  noVerticalPadding?: boolean
  noHeaderMargin?: boolean
  noHeaderTopMargin?: boolean
  classes?: { [key: string]: string }
  renderHeaderLeftColumn?: () => React.ReactElement
  renderHeaderRightColumn?: () => React.ReactElement
  expandable?: boolean
  isExpanded?: boolean
  setIsExpanded?: (isExpanded: boolean) => void
  withBorder?: boolean
  withHeaderBorder?: boolean
  isLoading?: boolean
}

const Card = forwardRef(
  (
    {
      children,
      title,
      size = 'large',
      noPadding,
      noVerticalPadding,
      noHeaderMargin,
      noHeaderTopMargin,
      classes = {},
      renderHeaderLeftColumn,
      renderHeaderRightColumn,
      expandable = false,
      isExpanded = false,
      setIsExpanded,
      withBorder = true,
      withHeaderBorder = false,
      isLoading,
      ...props
    }: IProps,
    ref: any,
  ) => {
    const sizeClasses = {
      [styles.noPadding]: noPadding,
      [styles.noVerticalPadding]: noVerticalPadding,
    }

    return (
      <MuiCard
        {...props}
        classes={{
          root: cn(styles.root, sizeClasses, {
            [styles.withBorder]: withBorder,
            [classes.root]: classes.root,
          }),
        }}
        ref={ref}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={cn(styles.headerWrap, {
            [styles.noHeaderMargin]: noHeaderMargin,
            [styles.noHeaderTopMargin]: noHeaderTopMargin,
            [styles.withBorder]: withHeaderBorder,
          })}
        >
          <Box display="flex" flex="1" flexDirection={renderHeaderLeftColumn ? 'row' : 'column'}>
            {title && (
              <CardHeader
                classes={{
                  root: cn(styles.headerRoot, sizeClasses),
                  title: cn(styles.title, sizeClasses, classes.title),
                }}
                title={
                  <Grid container>
                    <Grid item xs={12}>
                      {title}
                    </Grid>
                    <Grid item xs={12}>
                      {withBorder && <Divider className={styles.divider} light />}
                    </Grid>
                  </Grid>
                }
              />
            )}
            {renderHeaderLeftColumn && renderHeaderLeftColumn()}
          </Box>
          {renderHeaderRightColumn && <Box>{renderHeaderRightColumn()}</Box>}
        </Box>
        {expandable && (
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <CardContent
              classes={{
                root: cn(styles.content, classes.content, sizeClasses),
              }}
            >
              {isLoading ? <Skeleton variant="rectangular" width="100%" height="100%" /> : children}
            </CardContent>
          </Collapse>
        )}
        {!expandable && (
          <CardContent
            classes={{
              root: cn(styles.content, classes.content, sizeClasses),
            }}
          >
            {isLoading ? <Skeleton variant="rectangular" width="100%" height={118} /> : children}
          </CardContent>
        )}
      </MuiCard>
    )
  },
)

export default Card
