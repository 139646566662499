import FullPageFlags from './FullPageFlags'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { clients } from '../../../redux/clientInfo/selectors'

import { flags, isLoading, flagOptions } from '../../../redux/flag/selectors'
import { listFlags, getFlagsOptions } from '../../../redux/flag/actions'
import { list as listClients } from '../../../redux/clientInfo/actions'
import { listEntityInfo } from '../../../redux/entityInfo/actions'
import { user } from '../../../redux/profile/selectors'

const selector = createStructuredSelector({
  flags,
  isLoading,
  clients,
  user,
  flagOptions,
})
const actions = {
  listFlags,
  listClients,
  listEntityInfo,
  getFlagsOptions,
}

export default connect(selector, actions)(FullPageFlags)
