import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import PendingCashDominion from './PendingCashDominion'

import { pendingCashDominion } from '../../redux/clientInfo/selectors'
import { listPendingCashDominion } from '../../redux/clientInfo/actions'

const selector = createStructuredSelector({
  pendingCashDominion,
})
const actions = {
  listPendingCashDominion,
}

export default connect(selector, actions)(PendingCashDominion)
