import * as Yup from 'yup'
import { makeValidate } from 'mui-rff'
import { IField } from '@common/interfaces/client'

export const generateSchemaValidator = (fields: IField[]) => {
  const schemaFields: Record<string, Yup.BaseSchema<any>> = {}

  fields.forEach((field) => {
    let fieldSchema: Yup.BaseSchema<any> = Yup.mixed()

    switch (field.type) {
      case 'currency':
      case 'percent':
      case 'number':
        fieldSchema = Yup.number().typeError('Invalid number').min(0, 'Must be positive').nullable()
        break
      case 'date':
        fieldSchema = Yup.date().typeError('Please type date in MM/DD/YY format').nullable()
        break
      case 'boolean':
        fieldSchema = Yup.boolean().nullable()
        break
      case 'text':
        fieldSchema = Yup.string().nullable()
        break
    }

    if (field.isRequired) {
      fieldSchema = fieldSchema.required('Required')
    }

    schemaFields[field.name] = fieldSchema
  })

  const schema = Yup.object().shape(schemaFields)

  const validate = makeValidate(schema)

  return validate
}

export const AR_FIELDS = ['loanType', 'arAdvance']
export const INVENTORY_FIELDS = [
  'loanType',
  'inventoryAdvanceRateCost',
  'inventoryAdvanceRateNolv',
  'sublimit',
]
