import React, { useMemo, useEffect, useState, useCallback, useRef } from 'react'
import { useParams } from 'react-router'
import cn from 'classnames'
import styles from './BBCIneligibleInventoryTableDetail.module.scss'
import genericSs from '@styles/generic.module.scss'
import ActiveToolbar from '../ActiveToolbar'

import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import { IInventoryIneligibleStatsDetail } from '@common/interfaces/bbc'
import { debounceEventHandler, formatPrice, handleMultipleSelect } from '../../helpers/helpers'
import InfiniteScroll from 'react-infinite-scroll-component'
import Tooltip from '@mui/material/Tooltip'
import TableFiltersRow from '../Common/TableFiltersRow'
import { BBC_INVENTORY_INELIGIBLE_DETAILS_LIST_FILTERS_CONFIG } from '@common/constants/filters'
import TableLoader from '../Common/TableLoader'

interface IProps {
  inventoryIneligibleStatsDetail: IInventoryIneligibleStatsDetail[]
  listInventoryIneligibleStatsDetail: (id: string, data: object) => void
  filters: any
  isModalShown: boolean
  category: string
  reason: string
  dates: string[]
}

const BBCIneligibleInventoryTableDetail = ({
  inventoryIneligibleStatsDetail,
  listInventoryIneligibleStatsDetail,
  filters,
  isModalShown,
  category,
  reason,
  dates,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)

  const [isLoading, setIsLoading] = useState(true)
  const [activeItem, setActiveItem] = useState<number>()
  const [activeItems, setActiveItems] = useState([])
  const [orderBy, setOrderBy] = useState({
    field: 'description',
    direction: 'ASC',
  })

  const handleSelectRow = useMemo(
    () => handleMultipleSelect(setActiveItems, setActiveItem, activeItems, activeItem),
    [activeItems, activeItem],
  )
  const resetActiveItems = useCallback(() => setActiveItems([]), [])

  const handleOrderChange = useCallback((field: string) => {
    setOrderBy((order) => ({
      field,
      direction: order.field === field ? (order.direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
    }))
  }, [])

  const debounceFilterList = useMemo(
    () =>
      debounceEventHandler(async (data: any) => {
        !data.loadMore && setIsLoading(true)
        await listInventoryIneligibleStatsDetail(id, {
          ...data,
          category,
          reason,
          nestedRows: {
            keys: ['category', 'reason'],
          },
        })
        setIsLoading(false)
      }, 500),
    [listInventoryIneligibleStatsDetail, id, category, reason],
  )

  useEffect(() => {
    debounceFilterList({
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [orderBy, filters, debounceFilterList])

  const totalRow = useMemo(
    () =>
      inventoryIneligibleStatsDetail
        ?.filter((_, index) => activeItems.includes(index))
        .map((row) => row.values)
        .reduce(
          (total, row) => {
            dates.forEach((value: any, index: number) => {
              if (!total.values[index]) {
                total.values[index] = 0
              }
              total.values[index] += row[value] || 0
            })
            return total
          },
          { values: dates.map(() => 0) },
        ),
    [inventoryIneligibleStatsDetail, activeItems, dates],
  )

  const totalCount = useMemo(
    () => inventoryIneligibleStatsDetail?.[0]?.totalCount || 0,
    [inventoryIneligibleStatsDetail],
  )

  const loadMore = useCallback(() => {
    debounceFilterList({
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: Math.ceil(inventoryIneligibleStatsDetail?.length / 20),
    })
  }, [orderBy, filters, debounceFilterList, inventoryIneligibleStatsDetail])

  const filtersConfig = useMemo(
    () =>
      BBC_INVENTORY_INELIGIBLE_DETAILS_LIST_FILTERS_CONFIG.map((item) => {
        const newItem = item
        if (item?.field?.startsWith('value_')) {
          const index = Number(item.field.split('_')[1])
          const date = dates[index]
          if (!date) {
            return null
          }
          newItem.title = index === 0 ? 'Current BBC' : date
        }
        return newItem
      }).filter(Boolean),
    [dates],
  )

  return (
    <TableContainer
      className={styles.table}
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
      hasFooter
    >
      <Table ref={wrapperRef}>
        <TableHead>
          <TableFiltersRow
            filters={filtersConfig}
            orderBy={orderBy}
            handleOrderChange={handleOrderChange}
          />
        </TableHead>
        <TableBody id={reason} className={styles.scrollableDiv}>
          {isLoading ? (
            <TableLoader columnsCount={(dates?.length || 1) + 2} />
          ) : (
            <InfiniteScroll
              dataLength={inventoryIneligibleStatsDetail?.length || 0}
              next={loadMore}
              hasMore={inventoryIneligibleStatsDetail?.length < totalCount}
              loader={<TableLoader columnsCount={(dates?.length || 1) + 2} rowsCount={1} />}
              scrollableTarget={reason}
            >
              {inventoryIneligibleStatsDetail?.map((item, index) => (
                <TableRow
                  key={`${item.sku}-${item.description}-${item.reason}`}
                  data-index={index}
                  className={cn('activableRow', styles.parentRow, {
                    activeRow: activeItems.includes(index),
                    currentActiveRow: activeItem === index,
                  })}
                  onClick={(event) => handleSelectRow(event, index)}
                >
                  <TableCell className={genericSs.tableTextLeft}>
                    <Tooltip title={item.sku || ''} placement="top">
                      <span>{item.sku}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell className={genericSs.tableTextLeft}>{item.description}</TableCell>
                  {dates?.map((date) => (
                    <TableCell key={date} className={genericSs.tableTextRight}>
                      $ {formatPrice(item.values[date] || 0)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </InfiniteScroll>
          )}
        </TableBody>
      </Table>
      <ActiveToolbar
        activeItems={activeItems}
        className={styles.activeToolbar}
        isFullscreen={isModalShown}
        containerRef={wrapperRef}
        resetActiveItems={resetActiveItems}
      >
        {totalRow?.values?.map((value: number, index: number) => {
          return (
            <div key={index} className={genericSs.tableTextRight}>
              $ {formatPrice(value)}
            </div>
          )
        })}
      </ActiveToolbar>
    </TableContainer>
  )
}

export default BBCIneligibleInventoryTableDetail
