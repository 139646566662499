import React, { useCallback, useMemo, useEffect, useState } from 'react'
import useTable from '../../hooks/useTable'
import { Form } from 'react-final-form'
import InfiniteScroll from 'react-infinite-scroll-component'
import Box from '@mui/material/Box'
import { useParams } from 'react-router'
import { ICapTableSummaryData } from '@common/interfaces/capTable'
import { INVESTOR_ENTITY_SUMMARY_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { buildFiltersDefaults } from '../../helpers/filters'
import { debounceEventHandler, formatAmount, formatPercent } from '../../helpers/helpers'
import styles from './InvestmentSummaryTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableFiltersRow from '../Common/TableFiltersRow'
import FilterContainer from '../Filters/FilterContainer'
import { ILoadingData } from '../../redux/types'
import TableLoader from '../Common/TableLoader/TableLoader'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'
import { ROUTES } from '../../constants/routes'
import { IEntityInfo } from '@common/interfaces/entityInfo'
import { ExpandDetailIcon } from '../Common/Icons/Icons'
import InvestmentDetailRow from '../InvestmentRowDetail'
import Card from '../Common/Card'
import cn from 'classnames'

const filtersDefaults = buildFiltersDefaults(INVESTOR_ENTITY_SUMMARY_LIST_FILTERS_CONFIG)

interface IProps {
  listInvestorSummary: (id: string, params: object) => void
  investmentSummary: ILoadingData<ICapTableSummaryData>
  entityInfo: IEntityInfo
}

const InvestmentSummaryTable = ({ listInvestorSummary, investmentSummary, entityInfo }: IProps) => {
  const { id } = useParams<{ id?: string }>()
  const [expanded, setExpanded] = useState([])

  const { filters, handleFiltersChange, handleOrderChange, orderBy } = useTable({
    tableId: 'investmentSummary',
    filtersDefaults,
    sortDefault: {
      field: 'SUM(capTableDetails.outstandingShares * capTableDetails.pricePerShare)',
      direction: 'DESC',
    },
  })

  const { data: investmentData, totals } = useMemo(() => {
    return {
      data: investmentSummary?.data?.data,
      totals: investmentSummary?.data?.totals,
    }
  }, [investmentSummary])

  const fetchInvestmentData = useCallback(
    (params: any) => {
      if (id) {
        listInvestorSummary(id, params)
      }
    },
    [listInvestorSummary, id],
  )

  const debounceListInvestments = useMemo(
    () => debounceEventHandler(fetchInvestmentData, 500),
    [fetchInvestmentData],
  )

  useEffect(() => {
    if (entityInfo?.name) {
      debounceListInvestments({
        page: 0,
        perPage: PER_PAGE,
        investor: entityInfo?.name,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        skipLoader: true,
      })
    }
  }, [orderBy, filters, debounceListInvestments, entityInfo?.name])

  const loadMore = useCallback(() => {
    fetchInvestmentData({
      loadMore: true,
      page: Math.ceil(investmentData?.length / PER_PAGE),
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [investmentData, orderBy, filters, fetchInvestmentData])

  const handleExpand = useCallback(
    (clientName: string) => {
      setExpanded((values) =>
        values.includes(clientName)
          ? values.filter((item) => item !== clientName)
          : [...values, clientName],
      )
    },
    [setExpanded],
  )

  return (
    <Card id="investor-summary-table" noHeaderMargin>
      <TableContainer className={styles.table}>
        <Form
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={INVESTOR_ENTITY_SUMMARY_LIST_FILTERS_CONFIG}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              title="Investment Summary"
              appliedFilters={filters}
            />
          )}
        />
        <Table>
          <TableHead>
            <TableFiltersRow
              filters={INVESTOR_ENTITY_SUMMARY_LIST_FILTERS_CONFIG}
              orderBy={orderBy}
              handleOrderChange={handleOrderChange}
              isChildrenAtStart
            >
              <TableCell />
            </TableFiltersRow>
          </TableHead>
          <TableBody id="scrollableTableReporting">
            {!investmentData ? (
              <TableLoader
                columnsCount={INVESTOR_ENTITY_SUMMARY_LIST_FILTERS_CONFIG.length}
                height={24}
              />
            ) : (
              <InfiniteScroll
                dataLength={investmentData.length}
                next={loadMore}
                hasMore={investmentData.length < totals?.totalItems}
                loader={
                  <TableLoader columnsCount={INVESTOR_ENTITY_SUMMARY_LIST_FILTERS_CONFIG.length} />
                }
                scrollableTarget="scrollableTableReporting"
              >
                {investmentData.map((item) => {
                  const isExpanded = expanded.includes(item.clientName)

                  return (
                    <React.Fragment key={item?.id}>
                      <TableRow className={cn({ [styles.isRowExpanded]: isExpanded })}>
                        <TableCell className={genericSs.tableTextCenter}>
                          {
                            <ExpandDetailIcon
                              onClick={() => handleExpand(item.clientName)}
                              isExpanded={isExpanded}
                            />
                          }
                        </TableCell>
                        <TableCell className={genericSs.tableTextLeft}>
                          {item.clientId ? (
                            <Link
                              component={RouterLink}
                              to={generatePath(ROUTES.CLIENT_PAGE, { id: item.clientId })}
                            >
                              {item.clientName}
                            </Link>
                          ) : (
                            item.clientName
                          )}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          {item.totalInvestment && Math.ceil(item.totalInvestment) !== 0
                            ? `$${formatAmount(item.totalInvestment)}`
                            : '$-'}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          {item.outstandingOwnership
                            ? formatPercent(item.outstandingOwnership)
                            : '-'}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          {item.fullyDilutedOwnership
                            ? formatPercent(item.fullyDilutedOwnership)
                            : '-'}
                        </TableCell>
                        <TableCell className={genericSs.tableTextRight}>
                          {item.totalBoardSeats || 0}
                        </TableCell>
                        <TableCell className={genericSs.tableTextLeft}>
                          {item.boardSeatHolders}
                        </TableCell>
                      </TableRow>
                      {isExpanded && (
                        <TableRow>
                          <TableCell className={genericSs.nestedRowColumn} colSpan={7}>
                            <InvestmentDetailRow
                              clientName={item.clientName}
                              investmentDetailRows={item.rows}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  )
                })}
              </InfiniteScroll>
            )}
          </TableBody>
        </Table>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {totals?.totalItems > 0 && (
            <div className={genericSs.itemsCount}>
              {investmentData?.length} / {totals?.totalItems}
            </div>
          )}
        </Box>
      </TableContainer>
    </Card>
  )
}

export default InvestmentSummaryTable
