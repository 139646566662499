import { IUser } from './user'
import { IARAPReconcileSummary, OngoingReportingType } from './bbc'
import { IFile } from './box'
import { IClientInfo, IClientIntakeInfo, SalesforceStage } from './client'
import { ProspectTermStatus } from '../constants/prospects'

export interface IProspect extends IClientInfo {
  clientName: string
  lastDealActivityDate?: string
  arAmount?: string
  estimatedAvailability?: string
  inventoryAmount?: string
  netRevenue?: string
  applicationsDocuments?: {
    [key: string]: {
      type: string
      link: string
      files: IFile[]
    }
  }
  riskRating: number
  availableAR?: number
  availableInventory?: number
}

export interface ISalesforceProspectInfo {
  id: string
  name: string | null
  salesforceId: string
  clientName: string
  clientInfo?: IProspect
  opsReporting?: IOPSReporting
  salesforceStage: string | null
  salesforceType: string | null
  salesforceLink: string | null
  opsBoxLink: string | null
  lastActivityDate: string | null
  lastDealActivityDate: string
  commitmentAmount: number | null
  nextSteps: string | null
  salesforceOwner: string | null
  riskRating: number
  user?: IUser
}

export interface IProspectsAggregation {
  data: ISalesforceProspectInfo[]
  totalItems: number
  prospects: IProspect[]
  salesforceStages: string[]
  users: IUser[]
  numAccounts: number
  numTermSheets: number
  dealsClosed: number
  dealsClosedYtd: number
  dealsGoal: number
  referralsThisYear: number
  referralsLastYear: number
  referralsLastYtd: number
  updatedAt: Date | string
}
export enum Feedback {
  LIKED = 'LIKED',
  DISLIKED = 'DISLIKED',
  CLEAR = 'CLEAR',
}

export interface IFlagFeedback {
  id: string
  userId: string
  user?: IUser
  feedback: Feedback
}

export interface IProspectReportDocumentType {
  type: string
  title: string
  description: string
  isShortList?: boolean
}

export const PROSPECT_REPORT_DOCUMENTS_TYPES_LIST: IProspectReportDocumentType[] = [
  {
    type: OngoingReportingType.BalanceSheet,
    title: 'Historical P&L and Balance Sheet',
    description: 'Monthly - 2021, 2022, and YTD',
  },
  {
    type: OngoingReportingType.BalanceSheetProjections,
    title: 'Forecasted P&L and Balance Sheet',
    description: 'Monthly',
    isShortList: true,
  },
  {
    type: OngoingReportingType.AR,
    title: 'AR Aging detail report',
    description: 'Detailed report at invoice level',
  },
  {
    type: OngoingReportingType.AP,
    title: 'AP Aging detail report',
    description: 'Detailed report at invoice level',
  },
  {
    type: OngoingReportingType.Inventory,
    title: 'Detailed Inventory Perpetual Report',
    description: 'Count & cost by SKU. Aging as well, if readily available',
    isShortList: true,
  },
  {
    type: OngoingReportingType.CapTable,
    title: 'Detailed cap table',
    description: 'This document is required for your application',
    isShortList: true,
  },
  {
    type: OngoingReportingType.OrgChart,
    title: 'Org chart including board members',
    description: 'A typed list with name and title is fine',
    isShortList: true,
  },
]

export enum OPSReportingStatus {
  NotStarted = 'Not Started',
  InProgress = 'In Progress',
  Complete = 'Complete',
  Archived = 'Archived',
}

export enum OPSReportingFlowStatus {
  FileSelect = 'File Select',
  Mapping = 'Mapping',
  Complete = 'Complete',
  Waived = 'Waived',
}

export enum SalesforceTypes {
  Lead = 'Lead',
  Account = 'Account',
  Opportunity = 'Opportunity',
}

export interface IOPSReportingFilesMapping {
  [key: string]: {
    id?: string
    fileId?: string
    oldFileId?: string
    sheetName?: string
    processed?: boolean
    completed?: boolean
  }
}

export interface IOPSReporting {
  id: string
  status: OPSReportingStatus
  recordDate: string
  clientName: string
  clientInfo?: IProspect
  filesMapping?: IOPSReportingFilesMapping
  arReconcileSummary: IARAPReconcileSummary
  apReconcileSummary: IARAPReconcileSummary
  files?: IFile[]
  opsReportingFlows: IOPSReportingFlow[]
  preProcessed: boolean
  clientIntakeInfo?: IClientIntakeInfo
  clientIntakeSubmittedAt?: string
  salesforceProspectInfoId: string | null
  salesforceProspectInfo?: ISalesforceProspectInfo
  refreshedAt: string | null
}

export interface IOPSReportingFlowFiles {
  id: string
  fileId: string | null
  file?: IFile
  sheetName: string | null
  data?: any
}

export interface IOPSReportingFlow {
  id: string
  type: string
  status: OPSReportingFlowStatus
  boxLink?: string
  crossAge: number
  concentrationLimit: number
  advanceRate: number
  files: IOPSReportingFlowFiles[]
}

export interface IProspectReporting {
  id: string
  clientName: string
  clientId: string
  opsReportingId: string
  type: string
  boxLink?: string
  submittedDate?: string
  monthsProcessed?: string
  status: OPSReportingFlowStatus
  createdAt: string
  updatedAt: string | null
  completedAt: string | null
}

export interface IProspectReportingAggregation {
  data: IProspectReporting[]
  totalCount: number
}

export const PROSPECT_REPORTING_MAP = {
  [OngoingReportingType.AR]: 'AR Aging',
  [OngoingReportingType.AP]: 'AP Aging',
  [OngoingReportingType.IncomeStatement]: 'Income Statement',
  [OngoingReportingType.BalanceSheet]: 'Balance Sheet',
  [OngoingReportingType.IncomeStatementProjections]: 'Projected Income Statement',
  [OngoingReportingType.BalanceSheetProjections]: 'Projected Balance Sheet',
  [OngoingReportingType.Inventory]: 'Inventory',
  [OngoingReportingType.CapTable]: 'Detailed Cap Table',
  [OngoingReportingType.OrgChart]: 'Org Chart',
  [OngoingReportingType.Uncategorized]: 'Uncategorized Files',
  [OngoingReportingType.Miscellaneous]: 'Miscellaneous',
}

export const PROSPECT_REPORTING_TYPES = [
  OngoingReportingType.AR,
  OngoingReportingType.AP,
  OngoingReportingType.Inventory,
  OngoingReportingType.BalanceSheet,
  OngoingReportingType.IncomeStatement,
  OngoingReportingType.BalanceSheetProjections,
  OngoingReportingType.IncomeStatementProjections,
]

export const PROSPECT_REPORTING_EXTENDED_TYPES = [
  ...PROSPECT_REPORTING_TYPES,
  OngoingReportingType.CapTable,
  OngoingReportingType.OrgChart,
]

export enum ProspectFolderNames {
  AR = 'Aging Report/AR',
  AP = 'Aging Report/AP',
  Inventory = 'Inventory',
  Financials = 'Financials',
  Projections = 'Projections',
  CapTable = 'Cap Table',
  Misc = 'Misc',
  SampleInvoice = 'Sample Invoice',
  TermSheet = 'Term Sheet',
  Uncategorized = 'Uncategorized',
}

export const NON_UPLOADABLE_PROSPECT_FOLDERS = [
  ProspectFolderNames.SampleInvoice,
  ProspectFolderNames.TermSheet,
  ProspectFolderNames.Misc,
  ProspectFolderNames.CapTable,
  ProspectFolderNames.Uncategorized,
]

export enum ProspectFolderTypes {
  Atlas = 'Atlas',
  Originals = 'Originals',
}

export const PROSPECT_FILES_FOLDERS_MAP = {
  [OngoingReportingType.AR]: ProspectFolderNames.AR,
  [OngoingReportingType.AP]: ProspectFolderNames.AP,
  [OngoingReportingType.Inventory]: ProspectFolderNames.Inventory,
  [OngoingReportingType.BalanceSheet]: ProspectFolderNames.Financials,
  [OngoingReportingType.IncomeStatement]: ProspectFolderNames.Financials,
  [OngoingReportingType.BalanceSheetProjections]: ProspectFolderNames.Projections,
  [OngoingReportingType.IncomeStatementProjections]: ProspectFolderNames.Projections,
  [OngoingReportingType.CapTable]: ProspectFolderNames.CapTable,
  [OngoingReportingType.OrgChart]: ProspectFolderNames.CapTable,
  [OngoingReportingType.Miscellaneous]: ProspectFolderNames.Misc,
  [OngoingReportingType.Uncategorized]: ProspectFolderNames.Uncategorized,
}

export interface IProspectTerms {
  id: string
  clientName: string
  clientInfo?: IProspect
  recordDate: string
  status: ProspectTermStatus | null
  maxLineAmount: number
  minimumLineAmount: number
  minimumInterestEffectiveDate: string
  unusedLineFee: number
  interestRate: number
  interestRateBase: number
  interestRateAbovePrime: number
  loanType: string
  arAdvance: number
  inventoryAdvanceRateCost: number
  inventoryAdvanceRateNolv: number
  sublimit: number
  facilityFee: number
  terminationYear1: number
  terminationYear2: number
  expirationDate: string
  deposits: number
  guarentees: string
  subordination: boolean
  commentsForCommittee: string
  covenantType: string
  qualifiedEquityRound: number
  createdAt: string
  updatedAt: string
  summaryOfChanges: string
  boxLink: string | null
  user?: IUser
}

export interface IProspectSummaryInventory {
  numberOfSKUs: number
  inventoryValue: number
  averageCOGS: number
  dioInventory: number
  averageInventoryBS: number
  dioBS: number
}

export interface IProspectSummaryCashFlow {
  asOfDate: string
  numberOfMonthLast: number
  numberOfMonthNext: number
  mostRecentCash: number
  avgOperatingCashFlow: number
  monthsOfCashOnHandOperatingCashFlow: number | string
  monthsOfCashOnHandEBITDA: number | string
  avgEBITDA: number
  avgNetIncome: number
}

interface IProspectReportBuckets {
  _0_to_30_days: number
  _31_to_60_days: number
  _61_to_90_days: number
  _91_plus_days: number
  total: number
}

interface IProspectReportBase extends IProspectReportBuckets {
  id: string
  clientName: string
  total_percent: number
  entity_id: string | null
  prospect_entity_id: string | null
}

export interface IProspectAR extends IProspectReportBase {
  debtor: string | null
  cross_aged: number
  concentration_limit: number
  eligible_ar: number
  ineligible_ar: number
  available_ar: number
  eligible: boolean
  rows?: IProspectAR[]
  totalCount?: number
}

interface IProspectARTotals extends IProspectReportBuckets {
  cross_aged: number
  concentration_limit: number
  eligible_ar: number
  ineligible_ar: number
  available_ar: number
  totalItems: number
}
export interface IProspectARSummary {
  data: IProspectAR[]
  totals: IProspectARTotals
}

export interface IProspectAP extends IProspectReportBase {
  creditor: string | null
  rows: IProspectAP[]
  totalCount?: number
}

interface IProspectAPTotals extends IProspectReportBuckets {
  totalItems: number
}
export interface IProspectAPSummary {
  data: IProspectAP[]
  totals: IProspectAPTotals
}

export const PROSPECT_SALESFORCE_ARCHIVE_REASONS = [
  {
    value: SalesforceStage.TrackingEarly,
    label: 'Tracking-Too Early',
  },
  {
    value: SalesforceStage.Archived,
    label: 'Archived',
  },
  {
    value: SalesforceStage.Rejected,
    label: 'Rejected',
  },
]

export const BD_YEARLY_GOAL = 150000000

export enum ProspectRefreshType {
  Codat = 'codat',
  Box = 'box',
  Uncategorized = 'uncategorized',
}
