import { prefix } from './actions'
import { IRootState } from '../reducer'

export const flags = (state: IRootState) => state[prefix].flags

export const flagDetails = (state: IRootState) => state[prefix].flagDetails

export const isLoading = (state: IRootState) => state[prefix].isLoading

export const newFlagsCount = (state: IRootState) => state[prefix].newFlagsCount

export const flagOptions = (state: IRootState) => state[prefix].flagOptions
