import React, { useCallback, useState } from 'react'
import { IOptionType } from '@common/interfaces/entityInfo'

interface IProps {
  isAsync: boolean
  onInputChangeDefault?: (
    event: React.SyntheticEvent,
    inputValue: string,
    reason: 'input' | 'reset' | 'clear',
  ) => void
  loadOptions?: (inputValue: string) => Promise<IOptionType[]>
  minLength?: number
}

const useLoadedOptions = ({
  isAsync,
  onInputChangeDefault,
  loadOptions,
  minLength = 1,
}: IProps) => {
  const [asyncOptions, setAsyncOptions] = useState<IOptionType[]>([])

  const handleInputChange = useCallback(
    async (
      event: React.SyntheticEvent,
      inputValue: string,
      reason: 'input' | 'reset' | 'clear',
    ) => {
      if (onInputChangeDefault) {
        onInputChangeDefault(event, inputValue, reason)
      }

      if (!isAsync) {
        return
      }

      if (reason === 'input' && inputValue.length > minLength && loadOptions) {
        const loadedOptions = await loadOptions(inputValue)
        setAsyncOptions(loadedOptions)
      } else {
        setAsyncOptions([])
      }
    },
    [isAsync, loadOptions, onInputChangeDefault, minLength],
  )

  return {
    asyncOptions,
    handleInputChange,
  }
}

export default useLoadedOptions
