import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import ProspectHeaderLinks from './ProspectHeaderLinks'
import { reporting } from '../../redux/prospect/selectors'
import { show, update, createNewOps, pullReports } from '../../redux/prospect/actions'

const selector = createStructuredSelector({
  reporting,
})
const actions = {
  show,
  update,
  createNewOps,
  pullReports,
}

export default connect(selector, actions)(ProspectHeaderLinks)
