import React, { useMemo } from 'react'
import { Form } from 'react-final-form'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import Box from '@mui/material/Box'

import genericSs from '@styles/generic.module.scss'

import Modal from '../Common/Modal'
import ModalButton from '../Common/Button/Button'
import InputLabel from '../Common/InputLabel'
import TextField from '../Common/TextField'
import { ILCRCommentary } from '@common/interfaces/dueDiligence'

const schema = Yup.object().shape({
  field: Yup.string().required('Required'),
  helperText: Yup.string().nullable(),
})
const validate = makeValidate(schema)

interface IProps {
  item?: ILCRCommentary
  isSaving: boolean
  onSave: (data: any) => Promise<any>
  onClose: () => void
}

const LCRCommentaryFieldModal = ({ item, isSaving, onSave, onClose }: IProps) => {
  const initialValues = useMemo(
    () => ({
      field: item?.field || '',
      helperText: item?.helperText || '',
    }),
    [item],
  )

  return (
    <Modal open onCancel={onClose} title={item?.id ? 'Edit Commentary' : 'New Commentary'}>
      <Form
        initialValues={initialValues}
        onSubmit={onSave}
        validate={validate}
        render={({
          invalid,
          pristine,
          handleSubmit,
        }: {
          invalid: boolean
          pristine: boolean
          handleSubmit: any
        }) => (
          <form className={genericSs.textLeft} onSubmit={handleSubmit}>
            <Box mt={2}>
              <InputLabel htmlFor="field">Label</InputLabel>

              <TextField name="field" placeholder="Title for the commentary" fullWidth />
            </Box>

            <Box mt={2}>
              <InputLabel htmlFor="helperText">Description</InputLabel>

              <TextField
                name="helperText"
                placeholder="Instructions for how to fill out this field"
                fullWidth
              />
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-around" mt={2}>
              <ModalButton
                isLoading={isSaving}
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                disabled={invalid || pristine}
                small={false}
                fullWidth
              >
                Save
              </ModalButton>
            </Box>
          </form>
        )}
      />
    </Modal>
  )
}

export default LCRCommentaryFieldModal
