import React, { useEffect, useMemo } from 'react'
import { generatePath, useParams } from 'react-router-dom'
import { Box, Grid } from '@mui/material'

import LoanCommitteeReportHeader from '../../components/LoanCommitteeReportHeader'
import { DUE_DILIGENCE_ROUTES_MAP, IDueDiligence } from '@common/interfaces/dueDiligence'
import { OngoingReportingType, ReportingFlow } from '@common/interfaces/bbc'
import Card from '../../components/Common/Card'
import { WorkflowTypes } from '@common/interfaces/notes'
import BankTransactions from '../../components/BankTransactions'
import { ROUTES } from '../../constants/routes'
import DilutionReconciliation from '../../components/SalesChannelDistribution'
import LCRCommentary from '../../components/LCRCommentary'
import { LCRCommentarySections } from '@common/constants/lcrCommentary'

interface IProps {
  dueDiligenceInfo: IDueDiligence
  listDilutionDropdowns: (id: string, params: { reportingFlow: ReportingFlow }) => void
}

const LoanCommitteeReportTransactionPage = ({
  dueDiligenceInfo,
  listDilutionDropdowns,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    listDilutionDropdowns(id, { reportingFlow: ReportingFlow.LoanCommitteeReport })
  }, [id, listDilutionDropdowns])

  const editLink = useMemo(
    () =>
      generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_LIST, {
        id,
        type: DUE_DILIGENCE_ROUTES_MAP[OngoingReportingType.BankTransactions],
      }),
    [id],
  )

  return (
    <Box py={1} pr={2}>
      <LoanCommitteeReportHeader />
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card noHeaderMargin>
            <DilutionReconciliation reportingFlow={ReportingFlow.LoanCommitteeReport} />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <LCRCommentary section={LCRCommentarySections.DilutionAnalysis} />
        </Grid>

        <Grid item xs={12}>
          <Card noHeaderMargin>
            <BankTransactions
              workflow={WorkflowTypes.clientPage}
              clientName={dueDiligenceInfo?.clientName}
              reportingFlow={ReportingFlow.LoanCommitteeReport}
              editLink={editLink}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LoanCommitteeReportTransactionPage
