import React, { useMemo } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { withErrorBoundary, useErrorBoundary } from 'react-use-error-boundary'

import { logError } from './redux/logger/actions'

import BlankPage from './pages/BlankPage'
import DashboardPage from './pages/DashboardPage'
import ExportsDashboard from './components/ExportsDashboard'
import AuthContainer from './components/AuthContainer'
import PrivateRoute from './components/PrivateRoute'

import ClientSetupPage from './pages/ClientSetupPage'
import ClientSetupLoanStructureAssetBasedPage from './pages/ClientSetupLoanStructureAssetBasedPage'
import ClientSetupLoanStructureTermPage from './pages/ClientSetupLoanStructureTermPage'
import ClientSetupLoanStructureOveradvancePage from './pages/ClientSetupLoanStructureOveradvancePage'
import ClientSetupCollateralReceivablePage from './pages/ClientSetupCollateralReceivablePage'
import ClientSetupCollateralPayablePage from './pages/ClientSetupCollateralPayablePage'
import ClientSetupCollateralInventoryPage from './pages/ClientSetupCollateralInventoryPage'
import ClientSetupItemsToMonitorPage from './pages/ClientSetupItemsToMonitorPage'
import ClientSetupReportingPage from './pages/ClientSetupReportingPage'
import ClientSetupBankAccountPage from './pages/ClientSetupBankAccountPage'
import ClientSetupRelationshipManagerPage from './pages/ClientSetupRelationshipManagerPage'
import ClientSetupUsersPage from './pages/ClientSetupUsersPage'
import ClientSetupTerminationPage from './pages/ClientSetupTerminationPage'
import ClientSetupDocumentsPage from './pages/ClientSetupDocumentsPage'
import ClientManagementPage from './pages/ClientManagementPage'
import UserManagementPage from './pages/UserManagementPage'
import CollectionsDashboardPage from './pages/CollectionsDashboardPage'
import CollectionsChecksPage from './pages/CollectionsChecksPage'
import CollectionsWiresPage from './pages/CollectionsWiresPage'
import CollectionsMidDayWiresPage from './pages/CollectionsMidDayWiresPage'
import ActivityQueuePage from './pages/ActivityQueuePage'
import BBCCollateralEligibilityPage from './pages/BBCCollateralEligibilityPage'
import BBCCollateralEligibilityReceivablesPage from './pages/BBCCollateralEligibilityReceivablesPage'
import BBCCollateralEligibilityInventoryPage from './pages/BBCCollateralEligibilityInventoryPage'
import BBCSummaryPage from './pages/BBCSummaryPage'
import OutgoingWirePage from './pages/OutgoingWirePage'
import AccountActivityPage from './pages/AccountActivityPage'
import SubmitReportingPage from './pages/SubmitReportingPage'
import ClientPage from './pages/ClientPage'
import AuditPage from './pages/AuditPage'
import OngoingReportingPage from './pages/OngoingReportingPage'
import OngoingReportingSalesBySKUPage from './pages/OngoingReportingSalesBySKUPage'
import OngoingReportingARGeneralLedgerPage from './pages/OngoingReportingARGeneralLedgerPage'
import BankTransactionsPage from './pages/BankTransactionsPage'
import CapTablePage from './pages/CapTablePage'
import ProspectSetupPage from './pages/ProspectSetupPage'
import ProspectSummaryPage from './pages/ProspectSummaryPage'
import ProspectReportingPayablesPage from './pages/ProspectReportingPayablesPage'
import ProspectReportingInventoryPage from './pages/ProspectReportingInventoryPage'
import ProspectReportingFinancialsPage from './pages/ProspectReportingFinancialsPage'
import ProspectManageTermsPage from './pages/ProspectManageTermsPage'
import ProspectAddTermsPage from './pages/ProspectAddTermsPage'
import { ROUTES } from './constants/routes'
import EntityPage from './pages/EntityPage'
import EntityManagement from './pages/EntityManagementPage'
import EntitySettingsPage from './pages/EntitySettingsPage'
import EntitySettingsGeneralPage from './pages/EntitySettingsGeneralPage'
import EntitySettingsAliasMapping from './pages/EntitySettingsAliasMapping'
import ProspectReportingReceivablesPage from './pages/ProspectReportingReceivablesPage'
import ClientSettingsPage from './pages/ClientSettingsPage'
import ProspectPage from './pages/ProspectPage'
import ProspectApplicationPage from './pages/ProspectApplicationPage'
import ProspectAnalysisQueuePage from './pages/ProspectAnalysisQueuePage'
import NotificationPage from './pages/NotificationPage'
import DueDiligenceCompanyBackgroundPage from './pages/DueDiligenceCompanyBackgroundPage'
import DueDiligenceTeamPage from './pages/DueDiligenceTeamPage'
import DueDiligenceClientFinancialsPage from './pages/DueDiligenceClientFinancialsPage'
import DueDiligenceCapTableMappingPage from './pages/DueDiligenceCapTableMappingPage'
import DueDiligenceProcessDocumentsPage from './pages/DueDiligenceProcessDocumentsPage'
import DueDiligenceProcessDocumentsListPage from './pages/DueDiligenceProcessDocumentsListPage'
import DueDiligenceProcessDocumentsMappingPage from './pages/DueDiligenceProcessDocumentsMappingPage'
import DueDiligenceProcessDocumentsFinancialsPage from './pages/DueDiligenceProcessDocumentsFinancialsPage'
import DueDiligenceProcessDocumentsBankTransactionsPage from './pages/DueDiligenceProcessDocumentsBankTransactionsPage'
import DueDiligenceProcessDocumentsGeneralLedgerPage from './pages/DueDiligenceProcessDocumentsGeneralLedgerPage'
import DueDiligenceProcessDocumentsInventoryPage from './pages/DueDiligenceProcessDocumentsInventoryPage'
import DueDiligenceSalesBySKUPage from './pages/DueDiligenceSalesBySKUPage'
import DueDiligenceValidateDocumentsPage from './pages/DueDiligenceValidateDocumentsPage'
import BDOHomepage from './pages/BDOHomepage'
import UWDashboard from './components/UWDashboard'
import DueDiligenceClientDocumentRequestsPage from './pages/DueDiligenceClientDocumentRequestsPage'
import EntitySettingsReceivablesPage from './pages/EntitySettingsReceivablesPage'
import EntitySettingsPayablesPage from './pages/EntitySettingsPayablesPage'
import EntitySettingsRelatedEntitiesPage from './pages/EnititySettingsRelatedEntitiesPage'
import GlobalSearchPage from './pages/GlobalSearchPage'
import ClientSetupLoanStructureParticipantsPage from './pages/ClientSetupLoanStructureParticipantsPage'
import { debounceEventHandler, isLocalhost } from './helpers/helpers'
import RedirectPage from './pages/CodatRedirectPage'
import ParticipantPage from './pages/ParticipantPage'
import ParticipantSettingsPage from './pages/ParticipantSettingsPage'
import ClientVisualizationsPage from './pages/ClientVisualizationsPage'
import ParticipantManagementPage from './pages/ParticipantManagementPage'
import MarketNewsPage from './pages/MarketNewsPage'
import LoanCommitteeReportOverviewPage from './pages/LoanCommitteeReportOverviewPage'
import LoanCommitteeReportFinancialsPage from './pages/LoanCommitteeReportFinancialsPage'
import LoanCommitteeReportPayablesPage from './pages/LoanCommitteeReportPayablesPage'
import LoanCommitteeReportReceivablesPage from './pages/LoanCommitteeReportReceivablesPage'
import LoanCommitteeReportInventoryPage from './pages/LoanCommitteeReportInventoryPage'
import LoanCommitteeReportCapTablePage from './pages/LoanCommitteeReportCapTablePage'
import LoanCommitteeReportForecastPage from './pages/LoanCommitteeReportForecastPage'
import LoanCommitteeReportTransactionPage from './pages/LoanCommitteeReportTransactionPage'
import LoanCommitteeReportFieldExamPage from './pages/LoanCommitteeReportFieldExamPage'
import InsurancePage from './pages/OngoingReportingInsurancePage'

const WITH_REDIRECT = !isLocalhost()

const Routes = withErrorBoundary(() => {
  const dispatch = useDispatch()

  const debounceLogError = useMemo(
    () =>
      debounceEventHandler((error: Error) => {
        const { name, message, stack } = error as Error

        dispatch(
          logError({
            name,
            message,
            stack,
            location: JSON.stringify(window.location),
          }),
        )
        if (WITH_REDIRECT) {
          window.location.href = ROUTES.GLOBAL_ERROR
        }
      }, 500),
    [dispatch],
  )

  useErrorBoundary((error) => debounceLogError(error))

  return (
    <Switch>
      <PrivateRoute path={ROUTES.HOMEPAGE} component={DashboardPage} exact />
      <PrivateRoute path={ROUTES.SETTINGS_PROFILE} component={ClientSettingsPage} exact />
      <PrivateRoute path={ROUTES.SETTINGS} component={ClientSettingsPage} exact />
      <PrivateRoute path={ROUTES.SETTINGS_SECURITY} component={ClientSettingsPage} exact />
      <PrivateRoute
        path={ROUTES.CLIENT_SETTINGS_INTEGRATIONS}
        component={ClientSettingsPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.CLIENT_SETTINGS_NOTIFICATIONS}
        component={ClientSettingsPage}
        exact
      />

      <PrivateRoute path={ROUTES.CLIENT_MANAGEMENT} component={ClientManagementPage} exact />
      <PrivateRoute path={ROUTES.USER_MANAGEMENT} component={UserManagementPage} exact />
      <PrivateRoute path={ROUTES.AUDIT} component={AuditPage} exact />
      <PrivateRoute
        path={ROUTES.CLIENT_SETUP_CREATE}
        component={ClientSetupLoanStructureAssetBasedPage}
        exact
      />
      <PrivateRoute path={ROUTES.CLIENT_SETUP} component={ClientSetupPage} exact />
      <PrivateRoute
        path={ROUTES.CLIENT_SETUP_LOAN_STRUCTURE_ASSET_BASED}
        component={ClientSetupLoanStructureAssetBasedPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.CLIENT_SETUP_LOAN_STRUCTURE_TERM}
        component={ClientSetupLoanStructureTermPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.CLIENT_SETUP_LOAN_STRUCTURE_OVERADVANCE}
        component={ClientSetupLoanStructureOveradvancePage}
        exact
      />
      <PrivateRoute
        path={ROUTES.CLIENT_SETUP_LOAN_STRUCTURE_PARTICIPANTS}
        component={ClientSetupLoanStructureParticipantsPage}
        exact
      />

      <PrivateRoute
        path={ROUTES.PARTICIPANTS_MANAGEMENT}
        component={ParticipantManagementPage}
        exact
      />
      <PrivateRoute path={ROUTES.PARTICIPANTS_PAGE} component={ParticipantPage} exact />
      <PrivateRoute path={ROUTES.PARTICIPANT_SETTINGS} component={ParticipantSettingsPage} exact />

      <PrivateRoute
        path={ROUTES.CLIENT_SETUP_COLLATERAL_RECEIVABLE}
        component={ClientSetupCollateralReceivablePage}
        exact
      />
      <PrivateRoute
        path={ROUTES.CLIENT_SETUP_COLLATERAL_PAYABLE}
        component={ClientSetupCollateralPayablePage}
        exact
      />
      <PrivateRoute
        path={ROUTES.CLIENT_SETUP_COLLATERAL_INVENTORY}
        component={ClientSetupCollateralInventoryPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.CLIENT_SETUP_ITEMS_TO_MONITOR}
        component={ClientSetupItemsToMonitorPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.CLIENT_SETUP_REPORTING}
        component={ClientSetupReportingPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.CLIENT_SETUP_BANK_ACCOUNTS}
        component={ClientSetupBankAccountPage}
        exact
      />
      <PrivateRoute path={ROUTES.CLIENT_SETUP_USERS} component={ClientSetupUsersPage} exact />
      <PrivateRoute
        path={ROUTES.CLIENT_SETUP_RELATIONSHIP_MANAGEMENT}
        component={ClientSetupRelationshipManagerPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.CLIENT_SETUP_TERMINATION}
        component={ClientSetupTerminationPage}
        exact
      />
      <PrivateRoute path={ROUTES.CLIENT_SETUP_GENERAL} component={ClientSetupDocumentsPage} exact />

      <PrivateRoute
        path={ROUTES.COLLECTIONS_DASHBOARD}
        component={CollectionsDashboardPage}
        exact
      />

      <PrivateRoute
        path={ROUTES.COLLECTIONS_DASHBOARD_OVERALL}
        component={CollectionsDashboardPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.COLLECTIONS_DASHBOARD_NON_CLIENT_RELATED_CASH}
        component={CollectionsDashboardPage}
        exact
      />

      <PrivateRoute path={ROUTES.COLLECTIONS_WIRES} component={CollectionsWiresPage} exact />
      <PrivateRoute
        path={ROUTES.COLLECTIONS_MID_DAY_WIRES}
        component={CollectionsMidDayWiresPage}
        exact
      />
      <PrivateRoute path={ROUTES.COLLECTIONS_CHECKS} component={CollectionsChecksPage} exact />

      <PrivateRoute path={ROUTES.ACTIVITY_QUEUE} component={ActivityQueuePage} exact />
      <PrivateRoute path={ROUTES.OUTGOING_WIRE} component={OutgoingWirePage} exact />
      <Redirect from={ROUTES.COLLECTIONS} exact to={ROUTES.COLLECTIONS_DASHBOARD} />

      <PrivateRoute
        path={ROUTES.BBC_COLLATERAL_ELIGIBILITY}
        component={BBCCollateralEligibilityPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.BBC_COLLATERAL_ELIGIBILITY_RECEIVABLES}
        component={BBCCollateralEligibilityReceivablesPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.BBC_COLLATERAL_ELIGIBILITY_INVENTORY}
        component={BBCCollateralEligibilityInventoryPage}
        exact
      />
      <PrivateRoute path={ROUTES.BBC_SUMMARY} component={BBCSummaryPage} exact />
      <PrivateRoute path={ROUTES.BBC_SUMMARY_RECEIVABLES} component={BBCSummaryPage} exact />
      <PrivateRoute path={ROUTES.BBC_SUMMARY_PAYABLES} component={BBCSummaryPage} exact />
      <PrivateRoute path={ROUTES.BBC_SUMMARY_INVENTORY} component={BBCSummaryPage} exact />
      <Redirect from={ROUTES.BBC} exact to={ROUTES.BBC_SUMMARY} />

      <PrivateRoute
        path={ROUTES.ONGOING_REPORTING_SALES_BY_SKU}
        component={OngoingReportingSalesBySKUPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.ONGOING_REPORTING_AR_GENERAL_LEDGER}
        component={OngoingReportingARGeneralLedgerPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.ONGOING_REPORTING_BALANCE_SHEET}
        component={OngoingReportingPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.ONGOING_REPORTING_INCOME_STATEMENT}
        component={OngoingReportingPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.ONGOING_REPORTING_SUMMARY}
        component={OngoingReportingPage}
        exact
      />
      <Redirect
        from={ROUTES.ONGOING_REPORTING}
        exact
        to={ROUTES.ONGOING_REPORTING_INCOME_STATEMENT}
      />

      <PrivateRoute path={ROUTES.ACCOUNT_ACTIVITY} component={AccountActivityPage} exact />
      <PrivateRoute path={ROUTES.SUBMIT_REPORTING} component={SubmitReportingPage} exact />
      <PrivateRoute
        path={ROUTES.CLIENT_VISUALIZATIONS}
        component={ClientVisualizationsPage}
        exact
      />

      <PrivateRoute path={ROUTES.COMPANY} component={BlankPage} />
      <PrivateRoute path={ROUTES.CLIENT_SUBMIT_REPORTING_PAGE} component={SubmitReportingPage} />
      <PrivateRoute path={ROUTES.CLIENT_PAGE} component={ClientPage} />
      <PrivateRoute path={ROUTES.CLIENTS} component={BlankPage} />

      <PrivateRoute
        path={ROUTES.ONGOING_REPORTING_BANK_TRANSACTIONS_UPLOAD}
        component={BankTransactionsPage}
        exact
      />

      <PrivateRoute
        path={ROUTES.ONGOING_REPORTING_CAP_TABLE_UPLOAD}
        component={CapTablePage}
        exact
      />

      <PrivateRoute
        path={ROUTES.ONGOING_REPORTING_INSURANCE_PAGE}
        component={InsurancePage}
        exact
      />

      <PrivateRoute path={ROUTES.PROSPECT_SETUP} component={ProspectSetupPage} exact />
      <PrivateRoute path={ROUTES.PROSPECTS} component={BDOHomepage} />
      <PrivateRoute path={ROUTES.PROSPECT_PAGE} component={ProspectPage} exact />
      <PrivateRoute
        path={ROUTES.PROSPECT_APPLICATION_PAGE}
        component={ProspectApplicationPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.PROSPECT_ANALYSIS_QUEUE}
        component={ProspectAnalysisQueuePage}
        exact
      />
      <PrivateRoute path={ROUTES.PROSPECT_SUMMARY_PAGE} component={ProspectSummaryPage} exact />
      <Route path={ROUTES.CODAT_REDIRECT_PAGE} component={RedirectPage} exact />

      <PrivateRoute
        path={ROUTES.PROSPECT_REPORTING_RECEIVABLES}
        component={ProspectReportingReceivablesPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.PROSPECT_REPORTING_PAYABLES}
        component={ProspectReportingPayablesPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.PROSPECT_REPORTING_INVENTORY}
        component={ProspectReportingInventoryPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.PROSPECT_REPORTING_INCOME_STATEMENT}
        component={ProspectReportingFinancialsPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.PROSPECT_REPORTING_BALANCE_SHEET}
        component={ProspectReportingFinancialsPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.PROSPECT_REPORTING_INCOME_STATEMENT_PROJECTIONS}
        component={ProspectReportingFinancialsPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.PROSPECT_REPORTING_BALANCE_SHEET_PROJECTIONS}
        component={ProspectReportingFinancialsPage}
        exact
      />
      <PrivateRoute path={ROUTES.PROSPECT_ADD_TERMS_PAGE} component={ProspectAddTermsPage} exact />
      <PrivateRoute path={ROUTES.PROSPECT_EDIT_TERMS_PAGE} component={ProspectAddTermsPage} exact />

      <PrivateRoute
        path={ROUTES.PROSPECT_MANAGE_TERMS_PAGE}
        component={ProspectManageTermsPage}
        exact
      />
      <PrivateRoute path={ROUTES.DUE_DILIGENCE_HOME} component={UWDashboard} exact />

      <PrivateRoute
        path={ROUTES.DUE_DILIGENCE}
        component={DueDiligenceCompanyBackgroundPage}
        exact
      />
      <PrivateRoute path={ROUTES.DUE_DILIGENCE_TEAM_PAGE} component={DueDiligenceTeamPage} exact />
      <PrivateRoute
        path={ROUTES.DUE_DILIGENCE_CLIENT_FINANCIALS_PAGE}
        component={DueDiligenceClientFinancialsPage}
        exact
      />

      <PrivateRoute
        path={ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE}
        component={DueDiligenceProcessDocumentsPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.DUE_DILIGENCE_VALIDATE_DOCUMENTS_PAGE}
        component={DueDiligenceValidateDocumentsPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_LIST}
        component={DueDiligenceProcessDocumentsListPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_MAPPING}
        component={DueDiligenceProcessDocumentsMappingPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_FINANCIALS}
        component={DueDiligenceProcessDocumentsFinancialsPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_BANK_TRANSACTIONS}
        component={DueDiligenceProcessDocumentsBankTransactionsPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_GENERAL_LEDGER}
        component={DueDiligenceProcessDocumentsGeneralLedgerPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_INVENTORY}
        component={DueDiligenceProcessDocumentsInventoryPage}
        exact
      />

      <PrivateRoute
        path={ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_SALES_BY_SKU}
        component={DueDiligenceSalesBySKUPage}
        exact
      />

      <PrivateRoute
        path={ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_CAP_TABLE}
        component={DueDiligenceCapTableMappingPage}
        exact
      />

      <PrivateRoute
        path={ROUTES.DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_PAGE}
        component={LoanCommitteeReportOverviewPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_FINANCIALS_PAGE}
        component={LoanCommitteeReportFinancialsPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_FORECAST_PAGE}
        component={LoanCommitteeReportForecastPage}
        exact
      />

      <PrivateRoute
        path={ROUTES.DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_PAYABLES_PAGE}
        component={LoanCommitteeReportPayablesPage}
        exact
      />

      <PrivateRoute
        path={ROUTES.DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_RECEIVABLES_PAGE}
        component={LoanCommitteeReportReceivablesPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_INVENTORY_PAGE}
        component={LoanCommitteeReportInventoryPage}
        exact
      />

      <PrivateRoute
        path={ROUTES.DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_CAP_TABLE_PAGE}
        component={LoanCommitteeReportCapTablePage}
        exact
      />

      <PrivateRoute
        path={ROUTES.DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_TRANSACTIONS_PAGE}
        component={LoanCommitteeReportTransactionPage}
        exact
      />

      <PrivateRoute
        path={ROUTES.DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_FIELD_EXAM_PAGE}
        component={LoanCommitteeReportFieldExamPage}
        exact
      />

      <PrivateRoute path={ROUTES.ENTITY_PAGE} component={EntityPage} />
      <PrivateRoute path={ROUTES.ENTITY_MANAGEMENT} component={EntityManagement} />
      <PrivateRoute path={ROUTES.ENTITY_SETTINGS} component={EntitySettingsPage} exact />
      <PrivateRoute
        path={ROUTES.ENTITY_SETTINGS_GENERAL}
        component={EntitySettingsGeneralPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.ENTITY_SETTINGS_RECEIVABLES}
        component={EntitySettingsReceivablesPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.ENTITY_SETTINGS_PAYABLES}
        component={EntitySettingsPayablesPage}
        exact
      />
      <PrivateRoute
        path={ROUTES.ENTITY_SETTINGS_ALIAS_MAPPING}
        component={EntitySettingsAliasMapping}
        exact
      />
      <PrivateRoute
        path={ROUTES.ENTITY_SETTINGS_RELATED_ENTITIES}
        component={EntitySettingsRelatedEntitiesPage}
        exact
      />

      <PrivateRoute path={ROUTES.NOTIFICATIONS} component={NotificationPage} exact />

      <PrivateRoute path={ROUTES.MARKET_NEWS} component={MarketNewsPage} exact />

      <PrivateRoute path={ROUTES.EXPORTS_PAGE} component={ExportsDashboard} exact />

      <PrivateRoute
        path={ROUTES.DOCUMENT_REQUESTS}
        component={DueDiligenceClientDocumentRequestsPage}
        exact
      />
      <PrivateRoute path={ROUTES.GLOBAL_SEARCH} component={GlobalSearchPage} exact />

      <AuthContainer />
    </Switch>
  )
})

export default Routes
