import React, { useCallback, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { generatePath } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import styles from './DueDiligenceProcessDocumentsGeneralLedgerPage.module.scss'
import genericSs from '@styles/generic.module.scss'

import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { IDueDiligence } from '@common/interfaces/dueDiligence'
import { ROUTES } from '../../constants/routes'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import Button from '../../components/Common/Button'
import { IAlias, ReportingFlow } from '@common/interfaces/bbc'
import Tabs from '../../components/Common/Tabs'
import Card from '../../components/Common/Card'
import { IClientGeneralLedgerMapping } from '@common/interfaces/clientGeneralLedger'
import TypeMapping from '../../components/TypeMapping'
import AliasMapping from '../../components/AliasMapping'
import { ILoadingData } from '../../redux/types'
import DueDiligenceProcessDocumentsMappingLoader from '../../components/DueDiligenceProcessDocumentsMappingLoader'
import { ClientInfoStatus } from '@common/interfaces/client'

const GENERAL_LEDGER_TABS = {
  DEBTORS: 'Debtors',
  TYPES: 'Transaction Types',
}

const TABS = [GENERAL_LEDGER_TABS.DEBTORS, GENERAL_LEDGER_TABS.TYPES]

interface IProps {
  dueDiligenceInfo: IDueDiligence
  typeMapping: ILoadingData<{ data: IClientGeneralLedgerMapping[] }>
  aliasesData: ILoadingData<{ data: IAlias[] }>
  show: (id: string) => void
  listTypeMapping?: (id: string) => void
  listAliasMapping: (id: string, params: object) => void
  hideAliasMapping: () => void
  listEntityInfo: (data: object) => Promise<{ data: any }>
  addEntityInfo: (data: object) => Promise<any>
  updateAlias: (data: object) => Promise<any>
  deleteAlias: (data: object) => Promise<any>
}

const DueDiligenceProcessDocumentsGeneralLedgerPage = ({
  dueDiligenceInfo,
  typeMapping,
  aliasesData,
  show,
  listTypeMapping,
  listAliasMapping,
  hideAliasMapping,
  listEntityInfo,
  addEntityInfo,
  updateAlias,
  deleteAlias,
}: IProps) => {
  const { id, type: routeType } = useParams<{ id: string; type: string }>()
  const { pathname } = useLocation()
  const history = useHistory()
  const [currentTab, setCurrentTab] = useState<string>(GENERAL_LEDGER_TABS.DEBTORS)
  const readOnly = useMemo(
    () => dueDiligenceInfo?.clientStatus !== ClientInfoStatus.DueDiligence,
    [dueDiligenceInfo],
  )

  useLoadInfo({ id, info: dueDiligenceInfo, show })
  useSetPageTitle(dueDiligenceInfo?.clientName || '')

  const breadcrumbs = useMemo(
    () => [
      {
        link: ROUTES.DUE_DILIGENCE_HOME,
        Icon: HomeIcon,
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE, { id }),
        title: dueDiligenceInfo?.clientName,
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE, { id }),
        title: 'Process Documents',
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_LIST, { id, type: routeType }),
        title: 'General Ledger',
      },
      {
        link: pathname,
        title: 'Map General Ledger',
      },
    ],
    [dueDiligenceInfo, id, routeType, pathname],
  )

  const handleGoNext = useCallback(async () => {
    history.push(generatePath(ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE, { id }))
  }, [history, id])

  const handleChangeTab = useCallback(async (tab: string) => {
    setCurrentTab(tab)
  }, [])

  if (!dueDiligenceInfo) {
    return <DueDiligenceProcessDocumentsMappingLoader />
  }

  return (
    <Grid container py={3} pr={2} alignItems="flex-start" justifyContent="center" rowSpacing={2}>
      <Grid item container xs={12} justifyContent="space-between">
        <Grid item container xs={6} justifyContent="flex-start" alignItems="center">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Grid>
      </Grid>

      <Grid item xs={12} alignItems="flex-start" justifyContent="flex-start" ml={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <h2 className={genericSs.dueDiligenceHeader}>Map General Ledger</h2>
          <Box display="flex" alignItems="center" gap={3}>
            <Button
              className={genericSs.dueDiligenceSubmitButton}
              variant="contained"
              onClick={handleGoNext}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} alignItems="flex-start" justifyContent="flex-start">
        <Card className={styles.reconciliation} noHeaderMargin={false}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
            <Tabs tabs={TABS} selected={currentTab} handleChange={handleChangeTab} />
          </Box>

          {currentTab === GENERAL_LEDGER_TABS.DEBTORS && (
            <AliasMapping
              aliasesData={aliasesData}
              listEntityInfo={listEntityInfo}
              addEntityInfo={addEntityInfo}
              updateAlias={updateAlias}
              deleteAlias={deleteAlias}
              listAliasMapping={listAliasMapping}
              hideAliasMapping={hideAliasMapping}
              id={id}
              table="client_general_ledger"
              clientName={dueDiligenceInfo.clientName}
              tableClassName={styles.aliasesTable}
              readOnly={readOnly}
            />
          )}
          {currentTab === GENERAL_LEDGER_TABS.TYPES && (
            <TypeMapping
              id={id}
              typeMappingData={typeMapping}
              listTypeMapping={listTypeMapping}
              tableClassName={styles.typesTable}
              reportingFlow={ReportingFlow.DueDiligence}
              readOnly={readOnly}
            />
          )}
        </Card>
      </Grid>
    </Grid>
  )
}

export default DueDiligenceProcessDocumentsGeneralLedgerPage
