import React from 'react'
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import TextField from '@mui/material/TextField'
import { Field, FieldProps, FieldRenderProps } from 'react-final-form'
import { showErrorOnChange, ShowErrorFunc } from 'mui-rff'
import cn from 'classnames'
import { parseISO } from 'date-fns'

import styles from './KeyboardDateTimePicker.module.scss'

import { ReactComponent as CalendarIcon } from '../../../assets/images/calendar-icon.svg'

interface KeyboardDateTimePickerProps
  extends Partial<Omit<DateTimePickerProps<any, any>, 'onChange'>> {
  name: string
  placeholder?: string
  fieldProps?: Partial<FieldProps<any, any>>
  showError?: ShowErrorFunc
  size?: 'small' | 'medium' | 'large'
}

interface DatePickerWrapperProps
  extends FieldRenderProps<DateTimePickerProps<any, any>, HTMLElement> {}

const KeyboardDateTimePickerWrapper = (props: DatePickerWrapperProps) => {
  const {
    className,
    placeholder = '',
    input: { name, onChange, value, ...restInput },
    meta = {},
    showError = showErrorOnChange,
    onBlur,
    ...rest
  } = props

  const { error, submitError } = meta
  const isError = showError({ meta })

  const { helperText, ...lessRest } = rest

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateTimePicker
        className={className}
        components={{
          OpenPickerIcon: CalendarIcon,
        }}
        renderInput={({ InputProps, ...renderProps }) => (
          <TextField
            name={name}
            {...renderProps}
            {...restInput}
            error={isError}
            InputProps={{
              ...InputProps,
              classes: {
                error: styles.inputError,
              },
            }}
            inputProps={{
              ...renderProps.inputProps,
              placeholder: value ? undefined : placeholder,
              tabIndex: 0,
            }}
            helperText={isError ? error || submitError : helperText}
            variant="standard"
            onBlur={onBlur}
          />
        )}
        onChange={onChange}
        value={
          (value as any) === '' ? null : typeof value === 'string' ? parseISO(value as any) : value
        }
        InputAdornmentProps={{
          position: 'start',
        }}
        {...lessRest}
      />
    </LocalizationProvider>
  )
}

const KeyboardDateTimePicker = (props: KeyboardDateTimePickerProps) => {
  const { className = '', size, name, fieldProps, ...rest } = props

  return (
    <Field
      name={name}
      render={({ input, ...fieldRenderProps }) => {
        const handleOpen = () => input.onFocus()
        const handleClose = () => input.onBlur()

        return (
          <KeyboardDateTimePickerWrapper
            {...fieldRenderProps}
            {...rest}
            input={input}
            className={cn(className, {
              [styles.big]: size === 'large',
              [styles.root]: size === 'small',
            })}
            onOpen={handleOpen}
            onClose={handleClose}
          />
        )
      }}
      {...fieldProps}
    />
  )
}

export default KeyboardDateTimePicker
