export enum LCRCommentarySections {
  CompanyBackground = 'Company Background',
  TransactionOverview = 'Transaction Overview',
  MiscelleanousInfo = 'Miscelleanous Info',
  LegalConsiderations = 'Legal Considerations',
  ItemsToMonitor = 'Items to Monitor',
  HistoricalLineTurn = 'Historical Line Turn',
  HistoricalPL = 'Historical P&L',
  BalanceSheet = 'Balance Sheet',
  ForecastPL = 'Forecast P&L',
  StressCase = 'Stress Case',
  DilutionAnalysis = 'Dilution Analysis',
  CollateralAnalysisAR = 'Collateral Analysis - AR',
  CollateralAnalysisAP = 'Collateral Analysis - AP',
  CollateralAnalysisInventory = 'Collateral Analysis - Inventory',
  CollateralAnalysisSales = 'Collateral Analysis - Sales',
  InventoryAppraisal = 'Inventory Appraisal',
  CapitalizationStructure = 'Capitalization Structure',
  FieldExamReceivables = 'Field Exam - AR',
  FieldExamPayables = 'Field Exam - AP',
  FieldExamInventory = 'Field Exam - Inventory',
  FieldExamMiscellaneous = 'Field Exam - Miscellaneous',
}

export const LCR_COMMENTARY_SECTION_PAGE = {
  [LCRCommentarySections.CompanyBackground]: '',
  [LCRCommentarySections.TransactionOverview]: '',
  [LCRCommentarySections.MiscelleanousInfo]: '',
  [LCRCommentarySections.LegalConsiderations]: '',
  [LCRCommentarySections.ItemsToMonitor]: '',
  [LCRCommentarySections.HistoricalLineTurn]: '',
  [LCRCommentarySections.HistoricalPL]: '/financials',
  [LCRCommentarySections.BalanceSheet]: '/financials',
  [LCRCommentarySections.ForecastPL]: '/forecasts',
  [LCRCommentarySections.StressCase]: '/forecasts',
  [LCRCommentarySections.DilutionAnalysis]: '/transactions',
  [LCRCommentarySections.CollateralAnalysisAR]: '/receivables',
  [LCRCommentarySections.CollateralAnalysisAP]: '/payables',
  [LCRCommentarySections.CollateralAnalysisInventory]: '/inventory',
  [LCRCommentarySections.CollateralAnalysisSales]: '/inventory',
  [LCRCommentarySections.InventoryAppraisal]: '/inventory',
  [LCRCommentarySections.CapitalizationStructure]: '/cap-table',
  [LCRCommentarySections.FieldExamReceivables]: '/field-exam',
  [LCRCommentarySections.FieldExamPayables]: '/field-exam',
  [LCRCommentarySections.FieldExamInventory]: '/field-exam',
  [LCRCommentarySections.FieldExamMiscellaneous]: '/field-exam',
}

export const LCR_COMMENTARY_DEFAULT_CUSTOM_HEADER = {
  [LCRCommentarySections.CollateralAnalysisAR]: 'Collateral Analysis',
  [LCRCommentarySections.CollateralAnalysisAP]: 'Collateral Analysis',
  [LCRCommentarySections.CollateralAnalysisInventory]: 'Collateral Analysis',
  [LCRCommentarySections.CollateralAnalysisSales]: 'Collateral Analysis',
  [LCRCommentarySections.FieldExamReceivables]: 'Receivables',
  [LCRCommentarySections.FieldExamPayables]: 'Payables',
  [LCRCommentarySections.FieldExamInventory]: 'Inventory',
  [LCRCommentarySections.FieldExamMiscellaneous]: 'Miscellaneous',
}

export const LCR_COMMENTARY_DEFAULT: {
  field: string
  section: string
  helperText: string | null
  defaultNote?: { blocks: any[]; entityMap: any }
}[] = [
  {
    field: 'Company Background',
    section: LCRCommentarySections.CompanyBackground,
    helperText: null,
  },
  {
    field: 'Cash Burn Coverage',
    section: LCRCommentarySections.TransactionOverview,
    helperText:
      'Provide brief commentary on cash burn coverage under different scenarios, or note if profitable. If applicable, discuss how additional availability from Dwight or other pending cash infusions can impact coverage.',
    defaultNote: {
      blocks: [
        {
          key: '5hi92',
          data: {},
          text: 'As of xxx xxxx, the Company has $xxx in cash on hand. This would provide coverage as follows:',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '99joi',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'f6v3q',
          data: {},
          text: 'Historical:',
          type: 'unordered-list-item',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'saie',
          data: {},
          text: 'Base Case:',
          type: 'unordered-list-item',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '2i5fr',
          data: {},
          text: 'Stress Case:',
          type: 'unordered-list-item',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '669s6',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'frf5h',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'eipa2',
          data: {},
          text: 'Dwight Availability and Other Cash Infusions',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [
            {
              style: 'BOLD',
              length: 44,
              offset: 0,
            },
          ],
        },
      ],
      entityMap: {},
    },
  },
  {
    field: 'Financials - Investor Support & Investment Timeline',
    section: LCRCommentarySections.TransactionOverview,
    helperText:
      'Provide brief commentary or bullet points within each subsection. Follow the recommended format for the "Summary of Prior Rounds" subsection.',
    defaultNote: {
      blocks: [
        {
          key: 'cjij4',
          data: {},
          text: 'Summary of Prior Rounds',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 23, offset: 0 }],
        },
        {
          key: '66pv6',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '9si1g',
          data: {},
          text: '[Round - Date]',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 14, offset: 0 }],
        },
        {
          key: 'bkfio',
          data: {},
          text: 'Amount Invested:',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '21ckq',
          data: {},
          text: 'Key Investors:',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'b2sru',
          data: {},
          text: 'Valuation:',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'd183m',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'dncgv',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '5boi0',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'b2gj6',
          data: {},
          text: "Future Capital Raise Plans & Underwriting's Assessment",
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 54, offset: 0 }],
        },
      ],
      entityMap: {},
    },
  },
  {
    field: 'Collateral Performance - AR',
    section: LCRCommentarySections.TransactionOverview,
    helperText: 'Provide brief commentary after each bullet point.',
    defaultNote: {
      blocks: [
        {
          key: '18tf5',
          data: {},
          text: 'Customer Base:',
          type: 'unordered-list-item',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'btrp2',
          data: {},
          text: 'AR Agings:',
          type: 'unordered-list-item',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '7lu0p',
          data: {},
          text: 'AR Dilution:',
          type: 'unordered-list-item',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'c57c2',
          data: {},
          text: 'Other Commentary:',
          type: 'unordered-list-item',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
      ],
      entityMap: {},
    },
  },
  {
    field: 'Collateral Performance - Inventory',
    section: LCRCommentarySections.TransactionOverview,
    helperText: null,
    defaultNote: {
      blocks: [
        {
          key: 'f0koc',
          data: {},
          text: 'Inventory Description:',
          type: 'unordered-list-item',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '51nst',
          data: {},
          text: 'Weeks of Supply:',
          type: 'unordered-list-item',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'a923e',
          data: {},
          text: 'Agings:',
          type: 'unordered-list-item',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '164ur',
          data: {},
          text: 'Other Commentary:',
          type: 'unordered-list-item',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
      ],
      entityMap: {},
    },
  },
  {
    field: 'Founders / Management Team',
    section: LCRCommentarySections.TransactionOverview,
    helperText: null,
    defaultNote: {
      blocks: [
        {
          key: '1ij8n',
          data: {},
          text: '[Name - Title]',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 14, offset: 0 }],
        },
        {
          key: '1k4be',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '35u',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'd18h1',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '1en7l',
          data: {},
          text: '[Name - Title]',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 14, offset: 0 }],
        },
        {
          key: 'p3tf',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '6ss44',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'a2607',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '9nbum',
          data: {},
          text: "Underwriting's Assessment",
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 25, offset: 0 }],
        },
      ],
      entityMap: {},
    },
  },
  {
    field: 'Key Competitors',
    section: LCRCommentarySections.MiscelleanousInfo,
    helperText: null,
  },
  {
    field: 'Marketing Strategy',
    section: LCRCommentarySections.MiscelleanousInfo,
    helperText: null,
  },
  {
    field: 'Lawsuits / Negative Press',
    section: LCRCommentarySections.MiscelleanousInfo,
    helperText: null,
  },
  {
    field: 'Warehouse / Landlord Waivers',
    section: LCRCommentarySections.LegalConsiderations,
    helperText: null,
    defaultNote: {
      blocks: [
        {
          key: '8110l',
          data: {},
          text: 'Inventory Locations',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 19, offset: 0 }],
        },
        {
          key: '3kq3n',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '6fkhv',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '5euu4',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '7d702',
          data: {},
          text: 'Warehouse / Landlord Waivers (Eligible Locations)',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 49, offset: 0 }],
        },
      ],
      entityMap: {},
    },
  },
  {
    field: 'Subordination Agreements',
    section: LCRCommentarySections.LegalConsiderations,
    helperText: null,
  },
  {
    field: 'Co-Borrowers & Corporate Guaranties',
    section: LCRCommentarySections.LegalConsiderations,
    helperText: null,
  },
  {
    field: 'Validity & Support Guaranties',
    section: LCRCommentarySections.LegalConsiderations,
    helperText: null,
  },
  {
    field: 'Debt Payoffs & Lien Terminations',
    section: LCRCommentarySections.LegalConsiderations,
    helperText: null,
    defaultNote: {
      blocks: [
        {
          key: 'emsm',
          data: {},
          text: 'Debt Payoffs',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 12, offset: 0 }],
        },
        {
          key: 'c98oj',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '1o9in',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'a9bic',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '4gcsj',
          data: {},
          text: 'Lien Terminations',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 17, offset: 0 }],
        },
      ],
      entityMap: {},
    },
  },
  {
    field: 'Initial Reserves Summary',
    section: LCRCommentarySections.ItemsToMonitor,
    helperText: null,
    defaultNote: {
      blocks: [
        {
          key: 'f0rhq',
          data: {},
          text: 'Starting Reserves Summary',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 25, offset: 0 }],
        },
        {
          key: '6m0m7',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'hcg5',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '4lvf2',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '4doce',
          data: {},
          text: 'Items to Monitor Summary',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 24, offset: 0 }],
        },
      ],
      entityMap: {},
    },
  },
  {
    field: 'Stepped Up Reserves Summary',
    section: LCRCommentarySections.ItemsToMonitor,
    helperText: null,
    defaultNote: {
      blocks: [
        {
          key: 'ef9uj',
          data: {},
          text: 'Condition / Timing',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 18, offset: 0 }],
        },
        {
          key: '4ni0n',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'ajrjq',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '859so',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'cufnr',
          data: {},
          text: 'Stepped Up Reserves',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 19, offset: 0 }],
        },
      ],
      entityMap: {},
    },
  },
  {
    field: 'AR & Financials',
    section: LCRCommentarySections.ItemsToMonitor,
    helperText: null,
    defaultNote: {
      blocks: [
        {
          key: '7sa1p',
          data: {},
          text: 'Condition / Timing',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 18, offset: 0 }],
        },
        {
          key: 'd8a60',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '2v8vb',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'eqq5u',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'a120i',
          data: {},
          text: 'Reserve / Other Recommendation',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 30, offset: 0 }],
        },
        {
          key: '1teia',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '2oo1n',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'd4anb',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '9ab3s',
          data: {},
          text: 'Rationale / Other Commentary',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 28, offset: 0 }],
        },
      ],
      entityMap: {},
    },
  },
  {
    field: 'Inventory & Other',
    section: LCRCommentarySections.ItemsToMonitor,
    helperText: null,
    defaultNote: {
      blocks: [
        {
          key: '8kdvf',
          data: {},
          text: 'Condition / Timing',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 18, offset: 0 }],
        },
        {
          key: '8c9gl',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'cbjfv',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '1cqb0',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '23qe9',
          data: {},
          text: 'Reserve / Other Recommendation',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 30, offset: 0 }],
        },
        {
          key: '2ajfu',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'bc0uv',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '650c7',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'c6ktg',
          data: {},
          text: 'Rationale / Other Commentary',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 28, offset: 0 }],
        },
      ],
      entityMap: {},
    },
  },
  {
    field: 'Historical Line Turn',
    section: LCRCommentarySections.HistoricalLineTurn,
    helperText: null,
    defaultNote: {
      blocks: [
        {
          key: 'fd367',
          data: {},
          text: 'Line Turn Anomalies',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 19, offset: 0 }],
        },
        {
          key: '4cmt8',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '204s7',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'cj0bf',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '4q0ij',
          data: {},
          text: 'Calculation Assumptions',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 23, offset: 0 }],
        },
      ],
      entityMap: {},
    },
  },
  {
    field: 'Sales Trends',
    section: LCRCommentarySections.HistoricalPL,
    helperText: null,
  },
  {
    field: 'Sales Anomalies',
    section: LCRCommentarySections.HistoricalPL,
    helperText: null,
  },
  {
    field: 'P&L Dilution Trends',
    section: LCRCommentarySections.HistoricalPL,
    helperText: null,
  },
  {
    field: 'P&L Dilution Anomalies',
    section: LCRCommentarySections.HistoricalPL,
    helperText: null,
  },
  {
    field: 'Gross Margin Trends',
    section: LCRCommentarySections.HistoricalPL,
    helperText: null,
  },
  {
    field: 'Gross Margin Anomalies',
    section: LCRCommentarySections.HistoricalPL,
    helperText: null,
  },
  {
    field: 'Operating Expense Trends & Anomalies',
    section: LCRCommentarySections.HistoricalPL,
    helperText: null,
  },
  {
    field: 'Balance Sheet Trends & Anomalies',
    section: LCRCommentarySections.BalanceSheet,
    helperText: null,
  },
  {
    field: 'Notable Balance Sheet Items',
    section: LCRCommentarySections.BalanceSheet,
    helperText: null,
  },
  {
    field: 'Base Case Forecast',
    section: LCRCommentarySections.ForecastPL,
    helperText: null,
    defaultNote: {
      blocks: [
        {
          key: 'cjr58',
          data: {},
          text: 'Base Case Revenue:',
          type: 'unordered-list-item',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '7pd7e',
          data: {},
          text: 'Base Case Dilution:',
          type: 'unordered-list-item',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'ehhi4',
          data: {},
          text: 'Base Case Gross Margins:',
          type: 'unordered-list-item',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '4mul5',
          data: {},
          text: 'Base Case EBITDA:',
          type: 'unordered-list-item',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '178cb',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'rr50',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '4tpii',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '36dha',
          data: {},
          text: 'Base Case Drivers',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 17, offset: 0 }],
        },
        {
          key: 'a1cl5',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'buov4',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '3rpjq',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'cp24i',
          data: {},
          text: 'Base Case Cash Burn Coverage',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 28, offset: 0 }],
        },
      ],
      entityMap: {},
    },
  },
  {
    field: 'Stress Case Forecast',
    section: LCRCommentarySections.StressCase,
    helperText: null,
    defaultNote: {
      blocks: [
        {
          key: 'a6p1c',
          data: {},
          text: 'Stress Case Revenue:',
          type: 'unordered-list-item',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '1td04',
          data: {},
          text: 'Stress Case Dilution:',
          type: 'unordered-list-item',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '7uc7d',
          data: {},
          text: 'Stress Case Gross Margins:',
          type: 'unordered-list-item',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '2lgfs',
          data: {},
          text: 'Stress Case EBITDA:',
          type: 'unordered-list-item',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '6rilo',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'bkguj',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'firu4',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '8ijs3',
          data: {},
          text: 'Stress Case Assumptions',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 23, offset: 0 }],
        },
        {
          key: '39o00',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'bkbl6',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'bn422',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'dt5qh',
          data: {},
          text: 'Stress Case Cash Burn Coverage',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 30, offset: 0 }],
        },
      ],
      entityMap: {},
    },
  },
  {
    field: 'Dilution Calculation and Drivers',
    section: LCRCommentarySections.DilutionAnalysis,
    helperText: null,
    defaultNote: {
      blocks: [
        {
          key: '94ee5',
          data: {},
          text: "Underwriting's Calculation:",
          type: 'unordered-list-item',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'atouj',
          data: {},
          text: "Field Examiner's Calculation:",
          type: 'unordered-list-item',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'i9dc',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '72nsf',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '73dig',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '28smc',
          data: {},
          text: 'Dilution Drivers and Commentary',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 31, offset: 0 }],
        },
      ],
      entityMap: {},
    },
  },
  {
    field: 'Dilution Anomalies',
    section: LCRCommentarySections.DilutionAnalysis,
    helperText: null,
  },
  {
    field: 'AR Trends Commentary',
    section: LCRCommentarySections.CollateralAnalysisAR,
    helperText: null,
  },
  {
    field: 'AR Anomalies & Aged AR Commentary',
    section: LCRCommentarySections.CollateralAnalysisAR,
    helperText: null,
  },
  {
    field: 'Top 5 Customers',
    section: LCRCommentarySections.CollateralAnalysisAR,
    helperText: null,
  },
  {
    field: 'Top 5 Customer Anomalies',
    section: LCRCommentarySections.CollateralAnalysisAR,
    helperText: null,
  },
  {
    field: 'AP Trends Commentary',
    section: LCRCommentarySections.CollateralAnalysisAP,
    helperText: null,
  },
  {
    field: 'AP Anomalies & Aged AP Commentary',
    section: LCRCommentarySections.CollateralAnalysisAP,
    helperText: null,
  },
  {
    field: 'Top 5 Vendors',
    section: LCRCommentarySections.CollateralAnalysisAP,
    helperText: null,
  },
  {
    field: 'Top 5 Vendor Anomalies & Contras',
    section: LCRCommentarySections.CollateralAnalysisAP,
    helperText: null,
  },
  {
    field: 'Weeks of Supply',
    section: LCRCommentarySections.CollateralAnalysisSales,
    helperText: null,
  },
  {
    field: 'Aging Distribution',
    section: LCRCommentarySections.CollateralAnalysisInventory,
    helperText: null,
  },
  {
    field: 'Sizing Distribution',
    section: LCRCommentarySections.CollateralAnalysisInventory,
    helperText: null,
  },
  {
    field: 'Liquidation Channels',
    section: LCRCommentarySections.InventoryAppraisal,
    helperText: null,
    defaultNote: {
      blocks: [
        {
          key: 'cjr7s',
          data: {},
          text: "Appraiser's Liquidation Strategy",
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 32, offset: 0 }],
        },
        {
          key: '7kcti',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '7kj7t',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '9rqhh',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'chdfq',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'ecrcr',
          data: {},
          text: "Underwriting's Assessment",
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 25, offset: 0 }],
        },
      ],
      entityMap: {},
    },
  },
  {
    field: 'Lender Risks and Collateral Monitoring',
    section: LCRCommentarySections.InventoryAppraisal,
    helperText: null,
  },
  {
    field: 'Investor & Shareholder Background',
    section: LCRCommentarySections.CapitalizationStructure,
    helperText: null,
  },
  {
    field: 'Equity History',
    section: LCRCommentarySections.CapitalizationStructure,
    helperText: null,
    defaultNote: {
      blocks: [
        {
          key: '1q6tn',
          data: {},
          text: 'Summary of Prior Rounds (Including Key Investors, Amounts Invested, and Valuations)',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 83, offset: 0 }],
        },
        {
          key: '33su0',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '4lspg',
          data: {},
          text: '[Round - Date]',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 14, offset: 0 }],
        },
        {
          key: '3d7o6',
          data: {},
          text: 'Amount Invested:',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '964t9',
          data: {},
          text: 'Key Investors:',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '4vscb',
          data: {},
          text: 'Valuation:',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'atlvg',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '1mbce',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'fvbj3',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '77eah',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '1kj63',
          data: {},
          text: 'Secondary Sales and Preference Stack',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 36, offset: 0 }],
        },
        {
          key: '79nro',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: '2pouq',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'drkun',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'api88',
          data: {},
          text: '',
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
        },
        {
          key: 'a3q37',
          data: {},
          text: "Underwriting's Valuation Assessment",
          type: 'unstyled',
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [{ style: 'BOLD', length: 35, offset: 0 }],
        },
      ],
      entityMap: {},
    },
  },
  {
    field: 'Future Equity Raises',
    section: LCRCommentarySections.CapitalizationStructure,
    helperText: null,
  },
  {
    field: 'Debt History',
    section: LCRCommentarySections.CapitalizationStructure,
    helperText: null,
  },
  {
    field: 'Future Debt Raises',
    section: LCRCommentarySections.CapitalizationStructure,
    helperText: null,
  },
  {
    field: 'AR Verification Test',
    section: LCRCommentarySections.FieldExamReceivables,
    helperText: null,
  },
  {
    field: 'AR Billing / Shipping Test',
    section: LCRCommentarySections.FieldExamReceivables,
    helperText: null,
  },
  {
    field: 'Credit Memo Test',
    section: LCRCommentarySections.FieldExamReceivables,
    helperText: null,
  },
  {
    field: 'Past Due Test',
    section: LCRCommentarySections.FieldExamReceivables,
    helperText: null,
  },
  {
    field: 'Cash Application Test',
    section: LCRCommentarySections.FieldExamReceivables,
    helperText: null,
  },
  {
    field: 'Cash Disbursement Test',
    section: LCRCommentarySections.FieldExamPayables,
    helperText: null,
  },
  {
    field: 'Inventory Test Count',
    section: LCRCommentarySections.FieldExamInventory,
    helperText: null,
  },
  {
    field: 'Inventory Cost Test',
    section: LCRCommentarySections.FieldExamInventory,
    helperText: null,
  },
  {
    field: 'Inventory Gross Profit Test',
    section: LCRCommentarySections.FieldExamInventory,
    helperText: null,
  },
  {
    field: 'Bank Reconciliation',
    section: LCRCommentarySections.FieldExamMiscellaneous,
    helperText: null,
  },
  {
    field: 'Payroll & Sales Tax Review',
    section: LCRCommentarySections.FieldExamMiscellaneous,
    helperText: null,
  },
  {
    field: 'Insurance Review',
    section: LCRCommentarySections.FieldExamMiscellaneous,
    helperText: 'Provide brief commentary on any issues.',
  },
]
