import { combineReducers } from 'redux'
import * as actions from './actions'
import {
  ICapTableFilters,
  ICapTableMappingData,
  ICapTableSummaryData,
} from '@common/interfaces/capTable'
import { createLoadingDataReducer } from '../../helpers/redux'
import { ILoadingData } from '../types'

export interface ICapTableState {
  capTableMapping: ILoadingData<ICapTableMappingData>
  capTableSummary: ILoadingData<ICapTableSummaryData>
  capTableFilters: ILoadingData<ICapTableFilters>
}

export default combineReducers<ICapTableState>({
  capTableFilters: createLoadingDataReducer<ICapTableFilters>([
    actions.LIST_CAPTABLE_FILTERS_REQUEST,
  ]),
  capTableMapping: createLoadingDataReducer<ICapTableMappingData>(
    [actions.LIST_MAPPING_REQUEST],
    [actions.UPDATE_CAP_TABLE_DETAILS_REQUEST],
    true,
    [],
    true,
  ),

  capTableSummary: createLoadingDataReducer<ICapTableSummaryData>([actions.LIST_CAPTABLE_REQUEST]),
})
