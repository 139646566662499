import { connect } from 'react-redux'
import {
  showOngoingReporting,
  updateOngoingReportingStatus,
} from '../../redux/ongoingReporting/actions'
import { ongoingReporting } from '../../redux/ongoingReporting/selectors'
import InsurancePage from './InsurancePage'
import { createStructuredSelector } from 'reselect'
import { getEmbedLink, hideBoxPreview } from '../../redux/box/actions'

const selector = createStructuredSelector({
  ongoingReporting,
})

const actions = {
  showOngoingReporting,
  updateOngoingReportingStatus,
  getEmbedLink,
  hideBoxPreview,
}

export default connect(selector, actions)(InsurancePage)
