import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { generatePath, Link } from 'react-router-dom'
import { matchPath, useHistory } from 'react-router'
import LinkButton from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import { Form } from 'react-final-form'
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import cn from 'classnames'
import styles from './ClientAccountActivity.module.scss'
import genericSs from '@styles/generic.module.scss'
import SelectField from '../../Common/SelectField'
import { ReactComponent as DeleteIcon } from '@assets/images/delete-icon.svg'
import { ReactComponent as LinkIcon } from '@assets/images/link-out-icon.svg'
import Card from '../../Common/Card'
import ActiveToolbar from '../../ActiveToolbar'
import {
  AccountActivityPeriods,
  IAccountActivityData,
  IClientInfo,
  ACCOUNT_ACTIVITY_PERIOD_OPTIONS,
} from '@common/interfaces/client'
import {
  CLIENT_PAGE_ACTIVITY_OPTIONS,
  ClientPageActivityOption,
  ROUTES,
} from '../../../constants/routes'
import {
  dateToString,
  debounceEventHandler,
  formatDate,
  formatDateTime,
  formatPrice,
  formatter,
  handleStopPropagation,
} from '../../../helpers/helpers'
import TableContainer from '../../Common/TableContainer'
import Table from '../../Common/Table'
import TableHead from '../../Common/TableHead'
import TableBody from '../../Common/TableBody'
import TableFiltersRow from '../../Common/TableFiltersRow'
import TableRow from '../../Common/TableRow'
import TableCell from '../../Common/TableCell'
import { AGGREGATION_TYPES } from '@common/constants/client'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../../helpers/filters'
import { CLIENT_ACCOUNT_ACTIVITY_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import FilterContainer from '../../Filters/FilterContainer'
import { CLIENT_AGGREGATION_TYPES } from '@common/constants/client'
import Button from '../../Common/Button'
import ClientAccountActivityAction from './ClientAccountActivityAction'
import ClientAccountActivityCreateModal from '../ClientAccountActivityCreateModal'
import ClientAccountActivityEditModal from '../ClientAccountActivityEditModal'
import ClientAccountActivityDeleteModal from '../ClientAccountActivityDeleteModal'
import ClientAccountActivityRestoreModal from '../ClientAccountActivityRestoreModal'
import { ILoadingData } from '../../../redux/types'
import TableLoader from '../../Common/TableLoader'
import useTrackVisualizationsTable from '../../../hooks/useTrackVisualizationsTable'
import { CATEGORIES } from '@common/constants/tracking'
import useGraphToggle from '../../../hooks/useGraphTogggle'
import AccountActivityGraph from '../../AccountActivityGraph'
import DatePicker from '../../Common/DatePicker'
import AddButton from '../AddButton'
import { usePermissions } from '../../../helpers/permissionContext'
import ExportButton from '../../Common/ExportButton'
import useTable from '../../../hooks/useTable'
import { IAccountActivity } from '@common/interfaces/client'
import { ReactComponent as BoxPreviewIcon } from '@assets/images/box-preview.svg'

interface ITypeCellProps {
  item: IAccountActivity
  isAdminRightsRole: boolean
  handleOpenLink: (e: React.MouseEvent, item: IAccountActivity) => void
  handleItemClick: (item: IAccountActivity) => void
}

const TypeCell = ({ item, isAdminRightsRole, handleOpenLink, handleItemClick }: ITypeCellProps) => {
  const { type, boxLink, isHistorical } = useMemo(() => item, [item])

  const isCheckClickable = useMemo(() => {
    return boxLink && type === AGGREGATION_TYPES.check
  }, [boxLink, type])

  const isFundingClickable = useMemo(() => {
    return type === AGGREGATION_TYPES.funding && !isHistorical && boxLink
  }, [type, isHistorical, boxLink])

  if (isCheckClickable || isFundingClickable) {
    return (
      <Box display="flex" alignItems="center" gap={1}>
        <span>{item.type}</span>
        {isAdminRightsRole && (
          <Tooltip title="Open in Box">
            <LinkIcon onClick={(e) => handleOpenLink(e, item)} className={styles.linkIcon} />
          </Tooltip>
        )}
        <Tooltip title="Preview">
          <BoxPreviewIcon onClick={() => handleItemClick(item)} />
        </Tooltip>
      </Box>
    )
  }
  return <>{item.type}</>
}

const DescriptionCell = ({ row, hideLink }: { row: IAccountActivity; hideLink?: boolean }) => {
  const { entityInfo, type, borrowingBaseId } = useMemo(() => row, [row])

  const textContent = useMemo(() => {
    if (type === AGGREGATION_TYPES.wire) {
      return (
        <Tooltip title={row.originalMemo || row.description}>
          <span>{row.description}</span>
        </Tooltip>
      )
    }
    return <>{row.description}</>
  }, [row, type])

  const link = useMemo(() => {
    if (hideLink) {
      return null
    }
    if (entityInfo) {
      return generatePath(ROUTES.ENTITY_PAGE, { id: entityInfo.id })
    } else if (borrowingBaseId) {
      return generatePath(ROUTES.BBC_SUMMARY, { id: borrowingBaseId })
    }
    return null
  }, [hideLink, entityInfo, borrowingBaseId])

  const withLinkWrapper = useMemo(() => {
    if (link) {
      return (
        <LinkButton component={Link} to={link} onClick={handleStopPropagation}>
          {textContent}
        </LinkButton>
      )
    }
    return <>{textContent}</>
  }, [link, textContent])

  return withLinkWrapper
}

const filtersValidate = buildFiltersValidateSchema(CLIENT_ACCOUNT_ACTIVITY_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(CLIENT_ACCOUNT_ACTIVITY_LIST_FILTERS_CONFIG)

const GRAPH_FILTER_FIELDS = ['type', 'description']

interface IProps {
  isAdminRightsRole: boolean
  isClientUser: boolean
  clientInfo: IClientInfo
  accountActivityData: ILoadingData<IAccountActivityData>
  listAccountActivity: (
    clientId?: string,
    params?: {
      page?: number
      perPage?: number
      filters?: object
      orderBy?: string
      orderDirection?: string
    },
  ) => void
  exportAccountActivity: (
    clientId?: string,
    params?: {
      page?: number
      perPage?: number
      filters?: object
      orderBy?: string
      orderDirection?: string
    },
  ) => void
  listCustomers: (id?: string) => void
  customers: ILoadingData<{
    data: string[]
  }>
  setBoxViewerLink: (link: string) => void
  getEmbedLink: (fileId: string) => void
  setActivityOption?: (option: ClientPageActivityOption) => void
}

const ClientAccountActivity = ({
  isAdminRightsRole,
  isClientUser,
  clientInfo,
  accountActivityData,
  listAccountActivity,
  exportAccountActivity,
  customers,
  listCustomers,
  setBoxViewerLink,
  getEmbedLink,
  setActivityOption,
}: IProps) => {
  const history = useHistory()
  const [isExporting, setIsExporting] = useState(false)
  const [isCreateModalShown, setIsCreateModalShown] = useState(false)
  const [isEditModalShown, setIsEditModalShown] = useState(false)
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [isRestoreModalShown, setIsRestoreModalShown] = useState(false)
  const [currentReportingPeriod, setCurrentReportingPeriod] = useState(
    AccountActivityPeriods.Monthly,
  )
  const { isParticipant } = usePermissions()

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'accountActivity',
    filtersDefaults,
    sortDefault: {
      field: 'record_date',
      direction: 'DESC',
    },
  })

  const isInitialized = useMemo(() => !!accountActivityData?.data?.data, [accountActivityData])
  const visualizationsParams = useMemo(
    () => ({
      clientId: clientInfo?.id,
    }),
    [clientInfo],
  )
  useTrackVisualizationsTable({
    isInitialized,
    category: CATEGORIES.clientAccountActivity,
    params: visualizationsParams,
    filtersConfig: CLIENT_ACCOUNT_ACTIVITY_LIST_FILTERS_CONFIG,
    filters,
    orderBy,
  })

  const { TabsComponent, isGraphShown } = useGraphToggle({})

  const endOfToday = useMemo(() => moment().endOf('day'), [])

  const { accountActivity, isLoading } = useMemo(
    () => ({
      accountActivity: accountActivityData?.data,
      isLoading: accountActivityData?.isLoading,
    }),
    [accountActivityData],
  )
  const itemsCount = useMemo(() => accountActivity?.data.length, [accountActivity])

  const fetchAccountActivityList = useCallback(
    (data: any) => {
      const params = {
        ...data,
        filters: {
          ...data.filters,
        },
      }
      if (params?.filters.recordDateFrom && typeof params.filters.recordDateFrom !== 'string') {
        params.filters.recordDateFrom = dateToString(params.filters.recordDateFrom)
      }
      if (params?.filters.recordDateTo && typeof params.filters.recordDateTo !== 'string') {
        params.filters.recordDateTo = dateToString(params.filters.recordDateTo)
      }
      clientInfo && listAccountActivity(isClientUser ? undefined : clientInfo.id, params)
    },
    [isClientUser, clientInfo, listAccountActivity],
  )

  const debounceListAccountActivity = useMemo(
    () => debounceEventHandler(fetchAccountActivityList, 500),
    [fetchAccountActivityList],
  )

  const handleExportAggregation = useCallback(async () => {
    setIsExporting(true)
    await exportAccountActivity(isClientUser ? undefined : clientInfo.id, {
      page: 0,
      perPage: 0,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
    setIsExporting(false)
  }, [isClientUser, clientInfo, filters, orderBy, exportAccountActivity])

  useEffect(() => {
    debounceListAccountActivity({
      page: 0,
      perPage: PER_PAGE,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, debounceListAccountActivity])

  const refetchListAccountActivity = useCallback(() => {
    debounceListAccountActivity({
      page: 0,
      perPage: Math.max(itemsCount, PER_PAGE),
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [itemsCount, filters, orderBy, debounceListAccountActivity])

  const handleItemClick = useCallback(
    (item: any) => {
      if (isClientUser && item?.borrowingBase?.clientBoxLink) {
        setBoxViewerLink('Open Box')
        const fileId = item?.borrowingBase?.clientBoxLink?.split('/').pop() || null
        getEmbedLink(fileId)
      } else if ((!isClientUser || item?.type === AGGREGATION_TYPES.check) && item.boxLink) {
        setBoxViewerLink('Open Box')
        const fileId = item.boxLink.split('/').pop() || null
        getEmbedLink(fileId)
      }
    },
    [isClientUser, getEmbedLink, setBoxViewerLink],
  )

  const handleOpenLink = useCallback(
    (e: React.MouseEvent, item: any) => {
      e.stopPropagation()
      if (isClientUser && item?.borrowingBase?.clientBoxLink) {
        window.open(item?.borrowingBase?.clientBoxLink, '_blank')
      } else if (!isClientUser && item.boxLink) {
        window.open(item.boxLink, '_blank')
      }
    },
    [isClientUser],
  )

  const totalRow = useMemo(
    () =>
      accountActivity?.data
        .filter((_, index) => activeItems.includes(index))
        .reduce(
          (result, row) => {
            result.amount += row.amount || 0
            if (row.isDeletable && !row.isDeleted) {
              result.isDeletable = true
            }

            return result
          },
          {
            amount: 0,
            isDeletable: false,
          },
        ),
    [accountActivity, activeItems],
  )

  const loadMore = useCallback(() => {
    fetchAccountActivityList({
      loadMore: true,
      page: Math.ceil(accountActivity?.data.length / PER_PAGE),
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [accountActivity, orderBy, filters, fetchAccountActivityList])

  useEffect(() => {
    if (isClientUser) {
      listCustomers()
    } else if (clientInfo) {
      listCustomers(clientInfo.id)
    }
  }, [listCustomers, clientInfo, isClientUser])

  const { customersList } = useMemo(
    () => ({
      customersList: customers?.data?.data || [],
    }),
    [customers],
  )

  const filtersConfig = useMemo(
    () =>
      CLIENT_ACCOUNT_ACTIVITY_LIST_FILTERS_CONFIG?.filter((filter) => {
        if (!isAdminRightsRole) {
          return filter.type !== 'empty'
        }
        return true
      })
        ?.map((filter) => {
          if (isClientUser && filter.type === 'quickFilter') {
            return {
              ...filter,
              quickFilters: [
                {
                  title: 'Last Month',
                  filters: {
                    recordDateFrom: moment()
                      .startOf('month')
                      .subtract(1, 'month')
                      .format('YYYY-MM-DD'),
                    recordDateTo: moment().endOf('month').subtract(1, 'month').format('YYYY-MM-DD'),
                  },
                },
                {
                  title: 'Last Quarter',
                  filters: {
                    recordDateFrom: moment()
                      .startOf('quarter')
                      .subtract(1, 'quarter')
                      .format('YYYY-MM-DD'),
                    recordDateTo: moment()
                      .endOf('quarter')
                      .subtract(1, 'quarter')
                      .format('YYYY-MM-DD'),
                  },
                },
                {
                  title: 'Fundings',
                  filters: {
                    type: [AGGREGATION_TYPES.funding],
                  },
                },
              ],
            }
          }

          if (filter.field === 'type' && isClientUser) {
            return {
              ...filter,
              options: CLIENT_AGGREGATION_TYPES,
            }
          } else if (filter.field === 'description') {
            return {
              ...filter,
              options: customersList?.map((customer) => ({
                value: customer,
                label: customer,
              })),
            }
          }
          return filter
        })
        .filter((filter) =>
          isGraphShown && !isClientUser
            ? GRAPH_FILTER_FIELDS.includes(filter.field)
            : isGraphShown && isClientUser
            ? filter.field === 'description'
            : true,
        ),

    [isClientUser, isAdminRightsRole, customersList, isGraphShown],
  )

  const handleCreate = useCallback(() => {
    setIsCreateModalShown(true)
  }, [])

  const handleEdit = useCallback((item: any) => {
    setIsEditModalShown(true)
  }, [])

  const handleDelete = useCallback(() => {
    setIsDeleteModalShown(true)
  }, [])

  const handleRestore = useCallback(() => {
    setIsRestoreModalShown(true)
  }, [])

  const handleModalConfirm = useCallback(() => {
    setActiveItems([])

    setIsCreateModalShown(false)
    setIsEditModalShown(false)
    setIsDeleteModalShown(false)
    setIsRestoreModalShown(false)
    refetchListAccountActivity()
  }, [refetchListAccountActivity, setActiveItems])

  const handleModalCancel = useCallback(() => {
    setIsCreateModalShown(false)
    setIsEditModalShown(false)
    setIsDeleteModalShown(false)
    setIsRestoreModalShown(false)
  }, [])

  const activeAccountActivityItems = useMemo(
    () => accountActivity?.data.filter((_, index) => activeItems.includes(index)),
    [accountActivity, activeItems],
  )

  const [currentDateRange, setCurrentDateRange] = useState<{ startDate: string; endDate: string }>({
    startDate: moment().subtract(1, 'year').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  })

  const datesBoundary = useMemo(
    () => ({
      minDate: moment(clientInfo?.contractDate).format('YYYY-MM-DD'),
      maxDate: moment().format('YYYY-MM-DD'),
    }),
    [clientInfo],
  )

  const handleDateChange = useCallback(
    (values: { startDate: string; endDate: string }) => {
      setCurrentDateRange(values)
    },
    [setCurrentDateRange],
  )

  const handleReportingPeriodChange = useCallback(
    ({ target: { value } }) => {
      setCurrentReportingPeriod(value)
    },
    [setCurrentReportingPeriod],
  )

  const handleActivityOptionChange = useCallback(
    ({ target: { value } }) => {
      setActivityOption && setActivityOption(value)
    },
    [setActivityOption],
  )

  return (
    <Card noHeaderMargin withBorder={!isClientUser} noPadding={isClientUser}>
      <TableContainer
        className={cn(styles.aggregationTable, {
          [styles.aggregationTableFullHeight]: matchPath(history.location.pathname, {
            path: ROUTES.ACCOUNT_ACTIVITY,
          })?.isExact,
        })}
        size={isClientUser ? 'large' : 'small'}
        onActiveRowsChange={setActiveItems}
        onActiveRowChange={setActiveItem}
      >
        <Form
          validate={filtersValidate}
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={filtersConfig}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              appliedFilters={filters}
              appliedQuickFilter={quickFilter}
              handleAppliedQuickFilterChange={handleQuickFilterChange}
              withFullSearch={!isGraphShown}
              title="Account Activity"
              actions={
                <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                  {!isClientUser && setActivityOption && (
                    <SelectField
                      useFinalForm={false}
                      name="option"
                      value={ClientPageActivityOption.AccountActivity}
                      onChange={handleActivityOptionChange}
                      className={styles.selectField}
                      label="View"
                      variant="outlined"
                      options={CLIENT_PAGE_ACTIVITY_OPTIONS}
                      fullWidth={false}
                    />
                  )}
                  {isAdminRightsRole && activeItems.length > 1 && totalRow?.isDeletable && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleDelete}
                      startIcon={<DeleteIcon className={genericSs.iconPathStrokeWhite} />}
                    >
                      Delete
                    </Button>
                  )}
                  {isAdminRightsRole && !isGraphShown && (
                    <AddButton variant="outlined" onClick={handleCreate}></AddButton>
                  )}
                  {!isGraphShown && (
                    <ExportButton isLoading={isExporting} handleExport={handleExportAggregation} />
                  )}

                  {isGraphShown && (
                    <div className={styles.recordDateWrapper}>
                      <DatePicker
                        currentDateRange={currentDateRange}
                        datesBoundary={datesBoundary}
                        onChange={handleDateChange}
                      />
                    </div>
                  )}
                  {isGraphShown && (
                    <SelectField
                      key={currentReportingPeriod}
                      label="Period"
                      variant="outlined"
                      useFinalForm={false}
                      name="reportingPeriod"
                      options={ACCOUNT_ACTIVITY_PERIOD_OPTIONS}
                      onChange={handleReportingPeriodChange}
                      value={currentReportingPeriod}
                      defaultValue=""
                      className={styles.selectField}
                    />
                  )}

                  <div>{TabsComponent}</div>
                </Box>
              }
            />
          )}
        />
        {!isGraphShown ? (
          <>
            <Table>
              <TableHead>
                <TableFiltersRow
                  filters={filtersConfig}
                  orderBy={orderBy}
                  handleOrderChange={handleOrderChange}
                />
              </TableHead>
              <TableBody id="scrollableTable">
                {isLoading ? (
                  <TableLoader columnsCount={filtersConfig.length} height={26} />
                ) : (
                  accountActivity?.data &&
                  accountActivity.data.length > 0 && (
                    <InfiniteScroll
                      dataLength={accountActivity?.data.length}
                      next={loadMore}
                      hasMore={accountActivity?.data.length < accountActivity?.totals.totalItems}
                      loader={<TableLoader columnsCount={filtersConfig.length} rowsCount={1} />}
                      scrollableTarget="scrollableTable"
                    >
                      {accountActivity.data.map((item, index) => {
                        let hideRow = false
                        if (
                          item.isDeleted ||
                          (moment(item.recordDate).isAfter(endOfToday) && !isClientUser)
                        ) {
                          hideRow = true
                        }

                        return (
                          <TableRow
                            key={`${item.id}-${index}`}
                            data-index={index}
                            isActiveRow={activeItems.includes(index)}
                            isCurrentActiveRow={activeItem === index}
                            className={cn('activableRow', {
                              [styles.deletedRow]: hideRow,
                            })}
                            index={index}
                            onClick={(event) => handleSelectRow(event, index)}
                          >
                            <TableCell className={genericSs.tableTextRight}>
                              <Tooltip
                                title={!isClientUser ? formatDateTime(item.updatedAt) : ''}
                                placement="top"
                              >
                                <span>{formatDate(item.recordDate)}</span>
                              </Tooltip>
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              <TypeCell
                                item={item}
                                isAdminRightsRole={isAdminRightsRole}
                                handleOpenLink={handleOpenLink}
                                handleItemClick={handleItemClick}
                              />
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              <DescriptionCell
                                row={item}
                                hideLink={isClientUser || isParticipant}
                              />
                            </TableCell>
                            <TableCell className={genericSs.tableTextRight}>
                              <span className={genericSs.pricePrefix}>$</span>
                              {formatPrice(item.amount)}
                            </TableCell>
                            <TableCell className={genericSs.tableTextRight}>
                              {!hideRow && (
                                <span>
                                  <span className={genericSs.pricePrefix}>$</span>
                                  {formatPrice(item.loanBalanceEnding)}{' '}
                                </span>
                              )}
                            </TableCell>

                            {isAdminRightsRole && (
                              <ClientAccountActivityAction
                                item={item}
                                onEdit={handleEdit}
                                onDelete={handleDelete}
                                onRestore={handleRestore}
                              />
                            )}
                          </TableRow>
                        )
                      })}
                    </InfiniteScroll>
                  )
                )}
              </TableBody>
            </Table>

            <ActiveToolbar activeItems={activeItems} className={styles.activeToolbar}>
              <div className={genericSs.tableTextRight}>{formatter.format(totalRow?.amount)}</div>
            </ActiveToolbar>
          </>
        ) : (
          <AccountActivityGraph
            filters={filters}
            startDate={currentDateRange.startDate}
            endDate={currentDateRange.endDate}
            period={currentReportingPeriod}
            isClientUser={isClientUser}
          />
        )}
      </TableContainer>

      {isCreateModalShown && (
        <ClientAccountActivityCreateModal
          handleCancel={handleModalCancel}
          handleConfirm={handleModalConfirm}
        />
      )}

      {isEditModalShown && accountActivity?.data[activeItem] && (
        <ClientAccountActivityEditModal
          item={accountActivity?.data[activeItem]}
          handleCancel={handleModalCancel}
          handleConfirm={handleModalConfirm}
        />
      )}

      {isDeleteModalShown && activeItems.length > 0 && (
        <ClientAccountActivityDeleteModal
          activeItems={activeAccountActivityItems}
          handleCancel={handleModalCancel}
          handleConfirm={handleModalConfirm}
        />
      )}

      {isRestoreModalShown && activeItems.length > 0 && (
        <ClientAccountActivityRestoreModal
          activeItems={activeAccountActivityItems}
          handleCancel={handleModalCancel}
          handleConfirm={handleModalConfirm}
        />
      )}
    </Card>
  )
}

export default ClientAccountActivity
