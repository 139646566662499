import ProspectDashboardHeader from './ProspectDashboardHeader'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { reporting, refreshCount, prospectReporting } from '../../redux/prospect/selectors'
import { listProspectReporting, show } from '../../redux/prospect/actions'
import { pathname } from '../../redux/router/selectors'

const selector = createStructuredSelector({
  reporting,
  pathname,
  refreshCount,
  prospectReporting,
})
const actions = {
  listProspectReporting,
  show,
}

export default connect(selector, actions)(ProspectDashboardHeader)
