import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligenceProcessDocumentsMappingPage from './DueDiligenceProcessDocumentsMappingPage'

import {
  dueDiligenceInfo,
  aliasMapping,
  dueDiligenceReporting,
} from '../../redux/dueDiligence/selectors'
import {
  show,
  listAliasMapping,
  hideAliasMapping,
  showReporting,
} from '../../redux/dueDiligence/actions'
import { listEntityInfo, addEntityInfo } from '../../redux/entityInfo/actions'
import { updateAlias, deleteAlias } from '../../redux/arapmapping/actions'

const selector = createStructuredSelector({
  dueDiligenceInfo,
  aliasMapping,
  dueDiligenceReportingData: dueDiligenceReporting,
})
const actions = {
  show,
  listAliasMapping,
  hideAliasMapping,
  listEntityInfo,
  addEntityInfo,
  updateAlias,
  deleteAlias,
  showReporting,
}

export default connect(selector, actions)(DueDiligenceProcessDocumentsMappingPage)
