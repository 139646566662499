import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import isEqual from 'lodash/isEqual'
import cn from 'classnames'
import moment from 'moment'

import styles from './InventoryEligibleCustomRules.module.scss'
import genericSs from '@styles/generic.module.scss'

import Box from '@mui/material/Box'
import TableRow from '../../Common/TableRow'
import TableCell from '../../Common/TableCell'
import Table from '../../Common/Table'

import Radios from '../../Common/Radios'
import TableHead from '../../Common/TableHead'
import TableContainer from '../../Common/TableContainer'
import TableBody from '../../Common/TableBody'
import TableFiltersRow from '../../../components/Common/TableFiltersRow'
import FilterContainer from '../../../components/Filters/FilterContainer'
import TableFields from '../../TableFields'

import WarningModal from '../../WarningModal'
import InputLabel from '../../Common/InputLabel'
import {
  IInventoryIneligibilityCustomRule,
  CUSTOM_RULES_FIELD,
  CUSTOM_RULES_OPERATOR,
  CUSTOM_RULES_FIELD_TYPES,
  IInventoryIneligibilityField,
  INVENTORY_FIELD_TO_LABEL,
  IneligibleReasonsMap,
  INELIGIBLE_REASONS_LIST,
} from '@common/interfaces/bbc'
import { DATE_FORMAT } from '../../../constants/common'
import TextField from '../../Common/TextField'
import Checkbox from '../../Common/Checkbox'
import Button from '../../Common/Button'
import SelectField from '../../Common/SelectField'
import KeyboardDatePicker from '../../Common/KeyboardDatePicker'
import Modal from '../../Common/Modal'
import SelectWithCheckboxesField from '../../Common/SelectWithCheckboxesField'
import { debounceEventHandler } from '../../../helpers/helpers'
import Autocomplete from '../../Common/Autocomplete'
import { ILoadingData } from '../../../redux/types'
import AddButton from '../AddButton/AddButton'
import { CLIENT_INVENTORY_ELIGIBILITY_LIST_FILTERS_CONFIG } from '@common/constants/filters'
import { buildFiltersValidateSchema, buildFiltersDefaults } from '../../../helpers/filters'
import TableLoader from '../../Common/TableLoader'
import { AddIcon, EditIcon, DeleteIcon } from '../../Common/Icons'

const filtersDefaults = buildFiltersDefaults(CLIENT_INVENTORY_ELIGIBILITY_LIST_FILTERS_CONFIG)
const filtersValidate = buildFiltersValidateSchema(CLIENT_INVENTORY_ELIGIBILITY_LIST_FILTERS_CONFIG)

const schema = Yup.object().shape({
  rules: Yup.array().of(
    Yup.object().shape({
      field: Yup.string().required('Required'),
      label: Yup.string().required('Required'),
      ineligibleReason: Yup.string().required('Required'),
      operator: Yup.string().required('Required'),
      value: Yup.string().required('Required'),
    }),
  ),
})
const validate = makeValidate(schema)

const schemaModal = Yup.object().shape({
  label: Yup.string().required('Label is required'),
  field: Yup.string().required('Field is required'),
  operator: Yup.string().required('Operator is required'),
  ineligibleReason: Yup.string().required('An ineligible reason is required'),
  values: Yup.mixed().required('Value is required'),
  eligible: Yup.string().required('Eligibility is required'),
  overrides: Yup.object().test({
    name: 'requiredIf',
    exclusive: false,
    message: 'At least one is required',
    test(overrides) {
      if (this.parent.eligible !== true) {
        return true
      }

      const normalizedOverrides = overrides
        ? Object.entries(overrides)
            .filter(([key]) => key !== 'all')
            .reduce((acc: string[], [key, override]) => (override ? [...acc, key] : acc), [])
        : []

      return normalizedOverrides.length > 0
    },
  }),
})

const modalValidate = makeValidate(schemaModal)

const FIELDS_LIST = Object.keys(CUSTOM_RULES_FIELD).map((key) => ({
  value: CUSTOM_RULES_FIELD[key],
  label: key,
}))

const OPERATOR_LIST = Object.keys(CUSTOM_RULES_OPERATOR).map((key) => ({
  value: CUSTOM_RULES_OPERATOR[key],
  label: key,
}))

const STRING_OPERATOR_LIST = [
  {
    value: '=',
    label: '=',
  },
]

const ELIGIBILITY_LIST = [
  { label: 'Eligible', value: true },
  { label: 'Ineligible', value: false },
]

const mutators = {
  ...arrayMutators,
}

interface IProps {
  borrowingBaseId?: string
  clientId?: string
  clientName: string
  fields: ILoadingData<{ data: IInventoryIneligibilityField[] }>
  customRules: ILoadingData<IInventoryIneligibilityCustomRule[]>
  isCompleted?: boolean
  customRulesValues: { [key: string]: string[] }
  listCustomRules:
    | ((clientId: string, data?: object) => void)
    | ((data: { borrowingBaseId: string }) => void)
  createCustomRules: (data: object) => void
  updateCustomRule: (id: string, data: object) => void
  deleteCustomRule: (id: string, data: object) => void
  listCustomRulesLabels: () => void
  createCustomRulesLabel: (data: { label: string }) => void
  listCustomRulesValues: (data: {
    clientName: string
    field: string
    borrowingBaseId?: string
  }) => void
}

const InventoryEligibleCustomRuleTable = ({
  borrowingBaseId,
  clientId,
  clientName,
  fields,
  customRules,
  customRulesValues,
  isCompleted,
  listCustomRules,
  createCustomRules,
  updateCustomRule,
  deleteCustomRule,
  listCustomRulesLabels,
  createCustomRulesLabel,
  listCustomRulesValues,
}: IProps) => {
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [selectedRule, setSelectedRule] = useState(null)
  const [isChild, setIsChild] = useState(null)
  const [filters, setFilters] = useState(filtersDefaults)
  const [orderBy, setOrderBy] = useState({
    field: 'label',
    direction: 'ASC',
  })
  const [isRuleModalShown, setIsRuleModalShown] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [columnValue, setColumnValue] = useState('')

  const [isBBC, isClient] = useMemo(
    () => [Boolean(borrowingBaseId), Boolean(clientId)],
    [borrowingBaseId, clientId],
  )

  const { isLoading, customRulesData } = useMemo(
    () => ({
      isLoading: customRules.isLoading,
      isSaving: customRules.isSaving,
      customRulesData: customRules?.data
        ? Object.values(customRules?.data)
            .map((value) => value)
            .filter(Boolean)
        : [],
    }),
    [customRules],
  )

  useEffect(() => {
    listCustomRulesLabels()
  }, [listCustomRulesLabels])

  const handleFiltersChange = useCallback((data: any) => {
    setFilters(data)
  }, [])

  const handleOrderChange = useCallback((field: string) => {
    setOrderBy((order) => ({
      field,
      direction: order.field === field ? (order.direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
    }))
  }, [])

  const debounceListCustomRules = useMemo(
    () =>
      debounceEventHandler((data: any) => {
        if (isBBC) {
          ;(listCustomRules as (data: { borrowingBaseId: string }) => void)({ borrowingBaseId })
        } else if (isClient) {
          ;(listCustomRules as (clientId: string, data?: object) => void)(clientId, data)
        }
      }, 500),
    [listCustomRules, clientId, borrowingBaseId, isBBC, isClient],
  )

  useEffect(() => {
    debounceListCustomRules({
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, debounceListCustomRules])

  const handleEditRule = useCallback((values: any[], index: number, isChildRow = false) => {
    if (isChildRow) {
      setIsChild(isChildRow)
    }
    setSelectedRule({ ...values[index], isEdit: true })
    setIsRuleModalShown(true)
  }, [])

  const handleResetValues = useCallback(({ update }: any, values: any[], index: number) => {
    update(index, { ...values[index], value: '', values: [] })
  }, [])

  const handleCancelRule = useCallback(
    ({ update }: any, values: any[], index: number) => {
      const customRule = customRulesData.find(({ id }) => id === values[index].id)
      update(index, {
        ...customRule,
        label: {
          value: customRule.label,
          label: customRule.label,
        },
        isEdit: false,
      })
    },
    [customRulesData],
  )

  const handleSaveRule = useCallback(
    async (data: any) => {
      setIsSubmitting(true)
      const {
        field,
        label,
        operator,
        values: valuesList,
        eligible,
        overrides,
        ineligibleReason,
      } = data
      const valueItem = Array.isArray(valuesList) ? valuesList[0] : valuesList
      const valueArray =
        Array.isArray(valuesList) && valuesList.length > 0 ? valuesList : [valueItem]
      const normalizedOverrides = overrides
        ? Object.entries(overrides)
            .filter(([key]) => key !== 'all')
            .reduce((acc: string[], [key, override]) => (override ? [...acc, key] : acc), [])
        : overrides

      await createCustomRulesLabel({ label })
      const isMultiple = operator === '=' && CUSTOM_RULES_FIELD_TYPES[field] !== 'date'
      if (!selectedRule?.isEdit) {
        createCustomRules({
          clientName,
          field,
          label,
          operator,
          value: valueItem,
          values: isMultiple ? valueArray : [],
          eligible,
          overrides: normalizedOverrides,
          parentRuleId: selectedRule?.parentRuleId,
          ineligibleReason,
          borrowingBaseId,
        })
      } else {
        updateCustomRule(selectedRule.id, {
          field,
          label,
          operator,
          value: valueItem,
          values: isMultiple ? valueArray : [],
          eligible,
          overrides: normalizedOverrides,
          parentRuleId: selectedRule.parentRuleId,
          ineligibleReason,
          borrowingBaseId,
        })
      }
      setSelectedRule(null)
      setIsChild(false)
      setColumnValue('')
      setIsRuleModalShown(false)
      setIsSubmitting(false)
    },
    [
      createCustomRules,
      updateCustomRule,
      clientName,
      createCustomRulesLabel,
      selectedRule,
      borrowingBaseId,
    ],
  )

  const handleDeleteRule = useCallback(({ remove }: any, values: any[], index: number) => {
    const rule = values[index]

    if (rule.isNew) {
      remove(index)
    } else {
      setIsDeleteModalShown(true)
      setSelectedRule(rule)
    }
  }, [])

  const handleDeleteRuleConfirm = useCallback(() => {
    deleteCustomRule(selectedRule.id, { borrowingBaseId })
    setIsDeleteModalShown(false)
    setSelectedRule(null)
  }, [deleteCustomRule, selectedRule, borrowingBaseId])

  const handleDeleteRuleCancel = useCallback(() => {
    setIsDeleteModalShown(false)
    setSelectedRule(null)
  }, [])

  const handleAddRuleCancel = useCallback(() => {
    setIsRuleModalShown(false)
    setColumnValue('')
    setIsChild(false)
    setSelectedRule(null)
  }, [])

  const fetchCustomRulesValuesList = useMemo(
    () =>
      debounceEventHandler((field: string) => {
        if (field) {
          if (isBBC) {
            listCustomRulesValues({ field, clientName, borrowingBaseId })
          } else {
            listCustomRulesValues({ field, clientName })
          }
        }
      }, 500),
    [listCustomRulesValues, clientName, isBBC, borrowingBaseId],
  )

  const getCustomRulesValuesOptions = useCallback(
    (field: string) => {
      if (!field) {
        return []
      }
      if (!customRulesValues[field]) {
        fetchCustomRulesValuesList(field)
      }
      return (customRulesValues[field] || []).map((value) => ({
        value,
        label:
          CUSTOM_RULES_FIELD_TYPES[field] === 'date'
            ? moment(value).format('M/D/YY')
            : value.toString(),
      }))
    },
    [customRulesValues, fetchCustomRulesValuesList],
  )

  const renderValueField = useCallback(
    (fieldType: string, name: string, field: string, operator: string, disabled?: boolean) => {
      if (operator === '=' && fieldType !== 'date') {
        return (
          <Autocomplete
            fullWidth
            withBorder
            size="medium"
            clearIcon={null}
            name={name}
            placeholder=""
            options={getCustomRulesValuesOptions(field)}
            getOptionValue={(option) => option.value}
            disabled={disabled}
            // @ts-ignore
            multiple
          />
        )
      }
      switch (fieldType) {
        case 'number':
          return (
            <TextField
              name={name}
              InputProps={{
                type: 'number',
              }}
              disabled={disabled}
            />
          )
        case 'date':
          return <KeyboardDatePicker name={name} inputFormat={DATE_FORMAT} disabled={disabled} />
        case 'string':
        default:
          return <TextField name={name} disabled={disabled} />
      }
    },
    [getCustomRulesValuesOptions],
  )
  const availableOverrides = useMemo(() => {
    const fieldLabels = INVENTORY_FIELD_TO_LABEL

    return customRulesData || fields?.data?.data
      ? [
          ...new Set(
            [...(fields?.data?.data || []), ...(customRulesData || [])].map(({ field }) => field),
          ),
        ].map((field) => ({
          value: field,
          label: fieldLabels[field],
        }))
      : []
  }, [fields, customRulesData])
  const initialValues = useMemo(
    () => ({
      rules: (customRulesData || []).map((rule) => ({
        ...rule,
        label: {
          value: rule.label,
          label: rule.label,
        },
        overridesLabel: Array.isArray(rule.overrides)
          ? rule.overrides.map((field) => INVENTORY_FIELD_TO_LABEL[field]).join(', ')
          : '',
        overrides:
          Array.isArray(rule.overrides) &&
          rule.overrides.reduce(
            (acc, item: string) => ({
              ...acc,
              [item]: true,
            }),
            {
              all: isEqual(
                availableOverrides.map(({ value }) => value).sort(),
                (rule.overrides || []).sort(),
              ),
            },
          ),
      })),
    }),
    [availableOverrides, customRulesData],
  )

  const handleOpenAddRuleModal = useCallback(
    (isChildRow = false, rule = null) => {
      if (isChildRow && rule) {
        setIsChild(isChildRow)
        setSelectedRule({ parentRuleId: rule.id, label: rule.label })
      }
      setIsRuleModalShown(!isRuleModalShown)
    },
    [setIsRuleModalShown, isRuleModalShown],
  )

  return (
    <>
      <Form
        onSubmit={handleFiltersChange}
        initialValues={initialValues}
        validate={filtersValidate}
        mutators={mutators}
        render={({ values }: { form: any; handleSubmit: any; values: any }) => (
          <TableContainer className={styles.table}>
            <Form
              initialValues={filters}
              onSubmit={handleFiltersChange}
              validate={validate}
              mutators={{
                setFieldData: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value)
                },
              }}
              render={({
                values: filterValues,
                handleSubmit: handleFilterSubmit,
                form: { mutators: filterMutators },
              }) => (
                <div className={styles.filtersHeader}>
                  <FilterContainer
                    filters={CLIENT_INVENTORY_ELIGIBILITY_LIST_FILTERS_CONFIG}
                    title={'Custom rules'}
                    withFullSearch={isClient}
                    withFilters={isClient}
                    handleSubmit={handleFilterSubmit}
                    mutators={filterMutators}
                    values={values}
                    appliedFilters={filterValues}
                    actions={
                      <AddButton
                        type="button"
                        color="primary"
                        variant="outlined"
                        size="small"
                        onClick={handleOpenAddRuleModal}
                        disabled={isCompleted}
                      ></AddButton>
                    }
                  />
                </div>
              )}
            />

            <Table>
              <TableHead>
                <TableFiltersRow
                  filters={CLIENT_INVENTORY_ELIGIBILITY_LIST_FILTERS_CONFIG}
                  orderBy={orderBy}
                  handleOrderChange={handleOrderChange}
                  className={styles.filterHeaders}
                />
              </TableHead>
              <FieldArray name="rules">
                {({ fields: rules }) => (
                  <TableBody>
                    {isLoading && <TableLoader columnsCount={7} />}
                    {rules.map((name, index) => {
                      const item = values.rules[index]
                      if (!item) {
                        return null
                      }
                      const disabled = !item.isNew && !item.isEdit

                      return (
                        <>
                          <TableRow key={item.id}>
                            <TableCell className={genericSs.tableTextLeft}>
                              {item.label?.label}
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              {INVENTORY_FIELD_TO_LABEL[item.field]}
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              {item.ineligibleReason}
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              {item.operator}
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              {item.operator === '='
                                ? item.values
                                    .map((value: any) =>
                                      CUSTOM_RULES_FIELD_TYPES[item.field] === 'date'
                                        ? moment(value).format('M/D/YY')
                                        : value,
                                    )
                                    .join(', ')
                                : CUSTOM_RULES_FIELD_TYPES[item.field] === 'date'
                                ? moment(item.value).format('M/D/YY')
                                : item.value}
                            </TableCell>
                            <TableCell>
                              <Checkbox
                                className="focusableInput"
                                tabIndex={3 * index + 1}
                                checked={item.eligible}
                                name={`${name}.eligible`}
                                color="primary"
                                disabled={disabled}
                              />
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              {item.overridesLabel}
                            </TableCell>
                            <TableCell className={genericSs.tableTextRight}>
                              <AddIcon
                                disabled={isCompleted}
                                className={styles.iconButton}
                                onClick={() => handleOpenAddRuleModal(true, item)}
                                size="small"
                              />
                              <EditIcon
                                disabled={isCompleted}
                                className={styles.iconButton}
                                onClick={() => handleEditRule(values.rules, index)}
                                size="small"
                              />
                              {!item.isNew && item.isEdit && (
                                <DeleteIcon
                                  disabled={isCompleted}
                                  className={styles.iconButton}
                                  onClick={() => handleCancelRule(rules, values.rules, index)}
                                  size="small"
                                />
                              )}
                              {(item.isNew || !item.isEdit) && (
                                <DeleteIcon
                                  disabled={isCompleted}
                                  className={styles.iconButton}
                                  onClick={() => handleDeleteRule(rules, values.rules, index)}
                                  size="small"
                                />
                              )}
                            </TableCell>
                          </TableRow>
                          <FieldArray name={`${name}.children`}>
                            {({ fields: children }) =>
                              children.map((childrenName, childrenIndex) => {
                                const child = values.rules[index]?.children?.[childrenIndex]
                                if (!child) {
                                  return null
                                }
                                const childFieldType = child.field
                                  ? CUSTOM_RULES_FIELD_TYPES[child.field]
                                  : null
                                const childDisabled = !child.isNew && !child.isEdit

                                return (
                                  <TableRow
                                    key={child.id}
                                    className={cn(styles.childRow, {
                                      [styles.lastChildRow]:
                                        values.rules[index]?.children?.length - 1 === childrenIndex,
                                    })}
                                  >
                                    <TableCell>
                                      <div className={styles.childBorders} />
                                    </TableCell>
                                    <TableCell className={genericSs.tableTextLeft}>
                                      {childDisabled ? (
                                        INVENTORY_FIELD_TO_LABEL[child.field]
                                      ) : (
                                        <SelectField
                                          className={styles.fieldsList}
                                          name={`${childrenName}.field`}
                                          options={FIELDS_LIST}
                                          onBlur={() =>
                                            handleResetValues(
                                              children,
                                              values.rules[index]?.children,
                                              childrenIndex,
                                            )
                                          }
                                        />
                                      )}
                                    </TableCell>
                                    <TableCell className={genericSs.tableTextLeft}>
                                      {childDisabled ? (
                                        child.operator
                                      ) : (
                                        <SelectField
                                          className={styles.fieldsList}
                                          name={`${childrenName}.operator`}
                                          options={
                                            childFieldType === 'string'
                                              ? STRING_OPERATOR_LIST
                                              : OPERATOR_LIST
                                          }
                                          onBlur={() =>
                                            handleResetValues(
                                              children,
                                              values.rules[index]?.children,
                                              childrenIndex,
                                            )
                                          }
                                        />
                                      )}
                                    </TableCell>
                                    <TableCell className={genericSs.tableTextLeft}>
                                      {childDisabled
                                        ? child.operator === '='
                                          ? child.values
                                              .map((value: any) =>
                                                CUSTOM_RULES_FIELD_TYPES[child.field] === 'date'
                                                  ? moment(value).format('M/D/YY')
                                                  : value,
                                              )
                                              .join(', ')
                                          : CUSTOM_RULES_FIELD_TYPES[child.field] === 'date'
                                          ? moment(child.value).format('M/D/YY')
                                          : child.value
                                        : renderValueField(
                                            childFieldType,
                                            childrenName,
                                            child.field,
                                            child.operator,
                                            childDisabled,
                                          )}
                                    </TableCell>
                                    <TableCell colSpan={2} />
                                    <TableCell />
                                    <TableCell className={genericSs.tableTextRight}>
                                      <EditIcon
                                        className={styles.iconButton}
                                        onClick={() =>
                                          handleEditRule(
                                            values.rules[index]?.children,
                                            childrenIndex,
                                            true,
                                          )
                                        }
                                      />
                                      {!child.isNew && child.isEdit && (
                                        <DeleteIcon
                                          className={styles.iconButton}
                                          onClick={() =>
                                            handleCancelRule(
                                              children,
                                              values.rules[index]?.children,
                                              childrenIndex,
                                            )
                                          }
                                        />
                                      )}
                                      {(child.isNew || !child.isEdit) && (
                                        <DeleteIcon
                                          className={styles.iconButton}
                                          onClick={() =>
                                            handleDeleteRule(
                                              children,
                                              values.rules[index]?.children,
                                              childrenIndex,
                                            )
                                          }
                                        />
                                      )}
                                    </TableCell>
                                  </TableRow>
                                )
                              })
                            }
                          </FieldArray>
                        </>
                      )
                    })}
                  </TableBody>
                )}
              </FieldArray>
            </Table>
          </TableContainer>
        )}
      />

      {isRuleModalShown && (
        <Form
          onSubmit={handleSaveRule}
          validate={modalValidate}
          initialValues={{
            label: selectedRule?.label?.label ? selectedRule?.label?.label : selectedRule?.label,
            field: selectedRule?.field,
            operator: selectedRule?.operator,
            values:
              CUSTOM_RULES_FIELD_TYPES[selectedRule?.field] === 'string'
                ? selectedRule?.values
                : selectedRule?.value,
            eligible: selectedRule?.eligible,
            overrides: selectedRule?.overrides,
            ineligibleReason: selectedRule?.ineligibleReason,
          }}
          render={({ form, handleSubmit, dirty, invalid }) => (
            <form>
              <Modal
                open={isRuleModalShown}
                onCancel={handleAddRuleCancel}
                size="small"
                title={
                  (selectedRule?.isEdit ? 'Edit' : 'Add') + (isChild ? ' Child' : '') + ' Rule'
                }
                footer={[
                  <Button
                    key="submit"
                    color="primary"
                    variant="contained"
                    className={styles.submitModalButton}
                    disabled={!dirty || invalid}
                    onClick={handleSubmit}
                    isLoading={isSubmitting}
                  >
                    {(selectedRule?.isEdit ? 'Edit' : 'Add') + (isChild ? ' Child' : '') + ' Rule'}
                  </Button>,
                ]}
              >
                <Box className={styles.fieldsContainer}>
                  <TableFields
                    rows={[
                      [
                        <InputLabel key="label" htmlFor="label" size="normal" position="horizontal">
                          Label
                        </InputLabel>,
                      ],
                      [<TextField key="label" disabled={isChild} required name="label" />],
                      [
                        <InputLabel
                          key="columnNameLabel"
                          htmlFor="field"
                          size="normal"
                          position="horizontal"
                        >
                          Column Name
                        </InputLabel>,
                      ],
                      [
                        <SelectField
                          name="field"
                          options={FIELDS_LIST}
                          disabled={selectedRule && !isChild}
                          useFinalForm={false}
                          key="columnNameInput"
                          selectSize="medium"
                          border
                          fullWidth
                          value={selectedRule?.isEdit ? selectedRule?.field : columnValue}
                          onChange={(event) => {
                            const newValues = event?.target?.value
                            form.change('field', newValues)
                            setColumnValue(newValues)
                            form.change('ineligibleReason', IneligibleReasonsMap[newValues])
                          }}
                        />,
                      ],
                      [
                        <InputLabel
                          key="ineligibilityReasonLabel"
                          htmlFor="ineligibleReason"
                          size="normal"
                          position="horizontal"
                        >
                          Ineligibility Reason
                        </InputLabel>,
                      ],
                      [
                        <SelectField
                          name="ineligibleReason"
                          disabled={!form.getState().values?.field}
                          options={INELIGIBLE_REASONS_LIST}
                          key="ineligibilityReasonInput"
                          selectSize="medium"
                          border
                          fullWidth
                        />,
                      ],
                      [
                        <InputLabel
                          key="operatorLabel"
                          htmlFor="operator"
                          size="normal"
                          position="horizontal"
                        >
                          Operator
                        </InputLabel>,
                      ],
                      [
                        <SelectField
                          name="operator"
                          key="operatorInput"
                          disabled={!form.getState().values?.field}
                          options={
                            CUSTOM_RULES_FIELD_TYPES[form.getState().values?.field] === 'string'
                              ? STRING_OPERATOR_LIST
                              : OPERATOR_LIST
                          }
                          selectSize="medium"
                          border
                          fullWidth
                        />,
                      ],
                      [
                        <InputLabel
                          key="valueLabel"
                          htmlFor="values"
                          size="normal"
                          position="horizontal"
                        >
                          Value
                        </InputLabel>,
                      ],
                      [
                        renderValueField(
                          CUSTOM_RULES_FIELD_TYPES[form.getState().values?.field],
                          'values',
                          form.getState().values?.field,
                          form.getState().values?.operator,
                          !form.getState().values?.field || !form.getState().values?.operator,
                        ),
                      ],
                      [
                        <InputLabel
                          key="overridesLabel"
                          htmlFor="overrides"
                          size="normal"
                          position="horizontal"
                        >
                          Overrides
                        </InputLabel>,
                      ],

                      [
                        form.getState().values?.eligible ? (
                          <SelectWithCheckboxesField
                            className={styles.overridesList}
                            name="overrides"
                            options={availableOverrides}
                            selectAll
                          />
                        ) : (
                          <TextField
                            placeholder="Only available for eligible"
                            key="label"
                            name="overrides_placeholder"
                            disabled={true}
                          />
                        ),
                      ],
                      [<Radios name={`eligible`} data={ELIGIBILITY_LIST} />],
                    ]}
                  />
                </Box>
              </Modal>
            </form>
          )}
        />
      )}

      {isDeleteModalShown && selectedRule && (
        <WarningModal
          warningMessage="Custom rule will be deleted."
          onConfirm={handleDeleteRuleConfirm}
          onCancel={handleDeleteRuleCancel}
          confirmText="Delete"
          cancelText="Cancel"
        />
      )}
    </>
  )
}

export default InventoryEligibleCustomRuleTable
