import React, { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { generatePath } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import styles from './DueDiligenceProcessDocumentsMappingPage.module.scss'
import genericSs from '@styles/generic.module.scss'

import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import {
  DUE_DILIGENCE_ROUTES_MAP,
  IDueDiligence,
  IDueDiligenceReporting,
} from '@common/interfaces/dueDiligence'
import { ROUTES } from '../../constants/routes'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import Button from '../../components/Common/Button'
import { IAlias, OngoingReportingType } from '@common/interfaces/bbc'
import AliasMapping from '../../components/AliasMapping'
import { ILoadingData } from '../../redux/types'
import DueDiligenceProcessDocumentsMappingLoader from '../../components/DueDiligenceProcessDocumentsMappingLoader'
import { ClientInfoStatus } from '@common/interfaces/client'

interface IProps {
  dueDiligenceInfo: IDueDiligence
  aliasMapping: ILoadingData<{ data: IAlias[] }>
  dueDiligenceReportingData: ILoadingData<{ data: IDueDiligenceReporting[] }>
  show: (id: string) => void
  showReporting: (id: string) => void
  listAliasMapping: (id: string, params: object) => void
  hideAliasMapping: () => void
  listEntityInfo: (data: object) => Promise<{ data: any }>
  addEntityInfo: (data: object) => Promise<any>
  updateAlias: (data: object) => Promise<any>
  deleteAlias: (data: object) => Promise<any>
}

const DueDiligenceProcessDocumentsMappingPage = ({
  dueDiligenceInfo,
  aliasMapping,
  dueDiligenceReportingData,
  showReporting,
  show,
  listAliasMapping,
  hideAliasMapping,
  listEntityInfo,
  addEntityInfo,
  updateAlias,
  deleteAlias,
}: IProps) => {
  const { id, type: routeType } = useParams<{ id: string; type: string }>()
  const { pathname } = useLocation()
  const history = useHistory()
  const readOnly = useMemo(
    () => dueDiligenceInfo?.clientStatus !== ClientInfoStatus.DueDiligence,
    [dueDiligenceInfo],
  )

  const isAR = useMemo(
    () => routeType === DUE_DILIGENCE_ROUTES_MAP[OngoingReportingType.AR],
    [routeType],
  )

  useLoadInfo({ id, info: dueDiligenceInfo, show })
  useSetPageTitle(dueDiligenceInfo?.clientName || '')

  useEffect(() => {
    showReporting(id)
  }, [id, showReporting])

  const ddReporting = useMemo(
    () =>
      dueDiligenceReportingData?.data?.data.find(({ type }) =>
        isAR ? type === OngoingReportingType.AR : type === OngoingReportingType.AP,
      ),
    [dueDiligenceReportingData, isAR],
  )

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.DUE_DILIGENCE_HOME,
        Icon: HomeIcon,
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE, { id }),
        title: dueDiligenceInfo?.clientName,
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE, { id }),
        title: 'Process Documents',
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_LIST, { id, type: routeType }),
        title: `A${isAR ? 'R' : 'P'} Reports`,
      },
      {
        link: pathname,
        title: `Map A${isAR ? 'R' : 'P'} Aging Reports`,
      },
    ]
  }, [dueDiligenceInfo, id, routeType, pathname, isAR])

  const handleGoNext = useCallback(async () => {
    history.push(generatePath(ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE, { id }))
  }, [id, history])

  if (!dueDiligenceInfo) {
    return <DueDiligenceProcessDocumentsMappingLoader />
  }

  return (
    <Grid container py={3} pr={2} alignItems="flex-start" justifyContent="center" rowSpacing={2}>
      <Grid item container xs={12} justifyContent="space-between">
        <Grid item container xs={6} justifyContent="flex-start" alignItems="center">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Grid>
      </Grid>

      <Grid item xs={12} alignItems="flex-start" justifyContent="flex-start" ml={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <h2 className={genericSs.dueDiligenceHeader}>Map A{isAR ? 'R' : 'P'} Aging Reports</h2>
          <Box display="flex" alignItems="center" gap={2}>
            <Button
              className={genericSs.dueDiligenceSubmitButton}
              variant="contained"
              onClick={handleGoNext}
            >
              Next
            </Button>
          </Box>
        </Box>

        <AliasMapping
          aliasesData={aliasMapping}
          listEntityInfo={listEntityInfo}
          addEntityInfo={addEntityInfo}
          updateAlias={updateAlias}
          deleteAlias={deleteAlias}
          listAliasMapping={listAliasMapping}
          hideAliasMapping={hideAliasMapping}
          id={id}
          table={isAR ? 'client_ar_raw' : 'client_ap_raw'}
          clientName={dueDiligenceInfo.clientName}
          tableClassName={styles.documentTable}
          readOnly={readOnly}
          ddReportingId={ddReporting?.id}
        />
      </Grid>
    </Grid>
  )
}

export default DueDiligenceProcessDocumentsMappingPage
