import Base from './base'

export default class CapTable extends Base {
  list(params: object) {
    return this.apiClient.get(`cap-table`, params)
  }

  listFilters(params: object) {
    return this.apiClient.get(`cap-table-filters`, params)
  }

  listMapping(params: object) {
    return this.apiClient.get(`cap-table-mapping`, params)
  }

  listBoardSeats(id: string, params: object) {
    return this.apiClient.get(`board-seats/${id}`, params)
  }

  updateCapTableDetails(data: object) {
    return this.apiClient.put(`cap-table-details`, data)
  }

  upload(id: string) {
    return this.apiClient.get(`cap-table/salesforce/${id}`)
  }
  process(id: string, data: object) {
    return this.apiClient.put(`cap-table/${id}`, data)
  }
  update(id: string, data: object) {
    return this.apiClient.put(`cap-table/update/${id}`, data)
  }
}
