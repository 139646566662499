import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import cn from 'classnames'
import { useHistory } from 'react-router'
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone'
import moment from 'moment'
import Slide from '@mui/material/Slide'
import styles from './SubmitReportingPage.module.scss'
import genericSs from '@styles/generic.module.scss'
import { ReactComponent as DueIcon } from '@assets/images/warning-circle-icon-hollow.svg'
import { ReactComponent as ActionSuccessIcon } from '@assets/images/action-success-concentric.svg'
import { ReactComponent as ActionPendingIcon } from '@assets/images/action-pending.svg'
import { ReactComponent as ArrowRightIcon } from '@assets/images/direction-right-icon.svg'
import Modal from '../../components/Common/Modal'
import Card from '../../components/Common/Card'
import { ClientInfoStatus, IClientInfo } from '@common/interfaces/client'
import Button from '../../components/Common/Button'
import { ROUTES } from '../../constants/routes'
import { IUser, UserRole } from '@common/interfaces/user'
import { formatDate } from '../../helpers/helpers'
import {
  REPORTING_DOCUMENT_TYPES,
  REPORTING_DOCUMENTS_TITLES,
  ReportingDocumentStatuses,
} from '@common/constants/client'
import { IRequiredReport } from '@common/interfaces/requiredReport'
import SubmitReportingPageLoader from './SubmitReportingPageLoader'
import RouteLeavingGuard from '../../components/Common/RouteLeavingGuard'
import UploadFileManagement from '../../components/Common/UploadFileManagement'
import { DEFAULT_MAX_FILE_SIZE } from '../../constants/common'
import { humanReadableFileSize } from '@common/helpers/helpers'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'

const EMBEDDED_LINK_TYPES = {
  [REPORTING_DOCUMENT_TYPES.coi]: {
    dev: '1726634855740',
    prod: '1726625903534',
  },
  [REPORTING_DOCUMENT_TYPES.eop]: {
    dev: '1726634862940',
    prod: '1726628574213',
  },
}

interface IProps {
  clientInfo: IClientInfo
  showClient: (id: string) => void
  submitOngoingReporting: (id: string, data: FormData) => any
  isExtendedRightsRole: boolean
  isAdminLoggedAsClient: boolean
  user: IUser
  putNotification: (notification: object) => void
  getEmbedLink: (fileId: string) => void
  hideBoxPreview: () => void
}

const mutators = { ...arrayMutators }

interface ongoingReportingDocumentProps {
  item: IRequiredReport
  isExtendedRightsRole: boolean
  user: IUser
  isOverdue: boolean
  isSelected: boolean
  onSelect: (document: IRequiredReport) => void
  fieldsRef: any
}

const OngoingReportingDocument = ({
  item,
  isExtendedRightsRole,
  user,
  isOverdue,
  isSelected,
  onSelect,
  fieldsRef,
}: ongoingReportingDocumentProps) => {
  const handleSelectDocument = useCallback(() => {
    onSelect(item)
  }, [item, onSelect])

  if (item.documentName === REPORTING_DOCUMENT_TYPES.bankTransactions && !isExtendedRightsRole) {
    return null
  }

  return (
    <FieldArray name={item.name}>
      {({ fields }) => {
        if (isSelected) {
          fieldsRef.current = fields
        }

        return (
          <div>
            <div
              className={cn(styles.reportingDocumentsContainerListItem, {
                [styles.reportingDocumentsContainerListItemSelected]: isSelected,
              })}
              onClick={handleSelectDocument}
            >
              {isOverdue && <DueIcon />}
              <div className={styles.reportingDocumentsContainerListItemText}>
                <div className={styles.reportingDocumentsContainerListItemName}>
                  {REPORTING_DOCUMENTS_TITLES[item.documentName] || item.documentName}
                </div>
              </div>
              <div className={styles.reportingDocumentsContainerListItemIcons}>
                {isOverdue && item.daysSinceDue > 0 ? (
                  <div className={styles.reportingDocumentsContainerListItemOverDue}>
                    Due&nbsp;
                    {item.daysSinceDue > 60
                      ? `${Math.ceil(item.daysSinceDue / 30)} months`
                      : `${item.daysSinceDue} day${item.daysSinceDue > 1 ? 's' : ''}`}{' '}
                    ago
                  </div>
                ) : item.daysSinceDue < 0 ? (
                  <div className={styles.reportingDocumentsContainerListItemDue}>
                    Due by {formatDate(item.nextDueDate)}
                  </div>
                ) : null}

                {user.role !== UserRole.CLIENT_USER && (
                  <div>
                    <div className={styles.documentItemDescriptionLabel}>
                      Reporting submitted on{' '}
                      {item?.lastSubmittedAt ? formatDate(item.lastSubmittedAt) : '-'}
                    </div>
                    <div className={styles.documentItemDescriptionLabel}>
                      Current reporting is through{' '}
                      {item?.reportingThoughDate ? formatDate(item.reportingThoughDate) : '-'}
                    </div>
                  </div>
                )}

                {fields.length > 0 && (
                  <div className={styles.reportingDocumentsContainerListItemCount}>
                    {fields.length}
                  </div>
                )}
                <ArrowRightIcon />
              </div>
            </div>
          </div>
        )
      }}
    </FieldArray>
  )
}

const SubmitReportingPage = ({
  clientInfo,
  showClient,
  submitOngoingReporting,
  isExtendedRightsRole,
  isAdminLoggedAsClient,
  user,
  putNotification,
  getEmbedLink,
  hideBoxPreview,
}: IProps) => {
  const fieldsRef = useRef(null)
  const uploadContainerRef = useRef(null)

  const { id: clientId } = useParams<{ id: string }>()
  useEffect(() => {
    if (clientId) {
      showClient(clientId)
    }
  }, [clientId, showClient])
  const [selectedReportingDocument, setSelectedReportingDocument] = useState<IRequiredReport>(null)

  const handleSelectReportingDocument = useCallback(
    (document: IRequiredReport) => {
      hideBoxPreview()
      setSelectedReportingDocument(document)
    },
    [hideBoxPreview],
  )

  useEffect(() => {
    return () => {
      hideBoxPreview()
    }
  }, [hideBoxPreview])

  useSetPageTitle(clientId ? 'Ongoing Reporting' : 'Reporting')

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmitOngoingReporting = useCallback(
    async (data) => {
      setIsSubmitting(true)
      const { ...files } = data
      const formData = new FormData()

      Object.keys(files).forEach((type) => {
        // @ts-ignore
        files[type].forEach(({ file }, index) => {
          file instanceof File && formData.append(`files[${type}][${index}]`, file, file.name)
        })
      })

      const response = await submitOngoingReporting(clientId || 'me', formData)
      !response?.error && setIsSubmitted(true)
      setIsSubmitting(false)
    },
    [submitOngoingReporting, clientId],
  )

  const ongoingInitialValues = useMemo(() => ({}), [])

  const overdueReportingDocuments = useMemo(
    () =>
      clientInfo?.ongoingReportingDocuments?.filter(
        ({ status }) =>
          status &&
          [ReportingDocumentStatuses.Overdue, ReportingDocumentStatuses.Due].includes(status),
      ),
    [clientInfo],
  )
  const otherOngoingReportingDocuments = useMemo(
    () =>
      clientInfo?.ongoingReportingDocuments?.filter(
        ({ status }) =>
          !status ||
          ![ReportingDocumentStatuses.Overdue, ReportingDocumentStatuses.Due].includes(status),
      ),
    [clientInfo],
  )

  useEffect(() => {
    if (
      !selectedReportingDocument &&
      (overdueReportingDocuments?.length || otherOngoingReportingDocuments?.length)
    ) {
      setSelectedReportingDocument(
        [...(overdueReportingDocuments || []), ...(otherOngoingReportingDocuments || [])][0],
      )
    }
  }, [selectedReportingDocument, overdueReportingDocuments, otherOngoingReportingDocuments])

  const ORSchema = useMemo(() => {
    const files = {}
    if (clientInfo?.ongoingReportingDocuments?.length) {
      clientInfo?.ongoingReportingDocuments.forEach(({ name }) => {
        files[name] = Yup.array()
          .of(Yup.mixed())
          .test({
            name: 'requiredIf',
            exclusive: false,
            message: 'At least one file is required',
            test() {
              const { ...OngoingFiles } = this.parent
              // @ts-ignore
              return Object.values(OngoingFiles).some((items) => !!items?.length)
            },
          })
      })
    }

    return Yup.object().shape({
      ...files,
    })
  }, [clientInfo])

  const history = useHistory()

  const handleNavigate = useCallback(
    (path) => {
      history.push(path)
    },
    [history],
  )

  const handleFileUpload = useCallback((loadedFiles: File[]) => {
    if (!fieldsRef.current) {
      return
    }

    loadedFiles.forEach((file) => fieldsRef.current.push({ file }))
  }, [])

  const handleFileRejection = useCallback(
    (fileRejection: FileRejection[]) => {
      const firstError = fileRejection?.[0]?.errors?.[0]
      if (firstError) {
        putNotification({
          code: firstError.code,
          message:
            firstError.code === ErrorCode.FileTooLarge
              ? `File is too large, the maximum file size is ${humanReadableFileSize(
                  DEFAULT_MAX_FILE_SIZE,
                ).join(' ')}`
              : firstError.message,
          type: 'error',
        })
      }
    },
    [putNotification],
  )

  const handleFileDelete = useCallback((indexes: number[]) => {
    if (!fieldsRef.current) {
      return
    }

    indexes
      .sort((a, b) => b - a)
      .forEach((i) => {
        fieldsRef.current.remove(i)
      })
  }, [])

  const { getRootProps, getInputProps, open, isDragAccept, isDragReject } = useDropzone({
    noClick: true,
    maxSize: DEFAULT_MAX_FILE_SIZE,
    maxFiles: 99,
    multiple: true,
    noDragEventsBubbling: true,
    onDropAccepted: handleFileUpload,
    onDropRejected: handleFileRejection,
  })

  const getInsuranceTemplate = useCallback(
    async (type: string) => {
      if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
        const link = EMBEDDED_LINK_TYPES[type].dev
        if (link) {
          await getEmbedLink(link)
        }
      } else {
        const link = EMBEDDED_LINK_TYPES[type].prod
        if (link) {
          await getEmbedLink(link)
        }
      }
    },
    [getEmbedLink],
  )

  const helperText = useMemo(() => {
    if (!selectedReportingDocument) {
      return null
    }

    const currentThroughDate =
      moment().date() >= 20 ? moment().subtract(1, 'month') : moment().subtract(2, 'month')

    const dueDateThroughDate = selectedReportingDocument.nextDueDate
      ? moment(selectedReportingDocument.nextDueDate).startOf('month').subtract(1, 'month')
      : null

    const finalThroughDate = dueDateThroughDate
      ? moment.max(dueDateThroughDate, currentThroughDate).format('MMMM YYYY')
      : currentThroughDate.format('MMMM YYYY')

    const firstMonthRequired = selectedReportingDocument.reportingThoughDate
      ? moment(selectedReportingDocument.reportingThoughDate)
          .startOf('month')
          .add(1, 'month')
          .format('MMMM YYYY')
      : moment(finalThroughDate).startOf('month').subtract(11, 'month').format('MMMM YYYY')

    const areDatesEqual = moment(firstMonthRequired).isSame(finalThroughDate)

    switch (selectedReportingDocument.name) {
      case REPORTING_DOCUMENT_TYPES.salesBySKU:
      case REPORTING_DOCUMENT_TYPES.financials:
        if (areDatesEqual) {
          return `Please upload for ${firstMonthRequired}, broken out by month.`
        }
        return `Please upload for ${firstMonthRequired} through ${finalThroughDate}, broken out by month.`
      case REPORTING_DOCUMENT_TYPES.arGeneralLedger:
        if (areDatesEqual) {
          return `Please upload for ${firstMonthRequired}.`
        }
        return `Please upload for ${firstMonthRequired} through ${finalThroughDate}.`
      case REPORTING_DOCUMENT_TYPES.coi:
        return (
          <span>
            Refer to the{' '}
            <span
              className={styles.sampleLink}
              onClick={() => getInsuranceTemplate(REPORTING_DOCUMENT_TYPES.coi)}
            >
              Sample COI
            </span>{' '}
            for guidance on what will need to be included on the updated version. Please reach out
            to your relationship manager with any questions.
          </span>
        )
      case REPORTING_DOCUMENT_TYPES.eop:
        return (
          <span>
            Refer to the{' '}
            <span
              className={styles.sampleLink}
              onClick={() => getInsuranceTemplate(REPORTING_DOCUMENT_TYPES.eop)}
            >
              Sample EOP
            </span>{' '}
            for guidance on what will need to be included on the updated version. Ensure that
            finished goods at cost at each eligible inventory location have appropriate coverage.
            Please reach out to your relationship manager with any questions.
          </span>
        )
    }

    return null
  }, [selectedReportingDocument, getInsuranceTemplate])

  if (!clientInfo) {
    return <SubmitReportingPageLoader />
  }

  if ([ClientInfoStatus.Past, ClientInfoStatus.Archived].includes(clientInfo?.clientStatus)) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={styles.cardInProgressWrapper}
      >
        <Card className={cn(styles.cardInProgress, genericSs.textCenter)}>
          <ActionPendingIcon className={styles.iconPending} />
          <div className={cn(styles.actionTitle, genericSs.colorPrimary)}>Submit Reporting</div>
          <div className={styles.descriptionInProgress}>
            Submitting reporting is not allowed after payoff date
          </div>

          <Button
            variant="contained"
            color="primary"
            size="small"
            // @ts-ignore
            component={Link}
            to={clientId ? ROUTES.CLIENT_REPORTING : ROUTES.HOMEPAGE}
          >
            Return to Dashboard
          </Button>
        </Card>
      </Box>
    )
  }

  return (
    <div>
      <Box py={1} pr={2}>
        <Card noHeaderMargin withBorder={false} noPadding>
          <Grid item container xs={12} pb={2}>
            <Grid container item xs={12} alignItems="center">
              {!clientId && <span className={styles.title}>Ongoing Reporting</span>}
            </Grid>
          </Grid>
        </Card>

        {clientInfo?.ongoingReportingDocuments && (
          <Form
            initialValues={ongoingInitialValues}
            onSubmit={handleSubmitOngoingReporting}
            validate={makeValidate(ORSchema)}
            mutators={mutators}
            render={({
              values,
              invalid,
              handleSubmit,
              form,
            }: {
              values: any
              invalid: boolean
              handleSubmit: any
              errors: any
              form: any
            }) => (
              <>
                <Grid item container xs={12} justifyContent="center">
                  <div className={styles.reportingDocumentsContainer}>
                    <div
                      className={cn(styles.reportingDocumentsContainerListWrapper, {
                        [styles.reportingDocumentsContainerListWrapperShort]: isAdminLoggedAsClient,
                      })}
                    >
                      <div className={styles.reportingDocumentsContainerList}>
                        {overdueReportingDocuments.map((item) => (
                          <OngoingReportingDocument
                            key={item.name}
                            item={item}
                            isExtendedRightsRole={isExtendedRightsRole}
                            user={user}
                            isOverdue={true}
                            isSelected={selectedReportingDocument?.id === item.id}
                            onSelect={handleSelectReportingDocument}
                            fieldsRef={fieldsRef}
                          />
                        ))}
                        {otherOngoingReportingDocuments?.length > 0 &&
                          otherOngoingReportingDocuments.map((item) => (
                            <OngoingReportingDocument
                              key={item.name}
                              item={item}
                              isExtendedRightsRole={isExtendedRightsRole}
                              user={user}
                              isOverdue={false}
                              isSelected={selectedReportingDocument?.id === item.id}
                              onSelect={handleSelectReportingDocument}
                              fieldsRef={fieldsRef}
                            />
                          ))}
                      </div>
                    </div>

                    {selectedReportingDocument && (
                      <div className={styles.documentsUploadWrapper} ref={uploadContainerRef}>
                        <Slide
                          key={selectedReportingDocument.id}
                          direction="right"
                          in
                          timeout={700}
                          mountOnEnter
                          unmountOnExit
                          container={uploadContainerRef.current}
                        >
                          <div className={styles.documentsUpload}>
                            {helperText && (
                              <div className={genericSs.warningMessage}>{helperText}</div>
                            )}
                            <UploadFileManagement
                              files={
                                values[selectedReportingDocument.name]?.map(
                                  ({ file }: { file: File }) => file,
                                ) || []
                              }
                              onDelete={handleFileDelete}
                              isDragAccept={isDragAccept}
                              isDragReject={isDragReject}
                              getRootProps={getRootProps}
                              getInputProps={getInputProps}
                              open={open}
                              handleDelete={handleFileDelete}
                              dropzoneText="Drop files here or "
                            />
                          </div>
                        </Slide>
                      </div>
                    )}
                  </div>
                </Grid>

                <Grid item container xs={12} justifyContent="center">
                  <div className={styles.submitButtonContainer}>
                    <Box mt={3} display="flex" justifyContent={'flex-end'}>
                      <Button
                        className={cn(styles.submitButton, {
                          [styles.submitButtonDisabled]: invalid,
                        })}
                        disabled={invalid}
                        type="button"
                        onClick={async () => {
                          await handleSubmit()
                          form.reset()
                        }}
                        color="primary"
                        variant="contained"
                        size="small"
                        isLoading={isSubmitting}
                      >
                        Submit
                      </Button>
                    </Box>
                  </div>
                </Grid>

                <RouteLeavingGuard
                  when={form.getState().dirty}
                  navigate={handleNavigate}
                  shouldBlockNavigation={() => form.getState().dirty}
                  helperText="You have not submitted your documents. Are you sure you want to leave?"
                  buttonText="Submit documents"
                  alternateSubmit={async () => {
                    await handleSubmit()
                    form.reset()
                  }}
                  isAlternateSubmitInvalid={form.getState().invalid || isSubmitting}
                />
              </>
            )}
          />
        )}
      </Box>

      {isSubmitted && (
        <Modal open={isSubmitted} onCancel={() => setIsSubmitted(false)}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={styles.cardInProgressWrapper}
          >
            <Grid container xs={12} spacing={1} justifyContent={'center'} alignItems={'center'}>
              <Grid item xs={12} justifyContent={'center'} display={'flex'}>
                <ActionSuccessIcon className={styles.iconSuccess} />
              </Grid>
              <Grid item xs={12}>
                <div className={cn(styles.actionTitle)}>Document(s) submitted</div>
              </Grid>
              <Grid item xs={12} justifyContent={'center'} display={'flex'}>
                <div className={cn(genericSs.textCenter, styles.descriptionInProgress)}>
                  Thank you! Compliant reporting helps avoid funding interruption
                </div>
              </Grid>
            </Grid>
          </Box>
          <Grid
            container
            item
            xs={12}
            lg={12}
            justifyContent={'center'}
            display={'flex'}
            className={styles.confirmButtonContainer}
          >
            <Button
              className={styles.submitModalButton}
              variant="contained"
              color="primary"
              size="small"
              // @ts-ignore
              component={Link}
              to={clientId ? ROUTES.CLIENT_REPORTING : ROUTES.HOMEPAGE}
            >
              Back to dashboard
            </Button>
          </Grid>
        </Modal>
      )}
    </div>
  )
}

export default SubmitReportingPage
