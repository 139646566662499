import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import {
  generatePath,
  useHistory,
  matchPath,
  useLocation,
  Link as RouterLink,
} from 'react-router-dom'
import { useHotkeys } from 'react-hotkeys-hook'
import { AutocompleteProps } from 'mui-rff'
import MuiAutocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import CloseIcon from '@mui/icons-material/Close'
import CircularProgress from '@mui/material/CircularProgress'
import MuiAvatar from '@mui/material/Avatar'
import Link from '@mui/material/Link'
import sortBy from 'lodash/orderBy'
import cn from 'classnames'

import styles from './GlobalSearch.module.scss'
import genericSs from '@styles/generic.module.scss'

import { ReactComponent as SearchIcon } from '@assets/images/global-search-icon.svg'
import { ReactComponent as FundingIcon } from '@assets/images/money-icon-oulined.svg'
import { ReactComponent as ClientIcon } from '@assets/images/people-icon.svg'
import { ReactComponent as CollectionIcon } from '@assets/images/collections-icon.svg'
import { ReactComponent as FeeIcon } from '@assets/images/bill-icon.svg'
import { ReactComponent as NoteIcon } from '@assets/images/description-icon.svg'
import { ReactComponent as OngoingReportingIcon } from '@assets/images/pie-chart-icon.svg'
import { ReactComponent as UserIcon } from '@assets/images/user-management-icon.svg'
import { ReactComponent as EntityIcon } from '@assets/images/briefcase-icon.svg'
import { ReactComponent as NavigateIcon } from '@assets/images/chevron-forward-icon.svg'
import { ISearchResult, ISearchResultItem, SearchType } from '@common/interfaces/search'
import { ROUTES } from '../../constants/routes'
import { debounceEventHandler, highlightsMatches } from '../../helpers/helpers'
import { ClientInfoStatus } from '@common/interfaces/client'
import { UserRole } from '@common/interfaces/user'
import { REPORTING_DOCUMENT_TYPES } from '@common/constants/client'
import {
  seperateClientStatusResults,
  organizeResultsBySectionBasedOnSearchTerm,
} from './GlobalSearch.helpers'

export const TYPE_ICONS = {
  [SearchType.Funding]: FundingIcon,
  [SearchType.Client]: ClientIcon,
  [SearchType.Prospect]: ClientIcon,
  [SearchType.DueDiligence]: ClientIcon,
  [SearchType.Collection]: CollectionIcon,
  [SearchType.Fee]: FeeIcon,
  [SearchType.Note]: NoteIcon,
  [SearchType.OngoingReporting]: OngoingReportingIcon,
  [SearchType.User]: UserIcon,
  [SearchType.Entity]: EntityIcon,
  [SearchType.Participant]: EntityIcon,
}

interface IProps extends Partial<AutocompleteProps<ISearchResultItem, false, false, true>> {
  isLoading: boolean
  isBDO: boolean
  search: (data: object) => Promise<{ data: ISearchResult[] }>
}

const replaceJSX = (str: any, find: string) => {
  const replacedStr = highlightsMatches(str, find)

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: replacedStr,
      }}
    />
  )
}

const GlobalSearchOption = ({
  props,
  option,
  inputValue,
}: {
  props: any
  option: any
  inputValue: string
}) => {
  const renderTitle = useCallback((title: string) => replaceJSX(title, inputValue), [inputValue])
  const iconUrl = useMemo(
    () =>
      [SearchType.Client, SearchType.Prospect, SearchType.DueDiligence].includes(option.type)
        ? option.item.iconUrl
        : [SearchType.User].includes(option.type)
        ? option.item.avatar
        : null,
    [option],
  )
  const Icon = useMemo(() => TYPE_ICONS[option.type] || SearchIcon, [option])

  return (
    <Link component={RouterLink} to={option.link}>
      <ListItem {...props} key={option.id} className={styles.listItem} dense>
        <ListItemIcon className={styles.listItemIcon}>
          {iconUrl ? (
            <MuiAvatar src={iconUrl} alt={option.title} className={styles.avatar} />
          ) : (
            <Icon
              className={cn(styles.listItemIconSvg, {
                [styles.listItemIconFunding]: option.type === SearchType.Funding,
              })}
            />
          )}
        </ListItemIcon>
        <ListItemText
          classes={{
            root: styles.listItemText,
            primary: styles.listItemTextPrimary,
            secondary: styles.listItemTextSecondary,
          }}
          primary={renderTitle(option.title)}
          secondary={
            option.type === SearchType.Client ? (
              <span>
                Client
                {option.status !== ClientInfoStatus.Current && (
                  <span className={genericSs.grayCard}>{option.status}</span>
                )}
              </span>
            ) : (
              renderTitle(option.status)
            )
          }
        />
        <NavigateIcon className={styles.listItemNavigate} />
      </ListItem>
    </Link>
  )
}

const GlobalSearchSeeAll = ({ element }: { element: any }) => {
  if (!element) {
    return null
  }

  return ReactDOM.createPortal(
    <Link component={RouterLink} to={ROUTES.GLOBAL_SEARCH} className={styles.seeAllButton}>
      See all results
    </Link>,
    element,
  )
}

const GlobalSearch = ({ isLoading, isBDO, search }: IProps) => {
  const ref = useRef(null)
  const [asyncOptions, setAsyncOptions] = useState<ISearchResultItem[]>([])
  const [inputValue, setInputValue] = useState('')
  const history = useHistory()
  const location = useLocation()
  const inputref = useRef<HTMLInputElement>(null)
  const [listboxElement, setListboxElement] = useState(null)
  const [open, setOpen] = useState(false)

  const isGlobalSearchPage = useMemo(
    () => !!matchPath(location.pathname, ROUTES.GLOBAL_SEARCH),
    [location],
  )

  useHotkeys(
    'ctrl+shift+f',
    (event) => {
      event.preventDefault()
      inputref?.current?.focus()
    },
    { enabled: true },
  )

  const loadResults = useCallback(
    async (value: string) => {
      const res = await search({
        search: value,
      })
      let sortedResult = [...res.data]

      sortedResult = sortBy(sortedResult, 'maxScore', 'desc')
      sortedResult = seperateClientStatusResults(sortedResult)
      sortedResult = organizeResultsBySectionBasedOnSearchTerm(sortedResult, value)

      const results: ISearchResultItem[] = []
      sortedResult
        .map(({ items }) => items)
        .flat()
        .forEach((data: any) => {
          let link: string
          switch (data.type) {
            case SearchType.Client:
              link = generatePath(ROUTES.CLIENT_PAGE, { id: data.id })
              break
            case SearchType.DueDiligence:
              link = generatePath(ROUTES.DUE_DILIGENCE, { id: data.id })
              break
            case SearchType.Prospect:
              link = data.item.firstOPSReportingId
                ? generatePath(ROUTES.PROSPECT_PAGE, { id: data.item.firstOPSReportingId })
                : null
              break
            case SearchType.Funding:
              link = generatePath(ROUTES.BBC_SUMMARY, { id: data.id })
              break
            case SearchType.Collection:
              link = generatePath(ROUTES.CLIENT_PAGE, { id: data.item.clientId })
              break
            case SearchType.Fee:
              link = generatePath(ROUTES.CLIENT_PAGE, { id: data.item.clientId })
              break
            case SearchType.Note:
              link = data.item.borrowingBaseId
                ? generatePath(ROUTES.BBC_SUMMARY, { id: data.item.borrowingBaseId })
                : data.item.ongoingReportingId
                ? generatePath(ROUTES.ONGOING_REPORTING, { id: data.item.ongoingReportingId })
                : data.item.opsReportingId
                ? generatePath(ROUTES.PROSPECT_PAGE, { id: data.item.opsReportingId })
                : data.item.clientId
                ? generatePath(ROUTES.CLIENT_PAGE, { id: data.item.clientId })
                : ''
              break
            case SearchType.OngoingReporting:
              if (
                [
                  REPORTING_DOCUMENT_TYPES.financials,
                  REPORTING_DOCUMENT_TYPES.financialProjections,
                ].includes(data.item.type)
              ) {
                link = generatePath(ROUTES.ONGOING_REPORTING, { id: data.item.id })
              } else if (REPORTING_DOCUMENT_TYPES.salesBySKU === data.item.type) {
                link = generatePath(ROUTES.ONGOING_REPORTING_SALES_BY_SKU, { id: data.item.id })
              } else if (REPORTING_DOCUMENT_TYPES.arGeneralLedger === data.item.type) {
                link = generatePath(ROUTES.ONGOING_REPORTING_AR_GENERAL_LEDGER, {
                  id: data.item.id,
                })
              } else if (REPORTING_DOCUMENT_TYPES.bankTransactions === data.item.type) {
                link = generatePath(ROUTES.ONGOING_REPORTING_BANK_TRANSACTIONS_UPLOAD, {
                  id: data.item.id,
                })
              } else if (REPORTING_DOCUMENT_TYPES.capTable === data.item.type) {
                link = generatePath(ROUTES.ONGOING_REPORTING_CAP_TABLE_UPLOAD, { id: data.item.id })
              }
              break
            case SearchType.User:
              link =
                [UserRole.CLIENT_USER].includes(data.item.role) && data.item.clientId
                  ? generatePath(ROUTES.CLIENT_SETUP_USERS, { id: data.item.clientId })
                  : ROUTES.USER_MANAGEMENT
              break
            case SearchType.Entity:
              link = generatePath(ROUTES.ENTITY_PAGE, { id: data.id })
              break
            case SearchType.Participant:
              link = generatePath(ROUTES.PARTICIPANTS_PAGE, { id: data.id })
              break
            default:
              link = ''
          }

          results.push({
            link,
            title: data.title,
            id: data.id,
            type: data.type,
            status: data.status,
            item: data.item,
          })
        })
      return results
    },
    [search],
  )

  const handleInputChange = useCallback(
    async (event: React.ChangeEvent<{}>, value: string, reason: 'input' | 'reset' | 'clear') => {
      if (reason === 'input') {
        const loadedOptions = await loadResults(value)
        setAsyncOptions(loadedOptions)
      }
    },
    [loadResults],
  )

  const debounceHandleInputChange = useMemo(
    () => debounceEventHandler(handleInputChange, 500),
    [handleInputChange],
  )

  const handleInputClear = useCallback(() => {
    setInputValue('')
    setAsyncOptions([])
  }, [])

  const onChangeInput = useCallback((e) => setInputValue(e.target.value), [])

  const handleSelectOption = useCallback(
    (event, newValue: any, reason) => {
      if (reason === 'selectOption') {
        handleInputClear()
        inputref?.current?.blur()
      }
    },
    [inputref, handleInputClear],
  )

  useEffect(() => {
    if (!asyncOptions) {
      setListboxElement(null)
      return
    }
    setListboxElement(ref.current.querySelector('[role="presentation"] > div'))
  }, [asyncOptions])

  const handleFocus = useCallback(() => {
    setTimeout(() => {
      const element = ref.current.querySelector('[role="presentation"] > div')
      if (element) {
        setListboxElement(element)
      }
    }, 0)
  }, [])

  const handleOpen = useCallback(() => {
    if (!isGlobalSearchPage) {
      setOpen(true)
    }
  }, [isGlobalSearchPage])

  const handleClose = useCallback((event: React.SyntheticEvent, reason: string) => {
    if (reason === 'blur') {
      setTimeout(() => {
        setOpen(false)
      }, 200)
    } else {
      setOpen(false)
    }
  }, [])

  const handleKeyPress = useCallback(
    (event) => {
      if (event.keyCode === 13) {
        if (!isGlobalSearchPage) {
          if (asyncOptions.length) {
            if (listboxElement) {
              const focusedItemLink = listboxElement
                .querySelector('[class*="Mui-focused"]')
                ?.closest('a')
                ?.getAttribute('href')
              if (focusedItemLink) {
                history.push(focusedItemLink)
                return
              }
            }
            history.push(ROUTES.GLOBAL_SEARCH)
            handleInputClear()
          }
        }
      }
    },
    [history, isGlobalSearchPage, asyncOptions, listboxElement, handleInputClear],
  )

  return (
    <div ref={ref} className={styles.container}>
      <MuiAutocomplete
        open={open}
        key={1}
        disablePortal
        options={asyncOptions}
        getOptionLabel={(option: any) => option?.title || ''}
        className={cn(styles.inputRoot)}
        classes={{
          root: styles.root,
          input: styles.input,
          tag: styles.tag,
          listbox: styles.listbox,
          option: styles.option,
          popupIndicator: styles.popupIndicator,
          popupIndicatorOpen: styles.popupIndicatorOpen,
          paper: styles.paper,
          popper: styles.popper,
          focused: styles.focused,
          groupLabel: styles.groupLabel,
          clearIndicator: styles.clearIcon,
          endAdornment: styles.endAdornment,
        }}
        clearIcon={<CloseIcon onClick={handleInputClear} />}
        renderOption={(props: any, option: any) => (
          <GlobalSearchOption
            key={`${option.type}-${option.id}`}
            props={props}
            option={option}
            inputValue={inputValue}
          />
        )}
        filterOptions={(options) => options}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            onChange={onChangeInput}
            placeholder="Search [Ctl+Shift+F]"
            InputProps={{
              ...params.InputProps,
              className: cn(styles.inputField, styles.inputFieldMediumHeight),
              classes: {
                focused: styles.focusedInput,
              },
              disableUnderline: true,
              startAdornment:
                !isGlobalSearchPage && isLoading ? (
                  <CircularProgress size={16} className={styles.inputLoaderIcon} />
                ) : (
                  <SearchIcon className={styles.inputIcon} />
                ),
              inputRef: inputref,
              onKeyDown: handleKeyPress,
            }}
          />
        )}
        freeSolo
        inputValue={inputValue}
        groupBy={(option: any) => option.type}
        onInputChange={debounceHandleInputChange}
        openOnFocus
        onFocus={handleFocus}
        noOptionsText="No results"
        onChange={handleSelectOption}
        onOpen={handleOpen}
        onClose={handleClose}
      />
      {!isBDO && <GlobalSearchSeeAll element={asyncOptions.length > 0 && listboxElement} />}
    </div>
  )
}

export default GlobalSearch
