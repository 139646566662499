import EstimatedHistoricalLineTurn from './EstimatedHistoricalLineTurn'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import {
  historicalLineTurnData,
  historicalLineTurnOptionsData,
} from '../../redux/dueDiligence/selectors'
import {
  getHistoricalLineTurn,
  getHistoricalLineTurnOptions,
} from '../../redux/dueDiligence/actions'
const selector = createStructuredSelector({
  historicalLineTurnData,
  historicalLineTurnOptionsData,
})

const actions = {
  getHistoricalLineTurn,
  getHistoricalLineTurnOptions,
}

export default connect(selector, actions)(EstimatedHistoricalLineTurn)
