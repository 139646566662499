import React from 'react'
import { formatPrice, formatPercent } from '../../helpers/helpers'
import { IInvestorAggregationData } from '@common/interfaces/entityInfo'
import Grid from '@mui/material/Grid'
import styles from './EntityDashboardHeader.module.scss'
import { ReactComponent as MetricSummary } from '../../assets/images/chart-line.svg'

interface IProps {
  investorData?: IInvestorAggregationData
}

const InvestorDashboardSummaryMetrics = ({ investorData }: IProps) => {
  return (
    <Grid item className={styles.headerContainer} xs={6} lg={6}>
      {investorData && (
        <div className={styles.dashboardBlock}>
          <div className={styles.dashboardBlockTitle}>
            <MetricSummary className={styles.dashboardBlockContentIcon} />
            Summary Metrics
          </div>
          <Grid container spacing={6} className={styles.gridContainer}>
            <Grid item xs={12} lg={12} className={styles.blockContainer}>
              <div className={styles.gridContainerWrapper}>
                <div className={styles.dashboardBlockContent}>
                  <div className={styles.dashboardBlockContentLabel}>Total Investments</div>
                  <div className={styles.dashboardBlockContentValue}>
                    ${formatPrice(investorData.totalInvestment)}
                  </div>
                </div>
                <div className={styles.dashboardBlockContent}>
                  <div className={styles.dashboardBlockContentLabel}>Total Clients</div>
                  <div className={styles.dashboardBlockContentValue}>
                    {investorData.totalClients}
                  </div>
                </div>
                <div className={styles.dashboardBlockContent}>
                  <div className={styles.dashboardBlockContentLabel}>Total Prospects</div>
                  <div className={styles.dashboardBlockContentValue}>
                    {investorData.totalProspects}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={12} className={styles.blockContainerInvestments}>
              <div className={styles.gridContainerWrapperMetrics}>
                <div className={styles.dashboardBlockContent}>
                  <div className={styles.dashboardBlockContentLabel}>Total Board Seats</div>
                  <div className={styles.dashboardBlockContentValue}>
                    {investorData.totalBoardSeats}
                  </div>
                </div>
                <div className={styles.dashboardBlockContent}>
                  <div className={styles.dashboardBlockContentLabel}>Avg. Investment</div>
                  <div className={styles.dashboardBlockContentValue}>
                    ${formatPrice(investorData.averageInvestment)}
                  </div>
                </div>
                <div className={styles.dashboardBlockContent}>
                  <div className={styles.dashboardBlockContentLabel}>
                    Avg. Fully Diluted Ownership
                  </div>
                  <div className={styles.dashboardBlockContentValue}>
                    {formatPercent(investorData.avgFullyDilutedOwnership)}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </Grid>
  )
}

export default InvestorDashboardSummaryMetrics
