import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useParams } from 'react-router'
import Skeleton from '@mui/material/Skeleton'
import styles from './ClientCashFlow.module.scss'
import TextField from '../../Common/TextField'
import SelectField from '../../Common/SelectField'
import {
  FinancialsType,
  REPORTING_PERIOD_OPTIONS,
  ReportingFlow,
  ReportingPeriods,
} from '@common/interfaces/bbc'
import { ILoadingData } from '../../../redux/types'
import { IClientCashFlowData } from '@common/interfaces/client'
import {
  debounceEventHandler,
  formatDateMonthYear,
  handleMultipleSelect,
} from '../../../helpers/helpers'
import { ExpandAndMinimize } from '../../Common/Icons'
import FullscreenModal from '../../Common/FullscreenModal'
import DatePicker from '../../Common/DatePicker'
import ClientCashFlowGraph from './ClientCashFlowGraph'
import ClientCashFlowTable from './ClientCashFlowTable'
import { CATEGORIES } from '@common/constants/tracking'
import useTrackVisualizationsTableChartSelection from '../../../hooks/useTrackVisualizationsTableChartSelection'
import useTrackVisualizationsTable from '../../../hooks/useTrackVisualizationsTable'
import useGraphToggle from '../../../hooks/useGraphTogggle'
import Empty from '../../Common/Empty/Empty'

const options = [
  {
    value: FinancialsType.Actuals,
    label: FinancialsType.Actuals,
  },
  {
    value: FinancialsType.Projections,
    label: FinancialsType.Projections,
  },
]

interface IProps {
  datesBoundary?: { minDate: string; maxDate: string } | null
  currentReportingPeriod: ReportingPeriods
  currentOptions: FinancialsType[]
  currentDateRange: { startDate: string; endDate: string }
  cashFlowInfo: ILoadingData<IClientCashFlowData>
  reportingFlow: ReportingFlow
  showCashFlowInfo: (id: string, params: object) => void
  setCurrentReportingPeriod: Dispatch<SetStateAction<ReportingPeriods>>
  setCurrentOptions: Dispatch<SetStateAction<FinancialsType[]>>
  setCurrentDateRange: Dispatch<SetStateAction<{ startDate: string; endDate: string }>>
}

const ClientCashFlow = ({
  datesBoundary,
  currentReportingPeriod,
  currentOptions,
  currentDateRange,
  cashFlowInfo,
  reportingFlow,
  showCashFlowInfo,
  setCurrentReportingPeriod,
  setCurrentOptions,
  setCurrentDateRange,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const { isLoading, asOfDate, numberOfMonthLast, numberOfMonthNext, summary, headers, data } =
    useMemo(
      () => ({
        isLoading: cashFlowInfo.isLoading,
        asOfDate: cashFlowInfo.data?.asOfDate,
        numberOfMonthLast: cashFlowInfo.data?.numberOfMonthLast,
        numberOfMonthNext: cashFlowInfo.data?.numberOfMonthNext,
        summary: cashFlowInfo.data?.summary || {},
        headers: cashFlowInfo.data?.headers || [],
        data: cashFlowInfo.data?.data || [],
      }),
      [cashFlowInfo],
    )
  const [numberOfMonths, setNumberOfMonths] = useState(3)
  const [activeItem, setActiveItem] = useState<number>()
  const [activeItems, setActiveItems] = useState([])
  const wrapperRef = useRef(null)
  const [isModalShown, setIsModalShown] = useState(false)

  const { TabsComponent, isGraphShown } = useGraphToggle({})

  const handleSelectRow = useMemo(
    () => handleMultipleSelect(setActiveItems, setActiveItem, activeItems, activeItem),
    [activeItems, activeItem],
  )
  const resetActiveItems = useCallback(() => setActiveItems([]), [])

  const debounceShowCashFlowInfo = useMemo(
    () =>
      debounceEventHandler((params: any) => {
        showCashFlowInfo(id, params)
      }, 500),
    [id, showCashFlowInfo],
  )

  useEffect(() => {
    debounceShowCashFlowInfo({
      options: currentOptions,
      numberOfMonths,
      reportingPeriod: currentReportingPeriod,
      startDate: currentDateRange.startDate,
      endDate: currentDateRange.endDate,
    })
  }, [
    debounceShowCashFlowInfo,
    currentOptions,
    numberOfMonths,
    currentReportingPeriod,
    currentDateRange,
  ])

  const handleReportingPeriodChange = useCallback(
    ({ target: { value } }) => {
      setCurrentReportingPeriod(value)
    },
    [setCurrentReportingPeriod],
  )

  const handleStatementTypeChange = useCallback(
    ({ target: { value } }) => {
      if (!value.length) {
        setCurrentOptions([FinancialsType.Actuals])
      } else {
        setCurrentOptions(value)
      }
    },
    [setCurrentOptions],
  )

  const handleNumberOfMonthChange = useCallback(
    ({ target: { value } }) => {
      const maxNumberOfMonth =
        currentOptions.length > 1
          ? Math.min(numberOfMonthLast, numberOfMonthNext)
          : currentOptions[0] === FinancialsType.Actuals
          ? numberOfMonthLast
          : numberOfMonthNext

      setNumberOfMonths(
        value ? (+value && value <= maxNumberOfMonth ? +value : maxNumberOfMonth) : 1,
      )
    },
    [currentOptions, numberOfMonthLast, numberOfMonthNext],
  )

  const toggleFullScreenModal = useCallback(() => {
    if (isModalShown) {
      setIsModalShown(false)
    } else {
      setIsModalShown(true)
    }
  }, [isModalShown])

  const isInitialized = useMemo(() => !!cashFlowInfo?.data?.data, [cashFlowInfo])
  const visualizationsCategory = useMemo(
    () => (isGraphShown ? CATEGORIES.cashFlowChart : CATEGORIES.cashFlowTable),
    [isGraphShown],
  )
  const visualizationsParams = useMemo(
    () => ({
      clientId: id,
    }),
    [id],
  )
  const visualizationsFilters = useMemo(
    () => ({
      options: currentOptions,
      numberOfMonths,
      reportingPeriod: currentReportingPeriod,
      startDate: currentDateRange.startDate,
      endDate: currentDateRange.endDate,
    }),
    [
      currentOptions,
      numberOfMonths,
      currentReportingPeriod,
      currentDateRange.startDate,
      currentDateRange.endDate,
    ],
  )
  useTrackVisualizationsTableChartSelection({
    isInitialized,
    category: visualizationsCategory,
    params: visualizationsParams,
    isChart: isGraphShown,
  })
  useTrackVisualizationsTable({
    isInitialized,
    category: visualizationsCategory,
    params: visualizationsParams,
    filters: visualizationsFilters,
  })

  const isDD = useMemo(() => reportingFlow === ReportingFlow.DueDiligence, [reportingFlow])

  const isDataEmpty = useMemo(() => !isLoading && !headers.length, [isLoading, headers])

  return (
    <FullscreenModal
      isOpen={isModalShown}
      setIsOpen={setIsModalShown}
      classes={{ body: styles.fullScreenModal }}
    >
      <div className={styles.container} ref={wrapperRef}>
        <div className={styles.header}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Cash Flow</div>
            <div className={styles.subTitle}>
              {asOfDate ? (
                <span>as of {formatDateMonthYear(asOfDate)}</span>
              ) : (
                <Skeleton className={styles.subTitleMonthLoader} width={100} height={20} />
              )}
            </div>
          </div>
          <div className={styles.inputContainer}>
            <TextField
              useFinalForm={false}
              className={styles.textField}
              name="numberOfMonths"
              label="Summary Months"
              InputProps={{
                type: 'number',
              }}
              disabled={isLoading}
              value={numberOfMonths}
              onChange={handleNumberOfMonthChange}
            />
            {!isDD && (
              <div className={styles.selectFieldWrapper}>
                <SelectField
                  useFinalForm={false}
                  value={currentOptions}
                  onChange={handleStatementTypeChange}
                  name="option"
                  className={styles.selectField}
                  label="Statement Type"
                  variant="outlined"
                  options={options}
                  disabled={isLoading}
                  fullWidth={false}
                  multiple
                />
              </div>
            )}
            <div className={styles.selectFieldWrapper}>
              <SelectField
                useFinalForm={false}
                name="reportingPeriod"
                value={currentReportingPeriod}
                onChange={handleReportingPeriodChange}
                className={styles.selectField}
                label="Period"
                variant="outlined"
                options={REPORTING_PERIOD_OPTIONS}
                disabled={isLoading}
                fullWidth={false}
              />
            </div>
            <DatePicker
              reportingPeriod={currentReportingPeriod}
              datesBoundary={datesBoundary}
              currentDateRange={currentDateRange}
              onChange={setCurrentDateRange}
            />
            {TabsComponent}
            <ExpandAndMinimize action={toggleFullScreenModal} isExpanded={isModalShown} />
          </div>
        </div>
        {isDataEmpty ? (
          <Empty message="No cash flow exists for this client" height="40vh" />
        ) : isGraphShown ? (
          <ClientCashFlowGraph
            isModalShown={isModalShown}
            headers={headers}
            data={data}
            reportingPeriod={currentReportingPeriod}
          />
        ) : (
          <ClientCashFlowTable
            isLoading={isLoading}
            isModalShown={isModalShown}
            headers={headers}
            summary={summary}
            data={data}
            numberOfMonths={numberOfMonths}
            activeItem={activeItem}
            activeItems={activeItems}
            setActiveItem={setActiveItem}
            setActiveItems={setActiveItems}
            handleSelectRow={handleSelectRow}
            resetActiveItems={resetActiveItems}
            wrapperRef={wrapperRef}
          />
        )}
      </div>
    </FullscreenModal>
  )
}

export default ClientCashFlow
