import React, { useMemo } from 'react'
import { generatePath, Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import LinkButton from '@mui/material/Link'
import MuiAvatar from '@mui/material/Avatar'
import cn from 'classnames'

import styles from './GlobalSearchPage.module.scss'
import genericSs from '@styles/generic.module.scss'

import {
  IOngoingReporting,
  OngoingReportingStatus,
  REPORTING_STATUS_LABEL,
} from '@common/interfaces/bbc'
import { REPORTING_DOCUMENT_TYPES } from '@common/constants/client'
import { ROUTES } from '../../constants/routes'
import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import { formatDate } from '../../helpers/helpers'
import { replaceJSX } from './GlobalSearchPage'

const GlobalSearchPageTableRowOngoingReporting = ({
  ongoingReporting,
  searchString,
}: {
  ongoingReporting: IOngoingReporting
  searchString: string
}) => {
  const link = useMemo(() => {
    if (!ongoingReporting?.id) {
      return null
    }

    if (
      [REPORTING_DOCUMENT_TYPES.financials, REPORTING_DOCUMENT_TYPES.financialProjections].includes(
        ongoingReporting.type,
      )
    ) {
      return generatePath(ROUTES.ONGOING_REPORTING, { id: ongoingReporting.id })
    } else if (REPORTING_DOCUMENT_TYPES.salesBySKU === ongoingReporting.type) {
      return generatePath(ROUTES.ONGOING_REPORTING_SALES_BY_SKU, { id: ongoingReporting.id })
    } else if (REPORTING_DOCUMENT_TYPES.arGeneralLedger === ongoingReporting.type) {
      return generatePath(ROUTES.ONGOING_REPORTING_AR_GENERAL_LEDGER, {
        id: ongoingReporting.id,
      })
    } else if (REPORTING_DOCUMENT_TYPES.bankTransactions === ongoingReporting.type) {
      return generatePath(ROUTES.ONGOING_REPORTING_BANK_TRANSACTIONS_UPLOAD, {
        id: ongoingReporting.id,
      })
    } else if (REPORTING_DOCUMENT_TYPES.capTable === ongoingReporting.type) {
      return generatePath(ROUTES.ONGOING_REPORTING_CAP_TABLE_UPLOAD, { id: ongoingReporting.id })
    }

    return null
  }, [ongoingReporting])
  const statusStyle = useMemo(() => {
    switch (ongoingReporting.status) {
      case OngoingReportingStatus.NotStarted:
        return genericSs.notStarted
      case OngoingReportingStatus.Process:
        return genericSs.inProgress
      case OngoingReportingStatus.InReview:
        return genericSs.inProgress
      case OngoingReportingStatus.Verified:
        return genericSs.complete
      case OngoingReportingStatus.Uploaded:
        return genericSs.notStarted
      case OngoingReportingStatus.Archived:
        return genericSs.notStarted
      default:
        return ''
    }
  }, [ongoingReporting])

  return (
    <TableRow>
      <TableCell className={genericSs.tableTextLeft}>
        <LinkButton component={Link} to={link}>
          {replaceJSX(ongoingReporting.clientName, searchString)}'s{' '}
          {replaceJSX(ongoingReporting.type, searchString)} -{' '}
          {replaceJSX(formatDate(ongoingReporting.recordDate), searchString)}
        </LinkButton>
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <Box display="flex" alignItems="center" gap={2}>
          {ongoingReporting.clientIconUrl && (
            <MuiAvatar
              src={ongoingReporting.clientIconUrl}
              alt={ongoingReporting.clientName}
              className={styles.clientAvatar}
            />
          )}
          <LinkButton
            component={Link}
            to={generatePath(ROUTES.CLIENT_PAGE, { id: ongoingReporting.clientId })}
          >
            {replaceJSX(ongoingReporting.clientName, searchString)}
          </LinkButton>
        </Box>
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        {replaceJSX(ongoingReporting.type, searchString)}
      </TableCell>
      <TableCell className={genericSs.tableTextLeft}>
        <span className={cn(genericSs.statusCard, statusStyle)}>
          {replaceJSX(REPORTING_STATUS_LABEL[ongoingReporting.status], searchString)}
        </span>
      </TableCell>
    </TableRow>
  )
}

export default GlobalSearchPageTableRowOngoingReporting
