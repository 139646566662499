import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Skeleton from '@mui/material/Skeleton'
import Link from '@mui/material/Link'

import styles from './LoanCommitteeReportOverviewDealStructure.module.scss'

import { ROUTES } from '../../../constants/routes'
import { LOAN_TYPES } from '@common/constants/client'
import { allClientTermsInfo, FieldType } from '@common/interfaces/client'
import { IDueDiligence } from '@common/interfaces/dueDiligence'
import {
  AR_SPECIFIC_TERMS_FIELDS,
  INVENTORY_SPECIFIC_TERMS_FIELDS,
} from '@common/constants/prospects'
import RenderedValue from '../../../components/RenderedValue'

const STRUCTURE_DATA = [
  [
    {
      section: 'Line Utilization',
      data: ['maxLineAmount', 'minimumLineAmount', 'minimumInterestEffectiveDate'],
    },
    {
      section: 'Interest Rate',
      data: ['interestRateAbovePrime', 'interestRateBase', 'interestRate'],
    },
    {
      section: 'Fees',
      data: ['terminationYear1', 'terminationYear2', 'terminationOngoing', 'facilityFee'],
    },
  ],
  [
    {
      section: 'Collateral',
      data: [
        'loanType',
        'arAdvance',
        'inventoryAdvanceRateCost',
        'nolv',
        'inventoryAdvanceRateNolv',
        'sublimit',
      ],
    },
    {
      section: 'Miscellaneous',
      data: ['riskRating'],
    },
  ],
]

interface IProps {
  dueDiligenceInfo: IDueDiligence
  isLoading: boolean
}

const LoanCommitteeReportOverviewDealStructureRow = ({
  dueDiligenceInfo,
  field,
}: {
  dueDiligenceInfo: IDueDiligence
  field: string
}) => {
  const fieldWithConfig = useMemo(
    () =>
      [
        ...allClientTermsInfo,
        {
          value: 'riskRating',
          label: 'Risk Rating',
          type: FieldType.Number,
        },
      ].find(({ value }) => value === field),
    [field],
  )

  if (
    !fieldWithConfig ||
    (AR_SPECIFIC_TERMS_FIELDS.includes(field) &&
      (!dueDiligenceInfo?.loanType || dueDiligenceInfo.loanType === LOAN_TYPES.inventory)) ||
    (INVENTORY_SPECIFIC_TERMS_FIELDS.includes(field) &&
      (!dueDiligenceInfo?.loanType || dueDiligenceInfo.loanType === LOAN_TYPES.ar))
  ) {
    return null
  }

  return (
    <div className={styles.dataRow}>
      <div className={styles.label}>{fieldWithConfig.label}</div>
      <div className={styles.value}>
        {dueDiligenceInfo ? (
          <RenderedValue value={dueDiligenceInfo[field]} type={fieldWithConfig.type} />
        ) : (
          <Skeleton width={100} height={15} />
        )}
      </div>
    </div>
  )
}

const LoanCommitteeReportOverviewDealStructure = ({ dueDiligenceInfo }: IProps) => {
  const { id } = useParams<{ id: string }>()

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Deal Structure Summary</div>

        <Link
          component={RouterLink}
          to={generatePath(ROUTES.CLIENT_SETUP_LOAN_STRUCTURE_ASSET_BASED, { id })}
          className={styles.editLink}
        >
          Edit
        </Link>
      </div>

      <div className={styles.data}>
        {STRUCTURE_DATA.map((data, index) => (
          <div key={index} className={styles.column}>
            {data.map(({ section, data }) => (
              <div key={section} className={styles.section}>
                <div className={styles.sectionTitle}>{section}</div>
                <div className={styles.sectionData}>
                  {data.map((field) => (
                    <LoanCommitteeReportOverviewDealStructureRow
                      key={field}
                      field={field}
                      dueDiligenceInfo={dueDiligenceInfo}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default LoanCommitteeReportOverviewDealStructure
