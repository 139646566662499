import React, { useCallback, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import { Form } from 'react-final-form'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import setFieldData from 'final-form-set-field-data'
import Grid from '@mui/material/Grid'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import moment from 'moment'

import styles from './DealStructure.module.scss'
import termStructureStyles from '../../ProspectReportingSummaryTermStructure/ProspectReportingSummaryTermStructure.module.scss'

import AdministrationInfo from '../AdministrationInfo'
import Fees from '../Fees'
import InterestRate from '../InterestRate'
import Collateral from '../Collateral'
import LineUtilization from '../LineUtilization'
import Card from '../../Common/Card'
import { ClientInfoStatus, IClientInfo, clientTermsInfo } from '@common/interfaces/client'
import Modal from '../../Common/Modal'
import Button from '../../Common/Button'
import { dateToString } from '../../../helpers/helpers'
import KeyboardDatePicker from '../../Common/KeyboardDatePicker'
import { DATE_FORMAT } from '../../../constants/common'
import Table from '../../Common/Table'
import TableBody from '../../Common/TableBody'
import TableCell from '../../Common/TableCell'
import TableHead from '../../Common/TableHead'
import TableRow from '../../Common/TableRow'
import RouteLeavingGuard from '../../Common/RouteLeavingGuard'
import { useHistory } from 'react-router'
import TextField from '../../Common/TextField'
import Checkbox from '../../Common/Checkbox'
import { MenuIcon } from '../../Common/Icons'

const NUMBER_VALIDATOR = Yup.number()
  .typeError('Invalid number')
  .min(0, 'Must be positive')
  .nullable()
const INTEGER_VALIDATOR = NUMBER_VALIDATOR.integer('Must be integer')
const DATE_VALIDATOR = Yup.date().typeError('Please type date in MM/DD/YY format').nullable()

const BASE_VALIDATION = {
  lockbox: INTEGER_VALIDATOR,
  wireFee: NUMBER_VALIDATOR,
  lockboxFee: NUMBER_VALIDATOR,
  facilityFee: NUMBER_VALIDATOR,
  nolv: NUMBER_VALIDATOR,
  arAdvance: NUMBER_VALIDATOR,
  inventoryAdvanceRateCost: NUMBER_VALIDATOR,
  rawMaterialsAdvanceRateCost: NUMBER_VALIDATOR,
  inventoryAdvanceRateNolv: NUMBER_VALIDATOR,
  terminationYear1: NUMBER_VALIDATOR,
  terminationYear2: NUMBER_VALIDATOR,
  unusedLineFee: NUMBER_VALIDATOR,
  interestRate: NUMBER_VALIDATOR,
  interestRateBase: NUMBER_VALIDATOR,
  interestRateAbovePrime: NUMBER_VALIDATOR,
  interestPerDays: INTEGER_VALIDATOR,
  interestFloatDays: INTEGER_VALIDATOR,
  sublimit: NUMBER_VALIDATOR,
  accountNumber: INTEGER_VALIDATOR,
  minimumLineAmount: INTEGER_VALIDATOR,
  maxLineAmount: INTEGER_VALIDATOR,
  crossAge: NUMBER_VALIDATOR,
  effectiveDate: DATE_VALIDATOR,
  minimumInterestEffectiveDate: DATE_VALIDATOR,
  nextFacilityFeeDate: DATE_VALIDATOR,
  contractDate: DATE_VALIDATOR,
  maturityDate: DATE_VALIDATOR.min(
    Yup.ref('contractDate'),
    'Maturity date must be after contract date',
  ),
}

const schema = Yup.object().shape(BASE_VALIDATION)
const validate = makeValidate(schema)

interface IProps {
  clientInfo: IClientInfo
  loanBalanceStartDate: string
  updateClientTerms: (id: string, data: object) => Promise<any>
}

const DealStructure = ({ clientInfo, loanBalanceStartDate, updateClientTerms }: IProps) => {
  const [readOnly, setReadOnly] = useState(true)
  const [isTermsChangedModalShown, setIsTermsChangedModalShown] = useState(false)
  const [clientData, setClientData] = useState<any>({})
  const [isDirty, setIsDirty] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const history = useHistory()

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  let submit: any

  const handleNavigate = useCallback(
    (path) => {
      history.push(path)
    },
    [history],
  )

  const handleClientUpdate = useCallback(
    async (values: any, form: any) => {
      const dirtyFields = form.getState().dirtyFields
      const dataBeingUpdated = Object.keys(dirtyFields).reduce((acc: any, key: string) => {
        if (key) {
          if (values[key]?.toString() !== clientInfo[key]?.toString()) {
            acc[key] = values[key] || null
          }
        }
        return acc
      }, {})

      clientTermsInfo.forEach((item) => {
        if (Object.keys(dataBeingUpdated).includes(item.value)) {
          if (item.type === 'date' && dataBeingUpdated[item.value]) {
            dataBeingUpdated[item.value] = dateToString(dataBeingUpdated[item.value]) || null
          } else if (item.type === 'number') {
            dataBeingUpdated[item.value] = parseFloat(dataBeingUpdated[item.value]) || null
          }
        }
      })

      if (clientInfo.clientStatus !== ClientInfoStatus.Current) {
        const response = await updateClientTerms(clientInfo.id, dataBeingUpdated)
        if (!response.error) {
          setReadOnly(true)
        }
      } else if (clientInfo && Object.keys(dataBeingUpdated).length > 0) {
        setIsTermsChangedModalShown(true)
        setClientData(dataBeingUpdated)
      }
    },
    [clientInfo, updateClientTerms],
  )

  const handleConfirmClientTermChange = useCallback(
    async (values: any) => {
      const dataToUpload = {
        ...clientData,
        ...values,
      }
      const response = await updateClientTerms(clientInfo.id, dataToUpload)
      if (!response.error) {
        setIsTermsChangedModalShown(false)
        setClientData({})
        setReadOnly(true)
      }
    },
    [updateClientTerms, clientInfo, clientData],
  )
  const handleCloseTermsChangedModal = useCallback(() => {
    setIsTermsChangedModalShown(false)
  }, [])

  const tooltipMessage = useMemo(() => {
    if (!isDirty) {
      return 'No changes to save'
    }
    if (isInvalid) {
      return 'Please correct the errors before saving'
    }
    return null
  }, [isDirty, isInvalid])

  const toggleReadOnlyMode = useCallback(() => {
    setAnchorEl(null)
    setReadOnly((readOnly) => !readOnly)
  }, [])

  return (
    <Card
      withBorder={false}
      noPadding
      noHeaderTopMargin
      title={
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <span>Asset based loan</span>
          {readOnly ? (
            [ClientInfoStatus.Current, ClientInfoStatus.DueDiligence].includes(
              clientInfo?.clientStatus,
            ) ? (
              <MenuIcon isActive={isMenuOpen} onClick={handleClickMenu} size="small" />
            ) : null
          ) : (
            <Tooltip title={tooltipMessage} placement="top">
              <div className={styles.iconsWrapper}>
                <Button
                  type="submit"
                  small={false}
                  className={styles.editButtonIcon}
                  disabled={!isDirty || isInvalid}
                  onClick={(event) => {
                    submit(event)
                  }}
                >
                  Save
                </Button>
              </div>
            </Tooltip>
          )}

          <Menu open={isMenuOpen} anchorEl={anchorEl} onClose={handleCloseMenu}>
            <MenuItem onClick={toggleReadOnlyMode}>
              {clientInfo?.clientStatus === ClientInfoStatus.Current ? 'New Amendment' : 'Edit'}
            </MenuItem>
          </Menu>
        </Box>
      }
      className={styles.container}
    >
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Form<any, any>
            initialValues={clientInfo}
            // @ts-ignore
            mutators={{ setFieldData }}
            onSubmit={handleClientUpdate}
            validate={validate}
            render={({ form, values, handleSubmit, invalid, dirty }) => {
              setIsDirty(dirty)
              setIsInvalid(invalid)
              submit = handleSubmit
              return (
                <form id="dealStructureForm">
                  <div className={termStructureStyles.data}>
                    <div className={termStructureStyles.column}>
                      <LineUtilization clientInfo={values} readOnly={readOnly} />

                      <Collateral clientInfo={values} readOnly={readOnly} />
                    </div>

                    <div className={termStructureStyles.column}>
                      <InterestRate clientInfo={values} readOnly={readOnly} />

                      <Fees clientInfo={values} readOnly={readOnly} />

                      <AdministrationInfo clientInfo={values} readOnly={readOnly} />
                    </div>
                  </div>

                  <RouteLeavingGuard
                    when={form.getState().dirty}
                    navigate={handleNavigate}
                    shouldBlockNavigation={() => form.getState().dirty}
                    helperText="You have unsaved changes. If you leave before saving, your changes will be lost."
                    buttonText="Save changes"
                    alternateSubmit={handleSubmit}
                    isAlternateSubmitInvalid={form.getState().invalid}
                  />
                </form>
              )
            }}
          />
        </Grid>
      </Grid>

      <Form
        initialValues={{
          restartTerm: false,
          effectiveDate: moment().format('YYYY-MM-DD'),
        }}
        onSubmit={handleConfirmClientTermChange}
        render={({ values, form, handleSubmit }) => {
          return (
            <Modal
              open={isTermsChangedModalShown}
              title={'Confirmation'}
              onCancel={handleCloseTermsChangedModal}
              disableEnforceFocus
              footer={[
                <Button
                  key="cancel"
                  small={false}
                  color="primary"
                  variant="contained"
                  onClick={handleCloseTermsChangedModal}
                  secondary
                >
                  Cancel
                </Button>,
                <Button
                  small={false}
                  key="submit"
                  color="primary"
                  variant="contained"
                  onClick={async () => {
                    await handleSubmit()
                    form.reset()
                  }}
                >
                  Confirm
                </Button>,
              ]}
            >
              <form id="dealStructureConfirmForm">
                <Table className={styles.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Field</TableCell>
                      <TableCell>Old Value</TableCell>
                      <TableCell>New Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(clientData).map((key) => {
                      const label = clientTermsInfo.find((item) => item.value === key)?.label
                      return (
                        <TableRow key={key}>
                          <TableCell>{label}</TableCell>
                          <TableCell>{clientInfo[key]?.toString()}</TableCell>
                          <TableCell>{clientData[key]?.toString()}</TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>

                <div className={styles.effectiveDateContainer}>
                  <h4>When do you want these changes to go into effect?</h4>
                  <p>Effective Date</p>

                  <KeyboardDatePicker
                    className={styles.effectiveDate}
                    name={'effectiveDate'}
                    inputFormat={DATE_FORMAT}
                    minDate={moment(loanBalanceStartDate || undefined).toDate()}
                  />

                  <h4>Restart term?</h4>

                  <p className={styles.restartTermContainer}>
                    <Checkbox
                      className={styles.restartTerm}
                      name="restartTerm"
                      checked={values.restartTerm}
                      color="primary"
                      onChange={(event) => {
                        form.change('restartTerm', event.target.checked)
                      }}
                    />
                  </p>

                  <h4>Is this a legal amendment?</h4>
                  <p>Amendment box link</p>

                  <TextField name={'boxLink'} />
                </div>
              </form>
            </Modal>
          )
        }}
      />
    </Card>
  )
}

export default DealStructure
