import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import BBCRollforwardTable from './BBCRollforwardTable'

import {
  isLoading as isLoadingBBC,
  arRollforward,
  arRollforwardGraph,
  apRollforward,
  previousClientCollaterals,
  arapChartDataDropdownOptions,
} from '../../redux/bbc/selectors'
import {
  getBBCARRollforward,
  exportBBCARRollforward,
  getBBCARRollforwardGraph,
  getBBCAPRollforward,
  exportBBCAPRollforward,
  getARAPChartDropdownOptions,
} from '../../redux/bbc/actions'
import { debtorCategories } from '../../redux/options/selectors'

const selector = createStructuredSelector({
  isLoadingBBC,
  arRollforward,
  arRollforwardGraph,
  apRollforward,
  previousClientCollaterals,
  arapChartDataDropdownOptions,
  debtorCategories,
})

const actions = {
  getBBCARRollforward,
  getBBCAPRollforward,
  getBBCARRollforwardGraph,
  exportBBCARRollforward,
  exportBBCAPRollforward,
  getARAPChartDropdownOptions,
}

export default connect(selector, actions)(BBCRollforwardTable)
