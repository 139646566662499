import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { generatePath } from 'react-router-dom'
import { matchPath, useHistory, useParams } from 'react-router'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import styles from './ProspectHeader.module.scss'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import Button from '../Common/Button'
import { formatDate, formatPrice } from '../../helpers/helpers'
import { stringAvatar } from '../Notes/helpers/helpers'
import { ROUTES } from '../../constants/routes'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import Breadcrumbs from '../Common/Breadcrumbs'
import { IOPSReporting, IProspectTerms } from '@common/interfaces/prospects'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import Tabs from '../Common/Tabs'
import Modal from '../Common/Modal'
import { ReactComponent as EditIcon } from '@assets/images/pencil-icon.svg'
import { UploadFile } from '../Common/UploadFile'
import { usePermissions } from '../../helpers/permissionContext'
import { Form } from 'react-final-form'
import FormField from '../Common/FormField'
import { ILoadingData } from '../../redux/types'
import ProspectHeaderLinks from '../ProspectHeaderLinks'

enum ProspectHeaderTabNames {
  Application = 'Application',
  AnalysisQueue = 'Analysis queue',
  ManageTerms = 'Term Sheet',
  Summary = 'Summary',
}

const TABS_ROUTES = {
  [ProspectHeaderTabNames.Application]: ROUTES.PROSPECT_APPLICATION_PAGE,
  [ProspectHeaderTabNames.AnalysisQueue]: ROUTES.PROSPECT_ANALYSIS_QUEUE,
  [ProspectHeaderTabNames.ManageTerms]: ROUTES.PROSPECT_MANAGE_TERMS_PAGE,
  [ProspectHeaderTabNames.Summary]: ROUTES.PROSPECT_SUMMARY_PAGE,
}

const TABS = [
  ProspectHeaderTabNames.Application,
  ProspectHeaderTabNames.AnalysisQueue,
  ProspectHeaderTabNames.ManageTerms,
  ProspectHeaderTabNames.Summary,
]

enum Modals {
  EditProfilePic = 'editProfilePic',
  ImageManage = 'imageManage',
  SubmitOPS = 'submitOPS',
}

interface IProps {
  reporting: IOPSReporting
  termStructureSummaryInfo: ILoadingData<{ data: IProspectTerms }>
  isBDO: boolean
  show: (id: string) => void
  hide: () => void
  updateClient: (id: string, data: FormData) => void
  submitOps: (id: string, data: object) => Promise<void>
}

const HeaderLoader = ({ isLoading, value }: { isLoading: boolean; value: string }) => {
  return isLoading ? <Skeleton width={120} /> : <>{value || '-'}</>
}

const ProspectHeader = ({
  reporting,
  show,
  hide,
  updateClient,
  submitOps,
  termStructureSummaryInfo,
  isBDO,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const [isSaving, setIsSaving] = useState(false)
  const [modalsShown, setModalsShown] = useState([])
  const [iconUrl, setIconUrl] = useState<string | null>(null)
  const [loadedFile, setLoadedFile] = useState<File[]>([])
  const [savedProfilePic, setSavedProfilePic] = useState<string | null>(null)

  const { isUW } = usePermissions()

  const { termData } = useMemo(
    () => ({
      termData: termStructureSummaryInfo.data?.data,
    }),
    [termStructureSummaryInfo],
  )

  const { isLoading } = useLoadInfo({ id, info: reporting, show, hide })
  useSetPageTitle(reporting?.clientName || '')

  const breadcrumbs = useMemo(
    () => [
      {
        link: isUW ? ROUTES.DUE_DILIGENCE_HOME : ROUTES.PROSPECTS,
        Icon: HomeIcon,
      },
      {
        link: generatePath(ROUTES.PROSPECT_PAGE, { id }),
        title: reporting?.clientName,
      },
    ],
    [reporting, id, isUW],
  )

  const initials = useMemo(() => {
    const name = reporting?.clientName || ''
    return name.split(' ').length > 1 ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : name[0]
  }, [reporting])

  const selectedTab = useMemo(() => {
    const currentTab = TABS.find(
      (tab) =>
        !!matchPath(history.location.pathname, {
          path: TABS_ROUTES[tab],
        })?.isExact,
    )

    return currentTab || TABS[0]
  }, [history.location.pathname])

  const handleChangeTab = useCallback(
    (selectedTab: string) => {
      history.push(generatePath(TABS_ROUTES[selectedTab], { id }))
    },
    [history, id],
  )

  useEffect(() => {
    setIconUrl(savedProfilePic || reporting?.clientInfo?.iconUrl)
  }, [savedProfilePic, reporting])

  const openEditProfilePicModal = useCallback(() => {
    setModalsShown([Modals.EditProfilePic])
  }, [])

  const closeEditProfilePicModal = useCallback(() => {
    setModalsShown([])
    setLoadedFile([])
    setIconUrl(savedProfilePic || reporting?.clientInfo?.iconUrl)
  }, [savedProfilePic, reporting?.clientInfo?.iconUrl])

  const handleSetIconUrl = useCallback((image) => {
    setIconUrl(image)
  }, [])

  const handleUpdateClientInfo = useCallback(
    async (file: any) => {
      if (reporting?.clientInfo?.id) {
        const formData = new FormData()
        formData.append('files[profilePicture]', file[0], file[0].name)
        await updateClient(reporting?.clientInfo?.id, formData)
        await show(id)
      }
    },
    [reporting?.clientInfo?.id, updateClient, show, id],
  )

  const handleUploadFile = useCallback(
    async (loadedFiles: File[]) => {
      setLoadedFile(loadedFiles)
      const [image] = loadedFiles

      if (image) {
        const reader = new FileReader()
        reader.onload = () => {
          handleSetIconUrl(reader.result as string)
        }
        reader.readAsDataURL(image)
      }
    },
    [handleSetIconUrl],
  )

  const handleSaveProfilePic = useCallback(async () => {
    setIsSaving(true)
    await handleUpdateClientInfo(loadedFile)
    setSavedProfilePic(iconUrl)
    setIconUrl(iconUrl)
    setModalsShown([])
    setLoadedFile([])
    setIsSaving(false)
  }, [handleUpdateClientInfo, loadedFile, iconUrl, setLoadedFile])

  const handleDeleteFile = useCallback(async () => {
    setLoadedFile([])
    handleSetIconUrl(null)
  }, [handleSetIconUrl])

  const handleToggleImageManageModal = useCallback(() => {
    setModalsShown((modalShowns) =>
      modalShowns.includes(Modals.ImageManage)
        ? modalShowns.filter((modal) => modal !== Modals.ImageManage)
        : [...modalShowns, Modals.ImageManage],
    )
  }, [])

  const handleSubmitOPS = useCallback(async () => {
    setModalsShown([Modals.SubmitOPS])
  }, [])

  const handleConfirmSubmitOPS = useCallback(
    async (data?: object) => {
      setIsSaving(true)
      await submitOps(id, {
        ...data,
        prospectTermsId: termData?.id,
      })
      setIsSaving(false)
      setModalsShown([])
    },
    [submitOps, id, termData],
  )

  return (
    <>
      <Grid container item xs={12} mb={2}>
        <Grid item xs={6} justifyContent="flex-start" alignItems="center">
          <Breadcrumbs breadcrumbs={breadcrumbs} isLoading={isLoading} />
        </Grid>
        <Grid item container xs={6} justifyContent="flex-end" alignItems="center" gap={1.5}>
          <ProspectHeaderLinks />
        </Grid>
      </Grid>
      <div className={styles.headerContainer}>
        <div className={styles.avatarGridContainer}>
          {isLoading ? (
            <Skeleton width={140} height={140} />
          ) : (
            reporting && (
              <div className={styles.avatar} {...stringAvatar(reporting.clientName)}>
                {savedProfilePic || reporting.clientInfo?.iconUrl ? (
                  <div className={styles.avatarContainer}>
                    <img
                      className={styles.avatarImg}
                      src={savedProfilePic || reporting.clientInfo?.iconUrl}
                      alt="logo"
                    />
                  </div>
                ) : (
                  <div className={styles.avatarText}>{initials}</div>
                )}
                <EditIcon className={styles.editIcon} onClick={openEditProfilePicModal} />
              </div>
            )
          )}
        </div>
        <div className={styles.contentContainer}>
          <Grid container spacing={1}>
            <Grid container item xs={12} justifyContent="flex-start" alignItems="flex-start">
              <Grid item xs={3}>
                <div className={styles.headerTitle}>Salesforce Stage</div>
                <div className={styles.headerValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={reporting?.salesforceProspectInfo?.salesforceStage}
                  />
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className={styles.headerTitle}>Commitment Amount</div>
                <div className={styles.headerValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={
                      reporting?.salesforceProspectInfo?.commitmentAmount
                        ? `$ ${formatPrice(reporting.salesforceProspectInfo.commitmentAmount)}`
                        : '-'
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className={styles.headerTitle}>Last Activity</div>
                <div className={styles.headerValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={
                      reporting?.salesforceProspectInfo?.lastActivityDate
                        ? formatDate(reporting.salesforceProspectInfo.lastActivityDate)
                        : '-'
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className={styles.headerTitle}>BDO</div>
                <div className={styles.headerValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={reporting?.salesforceProspectInfo?.user?.firstName}
                  />
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className={styles.headerTitle}>Risk Rating</div>
                <div className={styles.headerValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={
                      reporting?.clientInfo?.riskRating
                        ? reporting.clientInfo.riskRating.toFixed(2)
                        : '-'
                    }
                  />
                </div>
              </Grid>
            </Grid>
            <div className={styles.infoDivider}></div>

            <Grid container item xs={12} justifyContent="flex-start">
              <Grid item xs={3}>
                <div className={styles.headerTitle}>AR Availability</div>
                <div className={styles.headerValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={
                      reporting?.clientInfo?.availableAR
                        ? ` $ ${formatPrice(reporting.clientInfo.availableAR)}`
                        : '-'
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className={styles.headerTitle}>Inventory Availability</div>
                <div className={styles.headerValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={
                      reporting?.clientInfo?.availableInventory
                        ? ` $ ${formatPrice(reporting.clientInfo.availableInventory)}`
                        : '-'
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={styles.headerTitle}>Next Steps</div>
                <div className={styles.headerValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={
                      reporting?.salesforceProspectInfo?.nextSteps
                        ? reporting.salesforceProspectInfo.nextSteps
                        : '-'
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <Grid item xs={12} mb={1.5} pl={3.75} display="flex" justifyContent="space-between">
        <Tabs tabs={TABS} selected={selectedTab} handleChange={handleChangeTab} />

        {selectedTab === ProspectHeaderTabNames.Summary && isBDO && termData?.id && (
          <Button onClick={handleSubmitOPS}>Submit OPS</Button>
        )}
      </Grid>

      {modalsShown.includes(Modals.EditProfilePic) && (
        <Modal
          open
          onCancel={closeEditProfilePicModal}
          classes={{ root: styles.modalRoot, title: styles.modalTitle, footer: styles.modalFooter }}
          title="Upload Image"
          size="small"
          footer={[
            <Button
              color="primary"
              variant="contained"
              small={false}
              onClick={handleSaveProfilePic}
              isLoading={isSaving}
              key="submit"
              className={styles.modalFooter}
              disabled={!loadedFile.length}
            >
              Update
            </Button>,
          ]}
        >
          {iconUrl && (
            <Box className={styles.avatarWrapper}>
              <img className={styles.avatarImg} src={iconUrl} alt="logo" />
            </Box>
          )}
          <Box className={styles.imageUploadWrapper}>
            <UploadFile
              title="Profile Picture"
              size="lg"
              onDropAccepted={handleUploadFile}
              files={loadedFile}
              onDelete={handleDeleteFile}
              acceptedFileTypes={['image']}
              maxFiles={1}
              isModalOpen={modalsShown.includes(Modals.ImageManage)}
              handleToggleModal={handleToggleImageManageModal}
            />
          </Box>
        </Modal>
      )}
      {modalsShown.includes(Modals.SubmitOPS) && (
        <Form
          onSubmit={handleConfirmSubmitOPS}
          initialValues={{
            commentsForCommittee: '',
          }}
          render={({ handleSubmit }) => (
            <Modal
              open
              onCancel={() => setModalsShown([])}
              classes={{ root: styles.modalRoot, title: styles.modalTitle }}
              title="Submit OPS"
              footer={[
                <Button
                  key="submit"
                  color="primary"
                  variant="contained"
                  small={false}
                  onClick={handleSubmit}
                  isLoading={isSaving}
                  fullWidth
                >
                  Confirm
                </Button>,
              ]}
            >
              <div className={styles.modalDescription}>
                Loan Committee will be notified that {reporting?.clientName} OPS is ready for
                review.
              </div>

              <FormField
                label="Comments for Committee"
                name="commentsForCommittee"
                type="text"
                rows={4}
              />
            </Modal>
          )}
        />
      )}
    </>
  )
}

export default ProspectHeader
