import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useParams } from 'react-router'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import genericSs from '@styles/generic.module.scss'
import styles from '../ProspectReportingPayablesPage/ProspectReportingPayablesPage.module.scss'

import ProspectInventoryAnalysis from '../../components/ProspectInventoryAnalysis'
import {
  IOPSReporting,
  OPSReportingFlowStatus,
  OPSReportingStatus,
} from '@common/interfaces/prospects'
import ProspectDashboardHeader from '../../components/ProspectDashboardHeader'
import ProspectFileSelect from '../../components/ProspectFileSelect'
import { OngoingReportingType } from '@common/interfaces/bbc'
import { ClientInfoStatus } from '@common/interfaces/client'
import OPSWaivedDocument from '../../components/OPSWaivedDocument'
import { usePermissions } from '../../helpers/permissionContext'

interface IProps {
  reporting: IOPSReporting
  show: (id: string) => void
  startReporting: (id: string, data?: object) => void
}

const ProspectReportingInventoryPage = ({ reporting, show, startReporting }: IProps) => {
  const { id } = useParams<{ id: string }>()

  const { isUW } = usePermissions()

  const readOnly = useMemo(
    () =>
      ![ClientInfoStatus.Prospect, ClientInfoStatus.Archived].includes(
        reporting?.clientInfo?.clientStatus,
      ) ||
      reporting?.status === OPSReportingStatus.Archived ||
      isUW,
    [reporting, isUW],
  )
  const [refreshCounter, setRefreshCounter] = useState(0)
  const [isFilesSaving, setIsFilesSaving] = useState(false)

  const [isFileSelectShown, setIsFileSelectShown] = useState(false)

  const opsReportingFlow = useMemo(
    () =>
      reporting?.opsReportingFlows?.filter(
        ({ type }) => type === OngoingReportingType.Inventory,
      )[0],
    [reporting],
  )

  const fileIdToUse = useMemo(() => opsReportingFlow?.files?.[0]?.fileId, [opsReportingFlow])

  const selectedFile = useMemo(
    () => reporting?.files.filter(({ id }) => id === fileIdToUse)[0],
    [fileIdToUse, reporting],
  )

  useEffect(() => {
    if (reporting?.id && !readOnly) {
      startReporting(reporting.id)
    }
  }, [reporting?.id, readOnly, startReporting])

  const handleOpenSelectFile = useCallback(() => {
    setIsFileSelectShown(true)
  }, [setIsFileSelectShown])

  const handleCloseSelectFile = useCallback(() => {
    setIsFileSelectShown(false)
    show(id)
  }, [setIsFileSelectShown, show, id])

  const isWaived = useMemo(
    () => opsReportingFlow?.status === OPSReportingFlowStatus.Waived,
    [opsReportingFlow],
  )

  return (
    <Box py={1} pr={2} className={styles.container} display="flex" flexDirection="column">
      <ProspectDashboardHeader
        setRefreshCounter={setRefreshCounter}
        currentReportType={OngoingReportingType.Inventory}
      />
      <Box flex="1" className={genericSs.overflowYAuto}>
        {!readOnly && opsReportingFlow && (
          <ProspectFileSelect
            isOpen={isFileSelectShown}
            reportType={OngoingReportingType.Inventory}
            setRefreshCounter={setRefreshCounter}
            setIsFilesSaving={setIsFilesSaving}
            opsReportingFlow={opsReportingFlow}
            handleOpenModal={handleOpenSelectFile}
            handleCloseModal={handleCloseSelectFile}
          />
        )}
        {isWaived ? (
          <OPSWaivedDocument
            reportType={OngoingReportingType.Inventory}
            openFileSelectModal={handleOpenSelectFile}
          />
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ProspectInventoryAnalysis
                refreshCounter={refreshCounter}
                selectedFile={selectedFile}
                isFilesSaving={isFilesSaving}
                handleOpenModal={handleOpenSelectFile}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  )
}

export default ProspectReportingInventoryPage
