import React, { useContext, useRef, useEffect, useMemo, useState, useCallback } from 'react'
import MuiAutocomplete from '@mui/material/Autocomplete'
import MuiTextField from '@mui/material/TextField'
import { AutocompleteProps } from 'mui-rff'
import cn from 'classnames'
import useLoadedOptions from '../../../hooks/useLoadedOptions'
import { IOptionType } from '@common/interfaces/entityInfo'
import { TableCellContext } from '../TableCell/TableCell'
import styles from './TaggingField.module.scss'
import genericSs from '@styles/generic.module.scss'

interface IProps extends Partial<AutocompleteProps<IOptionType, false, false, false>> {
  name?: string
  style?: object
  placeholder?: string
  onChange?: (event: object, newValue: object) => void
  disabledClearable?: boolean
  leftIcon?: React.ReactNode
  height?: 'small' | 'medium'
  isAsync?: boolean
  loadOptions?: (value: string) => Promise<IOptionType[]>
  isFreeSolo?: boolean
  renderTags?: (value: IOptionType[], getTagProps: (params: any) => any) => React.ReactNode
  limitTags?: number
  disabled?: boolean
  onOpen?: () => void
  limtDrowdown: boolean
  inTable?: boolean
  minPredictionLength?: number
}

const TaggingField = ({
  className,
  isFreeSolo,
  options,
  value,
  placeholder,
  renderTags,
  onChange,
  filterOptions,
  onInputChange,
  limitTags,
  disabled = false,
  onOpen,
  limtDrowdown,
  isAsync,
  loadOptions,
  inTable,
  minPredictionLength = 1,
}: IProps) => {
  const context = useContext(TableCellContext)
  const isActive = useMemo(() => context?.isActive, [context])
  const inputRef = useRef<HTMLInputElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const { asyncOptions, handleInputChange } = useLoadedOptions({
    isAsync,
    onInputChangeDefault: onInputChange,
    loadOptions,
    minLength: minPredictionLength,
  })
  const handleOpen = useCallback(() => {
    if (onOpen) {
      onOpen()
    }
    setIsOpen(true)
  }, [onOpen])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  useEffect(() => {
    if (inputRef.current && isActive && inTable) {
      inputRef.current.focus()
      // @ts-ignore
      handleOpen()
    }
  }, [isActive, handleOpen, inTable, inputRef])

  const renderedValue = useMemo(() => {
    if (Array.isArray(value) && value.length > 0) {
      return value?.map((item) => item.label).join(', ')
    }
    return placeholder
  }, [value, placeholder])

  const noValue = useMemo(() => {
    return !value || (Array.isArray(value) && value.length === 0)
  }, [value])

  return (
    <>
      {!isActive && inTable ? (
        <div
          className={cn({
            [genericSs.inactiveFormField]: noValue,
            syntheticInputField: inTable,
          })}
        >
          <span>{renderedValue}</span>
        </div>
      ) : (
        <MuiAutocomplete
          disableClearable
          renderTags={renderTags}
          options={isAsync ? asyncOptions : options}
          forcePopupIcon={false}
          className={cn(styles.tagsInput, className)}
          ChipProps={{
            className: styles.chip,
          }}
          onChange={onChange}
          multiple
          disableCloseOnSelect
          isOptionEqualToValue={(option: any, value: any) =>
            option?.value === (value && value?.value ? value.value : value)
          }
          limitTags={limitTags}
          classes={{
            inputRoot: styles.input,
            popper: cn(styles.popper, { [styles.popperSmall]: limtDrowdown }),
            focused: styles.focused,
          }}
          renderInput={(params) => (
            <MuiTextField
              fullWidth
              inputRef={inputRef}
              {...params}
              placeholder={placeholder}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                classes: {
                  root: styles.inputRoot,
                  focused: styles.focusedInput,
                },
                disableUnderline: true,
              }}
            />
          )}
          value={value as IOptionType[]}
          freeSolo={isFreeSolo}
          filterOptions={filterOptions}
          onInputChange={handleInputChange}
          // @ts-ignore
          getOptionValue={(option) => option.value}
          disabled={disabled}
          onOpen={handleOpen}
          onClose={handleClose}
          open={isOpen}
        />
      )}
    </>
  )
}
export default TaggingField
