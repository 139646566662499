import { IApi } from '../../api'
import { IUser } from '@common/interfaces/user'
import { CommonPromiseAction } from '../types'
import { createRequestTypes } from '../../helpers/helpers'

export const prefix = 'clientInfo'

export const LIST_REQUEST = `${prefix}/LIST_REQUEST`
export const LIST_SUCCESS = `${prefix}/LIST_SUCCESS`
export const LIST_FAILURE = `${prefix}/LIST_FAILURE`

export const list: CommonPromiseAction = (params: object = {}) => ({
  type: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
  promise: (api: IApi) => api.clientInfo.list(params),
})

export const SHOW_REQUEST = `${prefix}/SHOW_REQUEST`
export const SHOW_SUCCESS = `${prefix}/SHOW_SUCCESS`
export const SHOW_FAILURE = `${prefix}/SHOW_FAILURE`

export const show: CommonPromiseAction = (uid: string) => ({
  type: [SHOW_REQUEST, SHOW_SUCCESS, SHOW_FAILURE],
  promise: (api: IApi) => api.clientInfo.show(uid),
  uid,
})

export const HIDE = `${prefix}/HIDE`

export const hide: CommonPromiseAction = () => ({
  type: HIDE,
})

export const UPDATE_REQUEST = `${prefix}/UPDATE_REQUEST`
export const UPDATE_SUCCESS = `${prefix}/UPDATE_SUCCESS`
export const UPDATE_FAILURE = `${prefix}/UPDATE_FAILURE`

export const updateClient: CommonPromiseAction = (uid: string, data: object) => ({
  type: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  promise: (api: IApi) => api.clientInfo.update(uid, data),
  uid,
})

export const UPDATE_ITEMS_TO_MONITOR_REQUEST = `${prefix}/UPDATE_ITEMS_TO_MONITOR_REQUEST`
export const UPDATE_ITEMS_TO_MONITOR_SUCCESS = `${prefix}/UPDATE_ITEMS_TO_MONITOR_SUCCESS`
export const UPDATE_ITEMS_TO_MONITOR_FAILURE = `${prefix}/UPDATE_ITEMS_TO_MONITOR_FAILURE`

export const updateClientItemsToMonitor: CommonPromiseAction = (uid: string, data: string) => ({
  type: [
    UPDATE_ITEMS_TO_MONITOR_REQUEST,
    UPDATE_ITEMS_TO_MONITOR_SUCCESS,
    UPDATE_ITEMS_TO_MONITOR_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.update(uid, { itemsToMonitor: data }),
  uid,
})

export const UPDATE_TERMS_REQUEST = `${prefix}/UPDATE_TERMS_REQUEST`
export const UPDATE_TERMS_SUCCESS = `${prefix}/UPDATE_TERMS_SUCCESS`
export const UPDATE_TERMS_FAILURE = `${prefix}/UPDATE_TERMS_FAILURE`

export const updateClientTerms: CommonPromiseAction = (uid: string, data: object) => ({
  type: [UPDATE_TERMS_REQUEST, UPDATE_TERMS_SUCCESS, UPDATE_TERMS_FAILURE],
  promise: (api: IApi) => api.clientInfo.updateTerms(uid, data),
})

export const CALCULATE_TERMINATE_REQUEST = `${prefix}/CALCULATE_TERMINATE_REQUEST`
export const CALCULATE_TERMINATE_SUCCESS = `${prefix}/CALCULATE_TERMINATE_SUCCESS`
export const CALCULATE_TERMINATE_FAILURE = `${prefix}/CALCULATE_TERMINATE_FAILURE`

export const calculateTerminateClient: CommonPromiseAction = (uid: string) => ({
  type: [CALCULATE_TERMINATE_REQUEST, CALCULATE_TERMINATE_SUCCESS, CALCULATE_TERMINATE_FAILURE],
  promise: (api: IApi) => api.clientInfo.calculateTerminate(uid),
  uid,
})

export const GENERATE_TERMINATION_LETTER_REQUEST = `${prefix}/GENERATE_TERMINATION_LETTER_REQUEST`
export const GENERATE_TERMINATION_LETTER_SUCCESS = `${prefix}/GENERATE_TERMINATION_LETTER_SUCCESS`
export const GENERATE_TERMINATION_LETTER_FAILURE = `${prefix}/GENERATE_TERMINATION_LETTER_FAILURE`

export const generateTerminationLetter: CommonPromiseAction = (uid: string) => ({
  type: [
    GENERATE_TERMINATION_LETTER_REQUEST,
    GENERATE_TERMINATION_LETTER_SUCCESS,
    GENERATE_TERMINATION_LETTER_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.generateTerminationLetter(uid),
  uid,
})

export const TERMINATE_REQUEST = `${prefix}/TERMINATE_REQUEST`
export const TERMINATE_SUCCESS = `${prefix}/TERMINATE_SUCCESS`
export const TERMINATE_FAILURE = `${prefix}/TERMINATE_FAILURE`

export const terminateClient: CommonPromiseAction = (uid: string, data: object) => ({
  type: [TERMINATE_REQUEST, TERMINATE_SUCCESS, TERMINATE_FAILURE],
  promise: (api: IApi) => api.clientInfo.terminate(uid, data),
  uid,
})

export const LIST_DOCUMENTS_REQUEST = `${prefix}/LIST_DOCUMENTS_REQUEST`
export const LIST_DOCUMENTS_SUCCESS = `${prefix}/LIST_DOCUMENTS_SUCCESS`
export const LIST_DOCUMENTS_FAILURE = `${prefix}/LIST_DOCUMENTS_FAILURE`

export const listDocuments: CommonPromiseAction = (uid: string) => ({
  type: [LIST_DOCUMENTS_REQUEST, LIST_DOCUMENTS_SUCCESS, LIST_DOCUMENTS_FAILURE],
  promise: (api: IApi) => api.clientInfo.listDocuments(uid),
  uid,
})

export const LIST_RM_USERS_REQUEST = `${prefix}/LIST_RM_USERS_REQUEST`
export const LIST_RM_USERS_SUCCESS = `${prefix}/LIST_RM_USERS_SUCCESS`
export const LIST_RM_USERS_FAILURE = `${prefix}/LIST_RM_USERS_FAILURE`

export const listRelationshipManagers: CommonPromiseAction = (data: object) => ({
  type: [LIST_RM_USERS_REQUEST, LIST_RM_USERS_SUCCESS, LIST_RM_USERS_FAILURE],
  promise: (api: IApi) => api.user.list(data),
})

export const LIST_MANAGERS_REQUEST = `${prefix}/LIST_MANAGERS_REQUEST`
export const LIST_MANAGERS_SUCCESS = `${prefix}/LIST_MANAGERS_SUCCESS`
export const LIST_MANAGERS_FAILURE = `${prefix}/LIST_MANAGERS_FAILURE`

export const listClientManagers: CommonPromiseAction = (clientId: string, data?: object) => ({
  type: [LIST_MANAGERS_REQUEST, LIST_MANAGERS_SUCCESS, LIST_MANAGERS_FAILURE],
  promise: (api: IApi) =>
    api.user.list({
      clientId,
      ...data,
    }),
})

export const ADD_MANAGER_REQUEST = `${prefix}/ADD_MANAGER_REQUEST`
export const ADD_MANAGER_SUCCESS = `${prefix}/ADD_MANAGER_SUCCESS`
export const ADD_MANAGER_FAILURE = `${prefix}/ADD_MANAGER_FAILURE`

export const addManager: CommonPromiseAction = ({
  clientIds,
  managerId,
}: {
  clientIds: string[]
  managerId: string
}) => ({
  type: [ADD_MANAGER_REQUEST, ADD_MANAGER_SUCCESS, ADD_MANAGER_FAILURE],
  promise: (api: IApi) => api.clientInfo.addManager({ clientIds, managerId }),
})

export const ADD_OR_REMOVE_MANAGER_REQUEST = `${prefix}/ADD_OR_REMOVE_MANAGER_REQUEST`
export const ADD_OR_REMOVE_MANAGER_SUCCESS = `${prefix}/ADD_OR_REMOVE_MANAGER_SUCCESS`
export const ADD_OR_REMOVE_MANAGER_FAILURE = `${prefix}/ADD_OR_REMOVE_MANAGER_FAILURE`

export const addOrRemoveManager: CommonPromiseAction = ({
  clientIds,
  managerId,
}: {
  clientIds: string[]
  managerId: string
}) => ({
  type: [
    ADD_OR_REMOVE_MANAGER_REQUEST,
    ADD_OR_REMOVE_MANAGER_SUCCESS,
    ADD_OR_REMOVE_MANAGER_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.addOrRemoveManager({ clientIds, managerId }),
})

export const REMOVE_MANAGER_REQUEST = `${prefix}/REMOVE_MANAGER_REQUEST`
export const REMOVE_MANAGER_SUCCESS = `${prefix}/REMOVE_MANAGER_SUCCESS`
export const REMOVE_MANAGER_FAILURE = `${prefix}/REMOVE_MANAGER_FAILURE`

export const removeManager: CommonPromiseAction = ({
  clientIds,
  managerId,
}: {
  clientIds: string[]
  managerId: string
}) => ({
  type: [REMOVE_MANAGER_REQUEST, REMOVE_MANAGER_SUCCESS, REMOVE_MANAGER_FAILURE],
  promise: (api: IApi) => api.clientInfo.removeManager({ clientIds, managerId }),
})

export const NOTIFY_REQUEST = `${prefix}/NOTIFY_REQUEST`
export const NOTIFY_SUCCESS = `${prefix}/NOTIFY_SUCCESS`
export const NOTIFY_FAILURE = `${prefix}/NOTIFY_FAILURE`

export const notifyManager: CommonPromiseAction = ({
  clientId,
  managerId,
}: {
  clientId: string
  managerId: string
}) => ({
  type: [NOTIFY_REQUEST, NOTIFY_SUCCESS, NOTIFY_FAILURE],
  promise: (api: IApi) => api.clientInfo.notifyManager({ clientId, managerId }),
})

export const UPDATE_MANAGER_REQUEST = `${prefix}/UPDATE_MANAGER_REQUEST`
export const UPDATE_MANAGER_SUCCESS = `${prefix}/UPDATE_MANAGER_SUCCESS`
export const UPDATE_MANAGER_FAILURE = `${prefix}/UPDATE_MANAGER_FAILURE`

export const updateManager: CommonPromiseAction = ({
  clientId,
  managerId,
  data,
}: {
  clientId: string
  managerId: string
  data: Partial<IUser>
}) => ({
  type: [UPDATE_MANAGER_REQUEST, UPDATE_MANAGER_SUCCESS, UPDATE_MANAGER_FAILURE],
  promise: (api: IApi) => api.clientInfo.updateManager({ clientId, managerId, data }),
})

const LIST_ACCOUNT_ACTIVITY = createRequestTypes(prefix, 'LIST_ACCOUNT_ACTIVITY')
export const [
  LIST_ACCOUNT_ACTIVITY_REQUEST,
  LIST_ACCOUNT_ACTIVITY_SUCCESS,
  LIST_ACCOUNT_ACTIVITY_FAILURE,
] = LIST_ACCOUNT_ACTIVITY

export const listAccountActivity: CommonPromiseAction = (
  clientId?: string,
  params?: {
    page?: number
    perPage?: number
    filters?: object
    orderBy?: string
    orderDirection?: string
  },
) => ({
  type: LIST_ACCOUNT_ACTIVITY,
  promise: (api: IApi) => api.clientInfo.listAccountActivity(clientId, params),
  params,
})

export const DELETE_ACCOUNT_ACTIVITY = createRequestTypes(prefix, 'DELETE_ACCOUNT_ACTIVITY')
export const [
  DELETE_ACCOUNT_ACTIVITY_REQUEST,
  DELETE_ACCOUNT_ACTIVITY_SUCCESS,
  DELETE_ACCOUNT_ACTIVITY_FAILURE,
] = DELETE_ACCOUNT_ACTIVITY

export const deleteAccountActivity: CommonPromiseAction = (clientId: string, data: object) => ({
  type: DELETE_ACCOUNT_ACTIVITY,
  promise: (api: IApi) => api.clientInfo.deleteAccountActivity(clientId, data),
})

export const [
  EXPORT_ACCOUNT_ACTIVITY_REQUEST,
  EXPORT_ACCOUNT_ACTIVITY_SUCCESS,
  EXPORT_ACCOUNT_ACTIVITY_FAILURE,
] = createRequestTypes(prefix, 'EXPORT_ACCOUNT_ACTIVITY')

export const exportAccountActivity: CommonPromiseAction = (
  clientId?: string,
  params?: {
    page?: number
    perPage?: number
    filters?: object
    orderBy?: string
    orderDirection?: string
  },
) => ({
  type: EXPORT_ACCOUNT_ACTIVITY_REQUEST,
  clientId,
  params,
})

export const EXPORT_CLIENT_FEE_STATEMENT_REQUEST = `${prefix}/EXPORT_CLIENT_FEE_STATEMENT_REQUEST`
export const EXPORT_CLIENT_FEE_STATEMENT_SUCCESS = `${prefix}/EXPORT_CLIENT_FEE_STATEMENT_SUCCESS`
export const EXPORT_CLIENT_FEE_STATEMENT_FAILURE = `${prefix}/EXPORT_CLIENT_FEE_STATEMENT_FAILURE`

export const exportClientFeeStatement: CommonPromiseAction = (params: object) => ({
  type: [
    EXPORT_CLIENT_FEE_STATEMENT_REQUEST,
    EXPORT_CLIENT_FEE_STATEMENT_SUCCESS,
    EXPORT_CLIENT_FEE_STATEMENT_FAILURE,
  ],
  promise: (api: IApi) => api.loanBalance.exportClientFeeStatement(params),
  params,
})

export const GET_REQUEST_FUNDING_REQUEST = `${prefix}/GET_REQUEST_FUNDING_REQUEST`
export const GET_REQUEST_FUNDING_SUCCESS = `${prefix}/GET_REQUEST_FUNDING_SUCCESS`
export const GET_REQUEST_FUNDING_FAILURE = `${prefix}/GET_REQUEST_FUNDING_FAILURE`

export const getRequestFunding: CommonPromiseAction = () => ({
  type: [GET_REQUEST_FUNDING_REQUEST, GET_REQUEST_FUNDING_SUCCESS, GET_REQUEST_FUNDING_FAILURE],
  promise: (api: IApi) => api.clientInfo.getRequestFunding(),
})

export const REQUEST_FUNDING_REQUEST = `${prefix}/REQUEST_FUNDING_REQUEST`
export const REQUEST_FUNDING_SUCCESS = `${prefix}/REQUEST_FUNDING_SUCCESS`
export const REQUEST_FUNDING_FAILURE = `${prefix}/REQUEST_FUNDING_FAILURE`

export const requestFunding: CommonPromiseAction = (data: FormData) => ({
  type: [REQUEST_FUNDING_REQUEST, REQUEST_FUNDING_SUCCESS, REQUEST_FUNDING_FAILURE],
  promise: (api: IApi) => api.clientInfo.requestFunding(data),
})

export const MODIFY_FUNDING_REQUEST = `${prefix}/MODIFY_FUNDING_REQUEST`
export const MODIFY_FUNDING_SUCCESS = `${prefix}/MODIFY_FUNDING_SUCCESS`
export const MODIFY_FUNDING_FAILURE = `${prefix}/MODIFY_FUNDING_FAILURE`

export const modifyFunding: CommonPromiseAction = (data: FormData) => ({
  type: [MODIFY_FUNDING_REQUEST, MODIFY_FUNDING_SUCCESS, MODIFY_FUNDING_FAILURE],
  promise: (api: IApi) => api.clientInfo.modifyFunding(data),
})

export const CANCEL_FUNDING_REQUEST = `${prefix}/CANCEL_FUNDING_REQUEST`
export const CANCEL_FUNDING_SUCCESS = `${prefix}/CANCEL_FUNDING_SUCCESS`
export const CANCEL_FUNDING_FAILURE = `${prefix}/CANCEL_FUNDING_FAILURE`

export const cancelFunding: CommonPromiseAction = (data: object) => ({
  type: [CANCEL_FUNDING_REQUEST, CANCEL_FUNDING_SUCCESS, CANCEL_FUNDING_FAILURE],
  promise: (api: IApi) => api.clientInfo.cancelFunding(data),
})

export const SUBMIT_ONGOING_REPORTING_REQUEST = `${prefix}/SUBMIT_ONGOING_REPORTING_REQUEST`
export const SUBMIT_ONGOING_REPORTING_SUCCESS = `${prefix}/SUBMIT_ONGOING_REPORTING_SUCCESS`
export const SUBMIT_ONGOING_REPORTING_FAILURE = `${prefix}/SUBMIT_ONGOING_REPORTING_FAILURE`

export const submitOngoingReporting: CommonPromiseAction = (id: string, data: FormData) => ({
  type: [
    SUBMIT_ONGOING_REPORTING_REQUEST,
    SUBMIT_ONGOING_REPORTING_SUCCESS,
    SUBMIT_ONGOING_REPORTING_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.submitOngoingReporting(id, data),
})

export const [
  CREATE_REQUIRED_REPORTS_REQUEST,
  CREATE_REQUIRED_REPORTS_SUCCESS,
  CREATE_REQUIRED_REPORTS_FAILURE,
] = createRequestTypes(prefix, 'CREATE_REQUIRED_REPORTS')

export const createRequiredReports: CommonPromiseAction = (data: FormData) => ({
  type: [
    CREATE_REQUIRED_REPORTS_REQUEST,
    CREATE_REQUIRED_REPORTS_SUCCESS,
    CREATE_REQUIRED_REPORTS_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.createRequiredReports(data),
})

export const [
  LIST_CLIENTS_AGGREGATION_REQUEST,
  LIST_CLIENTS_AGGREGATION_SUCCESS,
  LIST_CLIENTS_AGGREGATION_FAILURE,
] = createRequestTypes(prefix, 'LIST_CLIENTS_AGGREGATION')

export const listClientsAggregation: CommonPromiseAction = (params = {}) => {
  return {
    type: [
      LIST_CLIENTS_AGGREGATION_REQUEST,
      LIST_CLIENTS_AGGREGATION_SUCCESS,
      LIST_CLIENTS_AGGREGATION_FAILURE,
    ],
    promise: (api: IApi) => api.clientInfo.listClientsAggregation(params),
    params,
  }
}

export const LIST_CLIENT_OVERADVANCE_REQUEST = `${prefix}/LIST_CLIENT_OVERADVANCE_REQUEST`
export const LIST_CLIENT_OVERADVANCE_SUCCESS = `${prefix}/LIST_CLIENT_OVERADVANCE_SUCCESS`
export const LIST_CLIENT_OVERADVANCE_FAILURE = `${prefix}/LIST_CLIENT_OVERADVANCE_FAILURE`

export const listOveradvances: CommonPromiseAction = (id: string, params?: any) => ({
  type: [
    LIST_CLIENT_OVERADVANCE_REQUEST,
    LIST_CLIENT_OVERADVANCE_SUCCESS,
    LIST_CLIENT_OVERADVANCE_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.listOveradvances(id, params),
})

export const CREATE_CLIENT_OVERADVANCE_REQUEST = `${prefix}/CREATE_CLIENT_OVERADVANCE_REQUEST`
export const CREATE_CLIENT_OVERADVANCE_SUCCESS = `${prefix}/CREATE_CLIENT_OVERADVANCE_SUCCESS`
export const CREATE_CLIENT_OVERADVANCE_FAILURE = `${prefix}/CREATE_CLIENT_OVERADVANCE_FAILURE`

export const createOveradvance: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    CREATE_CLIENT_OVERADVANCE_REQUEST,
    CREATE_CLIENT_OVERADVANCE_SUCCESS,
    CREATE_CLIENT_OVERADVANCE_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.createOveradvance(id, data),
})

export const UPDATE_CLIENT_OVERADVANCE_REQUEST = `${prefix}/UPDATE_CLIENT_OVERADVANCE_REQUEST`
export const UPDATE_CLIENT_OVERADVANCE_SUCCESS = `${prefix}/UPDATE_CLIENT_OVERADVANCE_SUCCESS`
export const UPDATE_CLIENT_OVERADVANCE_FAILURE = `${prefix}/UPDATE_CLIENT_OVERADVANCE_FAILURE`

export const updateOveradvance: CommonPromiseAction = (
  clientId: string,
  overadvanceId: string,
  data: object,
) => ({
  type: [
    UPDATE_CLIENT_OVERADVANCE_REQUEST,
    UPDATE_CLIENT_OVERADVANCE_SUCCESS,
    UPDATE_CLIENT_OVERADVANCE_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.updateOveradvance(clientId, overadvanceId, data),
})

export const DELETE_CLIENT_OVERADVANCE_REQUEST = `${prefix}/DELETE_CLIENT_OVERADVANCE_REQUEST`
export const DELETE_CLIENT_OVERADVANCE_SUCCESS = `${prefix}/DELETE_CLIENT_OVERADVANCE_SUCCESS`
export const DELETE_CLIENT_OVERADVANCE_FAILURE = `${prefix}/DELETE_CLIENT_OVERADVANCE_FAILURE`

export const deleteOveradvance: CommonPromiseAction = (
  clientId: string,
  overadvanceId: string,
) => ({
  type: [
    DELETE_CLIENT_OVERADVANCE_REQUEST,
    DELETE_CLIENT_OVERADVANCE_SUCCESS,
    DELETE_CLIENT_OVERADVANCE_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.deleteOveradvance(clientId, overadvanceId),
})

export const CREATE_OVERADVANCE_PAYMENT_REQUEST = `${prefix}/CREATE_OVERADVANCE_PAYMENT_REQUEST`
export const CREATE_OVERADVANCE_PAYMENT_SUCCESS = `${prefix}/CREATE_OVERADVANCE_PAYMENT_SUCCESS`
export const CREATE_OVERADVANCE_PAYMENT_FAILURE = `${prefix}/CREATE_OVERADVANCE_PAYMENT_FAILURE`

export const createOveradvancePayment: CommonPromiseAction = (
  clientId: string,
  overadvanceId: string,
  data: object,
) => ({
  type: [
    CREATE_OVERADVANCE_PAYMENT_REQUEST,
    CREATE_OVERADVANCE_PAYMENT_SUCCESS,
    CREATE_OVERADVANCE_PAYMENT_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.createOveradvancePayment(clientId, overadvanceId, data),
})

export const LIST_OVERADVANCE_PAYMENTS_REQUEST = `${prefix}/LIST_OVERADVANCE_PAYMENTS_REQUEST`
export const LIST_OVERADVANCE_PAYMENTS_SUCCESS = `${prefix}/LIST_OVERADVANCE_PAYMENTS_SUCCESS`
export const LIST_OVERADVANCE_PAYMENTS_FAILURE = `${prefix}/LIST_OVERADVANCE_PAYMENTS_FAILURE`

export const listOveradvancePayments: CommonPromiseAction = (
  clientId: string,
  overadvanceId: string,
) => ({
  type: [
    LIST_OVERADVANCE_PAYMENTS_REQUEST,
    LIST_OVERADVANCE_PAYMENTS_SUCCESS,
    LIST_OVERADVANCE_PAYMENTS_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.listOveradvancePayments(clientId, overadvanceId),
})

export const DELETE_OVERADVANCE_PAYMENT_REQUEST = `${prefix}/DELETE_OVERADVANCE_PAYMENT_REQUEST`
export const DELETE_OVERADVANCE_PAYMENT_SUCCESS = `${prefix}/DELETE_OVERADVANCE_PAYMENT_SUCCESS`
export const DELETE_OVERADVANCE_PAYMENT_FAILURE = `${prefix}/DELETE_OVERADVANCE_PAYMENT_FAILURE`

export const deleteOveradvancePayment: CommonPromiseAction = (
  clientId: string,
  overadvanceId: string,
  paymentId: string,
) => ({
  type: [
    DELETE_OVERADVANCE_PAYMENT_REQUEST,
    DELETE_OVERADVANCE_PAYMENT_SUCCESS,
    DELETE_OVERADVANCE_PAYMENT_FAILURE,
  ],
  promise: (api: IApi) =>
    api.clientInfo.deleteOveradvancePayment(clientId, overadvanceId, paymentId),
})

export const LIST_COMPLIANCE_REQUEST = `${prefix}/LIST_COMPLIANCE_REQUEST`
export const LIST_COMPLIANCE_SUCCESS = `${prefix}/LIST_COMPLIANCE_SUCCESS`
export const LIST_COMPLIANCE_FAILURE = `${prefix}/LIST_COMPLIANCE_FAILURE`

export const listCompliance: CommonPromiseAction = (params: object = {}) => ({
  type: [LIST_COMPLIANCE_REQUEST, LIST_COMPLIANCE_SUCCESS, LIST_COMPLIANCE_FAILURE],
  promise: (api: IApi) => api.clientInfo.listCompliance(params),
  params,
})

export const UPDATE_CLIENT_UNDERWRITING_REQUEST = `${prefix}/UPDATE_CLIENT_UNDERWRITING_REQUEST`
export const UPDATE_CLIENT_UNDERWRITING_SUCCESS = `${prefix}/UPDATE_CLIENT_UNDERWRITING_SUCCESS`
export const UPDATE_CLIENT_UNDERWRITING_FAILURE = `${prefix}/UPDATE_CLIENT_UNDERWRITING_FAILURE`

export const updateUnderwriting: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    UPDATE_CLIENT_UNDERWRITING_REQUEST,
    UPDATE_CLIENT_UNDERWRITING_SUCCESS,
    UPDATE_CLIENT_UNDERWRITING_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.updateUnderwriting(id, data),
})

const CREATE_CLIENT_FUNDING = createRequestTypes(prefix, 'CREATE_CLIENT_FUNDING')

export const createFunding: CommonPromiseAction = (id: string, data: object) => ({
  type: CREATE_CLIENT_FUNDING,
  promise: (api: IApi) => api.clientInfo.createFunding(id, data),
})

const UPDATE_CLIENT_FUNDING = createRequestTypes(prefix, 'UPDATE_CLIENT_FUNDING')

export const updateFunding: CommonPromiseAction = (id: string, data: object) => ({
  type: UPDATE_CLIENT_FUNDING,
  promise: (api: IApi) => api.clientInfo.updateFunding(id, data),
})

export const UPDATE_CLIENT_PASSTHROUGHS_REQUEST = `${prefix}/UPDATE_CLIENT_PASSTHROUGHS_REQUEST`
export const UPDATE_CLIENT_PASSTHROUGHS_SUCCESS = `${prefix}/UPDATE_CLIENT_PASSTHROUGHS_SUCCESS`
export const UPDATE_CLIENT_PASSTHROUGHS_FAILURE = `${prefix}/UPDATE_CLIENT_PASSTHROUGHS_FAILURE`

export const updatePassthroughs: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    UPDATE_CLIENT_PASSTHROUGHS_REQUEST,
    UPDATE_CLIENT_PASSTHROUGHS_SUCCESS,
    UPDATE_CLIENT_PASSTHROUGHS_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.updatePassthroughs(id, data),
})

export const DELETE_CLIENT_PASSTHROUGHS_REQUEST = `${prefix}/DELETE_CLIENT_PASSTHROUGHS_REQUEST`
export const DELETE_CLIENT_PASSTHROUGHS_SUCCESS = `${prefix}/DELETE_CLIENT_PASSTHROUGHS_SUCCESS`
export const DELETE_CLIENT_PASSTHROUGHS_FAILURE = `${prefix}/DELETE_CLIENT_PASSTHROUGHS_FAILURE`

export const deletePassthroughs: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    DELETE_CLIENT_PASSTHROUGHS_REQUEST,
    DELETE_CLIENT_PASSTHROUGHS_SUCCESS,
    DELETE_CLIENT_PASSTHROUGHS_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.deletePassthroughs(id, data),
})

export const LIST_CLIENT_TERM_LOAN_REQUEST = `${prefix}/LIST_CLIENT_TERM_LOAN_REQUEST`
export const LIST_CLIENT_TERM_LOAN_SUCCESS = `${prefix}/LIST_CLIENT_TERM_LOAN_SUCCESS`
export const LIST_CLIENT_TERM_LOAN_FAILURE = `${prefix}/LIST_CLIENT_TERM_LOAN_FAILURE`

export const listTermLoans: CommonPromiseAction = (id: string) => ({
  type: [
    LIST_CLIENT_TERM_LOAN_REQUEST,
    LIST_CLIENT_TERM_LOAN_SUCCESS,
    LIST_CLIENT_TERM_LOAN_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.listTermLoans(id),
})

const LIST_AMORTIZATION_SCHEDULE = createRequestTypes(prefix, 'LIST_AMORTIZATION_SCHEDULE')

export const [
  LIST_AMORTIZATION_SCHEDULE_REQUEST,
  LIST_AMORTIZATION_SCHEDULE_SUCCESS,
  LIST_AMORTIZATION_SCHEDULE_FAILURE,
] = LIST_AMORTIZATION_SCHEDULE

export const listAmortizationSchedule: CommonPromiseAction = (id: string, termLoanId: string) => ({
  type: LIST_AMORTIZATION_SCHEDULE,
  promise: (api: IApi) => api.clientInfo.listAmortizationSchedule(id, termLoanId),
})

export const CREATE_CLIENT_TERM_LOAN_REQUEST = `${prefix}/CREATE_CLIENT_TERM_LOAN_REQUEST`
export const CREATE_CLIENT_TERM_LOAN_SUCCESS = `${prefix}/CREATE_CLIENT_TERM_LOAN_SUCCESS`
export const CREATE_CLIENT_TERM_LOAN_FAILURE = `${prefix}/CREATE_CLIENT_TERM_LOAN_FAILURE`

export const createTermLoan: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    CREATE_CLIENT_TERM_LOAN_REQUEST,
    CREATE_CLIENT_TERM_LOAN_SUCCESS,
    CREATE_CLIENT_TERM_LOAN_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.createTermLoan(id, data),
})

export const UPDATE_CLIENT_TERM_LOAN_REQUEST = `${prefix}/UPDATE_CLIENT_TERM_LOAN_REQUEST`
export const UPDATE_CLIENT_TERM_LOAN_SUCCESS = `${prefix}/UPDATE_CLIENT_TERM_LOAN_SUCCESS`
export const UPDATE_CLIENT_TERM_LOAN_FAILURE = `${prefix}/UPDATE_CLIENT_TERM_LOAN_FAILURE`

export const updateTermLoan: CommonPromiseAction = (
  id: string,
  termLoanId: string,
  data: object,
) => ({
  type: [
    UPDATE_CLIENT_TERM_LOAN_REQUEST,
    UPDATE_CLIENT_TERM_LOAN_SUCCESS,
    UPDATE_CLIENT_TERM_LOAN_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.updateTermLoan(id, termLoanId, data),
})

export const DELETE_CLIENT_TERM_LOAN_REQUEST = `${prefix}/DELETE_CLIENT_TERM_LOAN_REQUEST`
export const DELETE_CLIENT_TERM_LOAN_SUCCESS = `${prefix}/DELETE_CLIENT_TERM_LOAN_SUCCESS`
export const DELETE_CLIENT_TERM_LOAN_FAILURE = `${prefix}/DELETE_CLIENT_TERM_LOAN_FAILURE`

export const deleteTermLoan: CommonPromiseAction = (id: string, termLoanId: string) => ({
  type: [
    DELETE_CLIENT_TERM_LOAN_REQUEST,
    DELETE_CLIENT_TERM_LOAN_SUCCESS,
    DELETE_CLIENT_TERM_LOAN_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.deleteTermLoan(id, termLoanId),
})

const CREATE_TERM_LOAN_ACTIVITY = createRequestTypes(prefix, 'CREATE_TERM_LOAN_ACTIVITY')

export const [
  CREATE_TERM_LOAN_ACTIVITY_REQUEST,
  CREATE_TERM_LOAN_ACTIVITY_SUCCESS,
  CREATE_TERM_LOAN_ACTIVITY_FAILURE,
] = CREATE_TERM_LOAN_ACTIVITY

export const createTermLoanActivity: CommonPromiseAction = (
  id: string,
  termLoanId: string,
  data: object,
) => ({
  type: CREATE_TERM_LOAN_ACTIVITY,
  promise: (api: IApi) => api.clientInfo.createTermLoanActivity(id, termLoanId, data),
})

const DELETE_TERM_LOAN_ACTIVITY = createRequestTypes(prefix, 'DELETE_TERM_LOAN_ACTIVITY')

export const [
  DELETE_TERM_LOAN_ACTIVITY_REQUEST,
  DELETE_TERM_LOAN_ACTIVITY_SUCCESS,
  DELETE_TERM_LOAN_ACTIVITY_FAILURE,
] = DELETE_TERM_LOAN_ACTIVITY

export const deleteTermLoanActivity: CommonPromiseAction = (
  id: string,
  termLoanId: string,
  activityId: string,
) => ({
  type: DELETE_TERM_LOAN_ACTIVITY,
  promise: (api: IApi) => api.clientInfo.deleteTermLoanActivity(id, termLoanId, activityId),
})

const UPDATE_TERM_LOAN_ACTIVITY = createRequestTypes(prefix, 'UPDATE_TERM_LOAN_ACTIVITY')

export const [
  UPDATE_TERM_LOAN_ACTIVITY_REQUEST,
  UPDATE_TERM_LOAN_ACTIVITY_SUCCESS,
  UPDATE_TERM_LOAN_ACTIVITY_FAILURE,
] = UPDATE_TERM_LOAN_ACTIVITY

export const updateTermLoanActivity: CommonPromiseAction = (
  id: string,
  termLoanId: string,
  activityId: string,
  data: object,
) => ({
  type: UPDATE_TERM_LOAN_ACTIVITY,
  promise: (api: IApi) => api.clientInfo.updateTermLoanActivity(id, termLoanId, activityId, data),
})

export const LIST_AR_ELIGIBILITY_REQUEST = `${prefix}/LIST_AR_ELIGIBILITY_REQUEST`
export const LIST_AR_ELIGIBILITY_SUCCESS = `${prefix}/LIST_AR_ELIGIBILITY_SUCCESS`
export const LIST_AR_ELIGIBILITY_FAILURE = `${prefix}/LIST_AR_ELIGIBILITY_FAILURE`

export const listArEligibility: CommonPromiseAction = (id: string, data: object) => ({
  type: [LIST_AR_ELIGIBILITY_REQUEST, LIST_AR_ELIGIBILITY_SUCCESS, LIST_AR_ELIGIBILITY_FAILURE],
  promise: (api: IApi) => api.clientInfo.listArEligibility(id, data),
  params: data,
})

export const LIST_AP_ELIGIBILITY_REQUEST = `${prefix}/LIST_AP_ELIGIBILITY_REQUEST`
export const LIST_AP_ELIGIBILITY_SUCCESS = `${prefix}/LIST_AP_ELIGIBILITY_SUCCESS`
export const LIST_AP_ELIGIBILITY_FAILURE = `${prefix}/LIST_AP_ELIGIBILITY_FAILURE`

export const listApEligibility: CommonPromiseAction = (id: string, data: object) => ({
  type: [LIST_AP_ELIGIBILITY_REQUEST, LIST_AP_ELIGIBILITY_SUCCESS, LIST_AP_ELIGIBILITY_FAILURE],
  promise: (api: IApi) => api.clientInfo.listApEligibility(id, data),
  params: data,
})

export const UPDATE_AP_ELIGIBILITY_REQUEST = `${prefix}/UPDATE_AP_ELIGIBILITY_REQUEST`
export const UPDATE_AP_ELIGIBILITY_SUCCESS = `${prefix}/UPDATE_AP_ELIGIBILITY_SUCCESS`
export const UPDATE_AP_ELIGIBILITY_FAILURE = `${prefix}/UPDATE_AP_ELIGIBILITY_FAILURE`

export const updateApEligibility: CommonPromiseAction = (
  id: string,
  itemId: string,
  data: object,
) => ({
  type: [
    UPDATE_AP_ELIGIBILITY_REQUEST,
    UPDATE_AP_ELIGIBILITY_SUCCESS,
    UPDATE_AP_ELIGIBILITY_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.updateApEligibility(id, itemId, data),
})

export const LIST_INVENTORY_ELIGIBLE_CATEGORIES_REQUEST = `${prefix}/LIST_INVENTORY_ELIGIBLE_CATEGORIES_REQUEST`
export const LIST_INVENTORY_ELIGIBLE_CATEGORIES_SUCCESS = `${prefix}/LIST_INVENTORY_ELIGIBLE_CATEGORIES_SUCCESS`
export const LIST_INVENTORY_ELIGIBLE_CATEGORIES_FAILURE = `${prefix}/LIST_INVENTORY_ELIGIBLE_CATEGORIES_FAILURE`

export const listInventoryCategories: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    LIST_INVENTORY_ELIGIBLE_CATEGORIES_REQUEST,
    LIST_INVENTORY_ELIGIBLE_CATEGORIES_SUCCESS,
    LIST_INVENTORY_ELIGIBLE_CATEGORIES_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.listInventoryCategories(id, data),
})

export const LIST_MASTER_INVENTORY_REQUEST = `${prefix}/LIST_MASTER_INVENTORY_REQUEST`
export const LIST_MASTER_INVENTORY_SUCCESS = `${prefix}/LIST_MASTER_INVENTORY_SUCCESS`
export const LIST_MASTER_INVENTORY_FAILURE = `${prefix}/LIST_MASTER_INVENTORY_FAILURE`

export const listMasterInventory: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    LIST_MASTER_INVENTORY_REQUEST,
    LIST_MASTER_INVENTORY_SUCCESS,
    LIST_MASTER_INVENTORY_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.listMasterInventory(id, data),
  params: data,
})

export const LIST_INVENTORY_ELIGIBLE_FIELDS_REQUEST = `${prefix}/LIST_INVENTORY_ELIGIBLE_FIELDS_REQUEST`
export const LIST_INVENTORY_ELIGIBLE_FIELDS_SUCCESS = `${prefix}/LIST_INVENTORY_ELIGIBLE_FIELDS_SUCCESS`
export const LIST_INVENTORY_ELIGIBLE_FIELDS_FAILURE = `${prefix}/LIST_INVENTORY_ELIGIBLE_FIELDS_FAILURE`

export const listEligibleFields: CommonPromiseAction = (id: string, data: object = {}) => ({
  type: [
    LIST_INVENTORY_ELIGIBLE_FIELDS_REQUEST,
    LIST_INVENTORY_ELIGIBLE_FIELDS_SUCCESS,
    LIST_INVENTORY_ELIGIBLE_FIELDS_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.listEligibleFields(id),
  params: data,
})

export const UPDATE_INVENTORY_INELIGIBILITY_FIELDS_REQUEST = `${prefix}/UPDATE_INVENTORY_INELIGIBILITY_FIELDS_REQUEST`
export const UPDATE_INVENTORY_INELIGIBILITY_FIELDS_SUCCESS = `${prefix}/UPDATE_INVENTORY_INELIGIBILITY_FIELDS_SUCCESS`
export const UPDATE_INVENTORY_INELIGIBILITY_FIELDS_FAILURE = `${prefix}/UPDATE_INVENTORY_INELIGIBILITY_FIELDS_FAILURE`

export const updateEligibleFieldsAliases: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    UPDATE_INVENTORY_INELIGIBILITY_FIELDS_REQUEST,
    UPDATE_INVENTORY_INELIGIBILITY_FIELDS_SUCCESS,
    UPDATE_INVENTORY_INELIGIBILITY_FIELDS_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.updateEligibleFieldsAliases(id, data),
  aliases: data,
})

export const LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_REQUEST = `${prefix}/LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_REQUEST`
export const LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_SUCCESS = `${prefix}/LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_SUCCESS`
export const LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_FAILURE = `${prefix}/LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_FAILURE`

export const listCustomRules: CommonPromiseAction = (id: string, data?: object) => ({
  type: [
    LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_REQUEST,
    LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_SUCCESS,
    LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.listCustomRules(id, data),
})
export const LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_LABELS_REQUEST = `${prefix}/LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_LABELS_REQUEST`
export const LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_LABELS_SUCCESS = `${prefix}/LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_LABELS_SUCCESS`
export const LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_LABELS_FAILURE = `${prefix}/LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_LABELS_FAILURE`

export const listCustomRulesLabels: CommonPromiseAction = (id: string) => ({
  type: [
    LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_LABELS_REQUEST,
    LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_LABELS_SUCCESS,
    LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_LABELS_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.listCustomRulesLabels(id),
})

export const LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_VALUES_REQUEST = `${prefix}/LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_VALUES_REQUEST`
export const LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_VALUES_SUCCESS = `${prefix}/LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_VALUES_SUCCESS`
export const LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_VALUES_FAILURE = `${prefix}/LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_VALUES_FAILURE`

export const listCustomRulesValues: CommonPromiseAction = (
  id: string,
  data: { clientName: string; field: string },
) => ({
  type: [
    LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_VALUES_REQUEST,
    LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_VALUES_SUCCESS,
    LIST_INVENTORY_ELIGIBLE_CUSTOM_RULES_VALUES_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.listCustomRulesValues(id, data),
  field: data.field,
})

export const LIST_AMENDMENTS_REQUEST = `${prefix}/LIST_AMENDMENTS_REQUEST`
export const LIST_AMENDMENTS_SUCCESS = `${prefix}/LIST_AMENDMENTS_SUCCESS`
export const LIST_AMENDMENTS_FAILURE = `${prefix}/LIST_AMENDMENTS_FAILURE`

export const listAmendments: CommonPromiseAction = (id: string, data: object) => ({
  type: [LIST_AMENDMENTS_REQUEST, LIST_AMENDMENTS_SUCCESS, LIST_AMENDMENTS_FAILURE],
  promise: (api: IApi) => api.clientInfo.listAmendments(id, data),
  params: data,
})

const CREATE_AMENDMENT = createRequestTypes(prefix, 'CREATE_AMENDMENT')

export const [, CREATE_AMENDMENT_SUCCESS] = CREATE_AMENDMENT

export const createAmendment: CommonPromiseAction = (id: string, data: object) => ({
  type: CREATE_AMENDMENT,
  promise: (api: IApi) => api.clientInfo.createAmendment(id, data),
})

export const UPDATE_AMENDMENT_REQUEST = `${prefix}/UPDATE_AMENDMENT_REQUEST`
export const UPDATE_AMENDMENT_SUCCESS = `${prefix}/UPDATE_AMENDMENT_SUCCESS`
export const UPDATE_AMENDMENT_FAILURE = `${prefix}/UPDATE_AMENDMENT_FAILURE`

export const updateAmendment: CommonPromiseAction = (id: string, itemId: string, data: object) => ({
  type: [UPDATE_AMENDMENT_REQUEST, UPDATE_AMENDMENT_SUCCESS, UPDATE_AMENDMENT_FAILURE],
  promise: (api: IApi) => api.clientInfo.updateAmendment(id, itemId, data),
})

export const DELETE_AMENDMENT_REQUEST = `${prefix}/DELETE_AMENDMENT_REQUEST`
export const DELETE_AMENDMENT_SUCCESS = `${prefix}/DELETE_AMENDMENT_SUCCESS`
export const DELETE_AMENDMENT_FAILURE = `${prefix}/DELETE_AMENDMENT_FAILURE`

export const deleteAmendment: CommonPromiseAction = (id: string, itemId: string) => ({
  type: [DELETE_AMENDMENT_REQUEST, DELETE_AMENDMENT_SUCCESS, DELETE_AMENDMENT_FAILURE],
  promise: (api: IApi) => api.clientInfo.deleteAmendment(id, itemId),
})

const GET_BANNER = createRequestTypes(prefix, 'GET_BANNER')
export const [, GET_BANNER_SUCCESS] = GET_BANNER

export const getBanner: CommonPromiseAction = () => ({
  type: GET_BANNER,
  promise: (api: IApi) => api.clientInfo.getBanner(),
})

const CLOSE_BANNER = createRequestTypes(prefix, 'CLOSE_BANNER')
export const [, CLOSE_BANNER_SUCCESS] = CLOSE_BANNER

export const closeBanner: CommonPromiseAction = (id: string) => ({
  type: CLOSE_BANNER,
  promise: (api: IApi) => api.clientInfo.closeBanner(id),
})

export const EXPORT_PORTFOLIO_REVIEW_REQUEST = `${prefix}/EXPORT_PORTFOLIO_REVIEW_REQUEST`
export const EXPORT_PORTFOLIO_REVIEW_SUCCESS = `${prefix}/EXPORT_PORTFOLIO_REVIEW_SUCCESS`
export const EXPORT_PORTFOLIO_REVIEW_FAILURE = `${prefix}/EXPORT_PORTFOLIO_REVIEW_FAILURE`

export const exportPortfolioReview: CommonPromiseAction = () => ({
  type: EXPORT_PORTFOLIO_REVIEW_REQUEST,
})

export const LIST_CLIENT_FINANCIALS_REPORTS_REQUEST = `${prefix}/LIST_CLIENT_FINANCIALS_REPORTS_REQUEST`
export const LIST_CLIENT_FINANCIALS_REPORTS_SUCCESS = `${prefix}/LIST_CLIENT_FINANCIALS_REPORTS_SUCCESS`
export const LIST_CLIENT_FINANCIALS_REPORTS_FAILURE = `${prefix}/LIST_CLIENT_FINANCIALS_REPORTS_FAILURE`

export const listFinancialsReports: CommonPromiseAction = (id: string) => ({
  type: [
    LIST_CLIENT_FINANCIALS_REPORTS_REQUEST,
    LIST_CLIENT_FINANCIALS_REPORTS_SUCCESS,
    LIST_CLIENT_FINANCIALS_REPORTS_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.listFinancialsReports(id),
})

export const HIDE_CLIENT_FINANCIALS_REPORTS = `${prefix}/HIDE_CLIENT_FINANCIALS_REPORTS`

export const hideFinancialsReports: CommonPromiseAction = () => ({
  type: HIDE_CLIENT_FINANCIALS_REPORTS,
})

export const LIST_CLIENT_FINANCIALS_SUMMARY_REQUEST = `${prefix}/LIST_CLIENT_FINANCIALS_SUMMARY_REQUEST`
export const LIST_CLIENT_FINANCIALS_SUMMARY_SUCCESS = `${prefix}/LIST_CLIENT_FINANCIALS_SUMMARY_SUCCESS`
export const LIST_CLIENT_FINANCIALS_SUMMARY_FAILURE = `${prefix}/LIST_CLIENT_FINANCIALS_SUMMARY_FAILURE`

export const listClientFinancialsSummary: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    LIST_CLIENT_FINANCIALS_SUMMARY_REQUEST,
    LIST_CLIENT_FINANCIALS_SUMMARY_SUCCESS,
    LIST_CLIENT_FINANCIALS_SUMMARY_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.listFinancialsSummary(id, data),
})

export const LIST_RISK_RATING_REQUEST = `${prefix}/LIST_RISK_RATING_REQUEST`
export const LIST_RISK_RATING_SUCCESS = `${prefix}/LIST_RISK_RATING_SUCCESS`
export const LIST_RISK_RATING_FAILURE = `${prefix}/LIST_RISK_RATING_FAILURE`

export const listRiskRating: CommonPromiseAction = (id: string) => ({
  type: [LIST_RISK_RATING_REQUEST, LIST_RISK_RATING_SUCCESS, LIST_RISK_RATING_FAILURE],
  promise: (api: IApi) => api.clientInfo.listRiskRating(id),
})

export const [
  LIST_RISK_RATING_DETAILS_REQUEST,
  LIST_RISK_RATING_DETAILS_SUCCESS,
  LIST_RISK_RATING_DETAILS_FAILURE,
] = createRequestTypes(prefix, 'LIST_RISK_RATING_DETAILS')

export const listRiskRatingDetails: CommonPromiseAction = (data: object) => ({
  type: [
    LIST_RISK_RATING_DETAILS_REQUEST,
    LIST_RISK_RATING_DETAILS_SUCCESS,
    LIST_RISK_RATING_DETAILS_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.listRiskRatingDetails(data),
  params: data,
})

export const [
  EXPORT_RISK_RATING_DETAILS_REQUEST,
  EXPORT_RISK_RATING_DETAILS_SUCCESS,
  EXPORT_RISK_RATING_DETAILS_FAILURE,
] = createRequestTypes(prefix, 'EXPORT_RISK_RATING_DETAILS')

export const exportRiskRatingDetails: CommonPromiseAction = (data: object = {}) => ({
  type: [
    EXPORT_RISK_RATING_DETAILS_REQUEST,
    EXPORT_RISK_RATING_DETAILS_SUCCESS,
    EXPORT_RISK_RATING_DETAILS_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.listRiskRatingDetails({ ...data, isFile: true }),
  params: data,
})

export const LIST_INVENTORY_HEALTH_REQUEST = `${prefix}/LIST_INVENTORY_HEALTH_REQUEST`
export const LIST_INVENTORY_HEALTH_SUCCESS = `${prefix}/LIST_INVENTORY_HEALTH_SUCCESS`
export const LIST_INVENTORY_HEALTH_FAILURE = `${prefix}/LIST_INVENTORY_HEALTH_FAILURE`

export const listInventoryHealth: CommonPromiseAction = (id: string) => ({
  type: [
    LIST_INVENTORY_HEALTH_REQUEST,
    LIST_INVENTORY_HEALTH_SUCCESS,
    LIST_INVENTORY_HEALTH_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.listInventoryHealth(id),
})

export const SHOW_CASH_FLOW_SUMMARY_INFO_REQUEST = `${prefix}/SHOW_CASH_FLOW_SUMMARY_INFO_REQUEST`
export const SHOW_CASH_FLOW_SUMMARY_INFO_SUCCESS = `${prefix}/SHOW_CASH_FLOW_SUMMARY_INFO_SUCCESS`
export const SHOW_CASH_FLOW_SUMMARY_INFO_FAILURE = `${prefix}/SHOW_CASH_FLOW_SUMMARY_INFO_FAILURE`

export const showCashFlowInfo: CommonPromiseAction = (id: string, params: object) => ({
  type: [
    SHOW_CASH_FLOW_SUMMARY_INFO_REQUEST,
    SHOW_CASH_FLOW_SUMMARY_INFO_SUCCESS,
    SHOW_CASH_FLOW_SUMMARY_INFO_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.showCashFlowInfo(id, params),
  params,
})

const EXPORT_MASTER_INVENTORY = createRequestTypes(prefix, 'EXPORT_MASTER_INVENTORY')

export const [
  EXPORT_MASTER_INVENTORY_REQUEST,
  EXPORT_MASTER_INVENTORY_SUCCESS,
  EXPORT_MASTER_INVENTORY_FAILURE,
] = EXPORT_MASTER_INVENTORY

export const exportMasterInventory: CommonPromiseAction = (id: string, params: object) => ({
  type: EXPORT_MASTER_INVENTORY,
  promise: (api: IApi) => api.clientInfo.exportMasterInventory(id, params),
})

export const BULK_UPDATE_MASTER_INVENTORY = createRequestTypes(
  prefix,
  'BULK_UPDATE_MASTER_INVENTORY',
)

export const [
  BULK_UPDATE_MASTER_INVENTORY_REQUEST,
  BULK_UPDATE_MASTER_INVENTORY_SUCCESS,
  BULK_UPDATE_MASTER_INVENTORY_FAILURE,
] = BULK_UPDATE_MASTER_INVENTORY

export const bulkUpdateMasterInventory: CommonPromiseAction = (id: string, data: object) => ({
  type: BULK_UPDATE_MASTER_INVENTORY,
  promise: (api: IApi) => api.clientInfo.bulkUpdateMasterInventory(id, data),
})

const LIST_CUSTOMERS = createRequestTypes(prefix, 'LIST_CUSTOMERS')

export const [LIST_CUSTOMERS_REQUEST, LIST_CUSTOMERS_SUCCESS, LIST_CUSTOMERS_FAILURE] =
  LIST_CUSTOMERS

export const listCustomers: CommonPromiseAction = (id?: string) => ({
  type: LIST_CUSTOMERS,
  promise: (api: IApi) => api.clientInfo.listCustomers(id),
})

const REFRESH_AUTO_REPORTING = createRequestTypes(prefix, 'REFRESH_AUTO_REPORTING')

export const refreshAutoReporting: CommonPromiseAction = (data?: object) => ({
  type: REFRESH_AUTO_REPORTING,
  promise: (api: IApi) => api.clientInfo.refreshAutoReporting(data),
})

export const REFRESH_COUNT = `${prefix}/REFRESH_COUNT`

export const refreshCount: CommonPromiseAction = () => ({
  type: REFRESH_COUNT,
})

const ACCOUNT_ACTIVITY_GRAPH = createRequestTypes(prefix, 'ACCOUNT_ACTIVITY_PORTFOLIO_GRAPH')

export const [
  ACCOUNT_ACTIVITY_GRAPH_REQUEST,
  ACCOUNT_ACTIVITY_GRAPH_SUCCESS,
  ACCOUNT_ACTIVITY_GRAPH_FAILURE,
] = ACCOUNT_ACTIVITY_GRAPH

export const getAccountActivityGraph: CommonPromiseAction = (id: string, data: object) => ({
  type: ACCOUNT_ACTIVITY_GRAPH,
  promise: (api: IApi) => api.clientInfo.getAccountActivityGraph(id, data),
})

const LIST_LOAN_LEDGER = createRequestTypes(prefix, 'LIST_LOAN_LEDGER')

export const [LIST_LOAN_LEDGER_REQUEST, LIST_LOAN_LEDGER_SUCCESS, LIST_LOAN_LEDGER_FAILURE] =
  LIST_LOAN_LEDGER

export const listLoanLedger: CommonPromiseAction = (id: string, data: object) => ({
  type: LIST_LOAN_LEDGER,
  promise: (api: IApi) => api.clientInfo.listLoanLedger(id, data),
  params: data,
})

const LIST_STRESS_CASE_ASSUMPTIONS = createRequestTypes(prefix, 'LIST_STRESS_CASE_ASSUMPTIONS')
export const [LIST_STRESS_CASE_ASSUMPTIONS_REQUEST, LIST_STRESS_CASE_ASSUMPTIONS_SUCCESS] =
  LIST_STRESS_CASE_ASSUMPTIONS

export const listStressCaseAssumptions: CommonPromiseAction = (id: string, data: object) => ({
  type: LIST_STRESS_CASE_ASSUMPTIONS,
  promise: (api: IApi) => api.clientInfo.listStressCaseAssumptions(id, data),
})

const UPDATE_STRESS_CASE_ASSUMPTION = createRequestTypes(prefix, 'UPDATE_STRESS_CASE_ASSUMPTION')
export const [UPDATE_STRESS_CASE_ASSUMPTION_REQUEST, UPDATE_STRESS_CASE_ASSUMPTION_SUCCESS] =
  UPDATE_STRESS_CASE_ASSUMPTION

export const updateStressCaseAssumption: CommonPromiseAction = (
  id: string,
  assumptionId: string,
  data: object,
) => ({
  type: UPDATE_STRESS_CASE_ASSUMPTION,
  promise: (api: IApi) => api.clientInfo.updateStressCaseAssumption(id, assumptionId, data),
})

const LIST_COLLECTIONS_RECONCILIATION_OPTIONS = createRequestTypes(
  prefix,
  `COLLECTIONS_RECONCILIATION_OPTIONS`,
)

export const [LIST_COLLECTIONS_RECONCILIATION_OPTIONS_REQUEST] =
  LIST_COLLECTIONS_RECONCILIATION_OPTIONS

export const listCollectionsReconciliationOptions: CommonPromiseAction = (
  id: string,
  params: object = {},
) => ({
  type: LIST_COLLECTIONS_RECONCILIATION_OPTIONS,
  promise: (api: IApi) => api.clientInfo.listCollectionsReconciliationOptions(id, params),
  params,
})

const LIST_COLLECTIONS_RECONCILIATION = createRequestTypes(prefix, `COLLECTIONS_RECONCILIATION`)

export const [LIST_COLLECTIONS_RECONCILIATION_REQUEST] = LIST_COLLECTIONS_RECONCILIATION

export const listCollectionsReconciliation: CommonPromiseAction = (
  id: string,
  params: object = {},
) => ({
  type: LIST_COLLECTIONS_RECONCILIATION,
  promise: (api: IApi) => api.clientInfo.listCollectionsReconciliation(id, params),
  params,
})

export const HIDE_COLLECTIONS_RECONCILIATION = `${prefix}/HIDE_COLLECTIONS_RECONCILIATION`

export const hideCollectionsReconciliation: CommonPromiseAction = () => ({
  type: HIDE_COLLECTIONS_RECONCILIATION,
})

const LIST_SALES_CHANNEL_DISTRIBUTION_OPTIONS = createRequestTypes(
  prefix,
  `SALES_CHANNEL_DISTRIBUTION_OPTIONS`,
)

export const [LIST_SALES_CHANNEL_DISTRIBUTION_OPTIONS_REQUEST] =
  LIST_SALES_CHANNEL_DISTRIBUTION_OPTIONS

export const listSalesChannelDistributionOptions: CommonPromiseAction = (
  id: string,
  params: object = {},
) => ({
  type: LIST_SALES_CHANNEL_DISTRIBUTION_OPTIONS,
  promise: (api: IApi) => api.clientInfo.listSalesChannelDistributionOptions(id, params),
  params,
})

const LIST_SALES_CHANNEL_DISTRIBUTION = createRequestTypes(prefix, `SALES_CHANNEL_DISTRIBUTION`)

export const [LIST_SALES_CHANNEL_DISTRIBUTION_REQUEST] = LIST_SALES_CHANNEL_DISTRIBUTION

export const listSalesChannelDistribution: CommonPromiseAction = (
  id: string,
  params: object = {},
) => ({
  type: LIST_SALES_CHANNEL_DISTRIBUTION,
  promise: (api: IApi) => api.clientInfo.listSalesChannelDistribution(id, params),
  params,
})

export const HIDE_SALES_CHANNEL_DISTRIBUTION = `${prefix}/HIDE_SALES_CHANNEL_DISTRIBUTION`

export const hideSalesChannelDistribution: CommonPromiseAction = () => ({
  type: HIDE_SALES_CHANNEL_DISTRIBUTION,
})

const LIST_PENDING_CASH_DOMINION = createRequestTypes(prefix, 'LIST_PENDING_CASH_DOMINION')
export const [LIST_PENDING_CASH_DOMINION_REQUEST, LIST_PENDING_CASH_DOMINION_SUCCESS] =
  LIST_PENDING_CASH_DOMINION

export const listPendingCashDominion: CommonPromiseAction = (id: string, data: object) => ({
  type: LIST_PENDING_CASH_DOMINION,
  promise: (api: IApi) => api.clientInfo.listPendingCashDominion(id, data),
})
