import React from 'react'
import cn from 'classnames'

import styles from '../DealStructure/DealStructure.module.scss'
import termStructureStyles from '../../ProspectReportingSummaryTermStructure/ProspectReportingSummaryTermStructure.module.scss'

import { ClientABLField } from '../ClientHelpers'
import { allClientTermsInfo, ClientSetupSections, IClientInfo } from '@common/interfaces/client'
import RenderedValue from '../../RenderedValue'

const FIELDS_MAP = allClientTermsInfo.filter(
  (term) => term.section === ClientSetupSections.InterestRate,
)

const InterestRate = ({ clientInfo, readOnly }: { clientInfo: IClientInfo; readOnly: boolean }) => {
  return (
    <div className={termStructureStyles.section}>
      <div className={termStructureStyles.sectionTitle}>Interest Rate</div>

      <div className={termStructureStyles.sectionData}>
        {FIELDS_MAP.map(({ label, value, type, disabled, placeholder }) => (
          <div key={value} className={termStructureStyles.dataRow}>
            <div className={cn(termStructureStyles.label, styles.label)}>{label}</div>
            <div className={cn(termStructureStyles.value, styles.value)}>
              {readOnly ? (
                <RenderedValue value={clientInfo?.[value]} type={type} percentWithScale />
              ) : (
                <ClientABLField
                  name={value}
                  type={type}
                  disabled={disabled}
                  placeholder={placeholder}
                  size="small"
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default InterestRate
