import { combineReducers } from 'redux'

import { IDebtorCategories } from '@common/interfaces/bbc'
import * as actions from './actions'
import { ILoadingData } from '../types'
import { createLoadingDataReducer } from '../../helpers/redux'

export interface IOptionsState {
  debtorCategories: ILoadingData<IDebtorCategories>
}

export default combineReducers<IOptionsState>({
  debtorCategories: createLoadingDataReducer<IDebtorCategories>([
    actions.GET_DEBTOR_CATEGORIES_REQUEST,
  ]),
})
