import { ReactComponent as PinIcon } from '@assets/images/star-icon.svg'
import { ReactComponent as PhoneIcon } from '@assets/images/phone.svg'
import { ReactComponent as FlagIcon } from '@assets/images/flag-flat-icon.svg'
import { ReactComponent as ArchiveIcon } from '@assets/images/archive.svg'
import { ReactComponent as CheckIcon } from '@assets/images/check-circle-icon-alt.svg'
import { ReactComponent as OpenIcon } from '@assets/images/open-icon.svg'
import { ReactComponent as ListViewIcon } from '@assets/images/list-view.svg'
import { NotesFilterOptions } from '@common/interfaces/notes'

export const ACCEPT_TYPES = {
  excel: [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
  ],
}

export const EXCEL_DATE_FORMAT = 'yyyy-mm-dd'

export const YEAR_FORMAT = 'yyyy'
export const YEAR_FORMAT_MOMENT = 'YYYY'
export const MONTH_YEAR_FORMAT_MOMENT = 'MMM YYYY'
export const MONTH_YEAR_FORMAT_SHORT_MOMENT = 'MMM YY'

export const QUARTER_YEAR_FORMAT_SHORT_MOMENT = '[Q]Q YY'

export const DATE_FORMAT = 'MM/dd/yy'

export const DATE_TIME_FORMAT = 'MM/dd/yy HH:mm'

export const DATE_FORMAT_MOMENT = 'M/D/YY'

export const DATE_FORMAT_SHORT_MOMENT = 'M/YY'

export const DATE_TIME_FORMAT_MOMENT = 'M/D/YY - h:mm a'

export const TIME_FORMAT_MOMENT = 'h:mm a'

export const DATE_FORMAT_MOMENT_FULL = 'MMM D, YYYY'

export const DATE_FORMAT_DOT = 'D.MMM.YYYY'

export const DATE_DATABASE_FORMAT = 'YYYY-MM-DD'

export const STATES = {
  AK: 'Alaska',
  AL: 'Alabama',
  AR: 'Arkansas',
  AS: 'American Samoa',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District of Columbia',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  ME: 'Maine',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MP: 'Northern Mariana Islands',
  MS: 'Mississippi',
  MT: 'Montana',
  NA: 'National',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'New York',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VI: 'Virgin Islands',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming',
}

export const PHONE_MASK = '+1 (999) 999-9999'
export const PHONE_VALIDATE_REGEXP = /\+1 \(\d\d\d\) \d\d\d-\d\d\d\d/
export const FEDERAL_TAX_ID_MASK = '99-9999999'
export const FEDERAL_TAX_VALIDATE_REGEXP = /\d{2}[-]?\d{7}/
export const SOCIAL_SECURITY_NUMBER_MASK = '999-99-9999'
export const SOCIAL_SECURITY_VALIDATE_REGEXP = /\d{3}[-]?\d{2}[-]?\d{4}/
export const DOMAIN_REGEXP = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/\n]+)/

export const DEFAULT_MAX_FILE_SIZE = 50 * 1024 * 1024

export const ACCEPTED_FILES_MAPPING = {
  excel: {
    'application/vnd.ms-excel': ['.xls', '.xlsx', '.csv', '.xlsm'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xls',
      '.xlsx',
      '.csv',
      '.xlsm',
    ],
    'text/csv': ['.xls', '.xlsx', '.csv', '.xlsm'],
    'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xls', '.xlsx', '.csv', '.xlsm'],
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12': ['.xls', '.xlsx', '.csv', '.xlsm'],
    'application/vnd.ms-excel.template.macroEnabled.12': ['.xls', '.xlsx', '.csv', '.xlsm'],
    'application/vnd.ms-excel.addin.macroEnabled.12': ['.xls', '.xlsx', '.csv', '.xlsm'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template': [
      '.xls',
      '.xlsx',
      '.csv',
      '.xlsm',
    ],
  },
  pdf: {
    'application/pdf': ['.pdf'],
  },
  document: {
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/msword': ['.doc'],
  },
  image: {
    'image/*': ['.jpeg', '.png', '.jpg'],
  },
}

export const SAVED_LABEL_DELAY = 3000

export const excelOnlineLink = (fileId: string) =>
  `https://dwightfunding.app.box.com/integrations/officeonline/openOfficeOnline?fileId=${fileId}&sharedAccessCode=`

export const boxOnlineLink = (type: 'file' | 'folder', id: string) =>
  `https://dwightfunding.app.box.com/${type}/${id}`

export const DEFAULT_EMPTY_ARRAY_PROP: any[] = []

export const NOTES_FILTER_ICONS = {
  [NotesFilterOptions.All]: ListViewIcon,
  [NotesFilterOptions.Pinned]: PinIcon,
  [NotesFilterOptions.Open]: OpenIcon,
  [NotesFilterOptions.Resolved]: CheckIcon,
  [NotesFilterOptions.CallAgenda]: PhoneIcon,
  [NotesFilterOptions.Archived]: ArchiveIcon,
  [NotesFilterOptions.ActionRequired]: FlagIcon,
}
