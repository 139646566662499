import React from 'react'
import cn from 'classnames'

import styles from '../DealStructure/DealStructure.module.scss'
import termStructureStyles from '../../ProspectReportingSummaryTermStructure/ProspectReportingSummaryTermStructure.module.scss'

import {
  ClientInfoStatus,
  allClientTermsInfo,
  ClientSetupSections,
  IClientInfo,
} from '@common/interfaces/client'
import { ClientABLField } from '../ClientHelpers'
import RenderedValue from '../../RenderedValue'

const FIELDS_MAP = allClientTermsInfo.filter((term) => term.section === ClientSetupSections.Fees)

const pastClientFields = ['applyServicingFee', 'servicingFee', 'gracePeriod']

interface IProps {
  clientInfo: IClientInfo
  readOnly: boolean
}

const Fees = ({ clientInfo, readOnly }: IProps) => {
  return (
    <div className={termStructureStyles.section}>
      <div className={termStructureStyles.sectionTitle}>Fees</div>

      <div className={termStructureStyles.sectionData}>
        {FIELDS_MAP.map(({ label, value, type, placeholder }) => {
          if (
            pastClientFields.includes(value) &&
            ![ClientInfoStatus.Past, ClientInfoStatus.PastArchived].includes(
              clientInfo?.clientStatus,
            )
          ) {
            return null
          }

          return (
            <div key={value} className={termStructureStyles.dataRow}>
              <div className={cn(termStructureStyles.label, styles.label)}>{label}</div>
              <div className={cn(termStructureStyles.value, styles.value)}>
                {readOnly ? (
                  <RenderedValue value={clientInfo?.[value]} type={type} percentWithScale />
                ) : (
                  <ClientABLField name={value} type={type} placeholder={placeholder} size="small" />
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Fees
