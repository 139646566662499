import {
  IOutstandingParticipationWiresData,
  IParticipantData,
  IParticipationData,
  IParticipant,
  IParticipationPageData,
} from '../../../../common/interfaces/participant'
import { combineReducers } from 'redux'
import * as actions from './actions'
import { ILoadingData } from '../types'
import { createLoadingDataReducer } from '../../helpers/redux'
import {
  CREATE_CHECK_SUCCESS,
  CREATE_WIRE_SUCCESS,
  DELETE_CHECK_SUCCESS,
  DELETE_WIRE_SUCCESS,
} from '../collections/actions'
import {
  CREATE_ADJUSTMENT_SUCCESS,
  DELETE_ADJUSTMENT_SUCCESS,
  UPDATE_ADJUSTMENT_SUCCESS,
} from '../adjustment/actions'
import {
  CREATE_FEE_SUCCESS,
  DELETE_FEE_SUCCESS,
  UPDATE_FEE_SUCCESS,
  RESTORE_FEE_SUCCESS,
} from '../fee/actions'
import {
  DELETE_ACCOUNT_ACTIVITY_SUCCESS,
  DELETE_CLIENT_PASSTHROUGHS_SUCCESS,
  UPDATE_CLIENT_PASSTHROUGHS_SUCCESS,
} from '../clientInfo/actions'
import { DELETE_PASS_THROUGH_SUCCESS } from '../passThrough/actions'
import { DELETE_OUTGOING_WIRE_SUCCESS } from '../outgoingWire/actions'

export interface IParticipantState {
  participantInfo: ILoadingData<IParticipant>
  participants: ILoadingData<IParticipantData>
  clientParticipations: ILoadingData<IParticipationData>
  participantParticipations: ILoadingData<IParticipationPageData>
  outstandingParticipationWires: ILoadingData<IOutstandingParticipationWiresData>
  refreshCount: number
  allParticipations: ILoadingData<IParticipationData>
}

const REFRESH_ACTIONS = [
  actions.CREATE_PARTICIPATION_WIRE_SUCCESS,
  actions.UPDATE_PARTICIPATION_WIRE_SUCCESS,
  actions.DELETE_PARTICIPATION_WIRE_SUCCESS,
  CREATE_WIRE_SUCCESS,
  DELETE_WIRE_SUCCESS,
  CREATE_CHECK_SUCCESS,
  DELETE_CHECK_SUCCESS,
  CREATE_ADJUSTMENT_SUCCESS,
  UPDATE_ADJUSTMENT_SUCCESS,
  DELETE_ADJUSTMENT_SUCCESS,
  CREATE_FEE_SUCCESS,
  DELETE_FEE_SUCCESS,
  UPDATE_FEE_SUCCESS,
  RESTORE_FEE_SUCCESS,
  UPDATE_CLIENT_PASSTHROUGHS_SUCCESS,
  DELETE_CLIENT_PASSTHROUGHS_SUCCESS,
  DELETE_ACCOUNT_ACTIVITY_SUCCESS,
  DELETE_PASS_THROUGH_SUCCESS,
  DELETE_OUTGOING_WIRE_SUCCESS,
]

export default combineReducers<IParticipantState>({
  participantInfo: createLoadingDataReducer<IParticipant>([
    actions.SHOW_PARTICIPANT_REQUEST,
    actions.UPDATE_PARTICIPANT_REQUEST,
  ]),
  participants: createLoadingDataReducer<IParticipantData>([actions.LIST_PARTICIPANT_REQUEST]),
  clientParticipations: createLoadingDataReducer<IParticipationData>([
    actions.LIST_CLIENT_PARTICIPATION_REQUEST,
  ]),
  allParticipations: createLoadingDataReducer<IParticipationData>([
    actions.LIST_ALL_PARTICIPATION_REQUEST,
  ]),
  outstandingParticipationWires: createLoadingDataReducer<IOutstandingParticipationWiresData>([
    actions.LIST_OUTSTANDING_WIRES_REQUEST,
  ]),
  refreshCount: (state = 1, action) => {
    if (REFRESH_ACTIONS.includes(action.type)) {
      return state + 1
    }
    return state
  },
  participantParticipations: createLoadingDataReducer<IParticipationPageData>([
    actions.LIST_PARTICIPANT_PARTICIPATION_REQUEST,
  ]),
})
