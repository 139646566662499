import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { generatePath, matchPath, Link as RouterLink } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import styles from './ProspectDashboardHeader.module.scss'
import { IOPSReporting, IProspectReportingAggregation } from '@common/interfaces/prospects'
import Card from '../../components/Common/Card'
import OPSTabs from '../../components/OPSTabs'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { ROUTES } from '../../constants/routes'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import ProspectHeaderLinks from '../ProspectHeaderLinks'
import { ILoadingData } from 'src/redux/types'
import { OngoingReportingType } from '@common/interfaces/bbc'

interface IProps {
  reporting: IOPSReporting
  show: (id: string) => void
  pathname: string
  refreshCount: number
  setRefreshCounter?: Dispatch<SetStateAction<number>>
  prospectReporting: ILoadingData<IProspectReportingAggregation>
  listProspectReporting: (data: object) => void
  currentReportType: OngoingReportingType
}

const ProspectDashboardHeader = ({
  reporting,
  show,
  pathname,
  refreshCount,
  setRefreshCounter,
  prospectReporting,
  listProspectReporting,
  currentReportType,
}: IProps) => {
  const { id } = useParams<{ id?: string }>()

  const prospectReportingList = useMemo(() => {
    return prospectReporting.data?.data || []
  }, [prospectReporting])

  const currentBoxLink = useMemo(() => {
    return prospectReportingList.find((item) => item.type === currentReportType)?.boxLink
  }, [prospectReportingList, currentReportType])

  useEffect(() => {
    listProspectReporting({ opsReportingId: id })
  }, [id, listProspectReporting])

  useLoadInfo({ id, info: reporting, show })

  useEffect(() => {
    setRefreshCounter && setRefreshCounter((counter) => counter + 1)
  }, [refreshCount, setRefreshCounter])

  const title = useMemo(() => {
    if (matchPath(pathname, generatePath(ROUTES.PROSPECT_SUMMARY_PAGE, { id }))) {
      return 'View OPS'
    }
    return 'Process Documents'
  }, [pathname, id])

  const breadcrumbs = useMemo(
    () => [
      {
        link: ROUTES.PROSPECTS,
        Icon: HomeIcon,
      },
      {
        link: generatePath(ROUTES.PROSPECT_PAGE, { id }),
        title: reporting?.clientName,
      },
      {
        title,
        link: generatePath(ROUTES.PROSPECT_REPORTING_RECEIVABLES, { id }),
      },
    ],
    [reporting, id, title],
  )

  if (!reporting) {
    return (
      <Grid item xs={12}>
        <Breadcrumbs breadcrumbs={breadcrumbs} isLoading />
        <Card
          className={styles.card}
          withBorder={false}
          title={
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Skeleton width={600} height={80} />

              <Box
                className={styles.icons}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={1}
              >
                <Skeleton width={36} height={60} />
                <Skeleton width={36} height={60} />
                <Skeleton width={36} height={60} />
              </Box>
            </Box>
          }
        />
      </Grid>
    )
  }

  return (
    <Grid item xs={12}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Card
        className={styles.card}
        withBorder={false}
        title={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <OPSTabs
              prospectInfo={reporting.clientInfo}
              opsReportingFlows={reporting.opsReportingFlows}
            />
            <Box
              className={styles.icons}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Link
                className={styles.linkButton}
                component={RouterLink}
                to={generatePath(ROUTES.PROSPECT_SUMMARY_PAGE, { id })}
              >
                Summary
              </Link>
              <ProspectHeaderLinks currentBoxLink={currentBoxLink} />
            </Box>
          </Box>
        }
      ></Card>
    </Grid>
  )
}

export default ProspectDashboardHeader
