import { IEntityInfo, EntityCateogry } from '@common/interfaces/entityInfo'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import EntityDashboardHeader from '../../components/EntityDashboardHeader'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { ROUTES } from '../../constants/routes'
import EntitySummaryTable from '../../components/EntityReportSummaryTable'
import EntityDilutionTable from '../../components/EntityDilutionTable'
import EntityActivityTable from '../../components/EntityActivityTable'
import { generatePath } from 'react-router-dom'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import EntityLoader from './EntityPageLoader'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import InvestmentSummaryTable from '../../components/InvestmentSummaryTable'
import { WorkflowTypes } from '@common/interfaces/notes'

interface IProps {
  isLoading: boolean
  getEntityInfo: (id: string) => void
  hideEntityInfo: () => void
  getEntityInfoDuplicates: (id: string) => Promise<any>
  entity: IEntityInfo
  getDebtorCategories: (data: object) => void
}

const ENTITY_REPORT_MAPPING = {
  Customer: 'AR',
  Vendor: 'AP',
}

const EntityPage = ({
  isLoading,
  getEntityInfo,
  hideEntityInfo,
  getEntityInfoDuplicates,
  entity,
  getDebtorCategories,
}: IProps) => {
  const { id: entityId } = useParams<{ id?: string }>()
  const [filteredTabs, setFilteredTabs] = useState<string[]>([])
  const [currentTab, setCurrentTab] = useState<string>(filteredTabs[0])
  const [isLoadingDuplicates, setIsLoadingDuplicates] = useState(true)

  useEffect(() => {
    const updatedFilteredTabs = [
      EntityCateogry.Customer,
      EntityCateogry.Vendor,
      EntityCateogry.Investor,
    ].filter((tab) => {
      return (
        (entity?.debtor && tab === EntityCateogry.Customer) ||
        (entity?.creditor && tab === EntityCateogry.Vendor) ||
        (entity?.investor && tab === EntityCateogry.Investor)
      )
    })
    setFilteredTabs(updatedFilteredTabs)
    setCurrentTab(updatedFilteredTabs[0])
  }, [entity?.debtor, entity?.creditor, entity?.investor])

  useSetPageTitle(entityId === entity?.id ? entity?.name : '')

  useEffect(() => {
    if (entityId) {
      hideEntityInfo()
      getEntityInfo(entityId)
    }
  }, [entityId, getEntityInfo, hideEntityInfo])

  const fetchDuplicates = useCallback(
    async (id: string) => {
      setIsLoadingDuplicates(true)
      await getEntityInfoDuplicates(id)
      setIsLoadingDuplicates(false)
    },
    [getEntityInfoDuplicates],
  )

  useEffect(() => {
    if (entity?.id) {
      fetchDuplicates(entity.id)
    }
  }, [entity?.id, fetchDuplicates])

  useEffect(() => {
    if (entityId) {
      getDebtorCategories({
        id: entityId,
        workflow: WorkflowTypes.entityPage,
      })
    }
  }, [entityId, getDebtorCategories])

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.HOMEPAGE,
        Icon: HomeIcon,
      },
      {
        link: ROUTES.ENTITY_MANAGEMENT,
        title: 'Entities',
      },
      {
        link: generatePath(ROUTES.ENTITY_PAGE, { id: entityId }),
        title: entity?.name,
      },
    ]
  }, [entityId, entity?.name])

  if (!entity && isLoading) {
    return <EntityLoader />
  }

  return (
    entity && (
      <Box py={1} px={2}>
        <Breadcrumbs breadcrumbs={breadcrumbs} isLoading={isLoading} noMarginLeft />
        <Grid container spacing={1}>
          <EntityDashboardHeader
            setCurrentTab={setCurrentTab}
            tabs={filteredTabs}
            isLoading={isLoading}
            isLoadingDuplicates={isLoadingDuplicates}
            isInvestor={currentTab === EntityCateogry.Investor}
          />
          {currentTab && currentTab !== EntityCateogry.Investor && (
            <Grid item xs={12}>
              <EntitySummaryTable reportType={ENTITY_REPORT_MAPPING[currentTab]} />
            </Grid>
          )}
          {currentTab === EntityCateogry.Customer && (
            <Grid item xs={12}>
              <EntityDilutionTable />
            </Grid>
          )}
          {currentTab === EntityCateogry.Customer && (
            <Grid item xs={12}>
              <EntityActivityTable />
            </Grid>
          )}
          {currentTab === EntityCateogry.Investor && (
            <Grid item xs={12}>
              <InvestmentSummaryTable entityInfo={entity} />
            </Grid>
          )}
        </Grid>
      </Box>
    )
  )
}

export default EntityPage
