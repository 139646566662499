import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { useParams, useHistory, useLocation } from 'react-router'
import { generatePath } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import styles from './BankTransactionsPage.module.scss'
import FullscreenModal from '../../components/Common/FullscreenModal'
import Card from '../../components/Common/Card'
import { IOngoingReporting, OngoingReportingStatus, ReportingFlow } from '@common/interfaces/bbc'
import Button from '../../components/Common/Button'
import { ROUTES } from '../../constants/routes'
import { REPORTING_DOCUMENT_TYPES } from '@common/constants/client'
import { BANK_TRANSACTIONS_TABS } from '@common/interfaces/bankTransactions'
import BankTransactionsMapping from '../../components/BankTransactionsMapping'
import moment from 'moment'
import { historyChangeEventHandler, visibilityChangeEventHandler } from '../../helpers/helpers'
import Tabs from '../../components/Common/Tabs'
import BankTransactionsMappingLegend from '../../components/BankTransactionsMappingLegend'
import { EditMapping, EditSourceData, ExternalLink } from '../../components/Common/Icons'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import BankTransactions from '../../components/BankTransactions'
import { WorkflowTypes } from '@common/interfaces/notes'
import OngoingReportingFiles from '../../components/OngoingReportingFiles'
import useOngoingReportingFileSheet from '../../hooks/useOngoingReportingFileSheet'
import { ILoadingData } from 'src/redux/types'
import { IBankAccount } from '@common/interfaces/bankAccount'
import SelectField from '../../components/Common/SelectField'
import SaveState from '../../components/Common/SaveState'

const TABS = [
  BANK_TRANSACTIONS_TABS.ACCOUNTS,
  BANK_TRANSACTIONS_TABS.DEBITS,
  BANK_TRANSACTIONS_TABS.CREDITS,
]

interface IProps {
  updateOngoingReportingStatus: (data: object) => void
  showOngoingReporting: (id: string) => Promise<void>
  ongoingReporting: IOngoingReporting
  trackActivity: (data: object, beacon?: boolean) => void
  calculateBankTransactionFlags: (id: string) => void
  processBankTransactions: (data: object) => Promise<any>
  banks: ILoadingData<{ data: IBankAccount[] }>
  listBankAccounts: (clientId: string, params: object) => void
  mapBankAccount: (data: object) => Promise<any>
}

const BankTransactionsPage = ({
  updateOngoingReportingStatus,
  showOngoingReporting,
  ongoingReporting,
  trackActivity,
  calculateBankTransactionFlags,
  processBankTransactions,
  banks,
  listBankAccounts,
  mapBankAccount,
}: IProps) => {
  const { id: ongoingReportingId } = useParams<{ id?: string }>()
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const [refreshCounter, setRefreshCounter] = useState(1)
  const [isSaving, setIsSaving] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [tabValue, setTabValue] = useState<string>(BANK_TRANSACTIONS_TABS.ACCOUNTS)
  const [isMappingModalOpen, setIsMappingModalOpen] = useState(false)
  const [isFileSelectShown, setIsFileSelectShown] = useState(false)
  const [startTime, setStartTime] = useState(moment().toISOString())
  const { pathname } = useLocation()

  const handleOpenSelectFile = useCallback(() => {
    setIsFileSelectShown(true)
  }, [setIsFileSelectShown])

  const handleCloseSelectFile = useCallback(() => {
    setIsFileSelectShown(false)
  }, [setIsFileSelectShown])

  useEffect(() => {
    if (ongoingReporting?.clientInfo?.id) {
      listBankAccounts(ongoingReporting?.clientInfo?.id, {
        filters: { isExternal: true },
      })
    }
  }, [ongoingReporting?.clientInfo?.id, listBankAccounts])

  const { clientBanks } = useMemo(
    () => ({
      clientBanks: banks?.data?.data?.map(({ id, bankName, bankAccountNumber }) => ({
        value: id,
        label: `${bankName} - ${bankAccountNumber.trim().slice(-4)}`,
      })),
    }),
    [banks],
  )

  const isDisabled = useMemo(
    () =>
      [OngoingReportingStatus.Verified, OngoingReportingStatus.Archived].includes(
        ongoingReporting?.status,
      ),
    [ongoingReporting],
  )
  useSetPageTitle(`${ongoingReporting?.clientName} Bank Monitoring ` || '')
  useEffect(() => {
    if (id && refreshCounter) {
      showOngoingReporting(id)
    }
  }, [id, showOngoingReporting, refreshCounter])

  const { fileSheets, currentSelectedFile } = useOngoingReportingFileSheet({
    reporting: ongoingReporting,
    type: REPORTING_DOCUMENT_TYPES.bankTransactions,
  })
  const handleToggleModal = useCallback(() => {
    setIsMappingModalOpen((isOpen) => !isOpen)
  }, [])

  const handleSelectFiles = useCallback(
    async (fileSheets: { fileId: string; sheetName: string }[]) => {
      const result = await processBankTransactions({
        id,
        fileSheets: fileSheets.map((item) => ({
          ...item,
          type: REPORTING_DOCUMENT_TYPES.bankTransactions,
        })),
      })
      setRefreshCounter((prev) => prev + 1)
      if (!result.error) {
        handleCloseSelectFile()
        handleToggleModal()
      }

      return result
    },
    [processBankTransactions, id, handleToggleModal, handleCloseSelectFile],
  )

  const logActivity = useCallback(
    (beacon: boolean = false) => {
      if (id && !isDisabled) {
        const endTime = moment().toISOString()
        const data = {
          ongoingReportingId: id,
          startedAt: startTime,
          finishedAt: endTime,
          workflow: REPORTING_DOCUMENT_TYPES.bankTransactions,
          step: REPORTING_DOCUMENT_TYPES.bankTransactions,
        }
        trackActivity(data, beacon)
        setStartTime(endTime)
      }
    },
    [id, startTime, trackActivity, isDisabled],
  )
  useEffect(() => {
    const unlisten = historyChangeEventHandler(logActivity, history, pathname)
    return unlisten
  }, [history, pathname, logActivity])

  useEffect(() => {
    const unlisten = visibilityChangeEventHandler(logActivity)
    return unlisten
  }, [logActivity])

  const handleSubmitTransactions = useCallback(async () => {
    logActivity()
    setIsSubmitting(true)
    await updateOngoingReportingStatus({
      id: ongoingReportingId,
      status: OngoingReportingStatus.Verified,
    })
    setIsSubmitting(false)
    history.push(ROUTES.ANALYSIS_QUEUE)
  }, [updateOngoingReportingStatus, ongoingReportingId, history, logActivity])

  const currentClientName = useMemo(() => {
    if (ongoingReporting?.id === id) {
      return ongoingReporting?.clientName
    }
  }, [ongoingReporting, id])

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.HOMEPAGE,
        Icon: HomeIcon,
      },
      {
        link: ROUTES.ANALYSIS_QUEUE,
        title: 'Analysis queue',
      },
      {
        title: currentClientName,
        link: ongoingReporting?.clientInfo?.id
          ? generatePath(ROUTES.CLIENT_PAGE, { id: ongoingReporting?.clientInfo?.id })
          : '',
      },
      {
        link: generatePath(ROUTES.ONGOING_REPORTING_BANK_TRANSACTIONS_UPLOAD, { id }),
        title: `Bank monitoring`,
      },
    ]
  }, [id, currentClientName, ongoingReporting?.clientInfo?.id])

  const handleCloseMappingModal = useCallback(() => {
    setRefreshCounter((refreshCounter) => refreshCounter + 1)
    !isDisabled && calculateBankTransactionFlags(id)
    setIsMappingModalOpen(false)
  }, [calculateBankTransactionFlags, id, isDisabled])

  const selectedFiles = useMemo(() => {
    return fileSheets?.sort((a, b) => a.id.localeCompare(b.id))?.filter((file) => file.sheetName)
  }, [fileSheets])

  const handleUpdateBankAccount = useCallback(
    async (value: string, fileSheetId: string) => {
      setIsSaving(true)
      await mapBankAccount({
        id,
        bankAccountId: value,
        fileSheetId,
      })
      await showOngoingReporting(id)
      setIsSaving(false)
    },
    [mapBankAccount, id, showOngoingReporting],
  )

  useEffect(() => {
    if (ongoingReporting?.id === id && !currentSelectedFile) {
      handleOpenSelectFile()
    }
  }, [currentSelectedFile, handleOpenSelectFile, id, ongoingReporting?.id])

  const mappingRequired = useMemo(() => {
    return ongoingReporting?.mappingRequired
  }, [ongoingReporting])

  return (
    <Box py={1} pr={2}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <Card
        withBorder={false}
        title={
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              {currentSelectedFile?.link && <ExternalLink link={currentSelectedFile?.link} />}

              <EditSourceData
                action={handleOpenSelectFile}
                error={!!fileSheets?.filter(({ file }) => file && file.error).length}
              />
              <EditMapping
                action={handleToggleModal}
                title="Mapping"
                mappingRequired={mappingRequired}
                mappingDisabled={isDisabled}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" ml={2}>
              {isDisabled ? (
                <Button
                  className={styles.button}
                  type="submit"
                  color="primary"
                  variant="contained"
                  size="small"
                  href={ROUTES.ANALYSIS_QUEUE}
                >
                  Back to Analysis queue
                </Button>
              ) : (
                <Button
                  className={styles.button}
                  type="button"
                  onClick={handleSubmitTransactions}
                  color="primary"
                  variant="contained"
                  size="small"
                  disabled={mappingRequired}
                  isLoading={isSubmitting}
                >
                  Submit
                </Button>
              )}
            </Box>
          </Box>
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <OngoingReportingFiles
              handleSelectFiles={handleSelectFiles}
              fileSheets={fileSheets}
              files={ongoingReporting?.files}
              isDisabled={isDisabled}
              handleCloseModal={handleCloseSelectFile}
              handleOpenModal={handleOpenSelectFile}
              isOpen={isFileSelectShown}
              setRefreshCounter={setRefreshCounter}
            />

            {isMappingModalOpen && (
              <FullscreenModal
                isOpen
                setIsOpen={handleCloseMappingModal}
                disableEnforceFocus
                showCloseIcon
                classes={{
                  body: styles.fullScreenModalBody,
                }}
              >
                <Card
                  withBorder={false}
                  classes={{
                    title: styles.mappingContainerTitle,
                    content: styles.mappingContainerContent,
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={1}
                    mr={5}
                    className={styles.mappingContainerTabs}
                  >
                    <Tabs tabs={TABS} selected={tabValue} handleChange={setTabValue} />
                    {tabValue !== BANK_TRANSACTIONS_TABS.ACCOUNTS && (
                      <BankTransactionsMappingLegend />
                    )}
                  </Box>
                  {tabValue === BANK_TRANSACTIONS_TABS.ACCOUNTS ? (
                    <Grid
                      container
                      className={styles.accountMappingContainer}
                      justifyContent={'flex-start'}
                      mt={2}
                      rowGap={2}
                    >
                      {selectedFiles.map((file) => (
                        <Grid
                          container
                          item
                          xs={12}
                          justifyContent={'space-between'}
                          display={'flex'}
                        >
                          <Grid
                            item
                            xs={12}
                            justifyContent={'space-between'}
                            display={'flex'}
                            alignItems={'center'}
                            gap={2}
                          >
                            <div className={styles.fileSheetName}>
                              {file.file.fileName} - {file.sheetName}
                            </div>
                            <div>
                              <SelectField
                                border
                                className={styles.selectField}
                                selectSize="medium"
                                placeholder={'Select bank account'}
                                useFinalForm={false}
                                options={clientBanks}
                                value={file?.bankAccountId || ''}
                                onChange={(event) =>
                                  handleUpdateBankAccount(event.target.value, file.id)
                                }
                                name="bankAccountId"
                              />
                            </div>
                          </Grid>
                        </Grid>
                      ))}
                      <Grid item xs={12} justifyContent={'flex-end'} display={'flex'}>
                        <SaveState isSaving={isSaving} isSaved={!isSaving} />
                      </Grid>
                    </Grid>
                  ) : (
                    <BankTransactionsMapping
                      id={id}
                      currentClientName={currentClientName}
                      transactionType={
                        tabValue === BANK_TRANSACTIONS_TABS.DEBITS ? 'Debit' : 'Credit'
                      }
                      isModalShown={true}
                      reportingFlow={ReportingFlow.OngoingReporting}
                      tableClassName={styles.mappingTable}
                    />
                  )}
                </Card>
              </FullscreenModal>
            )}

            <Grid item xs={12}>
              <BankTransactions
                workflow={WorkflowTypes.ongoingReporting}
                refreshCounter={refreshCounter}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Box>
  )
}

export default BankTransactionsPage
