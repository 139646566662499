import React, { useCallback, useState } from 'react'
import { generatePath } from 'react-router-dom'
import { useHistory } from 'react-router'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import styles from './ManageTermsActionMenu.module.scss'

import { IOPSReporting, IProspectTerms } from '@common/interfaces/prospects'
import { ROUTES } from '../../../constants/routes'
import WarningModal from '../../../components/WarningModal'
import { MenuIcon } from '../../../components/Common/Icons'

interface IProps {
  reporting: IOPSReporting
  term: IProspectTerms
  id: string
  deleteProspectTerms: (id: string, prospectTermsId: string) => void
  userId: string
}

const ManageTermsActionMenu = ({ term, id, deleteProspectTerms, userId }: IProps) => {
  const history = useHistory()

  const [anchorEl, setAnchorEl] = useState(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState(false)

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setMenuOpen(true)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
    setMenuOpen(false)
  }, [])

  const handleEdit = useCallback(() => {
    history.push(generatePath(ROUTES.PROSPECT_EDIT_TERMS_PAGE, { id, termId: term.id }))
    handleCloseMenu()
  }, [handleCloseMenu, id, history, term])

  const handleDeleteConfrim = useCallback(() => {
    deleteProspectTerms(id, term.id)
    handleCloseMenu()
  }, [handleCloseMenu, id, deleteProspectTerms, term])

  const handleDeleteCancel = useCallback(() => {
    setIsDeleteWarningOpen(false)
  }, [])

  const handleDelete = useCallback(() => {
    setIsDeleteWarningOpen(true)
    handleCloseMenu()
  }, [handleCloseMenu])

  if (term.user?.id !== userId) {
    return <div />
  }

  return (
    <div className={styles.wrapper}>
      <MenuIcon isActive={menuOpen} onClick={handleClickMenu} size="small" />
      <Menu open={menuOpen} onClose={handleCloseMenu} anchorEl={anchorEl}>
        <MenuItem disabled={term.user?.id !== userId} onClick={handleEdit}>
          Edit
        </MenuItem>
        <MenuItem disabled={term.user?.id !== userId} onClick={handleDelete}>
          Delete
        </MenuItem>
      </Menu>
      {isDeleteWarningOpen && (
        <WarningModal
          warningMessage="Are you sure you want to delete these terms?"
          onConfirm={handleDeleteConfrim}
          onCancel={handleDeleteCancel}
          confirmText="Delete"
          cancelText="Cancel"
        />
      )}
    </div>
  )
}

export default ManageTermsActionMenu
