import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'
import { createRequestTypes } from '../../helpers/helpers'

export const prefix = 'options'

const GET_DEBTOR_CATEGORIES = createRequestTypes(prefix, 'GET_DEBTOR_CATEGORIES')
export const [GET_DEBTOR_CATEGORIES_REQUEST] = GET_DEBTOR_CATEGORIES

export const getDebtorCategories: CommonPromiseAction = (params: object) => ({
  type: GET_DEBTOR_CATEGORIES,
  promise: (api: IApi) => api.option.getDebtorCategories(params),
  params,
})
