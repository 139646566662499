import React from 'react'
import Grid from '@mui/material/Grid'
import { IAlias } from '@common/interfaces/bbc'
import { tableFieldMapping } from '@common/constants/mappings'
import AliasMapping from '../AliasMapping'
import Card from '../Common/Card'
import { ILoadingData } from '../../redux/types'

interface IProps {
  aliases: ILoadingData<{ data: IAlias[] }>
  listEntityInfo: (data: object) => Promise<{ data: any }>
  addEntityInfo: (data: object) => Promise<any>
  updateAlias?: (data: object) => Promise<any>
  deleteAlias?: (data: object) => Promise<any>
  listAliasMapping?: (id: string, data: object) => void
  listEntityAliases?: (id: string, data: object) => void
  hideAliases?: () => void
  id?: string
  fileId?: string
  table?: keyof typeof tableFieldMapping
  clientName?: string | null
  tabOptions?: string[]
  handleTabChange?: (tab: string) => void
  currentTab?: string
  tableClassName?: string
  isProspect?: boolean
  readOnly?: boolean
  noPadding?: boolean
  bbcId?: string
}

const ClientAliasMapping = ({
  aliases,
  listEntityInfo,
  addEntityInfo,
  updateAlias,
  deleteAlias,
  listAliasMapping,
  listEntityAliases,
  hideAliases,
  id,
  fileId,
  table,
  clientName = null,
  tabOptions,
  handleTabChange,
  currentTab,
  tableClassName,
  isProspect = false,
  readOnly = false,
  noPadding = false,
  bbcId,
}: IProps) => {
  return (
    <Grid item xs={12}>
      <Card noHeaderMargin withBorder={false} noPadding={noPadding}>
        <AliasMapping
          aliasesData={aliases}
          listEntityInfo={listEntityInfo}
          addEntityInfo={addEntityInfo}
          updateAlias={updateAlias}
          deleteAlias={deleteAlias}
          listAliasMapping={id ? listEntityAliases : listAliasMapping}
          hideAliasMapping={hideAliases}
          id={fileId || id}
          table={table}
          clientName={clientName}
          tabOptions={tabOptions}
          handleTabChange={handleTabChange}
          currentTab={currentTab}
          tableClassName={tableClassName}
          isProspect={isProspect}
          readOnly={readOnly}
          bbcId={bbcId}
        />
      </Card>
    </Grid>
  )
}

export default ClientAliasMapping
