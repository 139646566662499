import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'

import OngoingReportingFiles from './OngoingReportingFiles'

import { isLoading as isLoadingFile } from '../../redux/file/selectors'
import { uploadFile, deleteFile } from '../../redux/file/actions'
import {
  isLoading as isLoadingOngoingReporting,
  ongoingReporting,
} from '../../redux/ongoingReporting/selectors'
import { showOngoingReporting } from '../../redux/ongoingReporting/actions'
import { downloadTemplate } from '../../redux/bbc/actions'

const selector = createStructuredSelector({
  isLoading: createSelector(
    isLoadingFile,
    isLoadingOngoingReporting,
    (fileLoading, reportingLoading) => fileLoading || reportingLoading,
  ),
  ongoingReporting,
})

const actions = {
  showOngoingReporting,
  uploadFile,
  deleteFile,
  downloadTemplate,
}

export default connect(selector, actions)(OngoingReportingFiles)
