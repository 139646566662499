import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { trackActivity } from '../../redux/activity/actions'

import BBCSummaryPage from './BBCSummaryPage'
import { isLoading, bbc, clientCollaterals, fields } from '../../redux/bbc/selectors'
import {
  calculateClientCollateral,
  submitForReview,
  submit,
  getPreviousBBCClientCollateral,
  listFields,
  getBBCSummaryStats,
  exportBBC,
  updateBBCPdf,
  getBBCFiles,
  show,
  hide,
  inventoryIneligibleSubmit,
  submitArIneligibility,
} from '../../redux/bbc/actions'
import { changePageTitle } from '../../redux/ui/actions'
import { getDebtorCategories } from '../../redux/options/actions'

const selector = createStructuredSelector({
  isLoading,
  bbc,
  clientCollaterals,
  fields,
})

const actions = {
  calculateClientCollateral,
  submitForReview,
  submit,
  listFields,
  getPreviousBBCClientCollateral,
  getBBCSummaryStats,
  exportBBC,
  updateBBCPdf,
  getBBCFiles,
  show,
  hide,
  inventoryIneligibleSubmit,
  submitArIneligibility,
  trackActivity,
  changePageTitle,
  getDebtorCategories,
}

export default connect(selector, actions)(BBCSummaryPage)
