import React, { useMemo } from 'react'

import { FieldType } from '@common/interfaces/client'
import { formatDate, formatPercent, formatPriceNoDecimal } from '../../helpers/helpers'

const RenderedValue = ({
  value,
  type,
  percentWithScale,
}: {
  value: string | number
  type: FieldType
  percentWithScale?: boolean
}) => {
  const renderedValue = useMemo(() => {
    if (value === null) {
      return '-'
    }

    if (type === FieldType.Currency) {
      return <>${formatPriceNoDecimal(value)}</>
    }

    if (type === FieldType.Percent) {
      return formatPercent(value as number, 2, percentWithScale ? 1 : 100)
    }

    if (type === FieldType.Date) {
      return value ? formatDate(value as string) : '-'
    }

    if (type === FieldType.Number) {
      if (value === null || value === undefined) {
        return '-'
      }

      return (+value as number)?.toFixed(2)
    }

    if (type === FieldType.Boolean || typeof value === 'boolean') {
      return value ? 'Yes' : 'No'
    }

    return value || '-'
  }, [value, type, percentWithScale])

  return <>{renderedValue}</>
}

export default RenderedValue
