import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { isLoading, entity } from '../../redux/entityInfo/selectors'
import {
  getEntityInfo,
  hideEntityInfo,
  getEntityInfoDuplicates,
} from '../../redux/entityInfo/actions'
import { getDebtorCategories } from '../../redux/options/actions'

import EntityPage from './EntityPage'

const selector = createStructuredSelector({
  isLoading,
  entity,
})

const actions = {
  getEntityInfo,
  hideEntityInfo,
  getEntityInfoDuplicates,
  getDebtorCategories,
}

export default connect(selector, actions)(EntityPage)
