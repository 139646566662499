import React from 'react'

import styles from './ProspectReportingSummaryTermStructure.module.scss'

import { IProspectTerms } from '@common/interfaces/prospects'
import { TERM_STRUCTURE_FIELDS } from '@common/constants/prospects'
import ProspectReportingSummaryTermStructureDataRow from './ProspectReportingSummaryTermStructureDataRow'

interface IProps {
  data: IProspectTerms
  percentWithScale?: boolean
}

const ProspectReportingSummaryTermStructureData = ({
  data: termData,
  percentWithScale,
}: IProps) => {
  return (
    <div className={styles.data}>
      {TERM_STRUCTURE_FIELDS.map((data, index) => (
        <div key={index} className={styles.column}>
          {data.map(({ section, data }) => (
            <div key={section} className={styles.section}>
              <div className={styles.sectionTitle}>{section}</div>
              <div className={styles.sectionData}>
                {data.map((field) => (
                  <ProspectReportingSummaryTermStructureDataRow
                    key={field}
                    field={field}
                    data={termData}
                    percentWithScale={percentWithScale}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default ProspectReportingSummaryTermStructureData
