import Base from './base'

export default class BankTransactions extends Base {
  listTransactions(params: { ongoingReportingId?: string; page?: number; filters?: object }) {
    return this.apiClient.get('bank-transactions', params)
  }
  listBankTransactionDetail(params: { ongoingReportingId?: string; cleanedDescription?: string }) {
    return this.apiClient.get('bank-transaction-detail', params)
  }
  updateTransactions(data: object) {
    return this.apiClient.put('bank-transactions/update', data)
  }

  listBankTransactionsRaw(params: object) {
    return this.apiClient.get('bank-transaction-raw', params)
  }

  listBankTransactionsGraph(params: object) {
    return this.apiClient.get('bank-transaction-graph', params)
  }

  processBankTransactions(params: object) {
    return this.apiClient.post(`bank-transactions/process`, params)
  }

  mapBankAccount(params: object) {
    return this.apiClient.post(`bank-transactions/map-bank-account`, params)
  }
}
