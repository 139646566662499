import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import cn from 'classnames'
import InfiniteScroll from 'react-infinite-scroll-component'

import styles from './BBCIneligibleARDetailsInvoices.module.scss'
import genericSs from '@styles/generic.module.scss'

import ActiveToolbar from '../ActiveToolbar'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableFiltersRow from '../Common/TableFiltersRow'
import TableLoader from '../Common/TableLoader'
import { IARIneligibleStatsDetailsInvoice, IClientCollateral } from '@common/interfaces/bbc'
import {
  debounceEventHandler,
  formatDate,
  formatPrice,
  handleMultipleSelect,
} from '../../helpers/helpers'
import {
  AR_INELIGIBLE_STATS_DETAILS_INVOICES_FILTERS_CONFIG,
  PER_PAGE,
} from '@common/constants/filters'

interface IProps {
  arDetailStats: IARIneligibleStatsDetailsInvoice[]
  clientCollaterals: IClientCollateral[]
  listArIneligibleStatsDetailsInvoices: (id: string, data: object) => void
  filters: any
  isModalShown: boolean
  label: string
  debtor: string
}

const BBCIneligibleARDetailsInvoices = ({
  arDetailStats,
  clientCollaterals,
  listArIneligibleStatsDetailsInvoices,
  filters,
  isModalShown,
  label,
  debtor,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)

  const [isLoading, setIsLoading] = useState(true)
  const [activeItem, setActiveItem] = useState<number>()
  const [activeItems, setActiveItems] = useState([])
  const [orderBy, setOrderBy] = useState({
    field: 'invoice',
    direction: 'ASC',
  })

  const handleSelectRow = useMemo(
    () => handleMultipleSelect(setActiveItems, setActiveItem, activeItems, activeItem),
    [activeItems, activeItem],
  )
  const resetActiveItems = useCallback(() => setActiveItems([]), [])

  const handleOrderChange = useCallback((field: string) => {
    setOrderBy((order) => ({
      field,
      direction: order.field === field ? (order.direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
    }))
  }, [])

  const debounceListArIneligibleStatsDetailsInvoices = useMemo(
    () =>
      debounceEventHandler(async (data: any) => {
        !data.loadMore && setIsLoading(true)
        await listArIneligibleStatsDetailsInvoices(id, {
          ...data,
          label,
          debtor,
          nestedRows: {
            keys: ['label', 'debtor'],
          },
        })
        setIsLoading(false)
      }, 500),
    [id, listArIneligibleStatsDetailsInvoices, label, debtor],
  )

  useEffect(() => {
    !arDetailStats &&
      debounceListArIneligibleStatsDetailsInvoices({
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        page: 0,
      })
  }, [orderBy, filters, debounceListArIneligibleStatsDetailsInvoices, arDetailStats])

  useEffect(() => {
    debounceListArIneligibleStatsDetailsInvoices({
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: 0,
    })
  }, [orderBy, filters, debounceListArIneligibleStatsDetailsInvoices])

  const loadMore = useCallback(() => {
    debounceListArIneligibleStatsDetailsInvoices({
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: Math.ceil(arDetailStats?.length / PER_PAGE),
    })
  }, [orderBy, filters, debounceListArIneligibleStatsDetailsInvoices, arDetailStats])

  const totalRow = useMemo(
    () =>
      arDetailStats
        ?.filter((_, index) => activeItems.includes(index))
        .reduce(
          (total, item) => {
            clientCollaterals.forEach((_, index) => {
              const field = `value_${index}`

              total[field] += item[field]
            })

            return total
          },
          {
            value_0: 0,
            value_1: 0,
            value_2: 0,
            value_3: 0,
            value_4: 0,
          },
        ),
    [activeItems, arDetailStats, clientCollaterals],
  )

  const totalCount = useMemo(() => arDetailStats?.[0]?.totalCount, [arDetailStats])

  const filtersConfig = useMemo(
    () =>
      AR_INELIGIBLE_STATS_DETAILS_INVOICES_FILTERS_CONFIG.filter(
        (filter) =>
          !filter.field.startsWith('value_') ||
          !!clientCollaterals[filter.field.replace('value_', '')],
      ).map((filter) => {
        if (!filter.field.startsWith('value_') || filter.field === 'value_0') {
          return filter
        }

        const index = +filter.field.replace('value_', '')
        return {
          ...filter,
          title: formatDate(clientCollaterals[index].recordDate),
        }
      }),
    [clientCollaterals],
  )

  return (
    <TableContainer
      className={styles.table}
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
      hasFooter
    >
      <Table ref={wrapperRef}>
        <TableHead>
          <TableFiltersRow
            filters={filtersConfig}
            orderBy={orderBy}
            handleOrderChange={handleOrderChange}
          />
        </TableHead>
        <TableBody
          id={`scrollableIneligibleARDetailsInvoices-${label}-${debtor}`}
          className={styles.scrollableDiv}
        >
          {isLoading ? (
            <TableLoader columnsCount={clientCollaterals.length + 1} />
          ) : (
            <InfiniteScroll
              dataLength={arDetailStats?.length || 0}
              next={loadMore}
              hasMore={arDetailStats?.length < totalCount}
              loader={<TableLoader columnsCount={clientCollaterals.length + 1} rowsCount={1} />}
              scrollableTarget={`scrollableIneligibleARDetailsInvoices-${label}-${debtor}`}
            >
              {arDetailStats?.map((row, index) => (
                <TableRow
                  key={row.invoice}
                  data-index={index}
                  className={cn('activableRow', {
                    activeRow: activeItems.includes(index),
                    currentActiveRow: activeItem === index,
                  })}
                  onClick={(event) => handleSelectRow(event, index)}
                >
                  <TableCell className={cn(genericSs.tableTextLeft, genericSs.textCapitalize)}>
                    {row.invoice}
                  </TableCell>
                  {clientCollaterals.map((_, key) => (
                    <TableCell key={key} className={genericSs.tableTextRight}>
                      $ {formatPrice(row[`value_${key}`])}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </InfiniteScroll>
          )}
        </TableBody>
      </Table>
      <ActiveToolbar
        activeItems={activeItems}
        className={styles.activeToolbar}
        isFullscreen={isModalShown}
        containerRef={wrapperRef}
        resetActiveItems={resetActiveItems}
      >
        {clientCollaterals.map((_, index) => (
          <div key={index} className={genericSs.tableTextRight}>
            $ {formatPrice(totalRow?.[`value_${index}`])}
          </div>
        ))}
      </ActiveToolbar>
    </TableContainer>
  )
}

export default BBCIneligibleARDetailsInvoices
