import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ProspectManageTermsPage from './ProspectManageTermsPage'
import { reporting, prospectTerms } from '../../redux/prospect/selectors'
import {
  listProspectTerms,
  createProspectTerms,
  generateTermSheet,
} from '../../redux/prospect/actions'
import { isAdmin } from '../../redux/profile/selectors'

const selector = createStructuredSelector({
  reporting,
  prospectTerms,
  isAdmin,
})
const actions = {
  createProspectTerms,
  listProspectTerms,
  generateTermSheet,
}

export default connect(selector, actions)(ProspectManageTermsPage)
