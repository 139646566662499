import { Action } from 'redux'
import { takeEvery, put } from 'redux-saga/effects'
import { EXPORT_NOTES_SUCCESS, EXPORT_NOTES_FAILURE, toggleNotesOff, toggleNotes } from './actions'
import { IResponse } from '@common/interfaces/request'
import { download } from '../../helpers/helpers'
import { GET_EMBED_LINK_REQUEST, GET_EMBED_LINK_SUCCESS, SET_BOX_VIEWER_LINK } from '../box/actions'

export function* exportNotes() {
  yield takeEvery(EXPORT_NOTES_SUCCESS, function* (action: IResponse & Action) {
    try {
      const fileName = action.data.name
      yield download(action.data, fileName)
    } catch (error) {
      yield put({ type: EXPORT_NOTES_FAILURE, error })
    }
  })
}

export function* hideNotesSaga() {
  yield takeEvery(
    [SET_BOX_VIEWER_LINK, GET_EMBED_LINK_SUCCESS, GET_EMBED_LINK_REQUEST],
    function* (action: IResponse & Action) {
      if (action.data) {
        yield put(toggleNotesOff())
      } else {
        yield put(toggleNotes())
      }
    },
  )
}
