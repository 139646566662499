import React, { useMemo } from 'react'
import { diff as deepDiff } from 'deep-object-diff'
import pick from 'lodash/pick'
import groupBy from 'lodash/groupBy'

import styles from './BBCLogsItem.module.scss'

import {
  BBC_LOG_FIELDS_TO_COMPARE,
  BBC_LOG_ITEM_DEFAULT,
  BBCLogType,
  IBBCLog,
} from '@common/interfaces/bbcLog'
import { INVENTORY_INELIGIBILITY_FIELDS } from '@common/interfaces/bbc'
import BBCLogsItemContentUsers from './BBCLogsItemContentUsers'

const ELIGIBLE_VALUES = {
  true: 'eligible',
  false: 'ineligible',
}

const BBCLogsItemContentInventoryEligibility = ({ bbcLog }: { bbcLog: IBBCLog }) => {
  const updates = useMemo(() => {
    const data = groupBy(
      bbcLog.inventoryIneligibilityCategories
        .map(({ oldItem, newItem }) => ({
          category: newItem?.category,
          ...pick(
            deepDiff(
              oldItem || BBC_LOG_ITEM_DEFAULT[BBCLogType.InventoryEligibility],
              newItem || {},
            ),
            BBC_LOG_FIELDS_TO_COMPARE[BBCLogType.InventoryEligibility],
          ),
        }))
        .map((diff: any) => {
          if (diff.eligible === null) {
            delete diff.eligible
          }

          return diff
        }),
      'category',
    )

    return Object.keys(data).reduce((result, category) => {
      const eligibilities = groupBy(data[category], 'eligible')

      Object.keys(eligibilities)
        .filter((eligible) => eligible !== 'undefined')
        .forEach((eligible) => {
          result.push({
            field: Object.values(INVENTORY_INELIGIBILITY_FIELDS).find(
              (item) => item.category === category,
            ).reason,
            eligible,
            count: eligibilities[eligible].length,
          })
        })

      const isCanadaMexicoLocations = groupBy(data[category], 'isCanadaMexicoLocation')
      Object.keys(isCanadaMexicoLocations)
        .filter((isCanadaMexicoLocation) => isCanadaMexicoLocation !== 'undefined')
        .forEach((isCanadaMexicoLocation) => {
          result.push({
            field: Object.values(INVENTORY_INELIGIBILITY_FIELDS).find(
              (item) => item.category === category,
            ).reason,
            isCanadaMexicoLocation: isCanadaMexicoLocation === 'true',
            count: isCanadaMexicoLocations[isCanadaMexicoLocation].length,
          })
        })

      return result
    }, [])
  }, [bbcLog])

  return (
    <div className={styles.itemData}>
      <BBCLogsItemContentUsers bbcLog={bbcLog} />
      &nbsp;
      <b>set</b>&nbsp;
      {updates.map(({ field, eligible, isCanadaMexicoLocation, count }, index) => (
        <React.Fragment key={`${field}-${eligible}`}>
          <b>
            {count} {field}
            {count > 1 ? 's' : ''}
          </b>
          &nbsp; to&nbsp;
          {eligible !== undefined
            ? ELIGIBLE_VALUES[eligible]
            : isCanadaMexicoLocation
            ? 'Cad/Mex'
            : 'USA'}
          {index === updates.length - 2 ? ' and ' : index !== updates.length - 1 ? ', ' : ''}
        </React.Fragment>
      ))}
    </div>
  )
}

export default BBCLogsItemContentInventoryEligibility
