import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import Tooltip from '@mui/material/Tooltip'
import cn from 'classnames'

import styles from './EntityReportSummaryDetailsTable.module.scss'
import genericSs from '@styles/generic.module.scss'

import ActiveToolbar from '../ActiveToolbar'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableContainer from '../Common/TableContainer'
import TableBody from '../Common/TableBody'
import { IReportSummaryStats } from '@common/interfaces/entityInfo'
import { debounceEventHandler, formatPrice, handleMultipleSelect } from '../../helpers/helpers'
import TableFiltersRow from '../Common/TableFiltersRow'
import { ENTITY_REPORT_DETAILS_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import TableLoader from '../Common/TableLoader'

interface IProps {
  rows: IReportSummaryStats[]
  reportType: 'AR' | 'AP'
  clientName: string
  filters: any
  relatedEntities?: string
  getEntityArDetails: (id: string, params: object) => void
  getEntityApDetails: (id: string, params: object) => void
}

const EntitySummaryDetailTable = ({
  rows,
  reportType,
  clientName,
  filters,
  relatedEntities,
  getEntityArDetails,
  getEntityApDetails,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)

  const [isLoading, setIsLoading] = useState(true)
  const [activeItem, setActiveItem] = useState<number>()
  const [activeItems, setActiveItems] = useState([])
  const [orderBy, setOrderBy] = useState({
    field: 'total',
    direction: 'DESC',
  })

  const handleSelectRow = useMemo(
    () => handleMultipleSelect(setActiveItems, setActiveItem, activeItems, activeItem),
    [activeItems, activeItem],
  )
  const resetActiveItems = useCallback(() => setActiveItems([]), [])

  const handleOrderChange = useCallback((orderField: string) => {
    setOrderBy((order) => ({
      field: orderField,
      direction: order.field === orderField ? (order.direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
    }))
  }, [])

  const filtersConfig = useMemo(
    () =>
      ENTITY_REPORT_DETAILS_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        title: item.field === 'total' ? `Total ${reportType}` : item.title,
      })),
    [reportType],
  )

  const debounceListEntityReportDatails = useMemo(
    () =>
      debounceEventHandler(async (data: any) => {
        !data.loadMore && setIsLoading(true)
        await (reportType === 'AR' ? getEntityArDetails : getEntityApDetails)(id, {
          ...data,
          clientName,
          relatedEntities,
          nestedRows: {
            keys: ['clientName'],
          },
        })
        setIsLoading(false)
      }, 500),
    [id, reportType, clientName, getEntityArDetails, getEntityApDetails, relatedEntities],
  )

  const loadMore = useCallback(() => {
    debounceListEntityReportDatails({
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: Math.ceil(rows?.length / PER_PAGE),
      perPage: PER_PAGE,
    })
  }, [orderBy, filters, debounceListEntityReportDatails, rows])

  useEffect(() => {
    !rows &&
      debounceListEntityReportDatails({
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        page: 0,
        perPage: PER_PAGE,
      })
  }, [orderBy, filters, debounceListEntityReportDatails, rows])

  useEffect(() => {
    debounceListEntityReportDatails({
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: 0,
    })
  }, [orderBy, filters, debounceListEntityReportDatails])

  const totalRow = useMemo(
    () =>
      rows
        ?.filter((_, index) => activeItems.includes(index))
        .reduce(
          (totalRowResult, row) => {
            totalRowResult._1To_30Days += row._1To_30Days || 0
            totalRowResult._31To_60Days += row._31To_60Days || 0
            totalRowResult._61To_90Days += row._61To_90Days || 0
            totalRowResult._91PlusDays += row._91PlusDays || 0
            totalRowResult.total += row.total || 0
            totalRowResult.percent += row.percent || 0

            return totalRowResult
          },
          {
            _1To_30Days: 0,
            _31To_60Days: 0,
            _61To_90Days: 0,
            _91PlusDays: 0,
            total: 0,
            percent: 0,
          },
        ),
    [activeItems, rows],
  )

  return (
    <TableContainer
      className={cn(styles.table, reportType === 'AR' ? styles.arTable : styles.apTable)}
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
      hasFooter
      size="small"
    >
      <Table>
        <TableHead>
          <TableFiltersRow
            filters={filtersConfig}
            orderBy={orderBy}
            handleOrderChange={handleOrderChange}
          />
        </TableHead>
        <TableBody id={`scrollableEntitySummaryDetailTable-${clientName}`}>
          {isLoading ? (
            <TableLoader columnsCount={filtersConfig.length} />
          ) : (
            <InfiniteScroll
              dataLength={rows?.length || 0}
              next={loadMore}
              hasMore={rows?.length < rows?.[0]?.totalCount}
              loader={<TableLoader columnsCount={filtersConfig.length} rowsCount={1} />}
              scrollableTarget={`scrollableEntitySummaryDetailTable-${clientName}`}
            >
              {rows?.map((item, index) => (
                <TableRow
                  key={item.invoice}
                  data-index={index}
                  className={cn('activableRow', {
                    activeRow: activeItems.includes(index),
                    currentActiveRow: activeItem === index,
                  })}
                  onClick={(event) => handleSelectRow(event, index)}
                >
                  <TableCell className={genericSs.tableTextLeft}>
                    <Tooltip
                      title={item.invoice || ''}
                      placement="top"
                      disableHoverListener={item.invoice?.length < 10 || !item.invoice}
                      disableTouchListener
                    >
                      <span>{item.invoice}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>{item.daysPastInvoice}</TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    <span className={genericSs.pricePrefix}>$</span>
                    {formatPrice(item._1To_30Days)}
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    <span className={genericSs.pricePrefix}>$</span>
                    {formatPrice(item._31To_60Days)}
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    <span className={genericSs.pricePrefix}>$</span>
                    {formatPrice(item._61To_90Days)}
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    <span className={genericSs.pricePrefix}>$</span>
                    {formatPrice(item._91PlusDays)}
                  </TableCell>
                  <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                    <span className={genericSs.pricePrefix}>$</span>
                    {formatPrice(item.total)}
                  </TableCell>
                  <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                    {(item.percent * 100 || 0).toFixed(2)}%
                  </TableCell>
                </TableRow>
              ))}
            </InfiniteScroll>
          )}
        </TableBody>
      </Table>

      <ActiveToolbar
        activeItems={activeItems}
        className={cn(
          styles.activeToolbar,
          reportType === 'AR' ? styles.arActiveToolbar : styles.apActiveToolbar,
        )}
        containerRef={wrapperRef}
        resetActiveItems={resetActiveItems}
      >
        <div className={genericSs.tableTextRight}>
          <span className={genericSs.pricePrefix}>$</span>
          {formatPrice(totalRow?._1To_30Days)}
        </div>
        <div className={genericSs.tableTextRight}>
          <span className={genericSs.pricePrefix}>$</span>
          {formatPrice(totalRow?._31To_60Days)}
        </div>
        <div className={genericSs.tableTextRight}>
          <span className={genericSs.pricePrefix}>$</span>
          {formatPrice(totalRow?._61To_90Days)}
        </div>
        <div className={genericSs.tableTextRight}>
          <span className={genericSs.pricePrefix}>$</span>
          {formatPrice(totalRow?._91PlusDays)}
        </div>
        <div className={cn(genericSs.tableTextRight, styles.totals)}>
          <span className={genericSs.pricePrefix}>$</span>
          {formatPrice(totalRow?.total)}
        </div>
        <div className={cn(genericSs.tableTextRight, styles.totals)}>
          {(totalRow?.percent * 100 || 0).toFixed(2)}%
        </div>
      </ActiveToolbar>
    </TableContainer>
  )
}

export default EntitySummaryDetailTable
