import { IRootState } from '../reducer'

export const isLoading = (state: IRootState) => state.dueDiligence.loadingArray.length > 0

export const dueDiligenceStats = (state: IRootState) => state.dueDiligence.dueDiligenceStats

export const prospects = (state: IRootState) => state.dueDiligence.prospects

export const dueDiligenceAggregation = (state: IRootState) =>
  state.dueDiligence.dueDiligenceAggregation

export const dueDiligenceInfo = (state: IRootState) => state.dueDiligence.dueDiligenceInfo

export const dueDiligenceReporting = (state: IRootState) => state.dueDiligence.dueDiligenceReporting

export const dueDiligenceReportingFlows = (state: IRootState) =>
  state.dueDiligence.dueDiligenceReportingFlows

export const aliasMapping = (state: IRootState) => state.dueDiligence.aliasMapping

export const dueDiligenceDocumentRequestsTypes = (state: IRootState) =>
  state.dueDiligence.dueDiligenceDocumentRequestsTypes

export const dueDiligenceDocumentRequests = (state: IRootState) =>
  state.dueDiligence.dueDiligenceDocumentRequests

export const dueDiligenceCompanyInfo = (state: IRootState) =>
  state.dueDiligence.dueDiligenceCompanyInfo

export const dueDiligenceTeamContacts = (state: IRootState) =>
  state.dueDiligence.dueDiligenceTeamContacts

export const dueDiligenceTeamExecutives = (state: IRootState) =>
  state.dueDiligence.dueDiligenceTeamExecutives

export const dueDiligenceTeamBoardMembers = (state: IRootState) =>
  state.dueDiligence.dueDiligenceTeamBoardMembers

export const dueDiligenceTeamReferences = (state: IRootState) =>
  state.dueDiligence.dueDiligenceTeamReferences

export const dueDiligenceFinancialsInventoryLocations = (state: IRootState) =>
  state.dueDiligence.dueDiligenceFinancialsInventoryLocations

export const dueDiligenceFinancialsBankAccounts = (state: IRootState) =>
  state.dueDiligence.dueDiligenceFinancialsBankAccounts

export const refreshCount = (state: IRootState) => state.dueDiligence.refreshCount

export const dueDiligenceProgress = (state: IRootState) =>
  state.dueDiligence.dueDiligenceInfo?.ddInfo?.progress

export const currentProcessDocumentsLink = (state: IRootState) =>
  state.dueDiligence.dueDiligenceReportingFlows?.data?.dueDiligenceReporting?.boxLink

export const lcrCommentaryData = (state: IRootState) => state.dueDiligence.lcrCommentaryData

export const historicalLineTurnData = (state: IRootState) =>
  state.dueDiligence.historicalLineTurnData

export const historicalLineTurnOptionsData = (state: IRootState) =>
  state.dueDiligence.historicalLineTurnOptionsData
