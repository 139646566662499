import React from 'react'
import cn from 'classnames'

import styles from '../DealStructure/DealStructure.module.scss'
import termStructureStyles from '../../ProspectReportingSummaryTermStructure/ProspectReportingSummaryTermStructure.module.scss'

import { ClientABLField } from '../ClientHelpers'
import { ClientSetupSections, allClientTermsInfo, IClientInfo } from '@common/interfaces/client'
import RenderedValue from '../../RenderedValue'

const FIELDS_MAP = allClientTermsInfo.filter(
  (term) => term.section === ClientSetupSections.AdministrationInfo,
)

interface IProps {
  clientInfo: IClientInfo
  readOnly: boolean
}

const AdministrationInfo = ({ clientInfo, readOnly }: IProps) => {
  return (
    <div className={termStructureStyles.section}>
      <div className={termStructureStyles.sectionTitle}>Administrative Information</div>

      <div className={termStructureStyles.sectionData}>
        {FIELDS_MAP.map(({ label, value, type, placeholder, options, disabled }) => (
          <div key={value} className={termStructureStyles.dataRow}>
            <div className={cn(termStructureStyles.label, styles.label)}>{label}</div>
            <div className={cn(termStructureStyles.value, styles.value)}>
              {readOnly ? (
                <RenderedValue value={clientInfo?.[value]} type={type} percentWithScale />
              ) : (
                <ClientABLField
                  name={value}
                  type={type}
                  disabled={disabled}
                  placeholder={placeholder}
                  options={options}
                  size="small"
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AdministrationInfo
