import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useParams } from 'react-router'
import { Form } from 'react-final-form'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import InfiniteScroll from 'react-infinite-scroll-component'
import cn from 'classnames'

import styles from './ProspectArApSummary.module.scss'
import genericSs from '@styles/generic.module.scss'

import { ExpandDetailIcon } from '../Common/Icons'
import Card from '../../components/Common/Card'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableFooter from '../Common/TableFooter'
import TableBody from '../Common/TableBody'
import { debounceEventHandler, formatter } from '../../helpers/helpers'
import TableFiltersRow from '../Common/TableFiltersRow'
import FullscreenModal from '../Common/FullscreenModal'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import {
  PER_PAGE,
  PROSPECTS_PAYABLES_SUMMARY_LIST_FILTERS_CONFIG,
  PROSPECTS_RECEIVABLES_SUMMARY_LIST_FILTERS_CONFIG,
} from '@common/constants/filters'
import FilterContainer from '../Filters/FilterContainer'
import { ExpandAndMinimize } from '../Common/Icons'
import TableLoader from '../Common/TableLoader'
import SelectField from '../Common/SelectField'
import {
  IProspectAP,
  IProspectAPSummary,
  IProspectAR,
  IProspectARSummary,
} from '@common/interfaces/prospects'
import { ILoadingData } from '../../redux/types'
import useTable from './../../hooks/useTable'
import ProspectReportDetailRow from '../ProspectReportDetailRow'
import EntityPreview from '../EntityPreview'
import { WorkflowTypes } from '@common/interfaces/notes'
import MultiCellTooltip, { IMultiCellTotal } from '../MultiCellTooltip'

const filtersDefaults = buildFiltersDefaults(PROSPECTS_RECEIVABLES_SUMMARY_LIST_FILTERS_CONFIG, {
  creditor: null,
  debtor: null,
})
const filtersValidate = {
  AR: buildFiltersValidateSchema(PROSPECTS_RECEIVABLES_SUMMARY_LIST_FILTERS_CONFIG),
  AP: buildFiltersValidateSchema(PROSPECTS_PAYABLES_SUMMARY_LIST_FILTERS_CONFIG),
}

const eligibilityOptions = [
  { label: 'Select', value: '', disabled: true },
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
]

const ITEMS_COUNT_LIMITED = 5

const CELL_INDEXES_MAP = {
  2: '_0_to_30_days',
  3: '_31_to_60_days',
  4: '_61_to_90_days',
  5: '_91_plus_days',
  6: 'cross_aged',
  7: 'concentration_limit',
  8: 'ineligible_ar',
  9: 'total',
  10: 'total_percent',
  11: 'eligible_ar',
  12: 'available_ar',
}

const PERCENT_CELL_INDEXES = [10]
const TOTALS_CELL_INDEXES = [6, 7, 8, 9, 10, 11, 12]

const ProspectArApSummaryCell = ({
  type,
  data,
  dataTotal,
  rowIndex,
  cellIndex,
  onSelectCell,
  isSelected,
  isActive,
  isTotals = false,
}: {
  type: 'currency' | 'percent'
  data: number
  dataTotal?: IMultiCellTotal
  rowIndex: number
  cellIndex: number
  onSelectCell: (event: any, rowIndex: number, columnIndex: number) => void
  isSelected: boolean
  isActive: boolean
  isTotals?: boolean
}) => {
  const handleSelectCell = useCallback(
    (event) => onSelectCell(event, rowIndex, cellIndex),
    [rowIndex, cellIndex, onSelectCell],
  )

  return (
    <TableCell
      className={cn(genericSs.tableTextRight, 'activableCell', {
        [styles.totals]: isTotals,
        activeCell: isSelected,
      })}
      onClick={handleSelectCell}
      data-index={cellIndex}
    >
      <MultiCellTooltip isActive={isActive} data={dataTotal}>
        {isTotals ? (
          <p>
            {type === 'percent' ? (
              <>{(+data * 100 || 0).toFixed(2)}%</>
            ) : (
              <>{formatter.format(data)}</>
            )}
          </p>
        ) : (
          <div>
            {type === 'percent' ? (
              <>{(+data * 100 || 0).toFixed(2)}%</>
            ) : (
              <>{formatter.format(data)}</>
            )}
          </div>
        )}
      </MultiCellTooltip>
    </TableCell>
  )
}

type IProspectARAP = IProspectAR | IProspectAP

interface IProps {
  listSummary: (id: string, params: object) => void
  updateSummary?: (id: string, data: object) => void
  summaryData: ILoadingData<IProspectARSummary | IProspectAPSummary>
  reportType: string
  refreshCounter?: number
  refreshCounterWithoutLoader?: number
  title?: any
  actionsSize?: number
  footer?: any
  tableClassName?: string
  readOnly?: boolean
  isSummary?: boolean
  isDataLimited?: boolean
  editLink?: string
  isFilesSaving?: boolean
}

const ProspectArApSummary = ({
  listSummary,
  updateSummary,
  reportType,
  summaryData,
  refreshCounter,
  refreshCounterWithoutLoader,
  title,
  actionsSize = 7,
  footer,
  tableClassName,
  readOnly = false,
  isSummary = false,
  isDataLimited = false,
  editLink,
  isFilesSaving,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  const [isModalShown, setIsModalShown] = useState(false)
  const [expanded, setExpanded] = useState([])

  const wrapperRef = useRef(null)

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeCells,
    activeRows,
    handleSelectCell,
    handleSelectActiveRow,
    resetActiveCells,
  } = useTable({
    tableId: 'prospectARAPSummary',
    filtersDefaults,
    sortDefault: { field: 'total', direction: 'DESC' },
  })

  const { isLoading, summary, summaryTotals } = useMemo(
    () => ({
      isLoading: summaryData.isLoading,
      summary: (isDataLimited && !isModalShown
        ? (summaryData.data?.data || []).slice(0, ITEMS_COUNT_LIMITED)
        : summaryData.data?.data || []) as IProspectARAP[],
      summaryTotals: summaryData.data?.totals,
    }),
    [summaryData, isDataLimited, isModalShown],
  )

  const debounceListSummary = useMemo(
    () =>
      debounceEventHandler((data: any) => {
        listSummary(id, data)
      }, 500),
    [id, listSummary],
  )

  useEffect(() => {
    debounceListSummary({
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [refreshCounter, filters, orderBy, debounceListSummary])

  useEffect(() => {
    debounceListSummary({
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      skipLoader: true,
    })
  }, [refreshCounterWithoutLoader, filters, orderBy, debounceListSummary])

  const loadMore = useCallback(() => {
    if (isDataLimited && !isModalShown) {
      return
    }
    listSummary(id, {
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: Math.ceil(summary.length / PER_PAGE),
    })
  }, [id, listSummary, orderBy, filters, summary, isDataLimited, isModalShown])

  const isAR = useMemo(() => reportType === 'AR', [reportType])
  const columnName = useMemo(() => (isAR ? 'debtor' : 'creditor'), [isAR])
  const activeItemNames = useMemo(
    () => [
      ...new Set(
        (summary || [])
          .filter((_, index) => activeRows.includes(index))
          .map((item) => item[columnName]),
      ),
    ],
    [summary, columnName, activeRows],
  )

  const handleChangeEligible = useCallback(
    (debtor, { target: { value } }) => {
      updateSummary(id, {
        debtors:
          activeItemNames.length > 1 && activeItemNames.includes(debtor)
            ? activeItemNames
            : [debtor],
        eligible: value,
      })
    },
    [activeItemNames, updateSummary, id],
  )

  const summaryRow = useMemo(
    () =>
      (summary || [])
        ?.filter((_, index) => activeRows.includes(index))
        .reduce(
          (result, row) => {
            result._0_to_30_days += row._0_to_30_days || 0
            result._31_to_60_days += row._31_to_60_days || 0
            result._61_to_90_days += row._61_to_90_days || 0
            result._91_plus_days += row._91_plus_days || 0

            result.cross_aged += ('cross_aged' in row && row.cross_aged) || 0
            result.concentration_limit +=
              ('concentration_limit' in row && row.concentration_limit) || 0
            result.ineligible_ar += ('ineligible_ar' in row && row.ineligible_ar) || 0
            result.eligible_ar += ('eligible_ar' in row && row.eligible_ar) || 0
            result.available_ar += ('available_ar' in row && row.available_ar) || 0
            result.total += row.total || 0
            result.total_percent += +row.total_percent || 0

            return result
          },
          {
            _0_to_30_days: 0,
            _31_to_60_days: 0,
            _61_to_90_days: 0,
            _91_plus_days: 0,
            cross_aged: 0,
            concentration_limit: 0,
            total: 0,
            total_percent: 0,
            ineligible_ar: 0,
            eligible_ar: 0,
            available_ar: 0,
          },
        ),
    [summary, activeRows],
  )

  const handleModalChange = useCallback(() => {
    setIsModalShown((prev) => !prev)
    resetActiveCells()
  }, [resetActiveCells])

  const filtersConfig = useMemo(
    () =>
      isAR
        ? PROSPECTS_RECEIVABLES_SUMMARY_LIST_FILTERS_CONFIG.filter(({ field }) =>
            isSummary
              ? ![
                  'cross_aged',
                  'concentration_limit',
                  'eligible_ar',
                  'available_ar',
                  'eligible',
                ].includes(field)
              : field !== 'ineligible_ar',
          )
        : PROSPECTS_PAYABLES_SUMMARY_LIST_FILTERS_CONFIG,
    [isAR, isSummary],
  )
  const header = useMemo(
    () =>
      `${
        isSummary ? (isAR ? 'Receivables Summary' : 'Payables Summary') : `${reportType} Summary`
      }`,
    [isSummary, isAR, reportType],
  )
  const tableID = useMemo(() => `scrollable${reportType}SummaryTable`, [reportType])

  const handleExpand = useCallback(
    (label: string) => {
      if (expanded.length === 0) {
        setIsModalShown(true)
      }
      setExpanded((values) =>
        values.includes(label) ? values.filter((item) => item !== label) : [...values, label],
      )
    },
    [expanded],
  )

  const availableCellIndexes = useMemo(
    () =>
      Object.keys(CELL_INDEXES_MAP)
        .filter(
          (cellIndex) =>
            !['cross_aged', 'concentration_limit', 'eligible_ar', 'available_ar'].includes(
              CELL_INDEXES_MAP[cellIndex],
            ) ||
            (isAR && !isSummary),
        )
        .filter(
          (cellIndex) =>
            !['ineligible_ar'].includes(CELL_INDEXES_MAP[cellIndex]) || (isAR && isSummary),
        ),
    [isAR, isSummary],
  )

  const totalCell: IMultiCellTotal = useMemo(() => {
    const rowIndexesWithActiveCells = activeCells
      .map((indexes, index) => (indexes?.length ? index : null))
      .filter((index) => index !== null)

    const cellIndexesWithActiveCells = [...new Set(activeCells.flat().filter(Boolean))]
    const filteredData = {
      fields: cellIndexesWithActiveCells.map((cellIndex) => CELL_INDEXES_MAP[cellIndex]),
      amounts: [] as number[],
      values: [] as number[],
    }

    const isSameType =
      cellIndexesWithActiveCells.every((cellIndex) => PERCENT_CELL_INDEXES.includes(cellIndex)) ||
      cellIndexesWithActiveCells.every((cellIndex) => !PERCENT_CELL_INDEXES.includes(cellIndex))
    rowIndexesWithActiveCells.forEach((rowIndex) => {
      activeCells[rowIndex].forEach((cellIndex) => {
        filteredData.values.push(summary?.[rowIndex][CELL_INDEXES_MAP[cellIndex]])
        if (!PERCENT_CELL_INDEXES.includes(cellIndex)) {
          filteredData.amounts.push(summary?.[rowIndex][CELL_INDEXES_MAP[cellIndex]])
        }
      })
    })
    const amountSum = filteredData.amounts.reduce(
      (result: number, amount: number) => result + amount,
      0,
    )
    const sum = filteredData.values.reduce((result: number, amount: number) => result + amount, 0)

    return {
      type: cellIndexesWithActiveCells.every((cellIndex) =>
        PERCENT_CELL_INDEXES.includes(cellIndex),
      )
        ? 'percent'
        : 'currency',
      count: filteredData.values.length,
      sum,
      avg: isSameType
        ? filteredData.values.length
          ? sum / filteredData.values.length
          : 0
        : filteredData.amounts.length
        ? amountSum / filteredData.amounts.length
        : 0,
    }
  }, [activeCells, summary])

  return (
    <FullscreenModal
      isOpen={isModalShown}
      setIsOpen={handleModalChange}
      classes={{ body: styles.fullScreenModal }}
    >
      <Card noHeaderMargin withBorder={false} ref={wrapperRef}>
        <TableContainer
          className={cn(styles.table, tableClassName, { [styles.arTable]: isAR })}
          hasFooter
        >
          <div className={styles.header}>
            <Form
              validate={filtersValidate[reportType]}
              onSubmit={handleFiltersChange}
              initialValues={filters}
              mutators={{
                setFieldData: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value)
                },
              }}
              render={({ values, handleSubmit, form: { mutators } }) => (
                <FilterContainer
                  filters={!isDataLimited || isModalShown ? filtersConfig : []}
                  handleSubmit={handleSubmit}
                  mutators={mutators}
                  values={values}
                  appliedFilters={filters}
                  title={
                    <Box display="flex" justifyContent="start" alignItems="center" gap={1}>
                      <span>{header}</span>
                      {editLink && (
                        <Link component={RouterLink} to={editLink} className={styles.editLink}>
                          Edit
                        </Link>
                      )}
                    </Box>
                  }
                  actions={
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      {!(typeof title === 'string') && title}

                      <ExpandAndMinimize action={handleModalChange} isExpanded={isModalShown} />
                    </Box>
                  }
                  actionsSize={actionsSize}
                  withFullSearch={!isDataLimited || isModalShown}
                />
              )}
            />
          </div>
          <Table>
            <TableHead>
              <TableFiltersRow
                filters={filtersConfig}
                orderBy={orderBy}
                handleOrderChange={handleOrderChange}
                isChildrenAtStart
              >
                <TableCell />
              </TableFiltersRow>
            </TableHead>
            <TableBody id={tableID}>
              {isFilesSaving || isLoading ? (
                <TableLoader
                  columnsCount={filtersConfig.length}
                  rowsCount={isDataLimited && !isModalShown ? ITEMS_COUNT_LIMITED : PER_PAGE}
                />
              ) : (
                summary?.length > 0 && (
                  <InfiniteScroll
                    dataLength={summary.length}
                    next={loadMore}
                    hasMore={
                      (!isDataLimited || isModalShown) && summary.length < summaryTotals.totalItems
                    }
                    loader={<TableLoader columnsCount={8} rowsCount={1} />}
                    scrollableTarget={tableID}
                  >
                    {summary.map((name, index) => {
                      const reportSum = summary[index]
                      if (!reportSum) {
                        return null
                      }

                      const entityName =
                        'debtor' in reportSum ? reportSum.debtor : reportSum.creditor

                      const isExpanded = expanded.includes(entityName)

                      return (
                        <React.Fragment>
                          <TableRow
                            key={entityName || 'UNMAPPED'}
                            data-index={index}
                            className="activableRow"
                          >
                            <TableCell className={genericSs.tableTextCenter}>
                              <ExpandDetailIcon
                                onClick={() => handleExpand(entityName)}
                                isExpanded={isExpanded}
                              />
                            </TableCell>
                            <TableCell
                              className={genericSs.tableTextLeft}
                              onClick={(event) => handleSelectActiveRow(event, index)}
                            >
                              <Tooltip title={entityName} placement="top" disableTouchListener>
                                <span className={styles.entityName}>
                                  {entityName ? entityName : 'UNMAPPED'}
                                </span>
                              </Tooltip>

                              {(reportSum.entity_id || reportSum.prospect_entity_id) && (
                                <EntityPreview
                                  id={reportSum.entity_id || reportSum.prospect_entity_id}
                                  workflow={WorkflowTypes.opsFlow}
                                  className={styles.entityPreviewIcon}
                                  itemId={id}
                                />
                              )}
                            </TableCell>

                            {availableCellIndexes.map((cellIndex) => (
                              <ProspectArApSummaryCell
                                key={cellIndex}
                                type={
                                  PERCENT_CELL_INDEXES.includes(+cellIndex) ? 'percent' : 'currency'
                                }
                                data={reportSum[CELL_INDEXES_MAP[cellIndex]]}
                                rowIndex={index}
                                cellIndex={+cellIndex}
                                onSelectCell={handleSelectCell}
                                isSelected={
                                  activeRows.includes(index) ||
                                  activeCells[index]?.includes(+cellIndex)
                                }
                                isActive={activeCells[index]?.includes(+cellIndex)}
                                dataTotal={totalCell}
                                isTotals={TOTALS_CELL_INDEXES.includes(+cellIndex)}
                              />
                            ))}

                            {isAR && !isSummary && (
                              <TableCell className={genericSs.tableTextRight}>
                                <SelectField
                                  className={cn(styles.select, {
                                    [styles.ineligible]:
                                      (reportSum as IProspectAR).eligible === false,
                                  })}
                                  useFinalForm={false}
                                  name="eligible"
                                  options={eligibilityOptions}
                                  onChange={(event) =>
                                    handleChangeEligible(reportSum[columnName], event)
                                  }
                                  disabled={readOnly}
                                  value={
                                    (reportSum as IProspectAR).eligible === null
                                      ? null
                                      : (reportSum as IProspectAR).eligible.toString()
                                  }
                                  defaultValue=""
                                />
                              </TableCell>
                            )}
                          </TableRow>
                          {isExpanded && (
                            <TableRow>
                              <TableCell className={genericSs.nestedRowColumn} colSpan={11}>
                                <ProspectReportDetailRow
                                  entityName={entityName}
                                  entityType={isAR ? 'debtor' : 'creditor'}
                                  detailRows={reportSum.rows as IProspectAR[]}
                                />
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      )
                    })}
                  </InfiniteScroll>
                )
              )}
            </TableBody>
            <TableFooter>
              {isFilesSaving || isLoading ? (
                <TableLoader columnsCount={filtersConfig.length} rowsCount={2} />
              ) : (
                summary?.length > 0 && (
                  <>
                    <TableRow>
                      <TableCell className={genericSs.tableTextLeft}>Total</TableCell>
                      <TableCell />
                      <TableCell className={genericSs.tableTextRight}>
                        {formatter.format(summaryTotals._0_to_30_days || 0)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatter.format(summaryTotals._31_to_60_days || 0)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatter.format(summaryTotals._61_to_90_days || 0)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatter.format(summaryTotals._91_plus_days || 0)}
                      </TableCell>
                      {isAR && !isSummary && (
                        <>
                          <TableCell className={genericSs.tableTextRight}>
                            {formatter.format(
                              ('cross_aged' in summaryTotals && summaryTotals.cross_aged) || 0,
                            )}
                          </TableCell>
                          <TableCell className={genericSs.tableTextRight}>
                            {formatter.format(
                              ('concentration_limit' in summaryTotals &&
                                summaryTotals.concentration_limit) ||
                                0,
                            )}
                          </TableCell>
                        </>
                      )}
                      {isAR && isSummary && (
                        <TableCell className={genericSs.tableTextRight}>
                          {formatter.format(
                            ('ineligible_ar' in summaryTotals && summaryTotals.ineligible_ar) || 0,
                          )}
                        </TableCell>
                      )}
                      <TableCell className={genericSs.tableTextRight}>
                        {formatter.format(summaryTotals.total)}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>100%</TableCell>
                      {isAR && !isSummary && (
                        <>
                          <TableCell className={genericSs.tableTextRight}>
                            {formatter.format(
                              ('eligible_ar' in summaryTotals && summaryTotals.eligible_ar) || 0,
                            )}
                          </TableCell>
                          <TableCell className={genericSs.tableTextRight}>
                            {formatter.format(
                              ('available_ar' in summaryTotals && summaryTotals.available_ar) || 0,
                            )}
                          </TableCell>
                          <TableCell />
                        </>
                      )}
                    </TableRow>
                    <TableRow>
                      <TableCell className={genericSs.tableTextLeft}>% of Total</TableCell>
                      <TableCell />
                      <TableCell className={genericSs.tableTextRight}>
                        {(
                          ((summaryTotals._0_to_30_days || 0) / summaryTotals.total) * 100 || 0
                        ).toFixed(2)}
                        %
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {(
                          ((summaryTotals._31_to_60_days || 0) / summaryTotals.total) * 100 || 0
                        ).toFixed(2)}
                        %
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {(
                          ((summaryTotals._61_to_90_days || 0) / summaryTotals.total) * 100 || 0
                        ).toFixed(2)}
                        %
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {(
                          ((summaryTotals._91_plus_days || 0) / summaryTotals.total) * 100 || 0
                        ).toFixed(2)}
                        %
                      </TableCell>
                      {isAR && !isSummary && (
                        <>
                          <TableCell className={genericSs.tableTextRight}>
                            {(
                              ((('cross_aged' in summaryTotals && summaryTotals.cross_aged) || 0) /
                                summaryTotals.total) *
                                100 || 0
                            ).toFixed(2)}
                            %
                          </TableCell>
                          <TableCell className={genericSs.tableTextRight}>
                            {(
                              ((('concentration_limit' in summaryTotals &&
                                summaryTotals.concentration_limit) ||
                                0) /
                                summaryTotals.total) *
                                100 || 0
                            ).toFixed(2)}
                            %
                          </TableCell>
                        </>
                      )}
                      {isAR && isSummary && (
                        <TableCell className={genericSs.tableTextRight}>
                          {(
                            ((('ineligible_ar' in summaryTotals && summaryTotals.ineligible_ar) ||
                              0) /
                              summaryTotals.total) *
                              100 || 0
                          ).toFixed(2)}
                          %
                        </TableCell>
                      )}
                      <TableCell className={genericSs.tableTextRight}>100%</TableCell>
                      <TableCell className={genericSs.tableTextRight}>100%</TableCell>
                      {isAR && !isSummary && (
                        <>
                          <TableCell className={genericSs.tableTextRight}>
                            {(
                              ((('eligible_ar' in summaryTotals && summaryTotals.eligible_ar) ||
                                0) /
                                summaryTotals.total) *
                                100 || 0
                            ).toFixed(2)}
                            %
                          </TableCell>
                          <TableCell className={genericSs.tableTextRight}>
                            {(
                              ((('available_ar' in summaryTotals && summaryTotals.available_ar) ||
                                0) /
                                summaryTotals.total) *
                                100 || 0
                            ).toFixed(2)}
                            %
                          </TableCell>
                          <TableCell />
                        </>
                      )}
                    </TableRow>
                  </>
                )
              )}
              {activeRows.length > 1 && (
                <TableRow className="summaryRow">
                  <TableCell className={genericSs.tableTextLeft}>
                    {activeRows.length} rows selected
                  </TableCell>
                  <TableCell />
                  <TableCell className={genericSs.tableTextRight}>
                    {formatter.format(summaryRow._0_to_30_days || 0)}
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    {formatter.format(summaryRow._31_to_60_days || 0)}
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    {formatter.format(summaryRow._61_to_90_days || 0)}
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>
                    {formatter.format(summaryRow._91_plus_days || 0)}
                  </TableCell>
                  {isAR && !isSummary && (
                    <>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatter.format(
                          ('cross_aged' in summaryRow && summaryRow.cross_aged) || 0,
                        )}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatter.format(
                          ('concentration_limit' in summaryRow && summaryRow.concentration_limit) ||
                            0,
                        )}
                      </TableCell>
                    </>
                  )}
                  {isAR && isSummary && (
                    <TableCell className={genericSs.tableTextRight}>
                      {formatter.format(
                        ('ineligible_ar' in summaryRow && summaryRow.ineligible_ar) || 0,
                      )}
                    </TableCell>
                  )}
                  <TableCell className={genericSs.tableTextRight}>
                    {formatter.format(summaryRow.total)}
                  </TableCell>
                  <TableCell className={genericSs.tableTextRight}>100%</TableCell>
                  {isAR && !isSummary && (
                    <>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatter.format(
                          ('eligible_ar' in summaryRow && summaryRow.eligible_ar) || 0,
                        )}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        {formatter.format(
                          ('available_ar' in summaryRow && summaryRow.available_ar) || 0,
                        )}
                      </TableCell>
                      <TableCell />
                    </>
                  )}
                </TableRow>
              )}
            </TableFooter>
          </Table>
        </TableContainer>
        {footer && footer}
      </Card>
    </FullscreenModal>
  )
}

export default ProspectArApSummary
