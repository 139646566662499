import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import Grid from '@mui/material/Grid'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import cn from 'classnames'

import styles from './LCRCommentary.module.scss'
import { ReactComponent as CheveronUp } from '@assets/images/chevron-up.svg'
import { ReactComponent as CheveronDown } from '@assets/images/down-chevron.svg'

import { ILoadingData } from '../../redux/types'
import {
  IDueDiligence,
  ILCRCommentary,
  ILCRCommentarySection,
} from '@common/interfaces/dueDiligence'
import Card from '../Common/Card'
import { ClientInfoStatus } from '@common/interfaces/client'
import { LCR_COMMENTARY_DEFAULT_CUSTOM_HEADER } from '@common/constants/lcrCommentary'
import LCRCommentaryLoader from './LCRCommentaryLoader'
import LCRCommentaryField from './LCRCommentaryField'
import { MenuIcon } from '../Common/Icons'
import { handleStopPropagation } from '../../helpers/helpers'
import LCRCommentaryFieldModal from './LCRCommentaryFieldModal'

interface IProps {
  section: string
  dueDiligenceInfo: IDueDiligence
  lcrCommentaryData: { [key: string]: ILoadingData<ILCRCommentarySection> }
  listLCRCommentary: (id: string, data: object) => void
  addLCRCommentary: (id: string, data: object) => Promise<any>
  updateLCRCommentary: (id: string, lcrCommentaryId: string, data: object) => Promise<any>
  deleteLCRCommentary: (id: string, lcrCommentaryId: string, data: object) => Promise<any>
}

const LCRCommentary = ({
  section,
  dueDiligenceInfo,
  lcrCommentaryData,
  listLCRCommentary,
  addLCRCommentary,
  updateLCRCommentary,
  deleteLCRCommentary,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  const [isExpanded, setIsExpanded] = useState(true)
  const [menuAnchorEl, setMenuAnchorEl] = useState(null)
  const [isAddFieldModalShown, setIsAddFieldModalShown] = useState(false)

  const isMenuOpen = useMemo(() => Boolean(menuAnchorEl), [menuAnchorEl])

  useEffect(() => {
    listLCRCommentary(id, {
      section,
    })
  }, [id, section, listLCRCommentary])

  const readOnly = useMemo(
    () => dueDiligenceInfo?.clientStatus !== ClientInfoStatus.DueDiligence,
    [dueDiligenceInfo],
  )

  const { isLoading, isSaving, data } = useMemo(() => {
    const lcrCommentarySectionData = lcrCommentaryData[section] || {
      isLoading: true,
      isSaving: false,
      data: {
        data: [] as ILCRCommentary[],
      },
    }

    return {
      isLoading: lcrCommentarySectionData.isLoading,
      isSaving: lcrCommentarySectionData.isSaving,
      data: lcrCommentarySectionData.data.data || [],
    }
  }, [section, lcrCommentaryData])

  const toggleExpand = useCallback(() => {
    setIsExpanded((isExpanded) => !isExpanded)
  }, [])

  const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setMenuAnchorEl(event.currentTarget)
  }, [])

  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null)
  }, [])

  const handleToggleAddFieldModal = useCallback(() => {
    setMenuAnchorEl(null)
    setIsAddFieldModalShown((isShown) => !isShown)
  }, [])

  const handleAddField = useCallback(
    async (data: Partial<ILCRCommentary>) => {
      const result = await addLCRCommentary(id, {
        ...data,
        section,
      })
      if (!result.error) {
        setIsAddFieldModalShown(false)
      }
    },
    [id, section, addLCRCommentary],
  )

  const handleEditField = useCallback(
    (lcrCommentaryId: string, data: ILCRCommentary) =>
      updateLCRCommentary(id, lcrCommentaryId, {
        section,
        field: data.field,
        helperText: data.helperText,
      }),
    [id, section, updateLCRCommentary],
  )

  const handleSaveNote = useCallback(
    (lcrCommentaryId: string, note: object) =>
      updateLCRCommentary(id, lcrCommentaryId, { section, note }),
    [id, section, updateLCRCommentary],
  )

  const handleDeleteField = useCallback(
    (lcrCommentaryId: string) =>
      deleteLCRCommentary(id, lcrCommentaryId, {
        section,
      }),
    [id, section, deleteLCRCommentary],
  )

  return (
    <Card noHeaderMargin noVerticalPadding withBorder={false}>
      <Grid container alignItems="flex-start" rowGap={1.5} mt={2.5} columnSpacing={3}>
        <Grid
          item
          xs={12}
          justifyContent="space-between"
          display="flex"
          className={styles.headerWrapper}
        >
          <div className={styles.header} onClick={toggleExpand}>
            {isExpanded ? <CheveronUp /> : <CheveronDown />}
            {LCR_COMMENTARY_DEFAULT_CUSTOM_HEADER[section] || section}
          </div>

          {!readOnly && (
            <div
              className={cn(styles.icons, {
                [styles.iconsActive]: isMenuOpen,
              })}
              onClick={toggleExpand}
            >
              <MenuIcon onClick={handleMenuOpen} isActive={isMenuOpen} size="small" />

              <Menu
                open={isMenuOpen}
                onClose={handleMenuClose}
                anchorEl={menuAnchorEl}
                disableScrollLock={false}
                onClick={handleStopPropagation}
              >
                <MenuItem onClick={handleToggleAddFieldModal}>Add Commentary</MenuItem>
              </Menu>
            </div>
          )}
        </Grid>

        {isExpanded &&
          (isLoading ? (
            <LCRCommentaryLoader />
          ) : (
            data.map((item) => (
              <LCRCommentaryField
                key={item.id}
                lcrCommentary={item}
                handleEdit={handleEditField}
                handleSaveNote={handleSaveNote}
                handleDelete={handleDeleteField}
                readOnly={readOnly}
                isFullWidth={data.length === 1}
                isSaving={isSaving}
              />
            ))
          ))}
      </Grid>

      {isAddFieldModalShown && (
        <LCRCommentaryFieldModal
          isSaving={isSaving}
          onSave={handleAddField}
          onClose={handleToggleAddFieldModal}
        />
      )}
    </Card>
  )
}

export default LCRCommentary
