import { Action } from 'redux'
import { History } from 'history'
import { select, takeEvery, put } from 'redux-saga/effects'
import { generatePath } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import {
  CREATE_SUCCESS,
  CREATE_PROSPECT_TERMS_SUCCESS,
  GENERATE_TERM_SHEET_SUCCESS,
  GENERATE_TERM_SHEET_FAILURE,
  refreshCount,
  START_REPORTING_SUCCESS,
  CREATE_NEW_OPS_SUCCESS,
  SUBMIT_OPS_SUCCESS,
  showTermStructureSummaryInfo,
} from './actions'
import { IResponse } from '@common/interfaces/request'
import { reporting } from './selectors'
import { UPDATE_OPS_REPORTING } from '@common/constants/webSockets'
import { IOPSReporting } from '@common/interfaces/prospects'
import { putNotification } from '../ui/actions'
import { listNotes } from '../notes/actions'
import { calculateOPSFlags } from '../flag/actions'
import { NOTES_LIST_PER_PAGE } from '@common/constants/filters'
import { Pages, WorkflowTypes } from '@common/interfaces/notes'

export function* createSuccess(history: History) {
  yield takeEvery([CREATE_SUCCESS, CREATE_NEW_OPS_SUCCESS], function* (data: IResponse & Action) {
    yield history.push(generatePath(ROUTES.PROSPECT_PAGE, { id: data.data.id }))
  })
}

export function* createProspectTermSuccess(history: History) {
  yield takeEvery([CREATE_PROSPECT_TERMS_SUCCESS], function* (data: IResponse & Action) {
    const { id } = yield select(reporting)
    yield history.push(
      generatePath(ROUTES.PROSPECT_EDIT_TERMS_PAGE, { id, termId: data.data.termId }),
    )
  })
}

export function* generateTermSheetSuccess() {
  yield takeEvery(GENERATE_TERM_SHEET_SUCCESS, function* (data: IResponse & Action) {
    try {
      if (!data.data.boxLink) {
        yield put({ type: GENERATE_TERM_SHEET_FAILURE, error: 'Unable to generate term sheet ' })
      }
      window.open(data.data.boxLink, '_blank')
    } catch (error) {
      yield put({ type: GENERATE_TERM_SHEET_FAILURE, error })
    }
  })
}

export function* updateOPSReporting() {
  yield takeEvery(UPDATE_OPS_REPORTING, function* (data: IResponse & Action) {
    const opsReporting: IOPSReporting = yield select(reporting)
    if (opsReporting?.id === data.data?.id) {
      yield put(refreshCount())
      yield put(
        putNotification({
          code: '',
          message: `${opsReporting.clientName} OPS reporting ${
            data.data.result.status
              ? 'has been successfully updated.'
              : 'failed to update. Please try again.'
          }`,
          type: data.data.result.status ? 'success' : 'error',
        }),
      )
    }
  })
}

export function* refreshOpsFlags() {
  yield takeEvery([START_REPORTING_SUCCESS], function* (data: IResponse & Action) {
    if (data.data.id) {
      yield put(calculateOPSFlags({ id: data.data.id }))
    }
  })
}

export function* submitOPSSuccess() {
  yield takeEvery([SUBMIT_OPS_SUCCESS], function* () {
    const { id } = yield select(reporting)

    yield put(showTermStructureSummaryInfo(id))
    yield put(
      listNotes(id, {
        filters: {
          pages: [Pages.ops],
        },
        orderBy: 'created_at',
        orderDirection: 'DESC',
        workflow: WorkflowTypes.opsFlow,
        page: 0,
        perPage: NOTES_LIST_PER_PAGE,
        skipLoader: true,
      }),
    )
  })
}
