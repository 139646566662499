import { createRequestTypes } from '../../helpers/helpers'
import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'

export const prefix = 'flag'

const LIST = createRequestTypes(prefix, 'LIST')

export const [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE] = LIST

export const listFlags: CommonPromiseAction = (params: object) => ({
  type: LIST,
  promise: (api: IApi) => api.flag.list(params),
  params,
})

const GET_FLAG_DETAILS = createRequestTypes(prefix, 'GET_FLAG_DETAILS')

export const [GET_FLAG_DETAILS_REQUEST, GET_FLAG_DETAILS_SUCCESS, GET_FLAG_DETAILS_FAILURE] =
  GET_FLAG_DETAILS

export const getFlagDetails: CommonPromiseAction = (id: string, data: object) => ({
  type: GET_FLAG_DETAILS,
  promise: (api: IApi) => api.flag.getFlagDetails(id, data),
  params: data,
})

const GET_FLAG_DETAILS_DUPLICATE_INVOICES = createRequestTypes(
  prefix,
  'GET_FLAG_DETAILS_DUPLICATE_INVOICES',
)

export const [
  GET_FLAG_DETAILS_DUPLICATE_INVOICES_REQUEST,
  GET_FLAG_DETAILS_DUPLICATE_INVOICES_SUCCESS,
  GET_FLAG_DETAILS_DUPLICATE_INVOICES_FAILURE,
] = GET_FLAG_DETAILS_DUPLICATE_INVOICES

export const getFlagDetailsDuplicateInvoices: CommonPromiseAction = (id: string, data: object) => ({
  type: GET_FLAG_DETAILS_DUPLICATE_INVOICES,
  promise: (api: IApi) => api.flag.getFlagDetailsDuplicateInvoices(id, data),
  params: data,
})

export const GET_FLAG_DETAILS_PAID_PRIORITY_INVOICES = createRequestTypes(
  prefix,
  'GET_FLAG_DETAILS_PAID_PRIORITY_INVOICES',
)

export const [
  GET_FLAG_DETAILS_PAID_PRIORITY_INVOICES_REQUEST,
  GET_FLAG_DETAILS_PAID_PRIORITY_INVOICES_SUCCESS,
  GET_FLAG_DETAILS_PAID_PRIORITY_INVOICES_FAILURE,
] = GET_FLAG_DETAILS_PAID_PRIORITY_INVOICES

export const getFlagDetailPaidPriorityInvoices: CommonPromiseAction = (
  id: string,
  data: object,
) => ({
  type: GET_FLAG_DETAILS_PAID_PRIORITY_INVOICES,
  promise: (api: IApi) => api.flag.getFlagDetailPaidPriorityInvoices(id, data),
  params: data,
})

const GET_FLAG_DETAILS_PENDING_CASH_DOMINION = createRequestTypes(
  prefix,
  'GET_FLAG_DETAILS_PENDING_CASH_DOMINION',
)

export const [
  GET_FLAG_DETAILS_PENDING_CASH_DOMINION_REQUEST,
  GET_FLAG_DETAILS_PENDING_CASH_DOMINION_SUCCESS,
  GET_FLAG_DETAILS_PENDING_CASH_DOMINION_FAILURE,
] = GET_FLAG_DETAILS_PENDING_CASH_DOMINION

export const getFlagDetailsPendingCashDominion: CommonPromiseAction = (
  id: string,
  data: object,
) => ({
  type: GET_FLAG_DETAILS_PENDING_CASH_DOMINION,
  promise: (api: IApi) => api.flag.getFlagDetailsPendingCashDominion(id, data),
  params: data,
})

const UPDATE_FLAG = createRequestTypes(prefix, 'UPDATE_FLAG')

export const [UPDATE_FLAG_REQUEST, UPDATE_FLAG_SUCCESS, UPDATE_FLAG_FAILURE] = UPDATE_FLAG

export const updateFlag: CommonPromiseAction = (id: string, params?: any) => {
  return {
    type: UPDATE_FLAG,
    promise: (api: IApi) => api.flag.update(id, params),
    params,
  }
}

const CALCULATE_BBC_FLAGS = createRequestTypes(prefix, 'CALCULATE_BBC_FLAGS')

export const [
  CALCULATE_BBC_FLAGS_REQUEST,
  CALCULATE_BBC_FLAGS_SUCCESS,
  CALCULATE_BBC_FLAGS_FAILURE,
] = CALCULATE_BBC_FLAGS

export const calculateBBCFlags: CommonPromiseAction = (params: object) => ({
  type: CALCULATE_BBC_FLAGS,
  promise: (api: IApi) => api.flag.calculateBBCFlags(params),
})

const CALCULATE_ENTITY_FLAGS = createRequestTypes(prefix, 'CALCULATE_ENTITY_FLAGS')

export const [
  CALCULATE_ENTITY_FLAGS_REQUEST,
  CALCULATE_ENTITY_FLAGS_SUCCESS,
  CALCULATE_ENTITY_FLAGS_FAILURE,
] = CALCULATE_ENTITY_FLAGS

export const calculateEntityFlags: CommonPromiseAction = (params: object) => ({
  type: CALCULATE_ENTITY_FLAGS,
  promise: (api: IApi) => api.flag.calculateEntityFlags(params),
})

const CALCULATE_OPS_FLAGS = createRequestTypes(prefix, 'CALCULATE_OPS_FLAGS')

export const [
  CALCULATE_OPS_FLAGS_REQUEST,
  CALCULATE_OPS_FLAGS_SUCCESS,
  CALCULATE_OPS_FLAGS_FAILURE,
] = CALCULATE_OPS_FLAGS

export const calculateOPSFlags: CommonPromiseAction = (params: object) => ({
  type: CALCULATE_OPS_FLAGS,
  promise: (api: IApi) => api.flag.calculateOPSFlags(params),
})

const CALCULATE_ONGOING_REPORTING_FLAGS = createRequestTypes(
  prefix,
  'CALCULATE_ONGOING_REPORTING_FLAGS',
)

export const [
  CALCULATE_ONGOING_REPORTING_FLAGS_REQUEST,
  CALCULATE_ONGOING_REPORTING_FLAGS_SUCCESS,
  CALCULATE_ONGOING_REPORTING_FLAGS_FAILURE,
] = CALCULATE_ONGOING_REPORTING_FLAGS

export const calculateOngoingReportingFlags: CommonPromiseAction = (params: object) => ({
  type: CALCULATE_ONGOING_REPORTING_FLAGS,
  promise: (api: IApi) => api.flag.calculateOngoingReportingFlags(params),
})

const GET_NEW_FLAGS_COUNT = createRequestTypes(prefix, 'GET_NEW_FLAG_COUNT')

export const [
  GET_NEW_FLAGS_COUNT_REQUEST,
  GET_NEW_FLAGS_COUNT_SUCCESS,
  GET_NEW_FLAGS_COUNT_FAILURE,
] = GET_NEW_FLAGS_COUNT

export const getNewFlagsCount: CommonPromiseAction = (params: object) => ({
  type: GET_NEW_FLAGS_COUNT,
  promise: (api: IApi) => api.flag.getNewFlagsCount(params),
})

const CALCULATE_BANK_TRANSACTION_FLAGS = createRequestTypes(
  prefix,
  'CALCULATE_BANK_TRANSACTION_FLAGS',
)
export const [
  CALCULATE_BANK_TRANSACTION_FLAGS_REQUEST,
  CALCULATE_BANK_TRANSACTION_FLAGS_SUCCESS,
  CALCULATE_BANK_TRANSACTION_FLAGS_FAILURE,
] = CALCULATE_BANK_TRANSACTION_FLAGS

export const calculateBankTransactionFlags: CommonPromiseAction = (id: string) => ({
  type: [
    CALCULATE_BANK_TRANSACTION_FLAGS_REQUEST,
    CALCULATE_BANK_TRANSACTION_FLAGS_SUCCESS,
    CALCULATE_BANK_TRANSACTION_FLAGS_FAILURE,
  ],
  promise: (api: IApi) => api.flag.calculateBankTransactionFlags(id),
})

const GET_FLAGS_OPTIONS = createRequestTypes(prefix, 'GET_FLAGS_OPTIONS')

export const [GET_FLAGS_OPTIONS_REQUEST] = GET_FLAGS_OPTIONS

export const getFlagsOptions: CommonPromiseAction = (params: object) => ({
  type: GET_FLAGS_OPTIONS,
  promise: (api: IApi) => api.flag.getOptions(params),
  params,
})
