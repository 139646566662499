import { createRequestTypes } from '../../helpers/helpers'
import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'
export const prefix = 'prospect'

export const LIST_REQUEST = `${prefix}/LIST_REQUEST`
export const LIST_SUCCESS = `${prefix}/LIST_SUCCESS`
export const LIST_FAILURE = `${prefix}/LIST_FAILURE`

export const listProspects: CommonPromiseAction = (params: object) => ({
  type: [LIST_REQUEST, LIST_SUCCESS, LIST_FAILURE],
  promise: (api: IApi) => api.prospect.list(params),
  params,
})

export const CREATE_REQUEST = `${prefix}/CREATE_REQUEST`
export const CREATE_SUCCESS = `${prefix}/CREATE_SUCCESS`
export const CREATE_FAILURE = `${prefix}/CREATE_FAILURE`

export const createProspect: CommonPromiseAction = (data: object) => ({
  type: [CREATE_REQUEST, CREATE_SUCCESS, CREATE_FAILURE],
  promise: (api: IApi) => api.prospect.create(data),
})

export const SHOW_REQUEST = `${prefix}/SHOW_REQUEST`
export const SHOW_SUCCESS = `${prefix}/SHOW_SUCCESS`
export const SHOW_FAILURE = `${prefix}/SHOW_FAILURE`

export const show: CommonPromiseAction = (id: string) => ({
  type: [SHOW_REQUEST, SHOW_SUCCESS, SHOW_FAILURE],
  promise: (api: IApi) => api.prospect.show(id),
})

export const SHOW_FOLDERS_REQUEST = `${prefix}/SHOW_FOLDERS_REQUEST`
export const SHOW_FOLDERS_SUCCESS = `${prefix}/SHOW_FOLDERS_SUCCESS`
export const SHOW_FOLDERS_FAILURE = `${prefix}/SHOW_FOLDERS_FAILURE`

export const showFoldersInfo: CommonPromiseAction = (id: string) => ({
  type: [SHOW_FOLDERS_REQUEST, SHOW_FOLDERS_SUCCESS, SHOW_FOLDERS_FAILURE],
  promise: (api: IApi) => api.prospect.showFoldersInfo(id),
})

export const HIDE = `${prefix}/HIDE`

export const hide: CommonPromiseAction = () => ({
  type: HIDE,
})

export const UPDATE_REQUEST = `${prefix}/UPDATE_REQUEST`
export const UPDATE_SUCCESS = `${prefix}/UPDATE_SUCCESS`
export const UPDATE_FAILURE = `${prefix}/UPDATE_FAILURE`

export const update: CommonPromiseAction = (id: string, data: object) => ({
  type: [UPDATE_REQUEST, UPDATE_SUCCESS, UPDATE_FAILURE],
  promise: (api: IApi) => api.prospect.update(id, data),
})

export const START_REPORTING_REQUEST = `${prefix}/START_REPORTING_REQUEST`
export const START_REPORTING_SUCCESS = `${prefix}/START_REPORTING_SUCCESS`
export const START_REPORTING_FAILURE = `${prefix}/START_REPORTING_FAILURE`

export const startReporting: CommonPromiseAction = (id: string, data?: object) => ({
  type: [START_REPORTING_REQUEST, START_REPORTING_SUCCESS, START_REPORTING_FAILURE],
  promise: (api: IApi) => api.prospect.startReporting(id, data),
})

export const UPDATE_REPORTING_REQUEST = `${prefix}/UPDATE_REPORTING_REQUEST`
export const UPDATE_REPORTING_SUCCESS = `${prefix}/UPDATE_REPORTING_SUCCESS`
export const UPDATE_REPORTING_FAILURE = `${prefix}/UPDATE_REPORTING_FAILURE`

export const updateReporting: CommonPromiseAction = (id: string, data: object | FormData) => ({
  type: [UPDATE_REPORTING_REQUEST, UPDATE_REPORTING_SUCCESS, UPDATE_REPORTING_FAILURE],
  promise: (api: IApi) => api.prospect.updateReporting(id, data),
})

export const LIST_INVENTORY_REQUEST = `${prefix}/LIST_INVENTORY_REQUEST`
export const LIST_INVENTORY_SUCCESS = `${prefix}/LIST_INVENTORY_SUCCESS`
export const LIST_INVENTORY_FAILURE = `${prefix}/LIST_INVENTORY_FAILURE`

export const listInventory: CommonPromiseAction = (id: string, data: object) => ({
  type: [LIST_INVENTORY_REQUEST, LIST_INVENTORY_SUCCESS, LIST_INVENTORY_FAILURE],
  promise: (api: IApi) => api.prospect.listInventory(id, data),
  // @ts-ignore
  loadMore: data.loadMore,
})

export const PULL_REPORTS_REQUEST = `${prefix}/PULL_REPORTS_REQUEST`
export const PULL_REPORTS_SUCCESS = `${prefix}/PULL_REPORTS_SUCCESS`
export const PULL_REPORTS_FAILURE = `${prefix}/PULL_REPORTS_FAILURE`

export const pullReports: CommonPromiseAction = (id: string, params?: object) => ({
  type: [PULL_REPORTS_REQUEST, PULL_REPORTS_SUCCESS, PULL_REPORTS_FAILURE],
  promise: (api: IApi) => api.prospect.pullReports(id, params),
})

export const CREATE_PROSPECT_TERMS_REQUEST = `${prefix}/CREATE_PROSPECT_TERMS_REQUEST`
export const CREATE_PROSPECT_TERMS_SUCCESS = `${prefix}/CREATE_PROSPECT_TERMS_SUCCESS`
export const CREATE_PROSPECT_TERMS_FAILURE = `${prefix}/CREATE_PROSPECT_TERMS_FAILURE`

export const createProspectTerms: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    CREATE_PROSPECT_TERMS_REQUEST,
    CREATE_PROSPECT_TERMS_SUCCESS,
    CREATE_PROSPECT_TERMS_FAILURE,
  ],
  promise: (api: IApi) => api.prospect.createProspectTerms(id, data),
})

export const UPDATE_PROSPECT_TERMS_REQUEST = `${prefix}/UPDATE_PROSPECT_TERMS_REQUEST`
export const UPDATE_PROSPECT_TERMS_SUCCESS = `${prefix}/UPDATE_PROSPECT_TERMS_SUCCESS`
export const UPDATE_PROSPECT_TERMS_FAILURE = `${prefix}/UPDATE_PROSPECT_TERMS_FAILURE`

export const updateProspectTerms: CommonPromiseAction = (
  id: string,
  prospectTermsId: string,
  data: object,
) => ({
  type: [
    UPDATE_PROSPECT_TERMS_REQUEST,
    UPDATE_PROSPECT_TERMS_SUCCESS,
    UPDATE_PROSPECT_TERMS_FAILURE,
  ],
  promise: (api: IApi) => api.prospect.updateProspectTerms(id, prospectTermsId, data),
})

export const DELETE_PROSPECT_TERMS_REQUEST = `${prefix}/DELETE_PROSPECT_TERMS_REQUEST`
export const DELETE_PROSPECT_TERMS_SUCCESS = `${prefix}/DELETE_PROSPECT_TERMS_SUCCESS`
export const DELETE_PROSPECT_TERMS_FAILURE = `${prefix}/DELETE_PROSPECT_TERMS_FAILURE`

export const deleteProspectTerms: CommonPromiseAction = (id: string, prospectTermsId: string) => ({
  type: [
    DELETE_PROSPECT_TERMS_REQUEST,
    DELETE_PROSPECT_TERMS_SUCCESS,
    DELETE_PROSPECT_TERMS_FAILURE,
  ],
  promise: (api: IApi) => api.prospect.deleteProspectTerms(id, prospectTermsId),
})

export const LIST_PROSPECT_TERMS_REQUEST = `${prefix}/LIST_PROSPECT_TERMS_REQUEST`
export const LIST_PROSPECT_TERMS_SUCCESS = `${prefix}/LIST_PROSPECT_TERMS_SUCCESS`
export const LIST_PROSPECT_TERMS_FAILURE = `${prefix}/LIST_PROSPECT_TERMS_FAILURE`

export const listProspectTerms: CommonPromiseAction = (id: string) => ({
  type: [LIST_PROSPECT_TERMS_REQUEST, LIST_PROSPECT_TERMS_SUCCESS, LIST_PROSPECT_TERMS_FAILURE],
  promise: (api: IApi) => api.prospect.listProspectTerms(id),
})

export const GENERATE_TERM_SHEET_REQUEST = `${prefix}/GENERATE_TERM_SHEET_REQUEST`
export const GENERATE_TERM_SHEET_SUCCESS = `${prefix}/GENERATE_TERM_SHEET_SUCCESS`
export const GENERATE_TERM_SHEET_FAILURE = `${prefix}/GENERATE_TERM_SHEET_FAILURE`

export const generateTermSheet: CommonPromiseAction = (id: string, prospectTermsId: string) => ({
  type: [GENERATE_TERM_SHEET_REQUEST, GENERATE_TERM_SHEET_SUCCESS, GENERATE_TERM_SHEET_FAILURE],
  promise: (api: IApi) => api.prospect.generateTermSheet(id, prospectTermsId),
})

export const UPDATE_FINANCIALS_DATES_REQUEST = `${prefix}/UPDATE_FINANCIALS_DATES_REQUEST`
export const UPDATE_FINANCIALS_DATES_SUCCESS = `${prefix}/UPDATE_FINANCIALS_DATES_SUCCESS`
export const UPDATE_FINANCIALS_DATES_FAILURE = `${prefix}/UPDATE_FINANCIALS_DATES_FAILURE`

export const updateFinancialsDates: CommonPromiseAction = (data: object | FormData) => ({
  type: [
    UPDATE_FINANCIALS_DATES_REQUEST,
    UPDATE_FINANCIALS_DATES_SUCCESS,
    UPDATE_FINANCIALS_DATES_FAILURE,
  ],
  promise: (api: IApi) => api.prospect.updateFinancialsDates(data),
})

export const LIST_PROSPECT_REPORTING_REQUEST = `${prefix}/LIST_PROSPECT_REPORTING_REQUEST`
export const LIST_PROSPECT_REPORTING_SUCCESS = `${prefix}/LIST_PROSPECT_REPORTING_SUCCESS`
export const LIST_PROSPECT_REPORTING_FAILURE = `${prefix}/LIST_PROSPECT_REPORTING_FAILURE`

export const listProspectReporting: CommonPromiseAction = (data: object) => ({
  type: [
    LIST_PROSPECT_REPORTING_REQUEST,
    LIST_PROSPECT_REPORTING_SUCCESS,
    LIST_PROSPECT_REPORTING_FAILURE,
  ],
  promise: (api: IApi) => api.prospect.listProspectReporting(data),
  params: data,
})

export const HIDE_PROSPECT_REPORTING = `${prefix}/HIDE_PROSPECT_REPORTING`

export const hideProspectReporting: CommonPromiseAction = () => ({
  type: HIDE_PROSPECT_REPORTING,
})

export const UPDATE_REPORTING_FLOW_ADDITIONAL_REQUEST = `${prefix}/UPDATE_REPORTING_FLOW_ADDITIONAL_REQUEST`
export const UPDATE_REPORTING_FLOW_ADDITIONAL_SUCCESS = `${prefix}/UPDATE_REPORTING_FLOW_ADDITIONAL_SUCCESS`
export const UPDATE_REPORTING_FLOW_ADDITIONAL_FAILURE = `${prefix}/UPDATE_REPORTING_FLOW_ADDITIONAL_FAILURE`

export const updateReportingFlowAdditionalData: CommonPromiseAction = (
  id: string,
  flowId: string,
  data: object,
) => ({
  type: [
    UPDATE_REPORTING_FLOW_ADDITIONAL_REQUEST,
    UPDATE_REPORTING_FLOW_ADDITIONAL_SUCCESS,
    UPDATE_REPORTING_FLOW_ADDITIONAL_FAILURE,
  ],
  promise: (api: IApi) => api.prospect.updateReportingFlowAdditionalData(id, flowId, data),
})

export const SHOW_INVENTORY_SUMMARY_INFO_REQUEST = `${prefix}/SHOW_INVENTORY_SUMMARY_INFO_REQUEST`
export const SHOW_INVENTORY_SUMMARY_INFO_SUCCESS = `${prefix}/SHOW_INVENTORY_SUMMARY_INFO_SUCCESS`
export const SHOW_INVENTORY_SUMMARY_INFO_FAILURE = `${prefix}/SHOW_INVENTORY_SUMMARY_INFO_FAILURE`

export const showInventorySummaryInfo: CommonPromiseAction = (id: string) => ({
  type: [
    SHOW_INVENTORY_SUMMARY_INFO_REQUEST,
    SHOW_INVENTORY_SUMMARY_INFO_SUCCESS,
    SHOW_INVENTORY_SUMMARY_INFO_FAILURE,
  ],
  promise: (api: IApi) => api.prospect.showInventorySummaryInfo(id),
})

export const SHOW_CASH_FLOW_SUMMARY_INFO_REQUEST = `${prefix}/SHOW_CASH_FLOW_SUMMARY_INFO_REQUEST`
export const SHOW_CASH_FLOW_SUMMARY_INFO_SUCCESS = `${prefix}/SHOW_CASH_FLOW_SUMMARY_INFO_SUCCESS`
export const SHOW_CASH_FLOW_SUMMARY_INFO_FAILURE = `${prefix}/SHOW_CASH_FLOW_SUMMARY_INFO_FAILURE`

export const showCashFlowSummaryInfo: CommonPromiseAction = (id: string, params: object) => ({
  type: [
    SHOW_CASH_FLOW_SUMMARY_INFO_REQUEST,
    SHOW_CASH_FLOW_SUMMARY_INFO_SUCCESS,
    SHOW_CASH_FLOW_SUMMARY_INFO_FAILURE,
  ],
  promise: (api: IApi) => api.prospect.showCashFlowSummaryInfo(id, params),
  params,
})

export const SHOW_TERM_STRUCTURE_SUMMARY_INFO_REQUEST = `${prefix}/SHOW_TERM_STRUCTURE_SUMMARY_INFO_REQUEST`
export const SHOW_TERM_STRUCTURE_SUMMARY_INFO_SUCCESS = `${prefix}/SHOW_TERM_STRUCTURE_SUMMARY_INFO_SUCCESS`
export const SHOW_TERM_STRUCTURE_SUMMARY_INFO_FAILURE = `${prefix}/SHOW_TERM_STRUCTURE_SUMMARY_INFO_FAILURE`

export const showTermStructureSummaryInfo: CommonPromiseAction = (id: string) => ({
  type: [
    SHOW_TERM_STRUCTURE_SUMMARY_INFO_REQUEST,
    SHOW_TERM_STRUCTURE_SUMMARY_INFO_SUCCESS,
    SHOW_TERM_STRUCTURE_SUMMARY_INFO_FAILURE,
  ],
  promise: (api: IApi) => api.prospect.showTermStructureSummaryInfo(id),
})

export const UPDATE_DATE_AR_REQUEST = `${prefix}/UPDATE_DATE_AR_REQUEST`
export const UPDATE_DATE_AR_SUCCESS = `${prefix}/UPDATE_DATE_AR_SUCCESS`
export const UPDATE_DATE_AR_FAILURE = `${prefix}/UPDATE_DATE_AR_FAILURE`

export const updateARDate: CommonPromiseAction = (data: object | FormData) => ({
  type: [UPDATE_DATE_AR_REQUEST, UPDATE_DATE_AR_SUCCESS, UPDATE_DATE_AR_FAILURE],
  promise: (api: IApi) => api.prospect.updateDate(data),
})

export const UPDATE_DATE_AP_REQUEST = `${prefix}/UPDATE_DATE_AP_REQUEST`
export const UPDATE_DATE_AP_SUCCESS = `${prefix}/UPDATE_DATE_AP_SUCCESS`
export const UPDATE_DATE_AP_FAILURE = `${prefix}/UPDATE_DATE_AP_FAILURE`

export const updateAPDate: CommonPromiseAction = (data: object | FormData) => ({
  type: [UPDATE_DATE_AP_REQUEST, UPDATE_DATE_AP_SUCCESS, UPDATE_DATE_AP_FAILURE],
  promise: (api: IApi) => api.prospect.updateDate(data),
})

export const GET_DATE_AP_REQUEST = `${prefix}/GET_DATE_AP_REQUEST`
export const GET_DATE_AP_SUCCESS = `${prefix}/GET_DATE_AP_SUCCESS`
export const GET_DATE_AP_FAILURE = `${prefix}/GET_DATE_AP_FAILURE`

export const getAPDate: CommonPromiseAction = (data: object | FormData) => ({
  type: [GET_DATE_AP_REQUEST, GET_DATE_AP_SUCCESS, GET_DATE_AP_FAILURE],
  promise: (api: IApi) => api.prospect.getDate(data),
})

export const GET_DATE_AR_REQUEST = `${prefix}/GET_DATE_AR_REQUEST`
export const GET_DATE_AR_SUCCESS = `${prefix}/GET_DATE_AR_SUCCESS`
export const GET_DATE_AR_FAILURE = `${prefix}/GET_DATE_AR_FAILURE`

export const getARDate: CommonPromiseAction = (data: object | FormData) => ({
  type: [GET_DATE_AR_REQUEST, GET_DATE_AR_SUCCESS, GET_DATE_AR_FAILURE],
  promise: (api: IApi) => api.prospect.getDate(data),
})

export const LIST_AR_SUMMARY_REQUEST = `${prefix}/LIST_AR_SUMMARY_REQUEST`
export const LIST_AR_SUMMARY_SUCCESS = `${prefix}/LIST_AR_SUMMARY_SUCCESS`
export const LIST_AR_SUMMARY_FAILURE = `${prefix}/LIST_AR_SUMMARY_FAILURE`

export const listARSummary: CommonPromiseAction = (id: string, params?: object) => ({
  type: [LIST_AR_SUMMARY_REQUEST, LIST_AR_SUMMARY_SUCCESS, LIST_AR_SUMMARY_FAILURE],
  promise: (api: IApi) => api.prospect.listArSummary(id, params),
  params,
})

export const UPDATE_AR_SUMMARY_REQUEST = `${prefix}/UPDATE_AR_SUMMARY_REQUEST`
export const UPDATE_AR_SUMMARY_SUCCESS = `${prefix}/UPDATE_AR_SUMMARY_SUCCESS`
export const UPDATE_AR_SUMMARY_FAILURE = `${prefix}/UPDATE_AR_SUMMARY_FAILURE`

export const updateARSummary: CommonPromiseAction = (id: string, data: object) => ({
  type: [UPDATE_AR_SUMMARY_REQUEST, UPDATE_AR_SUMMARY_SUCCESS, UPDATE_AR_SUMMARY_FAILURE],
  promise: (api: IApi) => api.prospect.updateArSummary(id, data),
})

export const LIST_AP_SUMMARY_REQUEST = `${prefix}/LIST_AP_SUMMARY_REQUEST`
export const LIST_AP_SUMMARY_SUCCESS = `${prefix}/LIST_AP_SUMMARY_SUCCESS`
export const LIST_AP_SUMMARY_FAILURE = `${prefix}/LIST_AP_SUMMARY_FAILURE`

export const listAPSummary: CommonPromiseAction = (id: string, params?: object) => ({
  type: [LIST_AP_SUMMARY_REQUEST, LIST_AP_SUMMARY_SUCCESS, LIST_AP_SUMMARY_FAILURE],
  promise: (api: IApi) => api.prospect.listApSummary(id, params),
  params,
})

export const PROSPECT_REFRESH_COUNT = `${prefix}/PROSPECT_REFRESH_COUNT`

export const refreshCount: CommonPromiseAction = () => ({
  type: PROSPECT_REFRESH_COUNT,
})

const LIST_AR_DETAIL = createRequestTypes(prefix, 'LIST_AR_DETAIL')

export const [LIST_AR_DETAIL_REQUEST, LIST_AR_DETAIL_SUCCESS, LIST_AR_DETAIL_FAILURE] =
  LIST_AR_DETAIL

export const listARDetail: CommonPromiseAction = (id: string, params?: object) => ({
  type: LIST_AR_DETAIL,
  promise: (api: IApi) => api.prospect.listArSummary(id, params),
  params,
})

const LIST_AP_DETAIL = createRequestTypes(prefix, 'LIST_AP_DETAIL')

export const [LIST_AP_DETAIL_REQUEST, LIST_AP_DETAIL_SUCCESS, LIST_AP_DETAIL_FAILURE] =
  LIST_AP_DETAIL

export const listAPDetail: CommonPromiseAction = (id: string, params?: object) => ({
  type: LIST_AP_DETAIL,
  promise: (api: IApi) => api.prospect.listApSummary(id, params),
  params,
})

const CREATE_NEW_OPS = createRequestTypes(prefix, 'CREATE_NEW_OPS')

export const [CREATE_NEW_OPS_REQUEST, CREATE_NEW_OPS_SUCCESS, CREATE_NEW_OPS_FAILURE] =
  CREATE_NEW_OPS

export const createNewOps: CommonPromiseAction = (id: string, params?: object) => ({
  type: CREATE_NEW_OPS,
  promise: (api: IApi) => api.prospect.createNewOps(id, params),
  params,
})

const SUBMIT_OPS = createRequestTypes(prefix, 'SUBMIT_OPS')

export const [SUBMIT_OPS_REQUEST, SUBMIT_OPS_SUCCESS, SUBMIT_OPS_FAILURE] = SUBMIT_OPS

export const submitOps: CommonPromiseAction = (id: string, params?: object) => ({
  type: SUBMIT_OPS,
  promise: (api: IApi) => api.prospect.submitOps(id, params),
  params,
})
