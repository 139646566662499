import { setBoxViewerLink, getEmbedLink } from './../../../redux/box/actions'
import { connect } from 'react-redux'
import { createStructuredSelector, createSelector } from 'reselect'
import { matchPath } from 'react-router'

import ClientAccountActivity from './ClientAccountActivity'

import {
  clientInfo as profileClientInfo,
  isAdminRightsRole,
  isClientUser,
} from '../../../redux/profile/selectors'
import {
  accountActivity,
  clientInfo,
  termLoans,
  customers,
} from '../../../redux/clientInfo/selectors'
import {
  listAccountActivity,
  deleteAccountActivity,
  exportAccountActivity,
  listCustomers,
} from '../../../redux/clientInfo/actions'
import { pathname } from '../../../redux/router/selectors'
import { ROUTES } from '../../../constants/routes'

const selector = createStructuredSelector({
  isAdminRightsRole,
  isClientUser,
  clientInfo: createSelector(profileClientInfo, clientInfo, pathname, (profileInfo, info, path) =>
    matchPath(path, { path: ROUTES.CLIENT_PAGE }) ? info : profileInfo,
  ),
  accountActivityData: accountActivity,
  termLoans,
  customers,
})

const actions = {
  listAccountActivity,
  deleteAccountActivity,
  exportAccountActivity,
  listCustomers,
  setBoxViewerLink,
  getEmbedLink,
}

export default connect(selector, actions)(ClientAccountActivity)
