import React from 'react'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'

import styles from './LCRCommentary.module.scss'

const LCRCommentaryLoader = () => {
  return (
    <Grid item xs={12}>
      <div className={styles.field}>
        <Skeleton width={150} height={24} />
      </div>

      <Skeleton width="100%" height={16} />
      <Skeleton width="100%" height={16} />
      <Skeleton width="25%" height={16} />
    </Grid>
  )
}

export default LCRCommentaryLoader
