import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  RawDraftContentState,
  CompositeDecorator,
  ContentState,
  RichUtils,
} from 'draft-js'
import Editor from '@draft-js-plugins/editor'
import createStaticToolbarPlugin from '@draft-js-plugins/static-toolbar'
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
} from '@draft-js-plugins/buttons'
import { diff as deepDiff } from 'deep-object-diff'
import cn from 'classnames'

import '@draft-js-plugins/mention/lib/plugin.css'
import 'draft-js/dist/Draft.css'
import '@draft-js-plugins/inline-toolbar/lib/plugin.css'

import styles from '../Notes/NotesContainer.module.scss'
import lcStyles from './LCRCommentary.module.scss'

import { debounceEventHandler } from '../../helpers/helpers'
import SaveState from '../Common/SaveState'
import { linkifyPlugin, onEditorTab } from '../Notes/NoteEditor/NoteEditor'

interface IProps extends Partial<React.ComponentProps<typeof Editor>> {
  value?: RawDraftContentState
  handleUpdate: (values: object) => Promise<void>
  noteText?: string
  key: string
}

const LCRCommentaryNoteEditor = ({
  value,
  readOnly = false,
  handleUpdate,
  noteText,
  key,
}: IProps) => {
  const valueRef = useRef(value)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const [editorState, setEditorState] = useState(() => {
    return EditorState.createEmpty()
  })

  useEffect(() => {
    if (value && Object.keys(value).length > 0) {
      setEditorState((editorState) =>
        EditorState.push(editorState, convertFromRaw(value), 'insert-fragment'),
      )
    }
  }, [value])

  const isEditing = useMemo(() => handleUpdate && !readOnly, [readOnly, handleUpdate])

  const onUpdate = useCallback(
    async (noteContentJson: any) => {
      // Ignore empty changes
      if (valueRef?.current && !Object.keys(deepDiff(valueRef.current, noteContentJson)).length) {
        return
      }

      setIsSaving(true)
      await handleUpdate(noteContentJson)
      valueRef.current = noteContentJson
      setIsSaving(false)
    },
    [handleUpdate],
  )

  const debounceHandleUpdateNotes = useMemo(
    () => debounceEventHandler(async (noteContentJson: any) => onUpdate(noteContentJson), 1000),
    [onUpdate],
  )

  const onEditorChange = useCallback(
    (editorState: EditorState) => {
      setEditorState(editorState)
      isEditing &&
        editorState.getLastChangeType() &&
        debounceHandleUpdateNotes(convertToRaw(editorState.getCurrentContent()))
    },
    [isEditing, debounceHandleUpdateNotes],
  )

  const { StaticToolbar, plugins, decorator } = useMemo(() => {
    const staticToolbarPlugin = createStaticToolbarPlugin({
      theme: {
        buttonStyles: {
          buttonWrapper: styles.toolbarButtonWrapper,
          button: cn(styles.toolbarButton, lcStyles.toolbarButton),
          active: styles.toolbarButtonActive,
        },
        toolbarStyles: { toolbar: cn(styles.toolbarStaticStyles, lcStyles.toolbarStaticStyles) },
      },
    })
    const { Toolbar } = staticToolbarPlugin

    const plugins = [linkifyPlugin, staticToolbarPlugin]

    return {
      plugins,
      StaticToolbar: Toolbar,
      decorator: new CompositeDecorator(
        [linkifyPlugin, staticToolbarPlugin]
          .reduce((acc, plugin) => (plugin.decorators ? [...acc, ...plugin.decorators] : acc), [])
          .filter((_, index) => index !== 1),
      ),
    }
  }, [])

  useEffect(() => {
    if ((handleUpdate || (readOnly && value)) && editorState === EditorState.createEmpty()) {
      if (Object.keys(value).length > 0) {
        setEditorState(EditorState.createWithContent(convertFromRaw(value), decorator))
      }
      if (noteText) {
        setEditorState(EditorState.createWithContent(ContentState.createFromText(noteText)))
      }
    }
  }, [noteText, decorator, readOnly, value, setEditorState, handleUpdate, editorState])

  const ref = useRef<Editor>(null)

  const focusEditor = useCallback(() => {
    ref.current!.focus()
  }, [])

  const onFocus = useCallback(() => {
    setIsEditMode(true)
  }, [])

  const onBlur = useCallback(() => {
    setIsEditMode(false)
  }, [])

  const handleTab = useCallback(
    (e) => {
      onEditorTab(e, editorState, onEditorChange)
    },
    [editorState, onEditorChange],
  )

  const handleKeyCommand = useCallback(
    (command, editorState) => {
      const newState = RichUtils.handleKeyCommand(editorState, command)
      if (newState) {
        onEditorChange(newState)
        return 'handled'
      }
      return 'not-handled'
    },
    [onEditorChange],
  )

  return (
    <div className={lcStyles.editorWrapper}>
      <div
        className={cn(lcStyles.editor, {
          [lcStyles.editMode]: isEditMode,
          [styles.editor]: !readOnly,
          [styles.readOnly]: readOnly,
          [lcStyles.readOnly]: readOnly,
        })}
        onClick={focusEditor}
      >
        <Editor
          editorKey={key}
          editorState={editorState}
          onTab={handleTab}
          onChange={onEditorChange}
          plugins={plugins}
          spellCheck
          ref={ref}
          readOnly={readOnly}
          blockStyleFn={() => styles['public-DraftStyleDefault-block']}
          handleKeyCommand={handleKeyCommand}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder="Add commentary"
        />
      </div>

      <div
        className={cn(styles.toolbarContainer, lcStyles.toolbarContainer, {
          [styles.toolbarContainerStaticVisible]: isEditMode,
        })}
      >
        <StaticToolbar>
          {(externalProps) => (
            <div className={styles.toolbarButtonWrapper}>
              <div className={cn(styles.toolbarButtonContainer, lcStyles.toolbarButtonContainer)}>
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                <UnorderedListButton {...externalProps} />
                <OrderedListButton {...externalProps} />
              </div>
            </div>
          )}
        </StaticToolbar>
      </div>

      {isEditing && <SaveState isSaving={isSaving} isSaved />}
    </div>
  )
}

export default LCRCommentaryNoteEditor
