import React, { useState, useCallback, useMemo, useEffect, SetStateAction, Dispatch } from 'react'
import { useParams } from 'react-router'
import Grid from '@mui/material/Grid'
import Modal from '../../components/Common/Modal'
import styles from '../OngoingReportingFiles/OngoingReportingFiles.module.scss'
import Tooltip from '@mui/material/Tooltip'
import { IFile } from '@common/interfaces/box'
import Button from '../../components/Common/Button'
import { MultipleFileSelect } from '../FileSelect'
import UploadFile from '../Common/UploadFile'
import {
  IOPSReporting,
  IOPSReportingFlow,
  OPSReportingFlowStatus,
} from '@common/interfaces/prospects'
import { OngoingReportingType, FILE_TYPE_TEMPLATES, WorkflowPage } from '@common/interfaces/bbc'
import { ReactComponent as UploadFileIcon } from '../../assets/images/upload-file.svg'
import { ReactComponent as BackIcon } from '../../assets/images/back-icon.svg'
import SaveState from '../Common/SaveState'
import SelectedFiles from '../SelectedFiles'
import FileHeaderMapping from '../FileHeaderMapping'
import classNames from 'classnames'

interface IProps {
  isOpen: boolean
  isLoading: boolean
  updateReporting: (id: string, data: object) => Promise<any>
  deleteFile: (ids: string[]) => Promise<void>
  downloadTemplate: (fileType?: string, fileId?: string) => void
  reporting: IOPSReporting
  reportType: OngoingReportingType
  startReporting: (id: string, data?: object) => Promise<any>
  opsReportingFlow: IOPSReportingFlow
  setRefreshCounter?: Dispatch<SetStateAction<number>>
  setIsFilesSaving?: Dispatch<SetStateAction<boolean>>
  handleOpenModal?: () => void
  handleCloseModal?: () => void
}

const ProspectFileSelect = ({
  isOpen,
  isLoading,
  updateReporting,
  deleteFile,
  downloadTemplate,
  reporting,
  reportType,
  startReporting,
  opsReportingFlow,
  setRefreshCounter,
  setIsFilesSaving,
  handleOpenModal,
  handleCloseModal,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  const [isSaving, setIsSaving] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [filesForDelete, setFilesForDelete] = useState<IFile[]>([])
  const [attempt, setAttempt] = useState(0)
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [uploadDocumentOpen, setUploadDocumentOpen] = useState(false)
  const [selectedHeaderMapping, setSelectedHeaderMapping] = useState('')

  const modalTitle = useMemo(
    () =>
      uploadDocumentOpen ? 'Upload file' : selectedHeaderMapping ? 'Column Mapping' : 'Select file',

    [uploadDocumentOpen, selectedHeaderMapping],
  )

  const files = useMemo(() => {
    if (!reporting?.files?.length) {
      return []
    }
    const opsReportingFlowFile = opsReportingFlow?.files[0]
    return reporting.files
      .filter(({ type }) => type === reportType)
      .map((file) => ({
        ...file,
        sheetName:
          file.id === opsReportingFlowFile?.fileId ? opsReportingFlowFile?.sheetName : null,
      }))
  }, [reporting, reportType, opsReportingFlow])

  useEffect(() => {
    if (files?.length === 0) {
      setUploadDocumentOpen(true)
    }
  }, [files])

  const handleToggleUploadDocumentOpen = useCallback(() => {
    setUploadDocumentOpen((isOpen) => !isOpen)
  }, [])

  const handleOpenHeaderMapping = useCallback(() => {
    setSelectedHeaderMapping(reportType)
  }, [reportType])

  const handleCloseHeaderMapping = useCallback(() => {
    setSelectedHeaderMapping('')
  }, [])

  const handleSelectFile = useCallback(
    async (fileId: string, sheetName: string, isReselect?: boolean) => {
      setIsFilesSaving && setIsFilesSaving(true)
      setIsSaving(true)
      setIsSaved(false)
      const opsReportingFlowFile = opsReportingFlow?.files[0]
      const result = await updateReporting(id, {
        filesMapping: {
          [reportType]: {
            oldFileId: opsReportingFlowFile?.fileId,
            fileId,
            sheetName,
          },
        },
      })
      if (result?.data?.success) {
        const startReportingResult = await startReporting(id, { type: reportType, isReselect })
        if (startReportingResult?.data?.success) {
          setIsSaved(true)
          handleCloseModal && handleCloseModal()
          handleCloseHeaderMapping()
        }
      } else if (result?.error?.code === 'FORMAT_ERROR') {
        await startReporting(id)
      }
      if (result?.data?.error?.code === 'MISSING_COLUMNS') {
        handleCloseHeaderMapping()
        handleOpenHeaderMapping()
      }
      setRefreshCounter && setRefreshCounter((counter) => counter + 1)
      setIsSaving(false)
      setIsFilesSaving && setIsFilesSaving(false)
      !sheetName && handleCloseHeaderMapping()
    },
    [
      id,
      updateReporting,
      reportType,
      startReporting,
      opsReportingFlow,
      setRefreshCounter,
      setIsFilesSaving,
      handleCloseModal,
      handleOpenHeaderMapping,
      handleCloseHeaderMapping,
    ],
  )

  const handleSelectMultipleFiles = useCallback(
    async (flowFiles: any) => {
      setIsFilesSaving && setIsFilesSaving(true)
      setIsSaving(true)
      setIsSaved(false)
      const result = await updateReporting(id, {
        flowFiles,
        type: reportType,
      })
      if (result?.data?.success) {
        const startReportingResult = await startReporting(id, { type: reportType })
        if (startReportingResult?.data?.success) {
          setIsSaved(true)
        }
      } else if (result?.data?.error?.code === 'FORMAT_ERROR') {
        await startReporting(id)
      }

      setRefreshCounter && setRefreshCounter((counter) => counter + 1)
      setIsSaving(false)
      setIsFilesSaving && setIsFilesSaving(false)
    },
    [id, updateReporting, reportType, startReporting, setRefreshCounter, setIsFilesSaving],
  )

  const handleUploadFile = useCallback(
    async (loadedFiles: File[]) => {
      if (loadedFiles?.length) {
        const file = loadedFiles[0]
        const formData = new FormData()

        formData.append('files[]', file, file.name)
        formData.append(`type`, reportType)

        setIsSaving(true)
        const result = await updateReporting(id, formData)
        setIsSaving(false)
        if (result?.data?.success) {
          setRefreshCounter && setRefreshCounter((counter) => counter + 1)
        }
      }
    },
    [updateReporting, id, setRefreshCounter, reportType],
  )

  const handleDeleteFiles = useCallback(
    (indexes: number[]) => {
      setIsDeleteModalShown(true)
      setFilesForDelete(
        indexes
          .sort((a, b) => b - a)
          .map((number, index) => files[number === undefined ? index : number]),
      )
    },
    [files],
  )

  const handleDeleteCancel = useCallback(() => {
    setIsDeleteModalShown(false)
    setFilesForDelete([])
  }, [])

  const handleDeleteConfirm = useCallback(async () => {
    await deleteFile(filesForDelete.map((file) => file.id))
    startReporting(id)
    setIsDeleteModalShown(false)
    setFilesForDelete([])
  }, [deleteFile, filesForDelete, startReporting, id])

  useEffect(() => {
    if (
      reporting?.files &&
      !filesForDelete.length &&
      attempt === 0 &&
      !opsReportingFlow?.files?.length
    ) {
      if (opsReportingFlow?.status !== OPSReportingFlowStatus.Waived) {
        handleOpenModal && handleOpenModal()
      }
      setAttempt((attempt) => attempt + 1)
    }
  }, [
    reporting,
    files,
    handleSelectFile,
    attempt,
    filesForDelete,
    opsReportingFlow,
    handleOpenModal,
  ])

  const handleClose = useCallback(() => {
    if (!isSaving) {
      handleCloseModal()
    }
  }, [handleCloseModal, isSaving])

  const selectedDocuments = useMemo(() => {
    const selectedFiles = []
    for (const file of opsReportingFlow.files) {
      const fileAndSheet = files?.find(({ id }) => id === file.fileId)
      if (fileAndSheet) {
        selectedFiles.push({
          ...fileAndSheet,
          sheetName: file.sheetName,
          fileSheetId: file.id,
        })
      }
    }
    return selectedFiles
  }, [files, opsReportingFlow])

  const [fileReselecting, setFileReselecting] = useState({
    fileId: '',
    sheetName: '',
  })

  const handleReselectFile = useCallback(async () => {
    const currentFile = selectedDocuments[0]
    setFileReselecting({ fileId: currentFile.id, sheetName: currentFile.sheetName })
    await handleSelectFile(currentFile.id, currentFile.sheetName, true)
    setFileReselecting({ fileId: '', sheetName: '' })
  }, [handleSelectFile, selectedDocuments])

  return (
    <Modal
      title={
        <Grid container justifyContent={'flex-start'} alignItems={'center'}>
          <Grid item>
            {uploadDocumentOpen && (
              <BackIcon className={styles.backButton} onClick={handleToggleUploadDocumentOpen} />
            )}
          </Grid>
          <Grid item>
            <h2 className={styles.modalTitle}>{modalTitle}</h2>
          </Grid>
        </Grid>
      }
      open={isOpen}
      classes={{
        root: classNames(styles.modalPaper, {
          [styles.columnMappingOpen]: !!selectedHeaderMapping,
        }),
      }}
      onCancel={handleClose}
    >
      <Grid container spacing={1}>
        {!uploadDocumentOpen ? (
          <Grid container item xs={12}>
            <Grid container item xs={12}>
              <SelectedFiles
                selectedDocuments={selectedDocuments}
                handleSelectMultipleFiles={handleSelectMultipleFiles}
                fileReselecting={fileReselecting}
              />
              {reportType === OngoingReportingType.Inventory && (
                <Grid item xs={12}>
                  {!selectedHeaderMapping && (
                    <h3 onClick={handleOpenHeaderMapping} className={styles.modalLink}>
                      Column Mapping
                    </h3>
                  )}

                  {selectedHeaderMapping === reportType && (
                    <FileHeaderMapping
                      id={id}
                      workflow={WorkflowPage.opsReporting}
                      fileType={selectedHeaderMapping}
                      onClose={handleCloseHeaderMapping}
                      onSave={handleReselectFile}
                    />
                  )}
                </Grid>
              )}
            </Grid>
            {!selectedHeaderMapping && (
              <>
                <Grid item xs={12}>
                  <h3 className={styles.filesListItemTitle}>{reportType}</h3>
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.filesList}>
                    <div className={styles.filesListItem}>
                      <MultipleFileSelect
                        updateFiles={handleSelectMultipleFiles}
                        files={files}
                        className={styles.filesListSelect}
                        selectedDocuments={opsReportingFlow.files}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid container mt={2} item xs={12}>
                  <Grid container item xs={8} justifyContent={'flex-start'}>
                    <span className={styles.uploadDocumentHelper}>
                      Want to use another document?
                    </span>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={4}
                    justifyContent={'flex-end'}
                    alignItems={'center'}
                    className={styles.uploadTextContainer}
                    onClick={handleToggleUploadDocumentOpen}
                  >
                    <UploadFileIcon />
                    <span className={styles.uploadText}>Upload another</span>
                  </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent="flex-end">
                  <SaveState isSaving={!isDeleteModalShown && isSaving} isSaved={isSaved} />
                </Grid>
              </>
            )}
          </Grid>
        ) : (
          <Grid container item xs={12} lg={12}>
            <Grid container item xs={12} lg={12} justifyContent={'center'}>
              <UploadFile
                title={''}
                key={+isLoading}
                onDelete={handleDeleteFiles}
                size="lg"
                onDropAccepted={handleUploadFile}
                acceptedFileTypes={['excel']}
                files={files}
                isInModal={false}
                isLoading={!isDeleteModalShown && isSaving}
              />
            </Grid>
            <Grid container item xs={12} lg={12}>
              <div className={styles.filesListItem}>
                <div className={styles.filesListItemDocuments}>
                  {FILE_TYPE_TEMPLATES[reportType] &&
                    FILE_TYPE_TEMPLATES[reportType].map(
                      (item: { value: string; label: string }, index: number) => (
                        <>
                          <Tooltip placement="top" title={'Download Template'}>
                            <div
                              onClick={() => {
                                downloadTemplate(item.value)
                              }}
                              className={styles.linksBlockItem}
                            >
                              {item.label}
                            </div>
                          </Tooltip>
                          {index !== FILE_TYPE_TEMPLATES[reportType]?.length - 1 && (
                            <div className={styles.templatesDivider} />
                          )}
                        </>
                      ),
                    )}
                </div>
              </div>
            </Grid>
          </Grid>
        )}
        {isDeleteModalShown && filesForDelete.length > 0 && (
          <Modal
            open={isDeleteModalShown}
            onCancel={handleDeleteCancel}
            title="Delete file?"
            footer={[
              <Button
                key="cancel"
                color="primary"
                variant="outlined"
                onClick={handleDeleteCancel}
                disabled={isLoading}
              >
                Cancel
              </Button>,
              <Button
                key="submit"
                color="primary"
                variant="contained"
                onClick={handleDeleteConfirm}
                isLoading={isLoading}
              >
                Delete
              </Button>,
            ]}
          >
            All related records will be deleted
          </Modal>
        )}
      </Grid>
    </Modal>
  )
}

export default ProspectFileSelect
