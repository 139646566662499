import React, { useEffect, useCallback, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'

import styles from './InsurancePage.module.scss'
import { IOngoingReporting, OngoingReportingStatus } from '@common/interfaces/bbc'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import Card from '../../components/Common/Card'
import KeyboardDatePicker from '../../components/Common/KeyboardDatePicker'
import genericSs from '@styles/generic.module.scss'
import { Form, FormSpy } from 'react-final-form'
import Grid from '@mui/material/Grid'
import Button from '../../components/Common/Button'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import { ExternalLink } from '../../components/Common/Icons'
import { ReactComponent as BoxPreviewIcon } from '@assets/images/box-preview.svg'
import Tooltip from '@mui/material/Tooltip'
interface IProps {
  ongoingReporting: IOngoingReporting
  showOngoingReporting: (id: string) => void
  updateOngoingReportingStatus: (data: Partial<IOngoingReporting>) => void
  getEmbedLink: (link: string) => void
  hideBoxPreview: () => void
}

const InsurancePage = ({
  ongoingReporting,
  showOngoingReporting,
  updateOngoingReportingStatus,
  getEmbedLink,
  hideBoxPreview,
}: IProps) => {
  const { id }: { id: string } = useParams()
  const { isLoading } = useLoadInfo({ id: id, info: ongoingReporting, show: showOngoingReporting })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const history = useHistory()
  useSetPageTitle(`${ongoingReporting?.type} Insurance`)
  const currentClientName = useMemo(() => {
    if (id === ongoingReporting?.id) {
      return ongoingReporting?.clientInfo?.clientName
    }
  }, [ongoingReporting, id])

  useEffect(() => {
    return () => {
      hideBoxPreview()
    }
  }, [hideBoxPreview])

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.HOMEPAGE,
        Icon: HomeIcon,
      },
      {
        link: ROUTES.ANALYSIS_QUEUE,
        title: 'Analysis queue',
      },
      {
        title: currentClientName,
        link: ongoingReporting?.clientInfo?.id
          ? generatePath(ROUTES.CLIENT_PAGE, { id: ongoingReporting?.clientInfo?.id })
          : '',
      },
      {
        link: generatePath(ROUTES.ONGOING_REPORTING_INSURANCE_PAGE, { id }),
        title: `${ongoingReporting?.type} Insurance`,
      },
    ]
  }, [id, currentClientName, ongoingReporting])

  const handleOpenPreview = useCallback(() => {
    const fileId = ongoingReporting?.files[0]?.fileId
    if (fileId) {
      getEmbedLink(fileId)
    }
  }, [ongoingReporting?.files, getEmbedLink])

  useEffect(() => {
    if (ongoingReporting?.files?.length) {
      handleOpenPreview()
    }
  }, [ongoingReporting?.files, handleOpenPreview])

  const handleChangeExpirationDate = useCallback(
    (values: { expirationDate: string }) => {
      updateOngoingReportingStatus({ id, expirationDate: values.expirationDate })
    },
    [id, updateOngoingReportingStatus],
  )

  const handleSave = useCallback(async () => {
    setIsSubmitting(true)
    await updateOngoingReportingStatus({ id, status: OngoingReportingStatus.Verified })
    setIsSubmitting(false)
    history.push(ROUTES.ANALYSIS_QUEUE)
  }, [id, updateOngoingReportingStatus, history])

  const submitText = useMemo(
    () => (ongoingReporting?.status === OngoingReportingStatus.Verified ? 'Update' : 'Submit'),
    [ongoingReporting?.status],
  )

  return (
    <Box p={3} className={styles.container} display="flex" flexDirection="column" overflow="hidden">
      <Breadcrumbs breadcrumbs={breadcrumbs} isLoading={isLoading} />
      <Card
        title={
          <Grid container justifyContent="space-between">
            <Grid item>Update Insurance</Grid>
          </Grid>
        }
        withBorder={false}
      >
        <div className={styles.datePickerContainer}>
          <h4 className={genericSs.textLeft}>
            <div className={styles.previewButtonContainer}>
              {`${ongoingReporting?.type} Expiration`}
              <Tooltip title="Preview" placement="top">
                <BoxPreviewIcon className={styles.previewButton} onClick={handleOpenPreview}>
                  Preview
                </BoxPreviewIcon>
              </Tooltip>
              <ExternalLink
                link={ongoingReporting?.files[0]?.link}
                disableHover
                title="Open in Box"
              />
            </div>
          </h4>
          <Form
            initialValues={{ expirationDate: ongoingReporting?.expirationDate }}
            onSubmit={handleChangeExpirationDate}
            render={({ handleSubmit }) => (
              <>
                <KeyboardDatePicker
                  placeholder="Expiration Date"
                  label={`${ongoingReporting?.type} Expiration`}
                  value={ongoingReporting?.expirationDate}
                  size="large"
                  name="expirationDate"
                />
                <FormSpy
                  subscription={{ values: true, dirty: true }}
                  onChange={({ values, dirty }) => {
                    if (dirty) {
                      handleSubmit(values)
                    }
                  }}
                />
              </>
            )}
          />
          <Grid mt={2} item display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              isLoading={isSubmitting}
            >
              {submitText}
            </Button>
          </Grid>
        </div>
      </Card>
    </Box>
  )
}

export default InsurancePage
