import React, { useCallback, useMemo } from 'react'
import { diff as deepDiff } from 'deep-object-diff'
import pick from 'lodash/pick'

import styles from './BBCLogsItem.module.scss'

import {
  BBC_LOG_FIELDS_TO_COMPARE,
  BBC_LOG_ITEM_DEFAULT,
  BBCLogType,
  IBBCLog,
  IBBCLogDebtorClientEligibility,
} from '@common/interfaces/bbcLog'
import { formatPercent, formatPrice } from '../../../helpers/helpers'
import BBCLogsItemContentUsers from './BBCLogsItemContentUsers'

const BBC_LOGS_AR_ELIGIBILITY_FIELD_LABEL_MAP = {
  creditLimits: 'credit limit',
  concentrationLimit: 'concentration limit',
  category: 'category',
  eligible: 'eligibility',
}

const BBCLogsItemContentAREligibilitySingle = ({
  bbcLog,
  debtorClientEligibility,
}: {
  bbcLog: IBBCLog
  debtorClientEligibility: IBBCLogDebtorClientEligibility
}) => {
  const renderFirstItemChanges = useCallback(() => {
    const diff = pick(
      deepDiff(
        debtorClientEligibility.oldItem || BBC_LOG_ITEM_DEFAULT[BBCLogType.AREligibility],
        debtorClientEligibility.newItem || {},
      ),
      BBC_LOG_FIELDS_TO_COMPARE[BBCLogType.AREligibility],
    )

    const renderValue = (field: string, value: any) => {
      if (field === 'category') {
        return value?.toLowerCase()
      }

      if (field === 'concentrationLimit') {
        return formatPercent(value)
      }

      if (field === 'eligible') {
        return value === null ? 'New' : value ? 'Yes' : 'No'
      }

      return '$' + formatPrice(value)
    }

    return Object.keys(diff).map((field, index) => (
      <React.Fragment key={field}>
        <b>{BBC_LOGS_AR_ELIGIBILITY_FIELD_LABEL_MAP[field]}</b>&nbsp;
        {debtorClientEligibility.oldItem &&
          field !== 'category' &&
          `from ${renderValue(field, debtorClientEligibility?.oldItem?.[field])} `}
        to&nbsp;<b>{renderValue(field, debtorClientEligibility?.newItem?.[field])}</b>
        {index !== Object.keys(diff).length - 1 && ', '}
      </React.Fragment>
    ))
  }, [debtorClientEligibility])

  return (
    <div className={styles.itemData}>
      <BBCLogsItemContentUsers bbcLog={bbcLog} />
      &nbsp;
      <b>updated</b>&nbsp;
      <b>{bbcLog.debtorClientEligibility?.[0]?.newItem?.entityInfo?.name}</b>&nbsp;
      {renderFirstItemChanges()}
    </div>
  )
}

const BBCLogsItemContentAREligibilityMultiple = ({ bbcLog }: { bbcLog: IBBCLog }) => {
  const updates = useMemo(() => {
    const diffs = bbcLog.debtorClientEligibility.map(({ oldItem, newItem }) =>
      Object.keys(
        pick(
          deepDiff(oldItem || BBC_LOG_ITEM_DEFAULT[BBCLogType.AREligibility], newItem || {}),
          BBC_LOG_FIELDS_TO_COMPARE[BBCLogType.AREligibility],
        ),
      ),
    )

    return diffs.reduce((result, updatedFields) => {
      updatedFields.forEach((field) => {
        if (!result[field]) {
          result[field] = 0
        }

        result[field]++
      })

      return result
    }, {})
  }, [bbcLog])

  return (
    <div className={styles.itemData}>
      <BBCLogsItemContentUsers bbcLog={bbcLog} />
      &nbsp;
      <b>updated</b>&nbsp;
      {Object.keys(updates).map((field, index) => (
        <React.Fragment key={field}>
          <b>{BBC_LOGS_AR_ELIGIBILITY_FIELD_LABEL_MAP[field]}</b>&nbsp; for <b>{updates[field]}</b>
          &nbsp;customer{updates[field] > 1 ? 's' : ''}
          {index === Object.keys(updates).length - 2
            ? ' and '
            : index !== Object.keys(updates).length - 1
            ? ', '
            : ''}
        </React.Fragment>
      ))}
    </div>
  )
}

const BBCLogsItemContentAREligibility = ({ bbcLog }: { bbcLog: IBBCLog }) => {
  if (bbcLog.debtorClientEligibility.length > 1) {
    return <BBCLogsItemContentAREligibilityMultiple bbcLog={bbcLog} />
  }

  return (
    <BBCLogsItemContentAREligibilitySingle
      bbcLog={bbcLog}
      debtorClientEligibility={bbcLog.debtorClientEligibility?.[0]}
    />
  )
}

export default BBCLogsItemContentAREligibility
