import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import CapTablePage from './CapTablePage'

import { ongoingReporting, isLoading } from '../../redux/ongoingReporting/selectors'
import { show as showClient } from '../../redux/clientInfo/actions'

import {
  showOngoingReporting,
  updateOngoingReportingStatus,
} from '../../redux/ongoingReporting/actions'

import { processCapTable, listCapTableFilters } from '../../redux/capTable/actions'
import { capTableFilters } from '../../redux/capTable/selectors'

const selector = createStructuredSelector({
  ongoingReporting,
  isLoading,
  capTableFilters,
})

const actions = {
  showOngoingReporting,
  processCapTable,
  showClient,
  updateOngoingReportingStatus,
  listCapTableFilters,
}

export { CapTablePage }

export default connect(selector, actions)(CapTablePage)
