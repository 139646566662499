import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import cn from 'classnames'
import Tooltip from '@mui/material/Tooltip'
import InfiniteScroll from 'react-infinite-scroll-component'

import styles from './BBCAPDetailRow.module.scss'
import genericSs from '@styles/generic.module.scss'

import ActiveToolbar from '../ActiveToolbar'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableFiltersRow from '../Common/TableFiltersRow'
import { IApSummaryStats, ReportingFlow } from '@common/interfaces/bbc'
import { debounceEventHandler, formatPrice, handleMultipleSelect } from '../../helpers/helpers'
import ChangedValue from '../ChangedValue'
import { BBC_AP_SUMMARY_DETAILS_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import TableLoader from '../Common/TableLoader'

interface IProps {
  apDetailStats: IApSummaryStats[]
  getBBCAPDetailStats: (id: string, data: object) => void
  filters: any
  recordDate: string
  isModalShown: boolean
  creditor: string
  reportingFlow: ReportingFlow
  clientName?: string
  compareDate?: string
}

const BBCAPDetailRow = ({
  apDetailStats,
  getBBCAPDetailStats,
  filters,
  isModalShown,
  recordDate,
  creditor,
  reportingFlow,
  clientName,
  compareDate,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)

  const [isLoading, setIsLoading] = useState(true)
  const [activeItem, setActiveItem] = useState<number>()
  const [activeItems, setActiveItems] = useState([])
  const [orderBy, setOrderBy] = useState({
    field: 'invoice',
    direction: 'ASC',
  })

  const isClientUserPage = useMemo(
    () => reportingFlow === ReportingFlow.ClientUserPage,
    [reportingFlow],
  )

  const filtersConfig = useMemo(
    () =>
      BBC_AP_SUMMARY_DETAILS_LIST_FILTERS_CONFIG.filter(
        (config) =>
          !isClientUserPage || (isClientUserPage && !['daysPastInvoice'].includes(config.field)),
      ),
    [isClientUserPage],
  )

  const handleSelectRow = useMemo(
    () => handleMultipleSelect(setActiveItems, setActiveItem, activeItems, activeItem),
    [activeItems, activeItem],
  )
  const resetActiveItems = useCallback(() => setActiveItems([]), [])

  const handleOrderChange = useCallback((field: string) => {
    setOrderBy((order) => ({
      field,
      direction: order.field === field ? (order.direction === 'DESC' ? 'ASC' : 'DESC') : 'ASC',
    }))
  }, [])

  const debounceListApSummary = useMemo(
    () =>
      debounceEventHandler(async (data: any) => {
        !data.loadMore && setIsLoading(true)
        await getBBCAPDetailStats(id, {
          ...data,
          recordDate,
          compareDate,
          creditor,
          reportingFlow,
          clientName,
          nestedRows: {
            keys: ['creditor'],
          },
        })
        setIsLoading(false)
      }, 500),
    [id, getBBCAPDetailStats, recordDate, creditor, reportingFlow, compareDate, clientName],
  )

  const loadMore = useCallback(() => {
    debounceListApSummary({
      loadMore: true,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      recordDate,
      page: Math.ceil(apDetailStats?.length / PER_PAGE),
    })
  }, [orderBy, filters, recordDate, debounceListApSummary, apDetailStats])

  const totalCount = useMemo(() => apDetailStats?.[0]?.totalCount, [apDetailStats])

  useEffect(() => {
    !apDetailStats &&
      debounceListApSummary({
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        page: 0,
      })
  }, [orderBy, filters, debounceListApSummary, apDetailStats])

  useEffect(() => {
    debounceListApSummary({
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      page: 0,
    })
  }, [orderBy, filters, debounceListApSummary])

  const totalRow = useMemo(
    () =>
      apDetailStats
        ?.filter((_, index) => activeItems.includes(index))
        .reduce(
          (totalRowResult, row) => {
            totalRowResult.daysPastInvoice += row.daysPastInvoice / activeItems?.length || 0
            totalRowResult.ap_1To_30Days += row.ap_1To_30Days || 0
            totalRowResult.ap_31To_60Days += row.ap_31To_60Days || 0
            totalRowResult.ap_61To_90Days += row.ap_61To_90Days || 0
            totalRowResult.ap_91PlusDays += row.ap_91PlusDays || 0
            totalRowResult.total += row.total || 0
            totalRowResult.percent += row.percent || 0
            return totalRowResult
          },
          {
            daysPastInvoice: 0,
            ap_1To_30Days: 0,
            ap_31To_60Days: 0,
            ap_61To_90Days: 0,
            ap_91PlusDays: 0,
            total: 0,
            percent: 0,
          },
        ),
    [activeItems, apDetailStats],
  )

  return (
    <TableContainer
      className={styles.table}
      onActiveRowsChange={setActiveItems}
      onActiveRowChange={setActiveItem}
      hasFooter
      size="small"
    >
      <Table ref={wrapperRef}>
        <TableHead>
          <TableFiltersRow
            filters={filtersConfig}
            orderBy={orderBy}
            handleOrderChange={handleOrderChange}
          />
        </TableHead>
        <TableBody id={creditor} className={styles.scrollableDiv}>
          {isLoading ? (
            <TableLoader columnsCount={filtersConfig?.length} />
          ) : (
            <InfiniteScroll
              dataLength={apDetailStats?.length || 0}
              next={loadMore}
              hasMore={apDetailStats?.length < totalCount}
              loader={<TableLoader columnsCount={filtersConfig?.length} rowsCount={1} />}
              scrollableTarget={creditor}
            >
              {apDetailStats?.map((item, index) => {
                return (
                  <TableRow
                    data-index={index}
                    className={cn('activableRow', {
                      activeRow: activeItems.includes(index),
                      currentActiveRow: activeItem === index,
                    })}
                    onClick={(event) => handleSelectRow(event, index)}
                  >
                    <TableCell className={genericSs.tableTextLeft}>
                      <Tooltip
                        title={item.memo || ''}
                        placement="top"
                        disableHoverListener={item.memo?.length < 10 || !item.memo}
                        disableTouchListener
                      >
                        <span>{item.memo}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={genericSs.tableTextLeft}>
                      <Tooltip
                        title={item.invoice || ''}
                        placement="top"
                        disableHoverListener={item.invoice?.length < 10 || !item.invoice}
                        disableTouchListener
                      >
                        <span>{item.invoice}</span>
                      </Tooltip>
                    </TableCell>
                    {!isClientUserPage && (
                      <TableCell className={genericSs.tableTextRight}>
                        <ChangedValue
                          className={styles.changedValue}
                          changedValue={item.daysPastInvoiceChanged}
                          isChip
                        />
                        {Math.round(+item?.daysPastInvoice)}
                      </TableCell>
                    )}
                    <TableCell className={genericSs.tableTextRight}>
                      {!isClientUserPage && (
                        <ChangedValue
                          className={styles.changedValue}
                          changedValue={item.ap_1To_30DaysChanged}
                          isChip
                        />
                      )}
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(item.ap_1To_30Days)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      {!isClientUserPage && (
                        <ChangedValue
                          className={styles.changedValue}
                          changedValue={item.ap_31To_60DaysChanged}
                          isChip
                        />
                      )}
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(item.ap_31To_60Days)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      {!isClientUserPage && (
                        <ChangedValue
                          className={styles.changedValue}
                          changedValue={item.ap_61To_90DaysChanged}
                          isChip
                        />
                      )}
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(item.ap_61To_90Days)}
                    </TableCell>
                    <TableCell className={genericSs.tableTextRight}>
                      {!isClientUserPage && (
                        <ChangedValue
                          className={styles.changedValue}
                          changedValue={item.ap_91PlusDaysChanged}
                          isChip
                        />
                      )}
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(item.ap_91PlusDays)}
                    </TableCell>
                    <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                      {!isClientUserPage && (
                        <ChangedValue
                          className={styles.changedValue}
                          changedValue={item.totalChanged}
                          isChip
                        />
                      )}
                      <span className={genericSs.pricePrefix}>$</span>
                      {formatPrice(item.total)}
                    </TableCell>
                    <TableCell className={cn(genericSs.tableTextRight, styles.totals)}>
                      {!isClientUserPage && (
                        <ChangedValue
                          className={styles.changedValue}
                          isChip
                          type="percent"
                          changedValue={item.percentChanged}
                        />
                      )}
                      {(item.percent || 0).toFixed(2)}%
                    </TableCell>
                  </TableRow>
                )
              })}{' '}
            </InfiniteScroll>
          )}
        </TableBody>
      </Table>
      <ActiveToolbar
        activeItems={activeItems}
        className={styles.activeToolbar}
        isFullscreen={isModalShown}
        containerRef={wrapperRef}
        resetActiveItems={resetActiveItems}
      >
        {!isClientUserPage && (
          <div className={genericSs.tableTextRight}>{Math.round(totalRow?.daysPastInvoice)}</div>
        )}
        <div className={genericSs.tableTextRight}>
          <span className={genericSs.pricePrefix}>$</span>
          {formatPrice(totalRow?.ap_1To_30Days)}
        </div>
        <div className={genericSs.tableTextRight}>
          <span className={genericSs.pricePrefix}>$</span>
          {formatPrice(totalRow?.ap_31To_60Days)}
        </div>
        <div className={genericSs.tableTextRight}>
          <span className={genericSs.pricePrefix}>$</span>
          {formatPrice(totalRow?.ap_61To_90Days)}
        </div>
        <div className={genericSs.tableTextRight}>
          <span className={genericSs.pricePrefix}>$</span>
          {formatPrice(totalRow?.ap_91PlusDays)}
        </div>
        <div className={cn(genericSs.tableTextRight, styles.totals)}>
          <span className={genericSs.pricePrefix}>$</span>
          {formatPrice(totalRow?.total)}
        </div>
        <div className={cn(genericSs.tableTextRight, styles.totals)}>
          {(totalRow?.percent || 0).toFixed(2)}%
        </div>
      </ActiveToolbar>
    </TableContainer>
  )
}

export default BBCAPDetailRow
