import { Box, Grid } from '@mui/material'
import React, { useMemo, useEffect } from 'react'
import LoanCommitteeReportHeader from '../../components/LoanCommitteeReportHeader'
import BBCARSummaryTable from '../../components/BBCARSummaryTable'
import { DUE_DILIGENCE_ROUTES_MAP, IDueDiligence } from '@common/interfaces/dueDiligence'
import { OngoingReportingType, ReportingFlow } from '@common/interfaces/bbc'
import { ROUTES } from '../../constants/routes'
import { generatePath, useParams } from 'react-router-dom'
import DilutionOverTime from '../../components/DilutionOverTime'
import CollectionsReconciliation from '../../components/CollectionsReconciliation'
import Card from '../../components/Common/Card'
import { GraphType } from '../../hooks/useGraphTogggle'
import LCRCommentary from '../../components/LCRCommentary'
import { LCRCommentarySections } from '@common/constants/lcrCommentary'

interface IProps {
  dueDiligenceInfo: IDueDiligence
  listDilutionDropdowns: (id: string, params: { reportingFlow: ReportingFlow }) => void
}

const LoanCommitteeReportReceivablesPage = ({
  dueDiligenceInfo,
  listDilutionDropdowns,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const { arEditLink, dilutionEditLink } = useMemo(
    () =>
      id && {
        arEditLink: generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_LIST, {
          id,
          type: DUE_DILIGENCE_ROUTES_MAP[OngoingReportingType.AR],
        }),
        dilutionEditLink: generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_LIST, {
          id,
          type: DUE_DILIGENCE_ROUTES_MAP[OngoingReportingType.ARGeneralLedger],
        }),
      },
    [id],
  )

  useEffect(() => {
    if (id && id === dueDiligenceInfo?.id) {
      listDilutionDropdowns(id, { reportingFlow: ReportingFlow.LoanCommitteeReport })
    }
  }, [id, dueDiligenceInfo, listDilutionDropdowns])

  return (
    <Box py={1} pr={2}>
      <LoanCommitteeReportHeader />
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <BBCARSummaryTable
            refreshCount={1}
            isFilesSaving={false}
            title={'Receivables'}
            reportingFlow={ReportingFlow.LoanCommitteeReport}
            dueDiligenceInfo={dueDiligenceInfo}
            editLink={arEditLink}
            fixedType={GraphType.Chart}
          />
        </Grid>
        <Grid item xs={12}>
          <BBCARSummaryTable
            refreshCount={1}
            isFilesSaving={false}
            title={'Receivables'}
            reportingFlow={ReportingFlow.LoanCommitteeReport}
            dueDiligenceInfo={dueDiligenceInfo}
            editLink={arEditLink}
            fixedType={GraphType.Table}
          />
        </Grid>

        <Grid item xs={12}>
          <LCRCommentary section={LCRCommentarySections.CollateralAnalysisAR} />
        </Grid>

        <Grid item xs={12}>
          <Card noHeaderMargin>
            <CollectionsReconciliation reportingFlow={ReportingFlow.LoanCommitteeReport} />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card noHeaderMargin>
            <DilutionOverTime
              reportingFlow={ReportingFlow.LoanCommitteeReport}
              editLink={dilutionEditLink}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LoanCommitteeReportReceivablesPage
