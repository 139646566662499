import React, { useCallback, useMemo, useState } from 'react'
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom'
import { CircularProgress, Menu, MenuItem } from '@mui/material'
import Modal from '../Common/Modal'
import { BoxLink, MenuIcon, SalesforceLink } from '../Common/Icons'
import SelectField from '../Common/SelectField'
import {
  IOPSReporting,
  OPSReportingStatus,
  ProspectRefreshType,
  SalesforceTypes,
} from '@common/interfaces/prospects'
import { ROUTES } from '../../constants/routes'
import ArchivedChip from '../Client/ArchivedChip'
import CodatSyncChip from '../Client/CodatSyncChip'
import styles from './ProspectHeaderLinks.module.scss'
import { PROSPECT_SALESFORCE_ARCHIVE_REASONS } from '@common/interfaces/prospects'
import { OPPORTUNITY_SALESFORCE_ARCHIVE_REASONS } from '@common/interfaces/dueDiligence'
import WarningModal from '../WarningModal'
import TextField from '../Common/TextField'
import { Form } from 'react-final-form'
import FormField from '../Common/FormField'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import { ClientInfoStatus } from '@common/interfaces/client'
import { usePermissions } from '../../helpers/permissionContext'
import Button from '../Common/Button'
import Box from '@mui/material/Box'
import { sortBy } from 'lodash'

const newOpsValidator = makeValidate(
  Yup.object().shape({
    type: Yup.string().required('Required'),
    salesforceLink: Yup.string().when('type', {
      is: 'ExistingLead',
      then: Yup.string().required('Required'),
    }),
  }),
)

const NewOPSOptions = [
  { value: 'NewLead', label: 'New Lead' },
  { value: 'ExistingLead', label: 'Existing Lead' },
]

enum Modals {
  UpdateStatus = 'updateStatus',
  ConvertToDiligence = 'convertToDiligence',
  ArchiveReason = 'archiveReason',
  CreateNewOPS = 'createNewOPS',
}

interface IProps {
  reporting: IOPSReporting
  update: (id: string, data: object) => Promise<any>
  show: (id: string) => void
  createNewOps: (id: string, data: object) => Promise<void>
  pullReports: (id: string, data: object) => Promise<void>
  currentBoxLink?: string
}

const ProspectHeaderLinks = ({
  reporting,
  update,
  show,
  createNewOps,
  pullReports,
  currentBoxLink,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const { pathname } = useLocation()
  const [modalsShown, setModalsShown] = useState([])
  const [archiveReason, setArchiveReason] = useState(null)
  const [reasonLost, setReasonLost] = useState(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [isSaving, setIsSaving] = useState(false)

  const isActionsMenuOpen = Boolean(anchorEl)

  const { isUW, isAdmin } = usePermissions()

  const handleUpdateProspect = useCallback(
    async (data: any) => {
      setIsSaving(true)
      const result = await update(id, data)
      if (!result.error) {
        show(id)

        setModalsShown([])
      }
      setIsSaving(false)
    },
    [update, id, show],
  )

  const readOnly = useMemo(
    () =>
      ![ClientInfoStatus.Prospect, ClientInfoStatus.Archived].includes(
        reporting?.clientInfo?.clientStatus,
      ) || isUW,
    [reporting, isUW],
  )

  const unEditable = useMemo(
    () => reporting?.status === OPSReportingStatus.Archived || readOnly,
    [reporting, readOnly],
  )

  const opsReportingVersions = useMemo(
    () =>
      sortBy(reporting?.clientInfo?.opsReportings, 'createdAt', 'desc').map((opsReporting) => ({
        value: opsReporting.id,
        label: opsReporting.salesforceProspectInfo?.name || opsReporting.clientName,
      })),
    [reporting],
  )

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const goToVersion = useCallback(
    ({ target: { value } }) => {
      history.push(pathname.replace(id, value))
    },
    [pathname, history, id],
  )

  const handleConvertToDiligenceConfirm = useCallback(async () => {
    await handleUpdateProspect({
      status: ClientInfoStatus.DueDiligence,
    })

    if (isUW || isAdmin) {
      history.push(generatePath(ROUTES.DUE_DILIGENCE, { id: reporting?.clientInfo?.id }))
    }
  }, [handleUpdateProspect, isUW, isAdmin, history, reporting?.clientInfo?.id])

  const handleConvertToDiligenceCancel = useCallback(() => {
    setModalsShown([])
  }, [])

  const handleChangeArchiveReason = useCallback(
    ({ target: { value } }) => {
      setArchiveReason(value)
    },
    [setArchiveReason],
  )

  const toggleArchiveReasonModalCancel = useCallback(() => {
    setArchiveReason(null)
    setModalsShown((modalShowns) => modalShowns.filter((modal) => modal !== Modals.ArchiveReason))
  }, [setModalsShown, setArchiveReason])

  const handleUpdateStatusCancel = useCallback(() => {
    setModalsShown([])
    toggleArchiveReasonModalCancel()
  }, [toggleArchiveReasonModalCancel])

  const handleChangeReasonLostText = useCallback(
    ({ target: { value } }) => {
      setReasonLost(value)
    },
    [setReasonLost],
  )

  const toggleUpdateStatusModalShown = useCallback(() => {
    setModalsShown([Modals.UpdateStatus])
    setAnchorEl(null)
  }, [])

  const toggleArchiveReasonModalShown = useCallback(() => {
    setModalsShown((modalShowns) => [...modalShowns, Modals.ArchiveReason])
    handleCloseMenu()
  }, [handleCloseMenu, setModalsShown])

  const toggleConvertToDiligenceModalShown = useCallback(() => {
    setModalsShown([Modals.ConvertToDiligence])
    setAnchorEl(null)
  }, [])

  const isLead = useMemo(
    () => reporting?.salesforceProspectInfo?.salesforceType === SalesforceTypes.Lead,
    [reporting],
  )

  const handleConfirmCreateNewOPS = useCallback(
    async (data?: object) => {
      setIsSaving(true)
      await createNewOps(id, data)
      setIsSaving(false)
      setModalsShown([])
    },
    [createNewOps, id],
  )

  const handleCreateNewOPS = useCallback(async () => {
    if (isLead) {
      await handleConfirmCreateNewOPS()
    } else {
      setModalsShown([Modals.CreateNewOPS])
    }
  }, [isLead, handleConfirmCreateNewOPS])

  const handleLaunchClientIntake = useCallback(() => {
    window.open(
      ROUTES.CLIENT_INTAKE.replace(
        ':salesforceId',
        reporting?.salesforceProspectInfo?.salesforceId,
      ),
      '_blank',
    )
    handleCloseMenu()
  }, [reporting, handleCloseMenu])

  const handleUpdateStatusConfirm = useCallback(async () => {
    await handleUpdateProspect({
      status:
        reporting?.status === OPSReportingStatus.Archived
          ? ClientInfoStatus.Prospect
          : ClientInfoStatus.Archived,
      archiveReason,
      reasonLost,
    })
    setReasonLost(null)
    toggleArchiveReasonModalCancel()
    history.push(generatePath(ROUTES.PROSPECT_PAGE, { id }))
  }, [
    handleUpdateProspect,
    reporting,
    toggleArchiveReasonModalCancel,
    archiveReason,
    reasonLost,
    history,
    id,
  ])

  const hasActiveOPSReporting = useMemo(
    () =>
      reporting?.clientInfo?.opsReportings?.filter(
        ({ status }) => status !== OPSReportingStatus.Archived,
      )?.length > 0,
    [reporting],
  )

  const handlePullCodatReports = useCallback(async () => {
    handleCloseMenu()
    if (reporting.refreshedAt) {
      return
    }
    await pullReports(id, { type: ProspectRefreshType.Codat })
  }, [handleCloseMenu, id, pullReports, reporting])

  const handlePullBoxReports = useCallback(async () => {
    handleCloseMenu()
    if (reporting.refreshedAt) {
      return
    }
    await pullReports(id, { type: ProspectRefreshType.Box })
  }, [handleCloseMenu, id, pullReports, reporting])

  const showRefreshCodat = useMemo(() => reporting?.clientInfo?.isErpConnected, [reporting])

  return (
    <>
      <ArchivedChip
        clientInfo={reporting?.clientInfo}
        isArchived={reporting?.status === OPSReportingStatus.Archived}
      />
      <CodatSyncChip clientInfo={reporting?.clientInfo} />
      {opsReportingVersions.length > 1 && (
        <SelectField
          label="Version"
          variant="outlined"
          className={styles.selectField}
          useFinalForm={false}
          name="compareDate"
          options={opsReportingVersions}
          onChange={goToVersion}
          value={reporting?.id}
          defaultValue=""
          withTopLabel
          selectSize="medium"
        />
      )}
      {reporting?.salesforceProspectInfo?.opsBoxLink && (
        <div>
          <BoxLink link={currentBoxLink || reporting?.salesforceProspectInfo?.opsBoxLink} />
        </div>
      )}
      {reporting?.salesforceProspectInfo?.salesforceLink && (
        <div>
          <SalesforceLink link={reporting?.salesforceProspectInfo?.salesforceLink} />
        </div>
      )}
      {!readOnly && <MenuIcon isActive={isActionsMenuOpen} onClick={handleClickMenu} />}
      <Menu open={isActionsMenuOpen} anchorEl={anchorEl} onClose={handleCloseMenu}>
        {showRefreshCodat && (
          <MenuItem
            disabled={unEditable}
            classes={{ root: styles.actionsMenuItem }}
            onClick={handlePullCodatReports}
          >
            Refresh Codat
          </MenuItem>
        )}
        <MenuItem
          disabled={unEditable}
          classes={{ root: styles.actionsMenuItem }}
          onClick={handlePullBoxReports}
        >
          Refresh Box
        </MenuItem>
        <MenuItem disabled={hasActiveOPSReporting} onClick={handleCreateNewOPS}>
          {isSaving ? <CircularProgress size={20} /> : 'Create New OPS'}
        </MenuItem>
        <MenuItem
          disabled={reporting?.status === OPSReportingStatus.Archived && hasActiveOPSReporting}
          onClick={
            reporting?.status === OPSReportingStatus.Archived
              ? toggleUpdateStatusModalShown
              : toggleArchiveReasonModalShown
          }
        >
          {reporting?.status === OPSReportingStatus.Archived ? 'Restore' : 'Archive'}
        </MenuItem>
        <MenuItem
          disabled={!reporting?.salesforceProspectInfo?.salesforceId}
          onClick={handleLaunchClientIntake}
        >
          Prospect View
        </MenuItem>
        {reporting?.status !== OPSReportingStatus.Archived && (
          <MenuItem
            disabled={reporting?.clientInfo?.clientStatus !== ClientInfoStatus.Prospect}
            onClick={toggleConvertToDiligenceModalShown}
          >
            Convert to Due Diligence
          </MenuItem>
        )}
      </Menu>
      {modalsShown.includes(Modals.UpdateStatus) && (
        <WarningModal
          onCancel={handleUpdateStatusCancel}
          warningMessage={`Are you sure you want to ${
            reporting?.status === OPSReportingStatus.Archived ? 'restore' : 'archive'
          } this client?`}
          onConfirm={handleUpdateStatusConfirm}
          isLoading={isSaving}
        />
      )}

      {modalsShown.includes(Modals.ConvertToDiligence) && (
        <WarningModal
          onCancel={handleConvertToDiligenceCancel}
          warningMessage={`Are you sure you want to convert this client to diligence?`}
          onConfirm={handleConvertToDiligenceConfirm}
          isLoading={isSaving}
        />
      )}

      {modalsShown.includes(Modals.ArchiveReason) && (
        <Modal
          open
          onCancel={toggleArchiveReasonModalCancel}
          classes={{ root: styles.modalRoot }}
          title="Archive prospect"
          footer={[
            <Button
              key="cancel"
              color="primary"
              variant="contained"
              small={false}
              onClick={toggleArchiveReasonModalCancel}
              secondary
            >
              Cancel
            </Button>,
            <Button
              isLoading={isSaving}
              key="submit"
              color="primary"
              variant="contained"
              small={false}
              onClick={toggleUpdateStatusModalShown}
              disabled={!archiveReason || !reasonLost}
            >
              Continue
            </Button>,
          ]}
        >
          <Box className={styles.fieldsContainer}>
            <SelectField
              name="archiveReason"
              useFinalForm={false}
              options={
                reporting?.salesforceProspectInfo?.salesforceType === SalesforceTypes.Opportunity
                  ? OPPORTUNITY_SALESFORCE_ARCHIVE_REASONS
                  : PROSPECT_SALESFORCE_ARCHIVE_REASONS
              }
              onChange={handleChangeArchiveReason}
              value={archiveReason}
              disabled={readOnly}
              selectSize="large"
              border
              placeholder="Select a reason"
            />
            <TextField
              name="reasonLost"
              className={styles.textFormField}
              useFinalForm={false}
              onChange={handleChangeReasonLostText}
              value={reasonLost}
              disabled={readOnly}
              multiline
              rows={3}
              placeholder="Please explain why you are archiving this prospect"
            />
          </Box>
        </Modal>
      )}
      {modalsShown.includes(Modals.CreateNewOPS) && (
        <Form
          onSubmit={handleConfirmCreateNewOPS}
          validate={newOpsValidator}
          initialValues={{
            type: '',
            salesforceLink: '',
          }}
          render={({ handleSubmit, values }) => (
            <Modal
              open
              onCancel={() => setModalsShown([])}
              classes={{ root: styles.modalRoot }}
              title="Create new OPS"
              footer={[
                <Button
                  key="cancel"
                  color="primary"
                  variant="contained"
                  small={false}
                  onClick={() => setModalsShown([])}
                  secondary
                >
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  color="primary"
                  variant="contained"
                  small={false}
                  onClick={handleSubmit}
                  isLoading={isSaving}
                >
                  Continue
                </Button>,
              ]}
            >
              <Box className={styles.fieldsContainer}>
                <FormField
                  label="Salesforce Integration"
                  name="type"
                  type="select"
                  options={NewOPSOptions}
                  placeholder="Salesforce Intergration"
                />
                {'type' in values && values.type === 'ExistingLead' && (
                  <FormField
                    label="Link"
                    name="salesforceLink"
                    type="text"
                    placeholder="Salesforce Link"
                  />
                )}
              </Box>
            </Modal>
          )}
        />
      )}
    </>
  )
}

export default ProspectHeaderLinks
