import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Skeleton from '@mui/material/Skeleton'
import Link from '@mui/material/Link'
import cn from 'classnames'

import genericSs from '@styles/generic.module.scss'
import styles from './ProspectReportingSummaryTermStructure.module.scss'

import { ILoadingData } from '../../redux/types'
import { IProspectTerms } from '@common/interfaces/prospects'
import { formatDate } from '../../helpers/helpers'
import { ROUTES } from '../../constants/routes'
import ProspectReportingSummaryTermStructureData from './ProspectReportingSummaryTermStructureData'

interface IProps {
  termStructureSummaryInfo: ILoadingData<{ data: IProspectTerms }>
  showTermStructureSummaryInfo: (id: string) => void
}

const ProspectReportingSummaryTermStructure = ({
  termStructureSummaryInfo,
  showTermStructureSummaryInfo,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  const { isLoading, termData } = useMemo(
    () => ({
      isLoading: termStructureSummaryInfo.isLoading,
      termData: termStructureSummaryInfo.data?.data,
    }),
    [termStructureSummaryInfo],
  )

  useEffect(() => {
    showTermStructureSummaryInfo(id)
  }, [showTermStructureSummaryInfo, id])

  if (termData && !termData.id) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Term Structure</div>

          <Link
            component={RouterLink}
            to={
              termData?.id
                ? generatePath(ROUTES.PROSPECT_EDIT_TERMS_PAGE, { id, termId: termData.id })
                : generatePath(ROUTES.PROSPECT_MANAGE_TERMS_PAGE, { id })
            }
            className={styles.editLink}
          >
            Edit
          </Link>
        </div>

        <div className={cn(styles.subTitle, genericSs.textCapitalize)}>
          {isLoading ? (
            <Skeleton className={styles.subTitleLoader} width={85} height={20} />
          ) : termData ? (
            `${termData.status || ''} - ${formatDate(termData.recordDate)}`
          ) : (
            '-'
          )}
        </div>
      </div>

      <ProspectReportingSummaryTermStructureData data={termData} />
    </div>
  )
}

export default ProspectReportingSummaryTermStructure
