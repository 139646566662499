import React, { useEffect, useMemo, useCallback } from 'react'
import styles from './BoxViewer.module.scss'
import cn from 'classnames'
import { ReactComponent as ArrowRight } from '@assets/images/arrow-right-circle-icon.svg'
import { historyChangeEventHandler } from '../../helpers/helpers'
import { useHistory, useLocation } from 'react-router'
import CircularProgress from '@mui/material/CircularProgress'

interface IProps {
  boxViewLink: string
  setBoxViewerLink: (link: string) => void
  isAdminLoggedAsClient: boolean
}

const BoxViewer = ({ boxViewLink, setBoxViewerLink, isAdminLoggedAsClient }: IProps) => {
  const onCancel = useCallback(() => {
    if (boxViewLink) {
      setBoxViewerLink('')
    }
  }, [boxViewLink, setBoxViewerLink])

  const isBoxViewerLinkReal = useMemo(() => {
    return boxViewLink.includes('https://')
  }, [boxViewLink])

  const history = useHistory()
  const { pathname } = useLocation()

  useEffect(() => {
    const unlisten = historyChangeEventHandler(onCancel, history, pathname)
    return unlisten
  }, [history, pathname, onCancel])

  return (
    <div
      className={cn(styles.customDrawer, {
        [styles.expanded]: !!boxViewLink,
        [styles.isAdminLoggedAsClient]: isAdminLoggedAsClient,
      })}
    >
      <ArrowRight className={cn(styles.modalClose)} onClick={onCancel} />
      <div className={cn(styles.modalBody)}>
        {isBoxViewerLinkReal ? (
          <iframe
            title="Dwight Funding"
            src={boxViewLink}
            width="100%"
            height="100%"
            allowFullScreen
            className={styles.iframe}
          />
        ) : (
          <div className={styles.loaderWrapper}>
            <CircularProgress color="primary" size={100} thickness={1} />
          </div>
        )}
      </div>
    </div>
  )
}

export default BoxViewer
