import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'
import { createRequestTypes } from '../../helpers/helpers'

export const prefix = 'bankTransactions'

export const LIST_TRANSACTIONS_REQUEST = `${prefix}/LIST_TRANSACTIONS_REQUEST`
export const LIST_TRANSACTIONS_SUCCESS = `${prefix}/LIST_TRANSACTIONS_SUCCESS`
export const LIST_TRANSACTIONS_FAILURE = `${prefix}/LIST_TRANSACTIONS_FAILURE`

export const listBankTransactions: CommonPromiseAction = (params: object) => ({
  type: [LIST_TRANSACTIONS_REQUEST, LIST_TRANSACTIONS_SUCCESS, LIST_TRANSACTIONS_FAILURE],
  promise: (api: IApi) => api.bankTransactions.listTransactions(params),
  params,
})

export const UPDATE_TRANSACTIONS_REQUEST = `${prefix}/UPDATE_TRANSACTIONS_REQUEST`
export const UPDATE_TRANSACTIONS_SUCCESS = `${prefix}/UPDATE_TRANSACTIONS_SUCCESS`
export const UPDATE_TRANSACTIONS_FAILURE = `${prefix}/UPDATE_TRANSACTIONS_FAILURE`

export const updateTransactions: CommonPromiseAction = (data: any) => ({
  type: [UPDATE_TRANSACTIONS_REQUEST, UPDATE_TRANSACTIONS_SUCCESS, UPDATE_TRANSACTIONS_FAILURE],
  promise: (api: IApi) => api.bankTransactions.updateTransactions(data),
})

export const LIST_TRANSACTION_DETAIL_REQUEST = `${prefix}/LIST_TRANSACTION_DETAIL_REQUEST`
export const LIST_TRANSACTION_DETAIL_SUCCESS = `${prefix}/LIST_TRANSACTION_DETAIL_SUCCESS`
export const LIST_TRANSACTION_DETAIL_FAILURE = `${prefix}/LIST_TRANSACTION_DETAIL_FAILURE`

export const listBankTransactionDetail: CommonPromiseAction = (params: object) => ({
  type: [
    LIST_TRANSACTION_DETAIL_REQUEST,
    LIST_TRANSACTION_DETAIL_SUCCESS,
    LIST_TRANSACTION_DETAIL_FAILURE,
  ],
  promise: (api: IApi) => api.bankTransactions.listBankTransactionDetail(params),
  params,
})

export const LIST_TRANSACTION_RAW = createRequestTypes(prefix, `LIST_TRANSACTION_RAW`)
export const [LIST_TRANSACTION_RAW_REQUEST] = LIST_TRANSACTION_RAW

export const listBankTransactionsRaw: CommonPromiseAction = (params: object) => ({
  type: LIST_TRANSACTION_RAW,
  promise: (api: IApi) => api.bankTransactions.listBankTransactionsRaw(params),
  params,
})

const EXPORT_BANK_TRANSACTIONS = createRequestTypes(prefix, 'EXPORT_BANK_TRANSACTIONS')
export const [
  EXPORT_BANK_TRANSACTIONS_REQUEST,
  EXPORT_BANK_TRANSACTIONS_SUCCESS,
  EXPORT_BANK_TRANSACTIONS_FAILURE,
] = EXPORT_BANK_TRANSACTIONS

export const exportBankTransactions: CommonPromiseAction = (params: object) => ({
  type: [
    EXPORT_BANK_TRANSACTIONS_REQUEST,
    EXPORT_BANK_TRANSACTIONS_SUCCESS,
    EXPORT_BANK_TRANSACTIONS_FAILURE,
  ],
  promise: (api: IApi) =>
    api.bankTransactions.listBankTransactionsRaw({
      ...params,
      isFile: true,
    }),
  params,
})

export const LIST_TRANSACTION_GRAPH = createRequestTypes(prefix, `LIST_TRANSACTION_GRAPH`)
export const [LIST_TRANSACTION_GRAPH_REQUEST] = LIST_TRANSACTION_GRAPH

export const listBankTransactionsGraph: CommonPromiseAction = (params: object) => ({
  type: LIST_TRANSACTION_GRAPH,
  promise: (api: IApi) => api.bankTransactions.listBankTransactionsGraph(params),
  params,
})

export const HIDE_TRANSACTIONS = `${prefix}/HIDE_TRANSACTIONS`

export const hideBankTransactionsData = () => ({
  type: HIDE_TRANSACTIONS,
})

export const PROCESS_BANK_TRANSACTIONS = createRequestTypes(prefix, 'PROCESS_BANK_TRANSACTIONS')
export const [
  PROCESS_BANK_TRANSACTIONS_REQUEST,
  PROCESS_BANK_TRANSACTIONS_SUCCESS,
  PROCESS_BANK_TRANSACTIONS_FAILURE,
] = PROCESS_BANK_TRANSACTIONS

export const processBankTransactions: CommonPromiseAction = (params: object) => ({
  type: PROCESS_BANK_TRANSACTIONS,
  promise: (api: IApi) => api.bankTransactions.processBankTransactions(params),
})

export const MAP_BANK_ACCOUNT = createRequestTypes(prefix, 'MAP_BANK_ACCOUNT')
export const [MAP_BANK_ACCOUNT_REQUEST, MAP_BANK_ACCOUNT_SUCCESS, MAP_BANK_ACCOUNT_FAILURE] =
  MAP_BANK_ACCOUNT

export const mapBankAccount: CommonPromiseAction = (params: object) => ({
  type: MAP_BANK_ACCOUNT,
  promise: (api: IApi) => api.bankTransactions.mapBankAccount(params),
})
